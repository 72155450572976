import { actionTabs } from '../constants';
import { productionStandSharedActions } from './productionStandShared.actions';

const productionStandSharedInitialState = {
    activeTab: actionTabs.execution,
    usersData: [],
    mediaOutputs: [],
    mediaInputs: [],
    documents: [],
    reportTabMedia: [],
};

export const productionStandSharedReducer = (state = productionStandSharedInitialState, { type, payload }) => {
    switch (type) {
        case productionStandSharedActions.SET_USERS_DATA: {
            return {
                ...state,
                usersData: payload,
            };
        }
        case productionStandSharedActions.SET_MEDIA_INPUTS: {
            return { ...state, mediaInputs: payload };
        }
        case productionStandSharedActions.SET_MEDIA_OUTPUTS: {
            return { ...state, mediaOutputs: payload };
        }
        case productionStandSharedActions.SET_MEDIA_DOCUMENTS: {
            return { ...state, documents: payload };
        }
        case productionStandSharedActions.SET_ACTIVE_TAB: {
            return { ...state, activeTab: payload };
        }
        case productionStandSharedActions.SET_REPORT_TAB_MEDIA: {
            return { ...state, reportTabMedia: payload };
        }
        case productionStandSharedActions.ADD_MEDIA_OUTPUT: {
            return {
                ...state,
                mediaOutputs: [...state.mediaOutputs, payload],
            };
        }
        case productionStandSharedActions.ADD_MEDIA_INPUTS: {
            return {
                ...state,
                mediaInputs: [...state.mediaInputs, payload],
            };
        }
        case productionStandSharedActions.SET_INITIAL_STATE_PRODUCTION_STAND_SHARED: {
            return productionStandSharedInitialState;
        }

        default:
            return state;
    }
};
