import React from 'react';

import { detect } from 'detect-browser';
import { Authenticator, SignUp, Greetings } from 'aws-amplify-react';
import { Hub } from 'aws-amplify';

import BrowserWarning from '../pages/BrowserWarning';
import SignInProvider from '../../providers/SignInProvider';
import ForgotPassword from '../common/authentication/ForgotPassword';
import Layouts from '../layouts';

/* Connect application to the store */
const withAuthenticator = ComposedComponent => {
    class hoc extends React.Component {
        state = {
            authState: '',
        };

        componentDidMount() {
            Hub.listen('auth', authData => {
                if (authData.payload.event === 'signOut') {
                    this.handleStateChange('signIn', null);
                }
            });
        }

        handleStateChange = authState => {
            this.setState({ authState });
        };

        render() {
            const browser = detect();

            if (browser && browser.name === 'ie') {
                return <BrowserWarning />;
            }

            return (
                <Authenticator hideDefault={true} hide={[Greetings, SignUp]} onStateChange={this.handleStateChange}>
                    <React.Fragment>
                        {this.state.authState !== 'signedIn' && (
                            <Layouts.Auth>
                                {this.state.authState === 'signIn' && (
                                    <SignInProvider {...this.props} onStateChange={this.handleStateChange} />
                                )}
                                {this.state.authState === '' && <SignInProvider {...this.props} onStateChange={this.handleStateChange} />}
                                {this.state.authState === 'forgotPassword' && (
                                    <ForgotPassword {...this.props} onStateChange={this.handleStateChange} />
                                )}
                            </Layouts.Auth>
                        )}
                        {this.state.authState === 'signedIn' && <ComposedComponent {...this.props} />}
                    </React.Fragment>
                </Authenticator>
            );
        }
    }

    return hoc;
};

export default withAuthenticator;
