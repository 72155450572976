import React from 'react';

// LIBS
import clsx from 'clsx';

// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { TextField as MuiTextField, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 4,
        '& .MuiInputBase-root': {
            background: theme.palette.input.background,
        },
        '& .MuiOutlinedInput-input': {
            fontSize: 16,
            lineHeight: '24px',
            padding: '16.5px 15px 16.5px 15px',
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        '& .Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `#f44336 !important`,
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.input.borderColor} !important`,
            '& legend': {
                width: 0,
            },
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.icon.color,
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.input.labelColor,
            '&.Mui-error': {
                color: theme.palette.input.labelErrorColor,
            },
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.input.labelColor,
        },
        '& .MuiInputLabel-outlined': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: 'calc(100% - 22px)',
            lineHeight: '18px',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                transform: 'translate(14px, 18px) scale(1)',
            },
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            transform: 'translate(14px, 9px) scale(0.7)',
        },
        '& .MuiChip-root': {
            height: 24,
            fontSize: 12,
            backgroundColor: theme.palette.input.chipColor,
        },
        '& .MuiOutlinedInput-multiline': {
            padding: 0,
        },
        '& .MuiAutocomplete-input': {
            padding: '7.5px 4px !important',
        },
        '& .MuiAutocomplete-endAdornment': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiFormHelperText-contained': {
            margin: 0,
        },
        [theme.breakpoints.down('xs')]: {
            borderRadius: 3,
        },
    },
    labeledInput: {
        '& .MuiOutlinedInput-root': {
            padding: 9,
            paddingTop: '20px !important',
            paddingBottom: '0px !important',
            '& .MuiInputBase-input': {
                padding: '6.5px 4px !important',
            },
        },
        '& .MuiAvatar-root': {
            width: 24,
            height: 24,
        },
    },
}));

const TextField = ({ placeholder, tooltip, select, ...inheritedProps }) => {
    const classes = useStyles();

    return (
        <Tooltip title={tooltip || ''} disableHoverListener={!tooltip} disableFocusListener={!tooltip} disableTouchListener={!tooltip}>
            <MuiTextField
                className={clsx(classes.root, inheritedProps.label ? classes.labeledInput : '', inheritedProps.label ? 'labeledInput' : '')}
                select={select}
                placeholder={placeholder}
                {...inheritedProps}
            />
        </Tooltip>
    );
};

export default TextField;
