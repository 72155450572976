import { useMutation } from '@apollo/client';
import { AppInsights } from 'applicationinsights-js';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GET_SINGLE_FILE, SAVE_USER_SETTINGS } from '../../../gqlQueries/queries';
import { store } from '../../../redux/store';
import { useJwtToken } from '../../../utils/awsAuth';
import { setCurrentUserData } from './store/userSettings.actions';
import { currentUserDataSelector } from './store/userSettings.selectors';

const userSettingsData = ComposedComponent => {
    return props => {
        const token = useJwtToken();

        useEffect(() => {
            if (process.env.REACT_APP_INSIGHTS_KEY) {
                AppInsights.trackPageView('User Settings', window.location.href);
            }
        }, []);

        const userData = useSelector(currentUserDataSelector);

        const [updateUser, { loading: userUpdateLoading }] = useMutation(SAVE_USER_SETTINGS, {
            onCompleted: data => {
                if (data?.updateUser?.user) {
                    store.dispatch(setCurrentUserData(data?.updateUser?.user));
                }
            },
        });

        const [fetchUserImage, { data: userImageData, loading: userImageLoading }] = useMutation(GET_SINGLE_FILE);

        const isLoading = Boolean(userUpdateLoading || userImageLoading);

        return (
            <React.Fragment>
                <ComposedComponent
                    {...props}
                    isLoading={isLoading}
                    user={userData}
                    userImageData={userImageData}
                    fetchUserImage={fetchUserImage}
                    updateUser={updateUser}
                    token={token}
                />
            </React.Fragment>
        );
    };
};

export default userSettingsData;
