export const projectDetailsActions = {
    SET_TAB_INDEX: 'PROJECT_DETAILS_OVERVIEW/SET_TAB_INDEX',
    RESET_STATE: 'PROJECT_DETAILS_OVERVIEW/RESET_STATE',
};

export const setProjectDetailsTabIndex = payload => {
    return {
        type: projectDetailsActions.SET_TAB_INDEX,
        payload,
    };
};

export const resetProjectDetailsState = payload => {
    return {
        type: projectDetailsActions.RESET_STATE,
        payload,
    };
};
