import { createSelector } from 'reselect';
import { globalActionTypes } from '../../../redux/actions';
import update from '../../../redux/update';

const actions = {
    clear: 'MAINTENANCE_STANDARD/TICKETS/CLEAR',
    changeValue: 'MAINTENANCE_STANDARD/TICKETS/CHANGE_VALUE',
    updateAssetData: 'MAINTENANCE_STANDARD/TICKETS/UPDATE_ASSET_DATA',
};

const initialState = {
    assetSelection: {
        assetData: {
            costCenter: null,
            parentAssetId: null,
            childAssetId: null,
        },
        costCenterOptions: [],
        assetParentOptions: [],
        assetChildrenOptions: [],
    },
};

export const maintenanceStandardTicketsReducer = (state = initialState, action) => {
    let newState = state;

    switch (action.type) {
        case globalActionTypes.generalClear:
        case actions.clear: {
            newState = initialState;
            break;
        }

        case actions.changeValue: {
            const { path, value } = action.payload;
            newState = update.set(state, path, value);
            break;
        }

        case actions.updateAssetData: {
            newState = {
                ...state,
                assetSelection: {
                    ...state.assetSelection,
                    assetData: {
                        ...state.assetSelection.assetData,
                        ...action.payload,
                    },
                },
            };

            break;
        }

        default:
            break;
    }

    return newState;
};

export const changeValueAction = (path, value) => {
    return {
        type: actions.changeValue,
        payload: {
            path,
            value,
        },
    };
};

export const updateAssetData = payload => {
    return {
        type: actions.updateAssetData,
        payload,
    };
};

export const stateClearAction = () => {
    return {
        type: actions.clear,
    };
};

export const selectAssetParentOptions = createSelector(
    state => state.maintenanceStandard.tickets.assetSelection,
    assetSelection => assetSelection?.assetParentOptions
);
export const selectCostCenterOptions = createSelector(
    state => state.maintenanceStandard.tickets.assetSelection,
    assetSelection => assetSelection?.costCenterOptions
);
export const selectAssetChildrenOptions = createSelector(
    state => state.maintenanceStandard.tickets.assetSelection,
    assetSelection => assetSelection?.assetChildrenOptions
);
export const selectAssetData = createSelector(
    state => state.maintenanceStandard.tickets.assetSelection,
    assetSelection => assetSelection?.assetData
);
