import { appLanguage } from '../../../../../constants/AppLanguage';
import { topBarActions } from './topBar.actions';

const topBarInitialState = {
    showTopbar: true,
    language: appLanguage.en,
};

export const topBarReducer = (state = topBarInitialState, { type, payload }) => {
    switch (type) {
        case topBarActions.HANDLE_TOP_BAR_VISIBILITY_CHANGE: {
            return {
                ...state,
                showTopbar: !state.showTopbar,
            };
        }
        case topBarActions.HANDLE_LANGUAGE_CHANGE: {
            return {
                ...state,
                language: payload.toUpperCase(),
            };
        }
        case topBarActions.SET_INITIAL_STATE_TOP_BAR: {
            return topBarInitialState;
        }

        default:
            return state;
    }
};
