export const leftBarActions = {
    SET_LEFT_BAR_COLLAPSED: 'LEFT_BAR/SET_LEFT_BAR_COLLAPSED',
    SET_INSPECTION_PLAN: 'LEFT_BAR/SET_INSPECTION_PLAN',
    SET_ACTIVE_GROUP: 'LEFT_BAR/SET_ACTIVE_GROUP',
    SET_ACTIVE_STEP: 'LEFT_BAR/SET_ACTIVE_STEP',
    SET_INSPECTION_LOCATION_FILTER: 'LEFT_BAR/SET_INSPECTION_LOCATION_FILTER',
    UPDATE_RESOLVED_TICKETS: 'LEFT_BAR/UPDATE_RESOLVED_TICKETS',
    CHANGE_SIGN_INSPECTION_REPORT_LEFT_BAR: 'LEFT_BAR/SIGN_INSPECTION_REPORT_LEFT_BAR',
    SET_INITIAL_STATE_LEFT_BAR: 'LEFT_BAR/SET_INITIAL_STATE_LEFT_BAR',
};

export const setLeftBarCollapsed = payload => ({
    type: leftBarActions.SET_LEFT_BAR_COLLAPSED,
    payload,
});

export const setInspectionPlan = payload => ({
    type: leftBarActions.SET_INSPECTION_PLAN,
    payload,
});

export const setActiveGroup = payload => ({
    type: leftBarActions.SET_ACTIVE_GROUP,
    payload,
});

export const setActiveStep = payload => ({
    type: leftBarActions.SET_ACTIVE_STEP,
    payload,
});

export const setInspectionLocationFilter = payload => ({
    type: leftBarActions.SET_INSPECTION_LOCATION_FILTER,
    payload,
});

export const updateResolvedTickets = payload => ({
    type: leftBarActions.UPDATE_RESOLVED_TICKETS,
    payload,
});

export const changeSignInspectionReportLeftBar = payload => ({
    type: leftBarActions.CHANGE_SIGN_INSPECTION_REPORT_LEFT_BAR,
    payload,
});

export const setInitialStateLeftBar = () => ({
    type: leftBarActions.SET_INITIAL_STATE_LEFT_BAR,
});
