import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import clsx from 'clsx';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LeftNavbarProvider from '../../providers/LeftNavbarProvider';
import ResultMessage from '../common/ResultMessage';
import TopNavbar from '../navigation/TopNavbar';
import UserSettingsModal from '../pages/userSettings/UserSettingsModal';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        paddingTop: 56
    },
    content: {
        width: "100%",
        "&.home": {
            backgroundColor: theme.palette.homeScreen.backgroundColor,
            paddingLeft: 0
        },
        "&.landing": {
            paddingLeft: 56,
            [theme.breakpoints.down("xs")]: {
                paddingLeft: 0
            }
        },
        "&.blur": {
            filter: "blur(0.3em)",
            pointerEvents: "none"
        }
    },
    innerContainer: {
        overflowX: "hidden",
        "&.offline": {
            paddingTop: 40
        }
    },
    offlineMessage: {
        display: "flex",
        position: "fixed",
        width: "100%",
        backgroundColor: theme.palette.warnMessage.background,
        alignItems: "center",
        padding: "0px 4px",
        zIndex: 999,
        "& .MuiSvgIcon-root": {
            margin: 8
        }
    }
}));

const GeneralContainer = props => {
    const classes = useStyles();
    const history = useHistory();

    const handleLandingClick = event => {
        if (isUserSettingsOpened) {
            setIsUserSettingsOpened(false);
        }

        if (isNavbarOpened) {
            setIsNavbarOpened(false);
        }

        if (props.onClick) {
            props.onClick(event);
        }
    };

    const isHomePage = () => {
        const currentLocation = history?.location?.pathname;
        return currentLocation === "/";
    };

    const [isNavbarOpened, setIsNavbarOpened] = useState(false);
    const [isUserSettingsOpened, setIsUserSettingsOpened] = useState(false);

    const isHome = isHomePage();

    return (
        <React.Fragment>
            <TopNavbar
                title={props.title}
                isOnline={props.isOnline}
                actionPanel={props.actionPanel}

                isNavbarOpened={isNavbarOpened}
                setIsNavbarOpened={setIsNavbarOpened}

                isUserSettingsOpened={isUserSettingsOpened}
                setIsUserSettingsOpened={setIsUserSettingsOpened}

                handleBackClick={props.handleBackClick}
            />
            <ResultMessage />
            {
                isUserSettingsOpened &&
                <UserSettingsModal />
            }
            <div className={classes.root} onClick={handleLandingClick}>
                <LeftNavbarProvider
                    open={isNavbarOpened}

                    showEditButton={props.showEditButton}
                    editModeActive={props.editModeActive}
                    setEditModeActive={props.setEditModeActive}
                />
                <div className={clsx(classes.content, isHome ? "home" : "landing", isNavbarOpened || isUserSettingsOpened ? "blur" : "")}>
                    {
                        !props.isOnline &&
                        <div className={classes.offlineMessage}>
                            <SignalWifiOffIcon />
                            {i18next.t("common.navigation-bar.offline-message")}
                        </div>
                    }
                    <div className={clsx(classes.innerContainer, !props.isOnline ? "offline" : "")}>
                        {props.children}
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

const mapState = ({ serviceWorker }) => {
    return {
        isOnline: serviceWorker.isOnline,
    };
}

const General = connect(mapState)(GeneralContainer);
export default General;
