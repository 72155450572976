import { defaultItemsPerPage } from '../constants/orderManagement.constants';
import { orderManagementActions } from './orderManagement.actions';

const orderManagementDefaultState = {
    filters: {
        searchTerm: '',
        selectedMaterialId: null,
        releaseStatus: null,
        scheduledReleaseDateFrom: null,
        scheduledReleaseDateTo: null,
        orderBy: null,
        archived: false,
        itemsPerPage: defaultItemsPerPage,
        page: 0,
    },
    showFilters: false,
    listItems: [],
};

export const orderManagementReducer = (state = orderManagementDefaultState, { type, payload }) => {
    switch (type) {
        case orderManagementActions.CHANGE_FILTER: {
            const { path, value } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    currentPage: 0,
                    [path]: value,
                },
            };
        }

        case orderManagementActions.SET_SEARCH_TERM: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    searchTerm: payload,
                },
            };
        }

        case orderManagementActions.SET_LIST_ITEMS: {
            return {
                ...state,
                listItems: payload,
            };
        }

        case orderManagementActions.TOGGLE_FILTER_VISIBILITY: {
            return {
                ...state,
                showFilters: !state?.showFilters,
            };
        }

        case orderManagementActions.CLEAR_FILTERS: {
            return {
                ...state,
                filters: { ...orderManagementDefaultState.filters, searchTerm: state.filters.searchTerm },
            };
        }

        case orderManagementActions.RESET_TO_INITIAL_STATE: {
            return orderManagementDefaultState;
        }

        default:
            return state;
    }
};
