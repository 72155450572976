import { viewType } from '../../../../../../constants/ViewType';
import { getElementItemByLanguage } from '../../../../utils/ordersCommon.utils';
import { ordersTimestampFilters } from '../../../../utils/ordersFilters.utils';
import { ordersFiltersActions } from './ordersFilters.actions';

const ordersFiltersInitialState = {
    isFilterButtonActive: false,
    searchTerm: '',
    timestamp: ordersTimestampFilters.fromLast2hTo2h,
    listViewTimestamp: ordersTimestampFilters.getOpenAll2h,
    ordersOverviewTimestamp: ordersTimestampFilters.fromLast2hTo12h,
    selectedStatus: null,
    zones: [],
    selectedZones: [],
    zonesChip: [],
    isZonesButtonActive: false,
    viewType: viewType.kanban,
    isDataLoading: false,
};

export const ordersFiltersReducer = (state = ordersFiltersInitialState, { type, payload }) => {
    switch (type) {
        case ordersFiltersActions.SET_SEARCH_TERM: {
            return {
                ...state,
                searchTerm: payload,
            };
        }
        case ordersFiltersActions.SET_ORDERS_TIMESTAMP: {
            return {
                ...state,
                timestamp: payload,
            };
        }
        case ordersFiltersActions.SET_ORDERS_TIMESTAMP_LIST_VIEW: {
            return {
                ...state,
                listViewTimestamp: payload,
            };
        }
        case ordersFiltersActions.SET_ORDERS_TIMESTAMP_ORDERS_OVERVIEW: {
            return {
                ...state,
                ordersOverviewTimestamp: payload,
            };
        }
        case ordersFiltersActions.SET_IS_FILTER_BUTTON_ACTIVE: {
            return {
                ...state,
                isFilterButtonActive: payload,
            };
        }
        case ordersFiltersActions.SET_ORDERS_SELECTED_STATUS: {
            return {
                ...state,
                selectedStatus: payload,
            };
        }
        case ordersFiltersActions.CLEAR_ORDERS_FILTER_BY_STATUS: {
            return {
                ...state,
                selectedStatus: null,
            };
        }
        case ordersFiltersActions.SET_ORDERS_SELECTED_ZONES: {
            return {
                ...state,
                selectedZones: payload,
            };
        }
        case ordersFiltersActions.SET_ORDERS_SELECTED_ZONES_CHIPS_FILTERS: {
            return {
                ...state,
                zonesChip: state.zonesChip.map(item => {
                    if (item.id !== payload) {
                        return item;
                    }

                    return {
                        ...item,
                        isActive: !item.isActive,
                    };
                }),
            };
        }
        case ordersFiltersActions.SET_ORDERS_ZONES_CHIPS: {
            return {
                ...state,
                zonesChip: payload.workplaces.map(zone => ({
                    id: zone._id,
                    label: getElementItemByLanguage(zone.workPlaceName, payload.language),
                    isActive: true,
                    capacity: zone.capacity.defaultCapacity,
                })),
            };
        }
        case ordersFiltersActions.SET_IS_ZONES_CHIP_FILTER_BUTTON_ACTIVE: {
            return {
                ...state,
                isZonesButtonActive: payload,
            };
        }
        case ordersFiltersActions.SET_ORDERS_VIEW: {
            return {
                ...state,
                viewType: payload,
            };
        }
        case ordersFiltersActions.SET_ORDERS_ZONES: {
            return {
                ...state,
                zones: payload,
            };
        }
        case ordersFiltersActions.CLEAR_ORDERS_FILTERS: {
            return {
                ...state,
                ...ordersFiltersInitialState,
            };
        }
        case ordersFiltersActions.SET_IS_DATA_LOADING: {
            return {
                ...state,
                isDataLoading: payload,
            };
        }

        default:
            return state;
    }
};
