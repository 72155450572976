import React, { useState } from 'react';

// LIBS
import { Auth } from 'aws-amplify';
import i18next from 'i18next';

// MATERIAL UI
import { Button, TextField } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

// UTILS
import update from '../../../redux/update';

const ForgotPassword = props => {
    const [model, setModel] = useState({
        email: "",
        code: "",
        password: ""
    });

    const [confirm, setConfirm] = useState(false);
    const [userException, setUserException] = useState(null);
    const [resetException, setResetException] = useState(null);

    const sendCode = async (model) => {
        try {
            const email = model.email && model.email.trim();

            if (email) {
                await Auth.forgotPassword(email);
                setConfirm(true);
                setUserException(null);
            }
        } catch (error) {
            setUserException(error);
        }
    };

    const sendNewPassword = async (model) => {
        try {
            const email = model.email && model.email.trim();
            const password = model.password && model.password.trim();
            const code = model.code && model.code.trim();

            if (email && code && password) {
                await Auth.forgotPasswordSubmit(email, code, password);
                props.onStateChange("signIn", {});
                setResetException(null);
            }
        } catch (error) {
            setResetException(error);
        }
    };

    const handleValueChange = (path, event) => {
        const value = event.target?.value || "";

        setModel(prev => {
            const newModel = update.set(prev, path, value);
            return newModel;
        });
    };

    const handleBackClick = () => {
        props.onStateChange("signIn", {});
    };

    const handleSubmit = e => {
        if (e.charCode === 13) {
            if (confirm) {
                sendNewPassword(model);
            } else {
                sendCode(model);
            }
        }
    };

    const getException = exception => {
        if (exception) {
            if (exception.code === "CodeMismatchException") {
                return {
                    codeException: exception.message
                };
            };

            if (exception.code === "InvalidParameterException") {
                return {
                    passwordException: i18next.t(`authentication.validation.password.invalidFormat`)
                };
            }

            return {
                generalException: exception.message
            };

        }
        return null;
    };

    const resetExceptionMessage = getException(resetException);

    return (
        <div className="login-info-outer-container">
            <div className="login-info-inner-container" onKeyPress={handleSubmit}>
                <div className="login-info-label">
                    {i18next.t(`authentication.forgotPassword.label`)}
                </div>
                {
                    !confirm &&
                    <React.Fragment>
                        <div className={`input ${userException ? "error" : ""}`}>
                            <TextField
                                label={i18next.t(`authentication.forgotPassword.email`)}
                                value={model.email || ""}
                                onChange={handleValueChange.bind({}, "email")}
                            />
                            {
                                userException &&
                                <div className="c-error"><ErrorIcon />{userException.message}</div>
                            }
                        </div>
                        <div className="login-info-actions">
                            <div className="action-link" onClick={handleBackClick}>
                                {i18next.t(`authentication.forgotPassword.back`)}
                            </div>
                            <div className="action-button">
                                <Button onClick={sendCode.bind({}, model)}>
                                    {i18next.t(`authentication.forgotPassword.send`)}
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>
                }
                {
                    confirm &&
                    <React.Fragment>
                        <div className={`input ${resetExceptionMessage?.codeException ? "error" : ""}`}>
                            <TextField
                                label={i18next.t(`authentication.forgotPassword.code`)}
                                value={model.code || ""}
                                onChange={handleValueChange.bind({}, "code")}
                            />
                            {
                                resetExceptionMessage?.codeException &&
                                <div className="c-error"><ErrorIcon />{resetExceptionMessage.codeException}</div>
                            }
                        </div>
                        <div className={`input ${resetExceptionMessage?.passwordException ? "error" : ""}`}>
                            <TextField
                                label={i18next.t(`authentication.forgotPassword.password`)}
                                type="password"
                                autoComplete="current-password"
                                value={model.password || ""}
                                onChange={handleValueChange.bind({}, "password")}
                            />
                            {
                                resetExceptionMessage?.passwordException &&
                                <div className="c-error"><ErrorIcon />{resetExceptionMessage.passwordException}</div>
                            }
                        </div>
                        {
                            resetExceptionMessage?.generalException &&
                            <div className="c-error"><ErrorIcon />{resetExceptionMessage.generalException}</div>
                        }
                        <div className="login-info-actions">
                            <div className="action-link" onClick={sendCode.bind({}, model)}>
                                {i18next.t(`authentication.forgotPassword.resendCode`)}
                            </div>
                            <div className="action-button">
                                <Button onClick={sendNewPassword.bind({}, model)}>
                                    {i18next.t(`authentication.forgotPassword.submit`)}
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>
                }

            </div>
        </div>
    );
}

export default ForgotPassword;