import React from 'react';
import UnknownError from '../pages/UnknownError';

const withErrorBoundary = ComposedComponent => {
    class hoc extends React.Component {
        constructor(props) {
            super(props);
            this.state = { error: null, errorInfo: null };
        }

        componentDidCatch(error, errorInfo) {
            this.setState({
                error: error,
                errorInfo: errorInfo
            })
        }

        render() {
            if (this.state.error) {
                return <UnknownError />
            }
            return <ComposedComponent {...this.props} />
        }
    }

    return hoc;
}

export default withErrorBoundary;