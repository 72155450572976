import { globalActionTypes } from "../../../redux/actions";
import update from "../../../redux/update";
import { mapJsonArrayModelToTemplateModel } from "../../../utils/mapperHelper";
import { get } from "lodash";

const actions = {
    init: "TEMPLATE/INIT",
    changeValue: "TEMPLATE/CHANGE_VALUE",
    changeMedia: "TEMPLATE/CHANGE_MEDIA",
    changeRadio: "TEMPLATE/CHANGE_RADIO",
    templateClear: "TEMPLATE/CLEAR"
};

const initialState = {};

// The main purpose is to store form data. 
export const templateReducer = (state = initialState, action) => {
    let newState = state;

    switch (action.type) {
        // Dispatch generalClear action to make sure, that data was cleared between pages
        case globalActionTypes.generalClear:
        case actions.templateClear: {
            newState = initialState;
            break;
        }

        // Dispatch this action to init values for the selected model
        case actions.init: {
            const inputs = mapJsonArrayModelToTemplateModel(action.payload.inputs);
            newState = update(state, {
                [action.payload.path]: {
                    $set: inputs
                }
            });
            break;
        }

        // Dispatch this action to change data for single inputs
        case actions.changeValue:
            newState = update.set(state, action.payload.id, {
                value: action.payload.value,
                groupId: action.payload.groupId,
                type: action.payload.type
            });
            break;

        case actions.changeMedia: {
            const { value, stepId, groupId, id } = action.payload;

            newState = update.set(state, `${stepId}.${id}`, {
                value: value,
                groupId: groupId
            });
            break;
        }

        // Dispatch this action to change data for radio inputs
        case actions.changeRadio: {
            // Get ids for current step
            const stepId = action.payload.id.split('.')[0];
            const filteredState = state[stepId] || {};
            const ids = Object.keys(filteredState) || [];
            const checked = get(state, `${action.payload.id}.value`);

            ids.forEach(id => {
                const obj = filteredState[id];
                // First, we need to set false for all other radio inputs in the selected group to make sure that only one is checked
                if (obj.groupId === action.payload.groupId && obj.type === action.payload.type) {
                    newState = update.set(newState, `${stepId}.${id}.value`, checked === "true" ? "" : "false");
                    newState = update.set(newState, `${stepId}.${id}.isEdited`, true);
                }
            })

            //Finally, set value
            newState = update.set(newState, action.payload.id, {
                value: checked !== "true" ? "true" : "",
                groupId: action.payload.groupId,
                type: action.payload.type,
                isEdited: true
            })
            break;
        }

        default: break;
    }

    return newState;
};

export const templateInitAction = (path, inputs) => {
    return {
        type: actions.init,
        payload: {
            path,
            inputs
        }
    }
}

export const changeValueAction = (id, value, groupId, type) => {
    return {
        type: actions.changeValue,
        payload: {
            id,
            value,
            groupId,
            type
        }
    };
};

export const changeMediaAction = (value, id, groupId, stepId) => {
    return {
        type: actions.changeMedia,
        payload: {
            value,
            id,
            groupId,
            stepId
        }
    };
};

export const changeRadioAction = (id, value, groupId, type) => {
    return {
        type: actions.changeRadio,
        payload: {
            id,
            value,
            groupId,
            type
        }
    };
};

export const templateClearAction = () => {
    return {
        type: actions.templateClear
    }
}