import { combineReducers } from 'redux';
import { fruitPreparationReducer } from './pages/fruitPreparation/store/fruitPreparation.reducer';
import { ordersReducer } from './pages/ordersOverview/store/orders.reducer';
import { ordersFiltersReducer } from './common/components/topBar/store/ordersFilters.reducer';
import { materialAllocationReducer } from './pages/materialAllocation/store/materialAllocation.reducer';

export const processReducer = combineReducers({
    ordersOverview: ordersReducer,
    fruitPreparation: fruitPreparationReducer,
    materialAllocation: materialAllocationReducer,
    filters: ordersFiltersReducer,
});