import germanLocale from '@uppy/locales/lib/de_DE';
import englishLocale from '@uppy/locales/lib/en_US';
import hungarianLocale from '@uppy/locales/lib/hu_HU';
import { language } from '../../../constants/Language';
import { AWS_AMPLIFY_DEFAULT_LANGUAGE } from '../../../utils/env';

export const uppyLocale = {
    [language.english]: englishLocale,
    [language.germany]: germanLocale,
    [language.hungarian]: hungarianLocale,
};

export const getUppyLocale = currentUserLanguage => {
    const language = currentUserLanguage || AWS_AMPLIFY_DEFAULT_LANGUAGE;
    return uppyLocale[language];
};
