import { sortBy, uniqBy } from 'lodash';
import moment from 'moment';

import { DEFAULT_LANGUAGE, DEFAULT_ZERO_VALUE, HHmm, NOT_APPLICABLE_VALUE } from '../common/constants/processContants';
import {
    criticalMinutes,
    OrdersBoardColumn,
    ProcessOrderColumnsStatus,
    ProcessStatusEnum,
} from '../pages/fruitPreparation/constants/processOrderConstants';

import { isArrayWithItems } from './../../../utils/arrayHelper';

export const getElementItemByLanguage = (array, language) => {
    if (isArrayWithItems(array)) {
        return array.find(x => x.language.toUpperCase() === language.toUpperCase())?.value || array.find(x => !!x.value)?.value || '';
    }

    return '';
};

export const getModelForKanbanView = (zones, orders) => {
    const model = [];

    zones.forEach(zone => {
        const obj = {};

        obj[OrdersBoardColumn.name] = zone.label;
        obj[OrdersBoardColumn.key] = zone.id;
        obj[OrdersBoardColumn.items] = {};

        const items = orders.filter(order => order.processRoutings[0]?.processSteps[0]?.workplaces[0]?._id === zone.id);

        Object.keys(ProcessOrderColumnsStatus).forEach(key => {
            const sortedItems = sortBy(
                items,
                [item => Date.parse(item.schedulingData.scheduledEndDate), item => +item.headerData.lotNumber],
                ['asc', 'asc']
            );

            const model = sortedItems
                .filter(order => ProcessOrderColumnsStatus[key].includes(order.headerData.orderStatus))
                ?.map(order => {
                    const isCriticalTicket = moment().isSameOrAfter(
                        moment(order?.schedulingData.latestStartDate).add(criticalMinutes, 'minutes')
                    );
                    const isOverdueTicket = moment().isAfter(
                        moment(order?.schedulingData.scheduledEndDate || order?.schedulingData.dueDate)
                    );

                    return {
                        order,
                        id: order._id,
                        status: order.headerData.orderStatus,
                        isCriticalTicket,
                        isOverdueTicket,
                    };
                });

            obj[OrdersBoardColumn.items][key] = uniqBy([...model], item => item.id);
        });

        const columnCapacity = obj[OrdersBoardColumn.items][ProcessOrderColumnsStatus[ProcessStatusEnum.IN_WORK]].length;
        const capacity = zone.capacity;

        obj[OrdersBoardColumn.capacity] = capacity;
        obj[OrdersBoardColumn.isDropDisabled] = columnCapacity >= capacity;

        const isCriticalColumn = obj[OrdersBoardColumn.items][ProcessOrderColumnsStatus[ProcessStatusEnum.IN_WORK]].some(
            order => order.isCriticalTicket
        );
        const isOverdueColumn = obj[OrdersBoardColumn.items][ProcessOrderColumnsStatus[ProcessStatusEnum.IN_WORK]].some(
            order => order.isOverdueTicket
        );

        if (isOverdueColumn) {
            obj[OrdersBoardColumn.columnColor] = {
                header: '#F5325C',
                body: 'rgba(245, 50, 92, 0.4)',
                icon: {
                    label: '#F3A5B6',
                    shadow: 'inset 0px 4px 10px rgba(0, 0, 0, 0.25)',
                    background: 'rgba(245, 50, 92, 0.7)',
                    render: () => (
                        <svg width="125" height="51" viewBox="0 0 125 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.839844" width="125" height="50.011" rx="1.30548" fill="#F5325C" />
                            <rect x="5" y="5.81641" width="35" height="22.8856" rx="1.30548" fill="#F3A5B6" />
                            <rect x="5.22168" y="36.5625" width="86.8146" height="9.74241" rx="1.30548" fill="#F3A5B6" />
                            <rect x="57" y="5.81641" width="35" height="22.8856" rx="1.30548" fill="#F3A5B6" />
                            <rect x="108.028" y="6.03711" width="9.79112" height="9.74241" rx="1.30548" fill="#F3A5B6" />
                            <rect x="108.028" y="36.5625" width="9.79112" height="9.74241" rx="1.30548" fill="#F3A5B6" />
                        </svg>
                    ),
                },
            };
        } else if (isCriticalColumn) {
            obj[OrdersBoardColumn.columnColor] = {
                header: '#EF7A12',
                body: '#FFCB9D',
                icon: {
                    label: '#FFCFA4',
                    shadow: 'inset 0px 4px 10px rgba(0, 0, 0, 0.25)',
                    background: '#FFA04D',
                    render: () => (
                        <svg width="125" height="51" viewBox="0 0 125 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="125" height="50.2611" rx="1.30548" fill="#EF7A12" />
                            <rect x="5" y="5" width="35" height="23" rx="1.30548" fill="#FFCB9D" />
                            <rect x="5.22168" y="35.9004" width="86.8146" height="9.79112" rx="1.30548" fill="#FFCB9D" />
                            <rect x="57" y="5" width="35" height="23" rx="1.30548" fill="#FFCB9D" />
                            <rect x="108.028" y="5.22266" width="9.79112" height="9.79112" rx="1.30548" fill="#FFCB9D" />
                            <rect x="108.028" y="35.9004" width="9.79112" height="9.79112" rx="1.30548" fill="#FFCB9D" />
                        </svg>
                    ),
                },
            };
        } else {
            obj[OrdersBoardColumn.columnColor] = {
                header: '#0EBF5A',
                body: '#B1EACA',
                icon: {
                    label: '#86F2B5',
                    shadow: 'inset 0px 4px 10px rgba(0, 0, 0, 0.25)',
                    background: '#4FD588',
                    render: () => (
                        <svg width="125" height="51" viewBox="0 0 125 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.472656" width="125" height="50.011" rx="1.30548" fill="#0EBF5A" />
                            <rect x="5" y="5.44922" width="35" height="22.8856" rx="1.30548" fill="#86F2B5" />
                            <rect x="5.22168" y="36.1953" width="86.8146" height="9.74241" rx="1.30548" fill="#86F2B5" />
                            <rect x="57" y="5.44922" width="35" height="22.8856" rx="1.30548" fill="#86F2B5" />
                            <rect x="108.028" y="5.66992" width="9.79112" height="9.74241" rx="1.30548" fill="#86F2B5" />
                            <rect x="108.028" y="36.1953" width="9.79112" height="9.74241" rx="1.30548" fill="#86F2B5" />
                        </svg>
                    ),
                },
            };
        }

        model.push(obj);
    });

    return model;
};

export const getListByElementLanguageInNestedArray = (array, nestedItem, language) => {
    if (!isArrayWithItems(array)) {
        return [];
    }

    return array
        .map(item => {
            let element = null;

            for (let i of item[nestedItem]) {
                if (i.language === language.toUpperCase()) {
                    element = { ...i, id: item._id };
                }
            }

            if (!element) {
                element = item[nestedItem]
                    .filter(i => i.language === DEFAULT_LANGUAGE)
                    .map(i => {
                        return { ...i, id: item._id };
                    })[DEFAULT_ZERO_VALUE];
            }

            return element;
        })
        .filter(item => item);
};

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    background: isDragging ? '#DBE0EB' : '#E8ECF2',
});

export const orderCustomFieldConverter = value => {
    switch (value) {
        case 'True':
            return true;
        case 'False':
            return false;
        default:
            return value;
    }
};

export const mapCustomFieldsToModel = customFields => {
    const model = {};

    customFields.forEach(f => {
        model[f.name.toLowerCase()] = orderCustomFieldConverter(f.value);
    });

    return model;
};

export const getWorkplaceName = (order, language) => {
    const workPlaceName = order?.resolvedProcessStep[0]?.resolvedWorkplaces[0]?.workPlaceName;

    return getElementItemByLanguage(workPlaceName, language);
};

export const mapOrderHeaderDataToRequestModel = headerData => {
    return {
        materialID: headerData.materialID,
        orderNo: headerData.orderNo,
        orderQty: headerData.orderQty,
        orderMinQty: headerData.orderMinQty,
        lotNumber: headerData.lotNumber,
        numberOfProductionLots: headerData.numberOfProductionLots,
        orderStatus: headerData.orderStatus,
        customFields: headerData.customFields.map(field => {
            return {
                name: field.name,
                value: field.value,
            };
        }),
    };
};

export const mapSchedulingDataDataToRequestModel = schedulingData => {
    return {
        dueDate: schedulingData.dueDate,
        demandDate: schedulingData.demandDate,
        latestStartDate: schedulingData.latestStartDate,
        scheduledEndDate: schedulingData.scheduledEndDate,
        actualStartDate: schedulingData.actualStartDate,
        confirmedEndDate: schedulingData.confirmedEndDate,
        estimatedDelay: {
            value: schedulingData.estimatedDelay.value,
            unit: schedulingData.estimatedDelay.unit,
        },
    };
};

export const getTicketClassNameByStatusAndTime = (orderStatus, isOverdue, isCritical) => {
    if (orderStatus === ProcessStatusEnum.IN_WORK) {
        if (isOverdue) {
            return `${ProcessStatusEnum.IN_WORK}_overdue`;
        }

        if (isCritical) {
            return `${ProcessStatusEnum.IN_WORK}_critical`;
        }

        return ProcessStatusEnum.IN_WORK;
    }

    if (isOverdue) {
        return `${ProcessStatusEnum.WAITING}_overdue`;
    }

    if (isCritical) {
        return `${ProcessStatusEnum.WAITING}_critical`;
    }

    return '';
};

export const orderReplace = order => {
    const refference = order.headerData.customFields.find(x => x.name === 'Reference');
    const orderNo = order.headerData.orderNo;
    const materialNumber = order.headerData?.resolvedMaterial?.materialNumber;

    return refference ? orderNo.replace(materialNumber, refference.value) : orderNo;
};

export const getReference = order => {
    const refference = order.headerData.customFields.find(x => x.name === 'Reference');

    return refference?.value || NOT_APPLICABLE_VALUE;
};

export const getMaterialNumber = order => {
    return order?.headerData.resolvedMaterial?.materialNumber;
};

export const getCroppedOrder = order => {
    const orderNo = order?.headerData.orderNo;

    if (!orderNo) {
        return null;
    }

    const index = orderNo.indexOf('-');

    return !!orderNo && !!index ? orderNo.substring(0, index) : NOT_APPLICABLE_VALUE;
};

export const getTopLevelProductionLine = order => {
    const workPlaceName = order.headerData.customFields.find(x => x.name === 'topLevelProductionLine');

    return workPlaceName?.value || '';
};

export const getOverdueTime = ({ scheduledEndDate, dueDate }) => {
    const date = scheduledEndDate || dueDate;

    const difference = moment().diff(moment(date));
    const duration = moment.duration(difference);
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (days) {
        return `${days}d${hours}h${minutes}m`;
    }

    if (hours) {
        return `${hours}h${minutes}m`;
    }

    return `${minutes}m`;
};

export const getProcessingTime = ({ scheduledEndDate, dueDate, latestStartDate }) => {
    const date = scheduledEndDate || dueDate;

    return [moment(latestStartDate).format(HHmm), moment(date).format(HHmm)];
};

export const roundOfNumberToTwoDigits = number => Math.round((number + Number.EPSILON) * 100) / 100;
