// Libs
import { times, get } from 'lodash';
import shortid from 'shortid';

// Utils
import createMongoObjectId from '../../../utils/createMongoObjID';
import update from '../../../redux/update';
import {
    mapDocumentationCharacteristicToTemplate,
    mapInputModelToTemplate,
    mapModelToTemplate,
    mapSectionModelToTemplate,
    setMultilanguageValue,
} from '../../../utils/protocolHelper';

// Constants
import { getElementSettings } from './constants/setup';
import { groups } from './constants/groups';
import { inputElements } from './constants/inputElements';
import { configurationType as configurationTypeConstant, configurationTypeSettings } from './constants/configurationType';
import { characteristics } from './constants/characteristics';
import { globalActionTypes } from '../../../redux/actions';
import { tabs } from './constants/tabs';
import { documentStatus } from '../../../constants/DocumentStatus';
import { revisionNumber } from '../../../constants/Revision';
import { isValueExists } from '../../../utils/valueHelper';
import { appLanguage } from '../../../constants/AppLanguage';
import { isArrayWithItems } from '../../../utils/arrayHelper';
import { store } from '../../../redux/store';

const getCurrentUserId = state => state?.currentUserData?._id;
const state = (store || null)?.preloadedState;

const actions = {
    initProtocolBuilder: 'PROTOCOL_BUILDER/INIT',
    setProtocolBuilderModel: 'PROTOCOL_BUILDER/SET_PROTOCOL_BUILDER_MODEL',
    setChangesSavedState: 'PROTOCOL_BUILDER/SET_CHANGES_SAVED_STATE',

    handleValueChange: 'PROTOCOL_BUILDER/HANDLE_VALUE_CHANGE',
    handleProtocolValueChange: 'PROTOCOL_BUILDER/HANDLE_PROTOCOL_VALUE_CHANGE',

    createProtocolSection: 'PROTOCOL_BUILDER/CREATE_SECTION',
    createHeaderProtocolSection: 'PROTOCOL_BUILDER/CREATE_HEADER_SECTION',
    createFooterProtocolSection: 'PROTOCOL_BUILDER/CREATE_FOOTER_SECTION',

    deleteProtocolSection: 'PROTOCOL_BUILDER/DELETE_PROTOCOL_SECTION',
    deleteProtocolSectionMediaOutput: 'PROTOCOL_BUILDER/DELETE_PROTOCOL_SECTION_MEDIA_OUTPUT',
    deleteProtocolSectionSignature: 'PROTOCOL_BUILDER/DELETE_PROTOCOL_SECTION_SIGNATURE',
    deleteProtocolSectionInputElement: 'PROTOCOL_BUILDER/DELETE_PROTOCOL_SECTION_INPUT_ELEMENT',

    moveProtocolSection: 'PROTOCOL_BUILDER/MOVE_PROTOCOL_SECTION',
    addProtocolSection: 'PROTOCOL_BUILDER/ADD_PROTOCOL_SECTION',
    removeBlankProtocolSection: 'PROTOCOL_BUILDER/REMOVE_BLANK_PROTOCOL_SECTION',
    relizeBlankProtocolSection: 'PROTOCOL_BUILDER/RELIZE_BLANK_PROTOCOL_SECTION',
    handleChangeProtocolSection: 'PROTOCOL_BUILDER/HANDLE_CHANGE_PROTOCOL_SECTION',

    gridRowsIncrement: 'PROTOCOL_BUILDER/GRID_ROWS_INCREMENT',
    gridRowsDecrement: 'PROTOCOL_BUILDER/GRID_ROWS_DECREMENT',
    gridRowsDelete: 'PROTOCOL_BUILDER/GRID_ROWS_DELETE',
    gridRowsMove: 'PROTOCOL_BUILDER/GRID_ROWS_MOVE',
    gridColumnsIncrement: 'PROTOCOL_BUILDER/GRID_COLUMNS_INCREMENT',
    gridColumnsDecrement: 'PROTOCOL_BUILDER/GRID_COLUMNS_DECREMENT',
    gridColumnsDelete: 'PROTOCOL_BUILDER/GRID_COLUMNS_DELETE',
    gridColumnsMove: 'PROTOCOL_BUILDER/GRID_COLUMNS_MOVE',

    listRowsIncrement: 'PROTOCOL_BUILDER/LIST_ROWS_INCREMENT',
    listRowsDecrement: 'PROTOCOL_BUILDER/LIST_ROWS_DECREMENT',
    listRowsDelete: 'PROTOCOL_BUILDER/LIST_ROWS_DELETE',

    moveDocumentationCharacteristic: 'PROTOCOL_BUILDER/MOVE_DOCUMENTATION_CHARACTERISTIC',
    dropDocumentationCharacteristic: 'PROTOCOL_BUILDER/DROP_DOCUMENTATION_CHARACTERISTIC',
    addDocumentationCharacteristic: 'PROTOCOL_BUILDER/ADD_DOCUMENTATION_CHARACTERISTIC',
    removeBlankDocumentationCharacteristic: 'PROTOCOL_BUILDER/REMOVE_BLANK_DOCUMENTATION_CHARACTERISTIC',
    relizeBlankDocumentationCharacteristic: 'PROTOCOL_BUILDER/RELIZE_BLANK_DOCUMENTATION_CHARACTERISTIC',

    handleChangeDocumentationCharacteristicSubtitle: 'PROCOOL_BUILDER/HANDLE_CHANGE_DOCUMENTATION_CHARACTERISTIC_SUBTITLE',

    moveInputElement: 'PROTOCOL_BUILDER/MOVE_INPUT_ELEMENT',
    moveInputElementBetweenGroups: 'PROTOCOL_BUILDER/MOVE_INPUT_ELEMENT_BETWEEN_GROUPS',
    addInputElement: 'PROTOCOL_BUILDER/ADD_INPUT_ELEMENT',
    dropInputElement: 'PROTOCOL_BUILDER/DROP_INPUT_ELEMENT',
    removeBlankInputElement: 'PROTOCOL_BUILDER/REMOVE_BLANK_INPUT_ELEMENT',
    relizeBlankInputElement: 'PROTOCOL_BUILDER/RELIZE_BLANK_INPUT_ELEMENT',

    addMediaOutputsSection: 'PROTOCOL_BUILDER/ADD_MEDIA_OUTPUTS_SECTION',
    removeBlankMediaOutputsSection: 'PROTOCOL_BUILDER/REMOVE_BLANK_MEDIA_OUTPUTS_SECTION',
    relizeBlankMediaOutputsSection: 'PROTOCOL_BUILDER/RELIZE_BLANK_MEDIA_OUTPUTS_SECTION',

    moveMediaOutputElement: 'PROTOCOL_BUILDER/MOVE_MEDIA_OUTPUT_ELEMENT',
    addMediaOutputElement: 'PROTOCOL_BUILDER/ADD_MEDIA_OUTPUT_ELEMENT',
    removeBlankMediaOutputElement: 'PROTOCOL_BUILDER/REMOVE_BLANK_MEDIA_OUTPUT_ELEMENT',
    relizeBlankMediaOutputElement: 'PROTOCOL_BUILDER/RELIZE_BLANK_MEDIA_OUTPUT_ELEMENT',

    addSignaturesSection: 'PROTOCOL_BUILDER/ADD_SIGNATURES_SECTION',
    removeBlankSignaturesSection: 'PROTOCOL_BUILDER/REMOVE_BLANK_SIGNATURES_SECTION',
    relizeBlankSignaturesSection: 'PROTOCOL_BUILDER/RELIZE_BLANK_SIGNATURES_SECTION',

    moveSignatureElement: 'PROTOCOL_BUILDER/MOVE_SIGNATURE_ELEMENT',
    addSignatureElement: 'PROTOCOL_BUILDER/ADD_SIGNATURE_ELEMENT',
    removeBlankSignatureElement: 'PROTOCOL_BUILDER/REMOVE_BLANK_SIGNATURE_ELEMENT',
    relizeBlankSignatureElement: 'PROTOCOL_BUILDER/RELIZE_BLANK_SIGNATURE_ELEMENT',

    handleChangeInputElement: 'PROTOCOL_BUILDER/HANDLE_CHANGE_INPUT_ELEMENT',
    handleChangeFormattedInputElement: 'PROTOCOL_BUILDER/HANDLE_CHANGE_FORMATTED_INPUT_ELEMENT',
    handleRadioChangeInputElement: 'PROTOCOL_BUILDER/HANDLE_RADIO_CHANGE_INPUT_ELEMENT',

    setSelectedElement: 'PROTOCOL_BUILDER/SET_SELECTED_ELEMENT',
    setSelectedElementConfigurations: 'PROTOCOL_BUILDER/SET_SELECTED_ELEMENT_CONFIGURATIONS',
    handleChangeSelectedElement: 'PROTOCOL_BUILDER/HANDLE_CHANGE_SELECTED_ELEMENT',
    handleCloseSelectedElement: 'PROTOCOL_BUILDER/HANDLE_CLOSE_SELECTED_ELEMENT',
    handleCloseSelectedElementConfigurations: 'PROTOCOL_BUILDER/HANDLE_CLOSE_SELECTED_ELEMENT_CONFIGURATIONS',
    handleDeleteSelectedElement: 'PROTOCOL_BUILDER/HANDLE_DELETE_SELECTED_ELEMENT',
    handleDuplicateSelectedElement: 'PROTOCOL_BUILDER/HANDLE_DUPLICATE_SELECTED_ELEMENT',
    handleSaveSelectedElement: 'PROTOCOL_BUILDER/HANDLE_SAVE_SELECTED_ELEMENT',

    setClipboardElement: 'PROTOCOL_BUILDER/SET_CLIPBOARD_ELEMENT',
    pasteElementFromClipboard: 'PROTOCOL_BUILDER/PASTE_ELEMENT_FROM_CLIPBOARD',

    addProtocolMedia: 'PROTOCOL_BUILDER/ADD_PROTOCOL_MEDIA',
    addProtocolTile: 'PROTOCOL_BUILDER/ADD_PROTOCOL_TILE',

    applyProtocolMetadataChanges: 'PROTOCOL_BUILDER/APPL_PROTOCOL_METADATA_CHANGES',

    addMyTemplate: 'PROTOCOL_BUILDER/ADD_MY_TEMPLATE',
    deleteMyTemplate: 'PROTOCOL_BUILDER/DELETE_MY_TEMPLATE',
};

const getInitialStorageState = () => {
    return {
        protocolSections: [],
        documentationCharacteristics: [],
        inputElements: [],
    };
};

const getInitialState = () => {
    return {
        inited: false,
        activeTab: tabs.edit,
        language: appLanguage.en,
        typeOfProtocol: 'protocol',
        protocolTemplate: {
            _id: createMongoObjectId(),
            protocolSection: [],
            metadata: {
                templateName: null,
                technicalReference: null,
                templateApprover: null,
                documentStatus: documentStatus.draft,
                revisionHistory: [
                    {
                        editor: { accountID: getCurrentUserId(state) },
                        revisionDate: new Date(),
                        revisionNumber: revisionNumber[0],
                    },
                ],
                revisionNumber: revisionNumber[0],
                revisionEditor: getCurrentUserId(state),
                responsibleDepartment: null,
                legalOwner: null,
                dateOfIssue: null,
                documentType: null,
                legalOwnerLogo: null,
            },
        },
        myTemplates: getInitialStorageState(),
        isHeaderExist: false,
        isFooterExist: false,
        isBlankProtocolSectionExist: false,
        selectedElement: {
            protocolSectionId: null,
            documentationCharacteristicId: null,
            inputElementId: null,
            signatureElementId: null,
            mediaOutputId: null,
            configurationType: null,
        },
        selectedElementConfigurations: {
            open: false,
            language: null,
        },
        clipboard: {
            _id: null,
            elementType: null,
            configurationType: null,
        },
        metadataModal: {
            isOpen: false,
            initialOpen: false,
        },
        changesSaved: true,
        isMediaUploadModalOpen: false,
        protocolTileData: [],
        protocolTileDataLoading: false,
        usersData: [],
        usersDataLoading: true,
    };
};

// The main purpose is to store form data.
export const templateBuilderReducer = (state = getInitialState(), action) => {
    let newState = { ...state };

    const elementSettings = getElementSettings();

    switch (action.type) {
        // Dispatch generalClear action to make sure, that data was cleared between pages
        case globalActionTypes.generalClear:
            newState = getInitialState();
            break;

        case actions.initProtocolBuilder: {
            newState = {
                ...state,
                myTemplates: {
                    ...state.myTemplates,
                    protocolSections: action.payload
                        ?.filter(template => template.protocolSection)
                        ?.map(template => ({
                            ...mapSectionModelToTemplate(template.protocolSection),
                            templateId: template._id,
                            templateName: template.templateName,
                        })),
                    documentationCharacteristics: action.payload
                        ?.filter(template => template.documentationCharacteristic)
                        ?.map(template => ({
                            ...mapDocumentationCharacteristicToTemplate(template.documentationCharacteristic),
                            templateId: template._id,
                            templateName: template.templateName,
                        })),
                    inputElements: action.payload
                        ?.filter(template => template.inputElement)
                        ?.map(template => ({
                            ...mapInputModelToTemplate(template.inputElement),
                            templateId: template._id,
                            templateName: template.templateName,
                        })),
                },
            };

            break;
        }

        case actions.addMyTemplate: {
            const { configurationType, newTemplate } = action.payload;

            if (configurationType === configurationTypeConstant.protocolSection) {
                newState = {
                    ...state,
                    myTemplates: {
                        ...state.myTemplates,
                        protocolSections: [
                            ...state.myTemplates.protocolSections,
                            {
                                ...mapSectionModelToTemplate(newTemplate.protocolSection),
                                templateId: newTemplate._id,
                                templateName: newTemplate.templateName,
                            },
                        ],
                    },
                };

                break;
            }

            if (configurationType === configurationTypeConstant.documentationCharacteristic) {
                newState = {
                    ...state,
                    myTemplates: {
                        ...state.myTemplates,
                        documentationCharacteristics: [
                            ...state.myTemplates.documentationCharacteristics,
                            {
                                ...mapDocumentationCharacteristicToTemplate(newTemplate.documentationCharacteristic),
                                templateId: newTemplate._id,
                                templateName: newTemplate.templateName,
                            },
                        ],
                    },
                };

                break;
            }

            if (configurationType === configurationTypeConstant.inputElement) {
                newState = {
                    ...state,
                    myTemplates: {
                        ...state.myTemplates,
                        inputElements: [
                            ...state.myTemplates.inputElements,
                            {
                                ...mapInputModelToTemplate(newTemplate.inputElement),
                                templateId: newTemplate._id,
                                templateName: newTemplate.templateName,
                            },
                        ],
                    },
                };

                break;
            }

            break;
        }
        case actions.deleteMyTemplate: {
            const { configurationType, templateId } = action.payload;

            if (configurationType === configurationTypeConstant.protocolSection) {
                newState = {
                    ...state,
                    myTemplates: {
                        ...state.myTemplates,
                        protocolSections: state.myTemplates.protocolSections.filter(template => template.templateId !== templateId),
                    },
                };

                break;
            }

            if (configurationType === configurationTypeConstant.documentationCharacteristic) {
                newState = {
                    ...state,
                    myTemplates: {
                        ...state.myTemplates,
                        documentationCharacteristic: state.myTemplates.documentationCharacteristic?.filter(
                            template => template.templateId !== templateId
                        ),
                    },
                };

                break;
            }

            if (configurationType === configurationTypeConstant.inputElement) {
                newState = {
                    ...state,
                    myTemplates: {
                        ...state.myTemplates,
                        inputElements: state.myTemplates.inputElements.filter(template => template.templateId !== templateId),
                    },
                };

                break;
            }

            break;
        }
        case actions.setProtocolBuilderModel: {
            const template = mapModelToTemplate(action.payload.model, action.payload.operation);

            const isHeaderExist = template.protocolSection.some(section => section.type === groups.title);
            const isFooterExist = template.protocolSection.some(section => section.type === groups.footer);

            newState = update(state, {
                protocolTemplate: { $set: template },
                inited: { $set: true },
                isHeaderExist: { $set: isHeaderExist },
                isFooterExist: { $set: isFooterExist },
            });
            break;
        }
        case actions.setChangesSavedState: {
            const { value } = action.payload;
            newState = update.set(state, 'changesSaved', value);
            break;
        }

        case actions.handleValueChange: {
            const { path, value, allowTrackChanges } = action.payload;
            newState = update.set(state, path, value);
            if (allowTrackChanges) {
                newState = update.set(newState, 'changesSaved', false);
            }
            break;
        }

        case actions.handleProtocolValueChange: {
            const { path, value, allowLanguages } = action.payload;

            let newValue = value;

            if (allowLanguages) {
                const oldValue = get(state.protocolTemplate, path) || [];
                newValue = setMultilanguageValue(value, oldValue, state.language);
            }

            newState.protocolTemplate = update.set(state.protocolTemplate, path, newValue);
            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.createProtocolSection: {
            const { section } = action.payload;
            const { value, template } = section;

            const newElement = template
                ? {
                      ...template,
                      _id: createMongoObjectId(),
                      key: shortid.generate(),
                      documentationCharacteristic: template.documentationCharacteristic?.map(characteristic => {
                          return {
                              ...characteristic,
                              _id: createMongoObjectId(),
                              inputElement: characteristic.inputElement?.map(input => {
                                  return {
                                      ...input,
                                      _id: createMongoObjectId(),
                                      key: shortid.generate(),
                                  };
                              }),
                          };
                      }),
                      mediaOutputs: template.mediaOutputs?.map(mediaOutput => {
                          return {
                              ...mediaOutput,
                              _id: createMongoObjectId(),
                              key: shortid.generate(),
                          };
                      }),
                  }
                : elementSettings[value].getInitialState(createMongoObjectId());

            if (state.isFooterExist) {
                newState = update(state, {
                    protocolTemplate: {
                        protocolSection: {
                            $splice: [[state.protocolTemplate.protocolSection.length - 1, 0, newElement]],
                        },
                    },
                });
                break;
            }

            newState = update(state, {
                protocolTemplate: {
                    protocolSection: {
                        $push: [newElement],
                    },
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.createHeaderProtocolSection: {
            const { section } = action.payload;
            const { value, template } = section;

            const newElement = template
                ? {
                      ...template,
                      _id: createMongoObjectId(),
                      key: shortid.generate(),
                  }
                : elementSettings[value].getInitialState(createMongoObjectId());

            newState = update(state, {
                protocolTemplate: {
                    protocolSection: {
                        $splice: [[0, 0, newElement]],
                    },
                },
                isHeaderExist: { $set: true },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.createFooterProtocolSection: {
            const { section } = action.payload;
            const { value, template } = section;

            const newElement = template
                ? {
                      ...template,
                      _id: createMongoObjectId(),
                      key: shortid.generate(),
                  }
                : elementSettings[value].getInitialState(createMongoObjectId());

            newState = update(state, {
                protocolTemplate: {
                    protocolSection: {
                        $push: [newElement],
                    },
                },
                isFooterExist: { $set: true },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.deleteProtocolSection: {
            const { section } = action.payload;
            const index = state.protocolTemplate.protocolSection.findIndex(el => el._id === section._id);

            newState = update(state, {
                protocolTemplate: {
                    protocolSection: {
                        $splice: [[index, 1]],
                    },
                },
            });

            if (section.value === groups.title) {
                newState = update.set(newState, 'isHeaderExist', false);
            }
            if (section.value === groups.footer) {
                newState = update.set(newState, 'isFooterExist', false);
            }

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.deleteProtocolSectionMediaOutput: {
            const { mediaOutput } = action.payload;

            const index = state.protocolTemplate.protocolSection.findIndex(el => el._id === mediaOutput.sectionId);
            if (state.protocolTemplate.protocolSection[index].mediaOutputs.length === 1) {
                newState = update(state, {
                    protocolTemplate: {
                        protocolSection: {
                            [index]: {
                                mediaOutputs: {
                                    $set: null,
                                },
                            },
                        },
                    },
                });
                break;
            }

            const mediaIndex = state.protocolTemplate.protocolSection[index].mediaOutputs.findIndex(el => el._id === mediaOutput._id);
            newState = update(state, {
                protocolTemplate: {
                    protocolSection: {
                        [index]: {
                            mediaOutputs: {
                                $splice: [[mediaIndex, 1]],
                            },
                        },
                    },
                },
            });

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.deleteProtocolSectionSignature: {
            const { signature } = action.payload;

            const index = state.protocolTemplate.protocolSection.findIndex(el => el._id === signature.sectionId);
            if (state.protocolTemplate.protocolSection[index].signedBy.length === 1) {
                newState = update(state, {
                    protocolTemplate: {
                        protocolSection: {
                            [index]: {
                                signedBy: {
                                    $set: null,
                                },
                            },
                        },
                    },
                });
                break;
            }

            const signatureIndex = state.protocolTemplate.protocolSection[index].signedBy.findIndex(el => el._id === signature._id);
            newState = update(state, {
                protocolTemplate: {
                    protocolSection: {
                        [index]: {
                            signedBy: {
                                $splice: [[signatureIndex, 1]],
                            },
                        },
                    },
                },
            });

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.deleteProtocolSectionInputElement: {
            const { inputElement } = action.payload;

            const elementIndex = state.protocolTemplate.protocolSection.findIndex(el => el._id === inputElement.sectionId);
            const documentationCharacteristicIndex = state.protocolTemplate.protocolSection[
                elementIndex
            ].documentationCharacteristic.findIndex(el => el._id === inputElement.characteristicId);
            const inputIndex = state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                documentationCharacteristicIndex
            ].inputElement.findIndex(el => el._id === inputElement._id);

            newState.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[documentationCharacteristicIndex] = update(
                state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[documentationCharacteristicIndex],
                {
                    inputElement: {
                        $splice: [[inputIndex, 1]],
                    },
                }
            );
            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.moveProtocolSection: {
            const { dragIndex, hoverIndex } = action.payload;
            const dragElement = state.protocolTemplate.protocolSection[dragIndex];

            newState = update(state, {
                protocolTemplate: {
                    protocolSection: {
                        $splice: [
                            [dragIndex, 1],
                            [hoverIndex, 0, dragElement],
                        ],
                    },
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.addProtocolSection: {
            const { index, type, template } = action.payload;

            const newElement = template
                ? {
                      ...template,
                      _id: undefined,
                      key: shortid.generate(),
                      documentationCharacteristic: template.documentationCharacteristic?.map(characteristic => {
                          return {
                              ...characteristic,
                              _id: createMongoObjectId(),
                              inputElement: characteristic.inputElement?.map(input => {
                                  return {
                                      ...input,
                                      _id: createMongoObjectId(),
                                      key: shortid.generate(),
                                  };
                              }),
                          };
                      }),
                      mediaOutputs: template.mediaOutputs?.map(mediaOutput => {
                          return {
                              ...mediaOutput,
                              _id: createMongoObjectId(),
                              key: shortid.generate(),
                          };
                      }),
                  }
                : elementSettings[type].getInitialState();

            newState = update(state, {
                protocolTemplate: {
                    protocolSection: {
                        $splice: [[index, 0, newElement]],
                    },
                },
                isBlankProtocolSectionExist: { $set: true },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.removeBlankProtocolSection: {
            const index = state.protocolTemplate.protocolSection.findIndex(el => el._id === undefined);
            if (index !== -1) {
                newState = update(state, {
                    protocolTemplate: {
                        protocolSection: {
                            $splice: [[index, 1]],
                        },
                    },
                    isBlankProtocolSectionExist: { $set: false },
                });
            }
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.relizeBlankProtocolSection: {
            const index = state.protocolTemplate.protocolSection.findIndex(el => el._id === undefined);

            if (index !== -1) {
                const protocolSection = state.protocolTemplate.protocolSection[index];

                newState = update(state, {
                    protocolTemplate: {
                        protocolSection: {
                            [index]: {
                                _id: { $set: createMongoObjectId() },
                            },
                        },
                    },
                    isBlankProtocolSectionExist: { $set: false },
                });

                if (protocolSection.type === groups.title) {
                    newState = update.set(newState, 'isHeaderExist', true);
                }
                if (protocolSection.type === groups.footer) {
                    newState = update.set(newState, 'isFooterExist', true);
                }
            }

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.handleChangeProtocolSection: {
            const { index, path, value, formattedValue, allowLanguages, language } = action.payload;

            let newValue = value;

            if (allowLanguages) {
                const oldValue = get(state.protocolTemplate.protocolSection[index], path) || [];
                newValue = setMultilanguageValue(value, oldValue, language || state.language);
                if (isValueExists(formattedValue)) {
                    newValue = setMultilanguageValue(formattedValue, newValue, language || state.language, 'formattedValue');
                }
            }

            newState.protocolTemplate.protocolSection[index] = update.set(state.protocolTemplate.protocolSection[index], path, newValue);
            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.gridRowsIncrement: {
            const { index } = action.payload;
            const { rows, columns } = state.protocolTemplate.protocolSection[index];

            newState.protocolTemplate.protocolSection[index] = update(state.protocolTemplate.protocolSection[index], {
                rows: { $set: rows + 1 },
                documentationCharacteristic: {
                    $push: times(columns, columnIndex => {
                        const documentationCharacteristic = state.protocolTemplate.protocolSection[index].documentationCharacteristic.find(
                            item => item.row === rows && item.column === columnIndex + 1
                        );

                        return {
                            _id: createMongoObjectId(),
                            row: rows + 1,
                            column: columnIndex + 1,
                            highlighted: documentationCharacteristic.highlighted,
                            autoFillValue: documentationCharacteristic.autoFillValue,
                            inputElement: documentationCharacteristic.inputElement.map(item => {
                                return {
                                    ...item,
                                    _id: createMongoObjectId(),
                                    key: shortid.generate(),
                                };
                            }),
                        };
                    }),
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.gridRowsDecrement: {
            const { index } = action.payload;
            const { rows, documentationCharacteristic } = state.protocolTemplate.protocolSection[index];

            newState.protocolTemplate.protocolSection[index] = update(state.protocolTemplate.protocolSection[index], {
                rows: { $set: rows - 1 },
                documentationCharacteristic: {
                    $set: documentationCharacteristic.filter(item => item.row !== rows),
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.gridRowsDelete: {
            const { sectionIndex, index } = action.payload;
            const { rows, documentationCharacteristic } = state.protocolTemplate.protocolSection[sectionIndex];

            newState.protocolTemplate.protocolSection[sectionIndex] = update(newState.protocolTemplate.protocolSection[sectionIndex], {
                rows: { $set: rows - 1 },
                documentationCharacteristic: {
                    $set: documentationCharacteristic.filter(item => item.row !== index),
                },
            });

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic.forEach(
                (characteristic, characteristicIndex) => {
                    if (characteristic.row > index) {
                        const characteristicRow =
                            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].row;

                        newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex] = update(
                            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex],
                            {
                                row: {
                                    $set: characteristicRow - 1,
                                },
                            }
                        );
                    }
                }
            );

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.gridColumnsIncrement: {
            const { index } = action.payload;
            const { rows, columns, type } = state.protocolTemplate.protocolSection[index];

            const isTableSection = type === groups.table;

            newState.protocolTemplate.protocolSection[index] = update(state.protocolTemplate.protocolSection[index], {
                columns: { $set: columns + 1 },
                documentationCharacteristic: {
                    $push: times(isTableSection ? rows + 1 : rows, rowIndex => {
                        return {
                            _id: createMongoObjectId(),
                            row: isTableSection ? rowIndex : rowIndex + 1,
                            column: columns + 1,
                            value: undefined,
                            inputElement: [],
                        };
                    }),
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.gridColumnsDecrement: {
            const { index } = action.payload;
            const { columns, documentationCharacteristic } = state.protocolTemplate.protocolSection[index];

            newState.protocolTemplate.protocolSection[index] = update(state.protocolTemplate.protocolSection[index], {
                columns: { $set: columns - 1 },
                documentationCharacteristic: {
                    $set: documentationCharacteristic.filter(item => item.column !== columns),
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.gridColumnsDelete: {
            const { sectionIndex, index } = action.payload;
            const { columns, documentationCharacteristic } = state.protocolTemplate.protocolSection[sectionIndex];

            newState.protocolTemplate.protocolSection[sectionIndex] = update(newState.protocolTemplate.protocolSection[sectionIndex], {
                columns: { $set: columns - 1 },
                documentationCharacteristic: {
                    $set: documentationCharacteristic.filter(item => item.column !== index),
                },
            });

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic.forEach(
                (characteristic, characteristicIndex) => {
                    if (characteristic.column > index) {
                        const characteristicColumn =
                            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].column;

                        newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex] = update(
                            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex],
                            {
                                column: {
                                    $set: characteristicColumn - 1,
                                },
                            }
                        );
                    }
                }
            );

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.gridColumnsMove: {
            const { sectionIndex, dragIndex, hoverIndex } = action.payload;

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic.forEach(
                (characteristic, characteristicIndex) => {
                    const currentColumn =
                        newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].column;

                    if (currentColumn === dragIndex + 1) {
                        const difference = Math.abs(hoverIndex - dragIndex);
                        const newColumn = dragIndex < hoverIndex ? currentColumn + difference : currentColumn - difference;

                        newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex] = update(
                            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex],
                            {
                                column: {
                                    $set: newColumn,
                                },
                            }
                        );
                    }

                    if (currentColumn === hoverIndex + 1) {
                        const difference = Math.abs(hoverIndex - dragIndex);
                        const newColumn = dragIndex < hoverIndex ? currentColumn - difference : currentColumn + difference;

                        newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex] = update(
                            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex],
                            {
                                column: {
                                    $set: newColumn,
                                },
                            }
                        );
                    }
                }
            );

            break;
        }
        case actions.gridRowsMove: {
            const { sectionIndex, dragIndex, hoverIndex } = action.payload;

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic.forEach(
                (characteristic, characteristicIndex) => {
                    const currentRow =
                        newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].row;

                    if (currentRow === dragIndex + 1) {
                        const difference = Math.abs(hoverIndex - dragIndex);
                        const newRow = dragIndex < hoverIndex ? currentRow + difference : currentRow - difference;

                        newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex] = update(
                            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex],
                            {
                                row: {
                                    $set: newRow,
                                },
                            }
                        );
                    }

                    if (currentRow === hoverIndex + 1) {
                        const difference = Math.abs(hoverIndex - dragIndex);
                        const newRow = dragIndex < hoverIndex ? currentRow - difference : currentRow + difference;

                        newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex] = update(
                            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex],
                            {
                                row: {
                                    $set: newRow,
                                },
                            }
                        );
                    }
                }
            );

            break;
        }

        case actions.listRowsIncrement: {
            const { index } = action.payload;
            const { documentationCharacteristic } = state.protocolTemplate.protocolSection[index];

            const { alignment, subtitle, inputElement } = documentationCharacteristic[documentationCharacteristic.length - 1];

            newState.protocolTemplate.protocolSection[index] = update(state.protocolTemplate.protocolSection[index], {
                documentationCharacteristic: {
                    $push: [
                        {
                            _id: createMongoObjectId(),
                            alignment: alignment || undefined,
                            subtitle: subtitle
                                ? {
                                      ...subtitle,
                                      _id: createMongoObjectId(),
                                      key: shortid.generate(),
                                  }
                                : undefined,
                            inputElement: inputElement.map(item => {
                                return {
                                    ...item,
                                    _id: createMongoObjectId(),
                                    key: shortid.generate(),
                                };
                            }),
                        },
                    ],
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.listRowsDecrement: {
            const { index } = action.payload;

            newState.protocolTemplate.protocolSection[index] = update(state.protocolTemplate.protocolSection[index], {
                documentationCharacteristic: {
                    $splice: [[-1, 1]],
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.listRowsDelete: {
            const { index, sectionIndex } = action.payload;

            const rows = newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic.length;
            if (rows > 1) {
                newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                    documentationCharacteristic: {
                        $splice: [[index, 1]],
                    },
                });

                newState = update.set(newState, 'changesSaved', false);
            }
            break;
        }

        case actions.moveDocumentationCharacteristic: {
            const { sectionIndex, dragIndex, hoverIndex } = action.payload;

            const dragElement = state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[dragIndex];

            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                documentationCharacteristic: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragElement],
                    ],
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.dropDocumentationCharacteristic: {
            const { sectionIndex, characteristicIndex, template } = action.payload;

            const newInputElements = template.inputElement.map(element => {
                return {
                    ...element,
                    _id: createMongoObjectId(),
                    key: shortid.generate(),
                };
            });

            newState.protocolTemplate.protocolSection[sectionIndex] = update(newState.protocolTemplate.protocolSection[sectionIndex], {
                documentationCharacteristic: {
                    [characteristicIndex]: {
                        highlighted: { $set: template.highlighted },
                        autoFillValue: { $set: template.autoFillValue },
                        inputElement: {
                            $push: newInputElements,
                        },
                    },
                },
            });

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.addDocumentationCharacteristic: {
            const { sectionIndex, characteristicIndex, template } = action.payload;

            const newElement = {
                ...template,
                _id: undefined,
                key: shortid.generate(),
                inputElement: template?.inputElement?.map(element => {
                    return {
                        ...element,
                        _id: createMongoObjectId(),
                        key: shortid.generate(),
                    };
                }),
            };

            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                documentationCharacteristic: {
                    $splice: [[characteristicIndex, 0, newElement]],
                },
            });

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.removeBlankDocumentationCharacteristic: {
            const { sectionIndex } = action.payload;

            const index = state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic.findIndex(
                el => el._id === undefined
            );
            if (index !== -1) {
                newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                    documentationCharacteristic: {
                        $splice: [[index, 1]],
                    },
                });
            }
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.relizeBlankDocumentationCharacteristic: {
            const { sectionIndex } = action.payload;

            const index = state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic.findIndex(
                el => el._id === undefined
            );
            if (index !== -1) {
                newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                    documentationCharacteristic: {
                        [index]: {
                            _id: { $set: createMongoObjectId() },
                        },
                    },
                });
            }
            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.handleChangeDocumentationCharacteristicSubtitle: {
            const { sectionIndex, characteristicIndex, path, value, allowLanguages, language } = action.payload;

            let newValue = value;

            if (allowLanguages) {
                const oldValue =
                    get(
                        state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].subtitle,
                        path
                    ) || [];
                newValue = setMultilanguageValue(value, oldValue, language || state.language);
            }

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].subtitle = update.set(
                state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].subtitle,
                path,
                newValue
            );

            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.moveInputElement: {
            const { sectionIndex, characteristicIndex, dragIndex, hoverIndex } = action.payload;

            const dragElement =
                state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[
                    dragIndex
                ];

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex] = update(
                state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex],
                {
                    inputElement: {
                        $splice: [
                            [dragIndex, 1],
                            [hoverIndex, 0, dragElement],
                        ],
                    },
                }
            );
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.moveInputElementBetweenGroups: {
            const { sectionIndex, dragIndex, hoverIndex, dragCharacteristicIndex, dropCharacteristicIndex } = action.payload;

            const dragElement =
                state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[dragCharacteristicIndex].inputElement[
                    dragIndex
                ];

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[dragCharacteristicIndex] = update(
                state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[dragCharacteristicIndex],
                {
                    inputElement: {
                        $splice: [[dragIndex, 1]],
                    },
                }
            );
            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[dropCharacteristicIndex] = update(
                state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[dropCharacteristicIndex],
                {
                    inputElement: {
                        $splice: [[hoverIndex, 0, dragElement]],
                    },
                }
            );
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.addInputElement: {
            const { sectionIndex, characteristicIndex, index, type, template } = action.payload;

            const newElement = template
                ? {
                      ...template,
                      _id: undefined,
                      key: shortid.generate(),
                  }
                : elementSettings[type].getInitialState();

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex] = update(
                state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex],
                {
                    inputElement: {
                        $splice: [[index, 0, newElement]],
                    },
                }
            );
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.dropInputElement: {
            const { sectionIndex, characteristicIndex, type, template } = action.payload;

            const newElement = template
                ? {
                      ...template,
                      _id: createMongoObjectId(),
                      key: shortid.generate(),
                  }
                : elementSettings[type].getInitialState(createMongoObjectId());

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex] = update(
                state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex],
                {
                    inputElement: {
                        $push: [newElement],
                    },
                }
            );
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.removeBlankInputElement: {
            const { sectionIndex, characteristicIndex } = action.payload;

            const index = state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[
                characteristicIndex
            ].inputElement.findIndex(el => el._id === undefined);
            if (index !== -1) {
                newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex] = update(
                    state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex],
                    {
                        inputElement: {
                            $splice: [[index, 1]],
                        },
                    }
                );
            }
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.relizeBlankInputElement: {
            const { sectionIndex, characteristicIndex } = action.payload;

            const index = state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[
                characteristicIndex
            ].inputElement.findIndex(el => el._id === undefined);
            if (index !== -1) {
                newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex] = update(
                    state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex],
                    {
                        inputElement: {
                            [index]: {
                                _id: { $set: createMongoObjectId() },
                            },
                        },
                    }
                );
            }
            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.addMediaOutputsSection: {
            const { sectionIndex } = action.payload;
            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                mediaOutputs: {
                    $set: [],
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.removeBlankMediaOutputsSection: {
            const { sectionIndex } = action.payload;
            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                mediaOutputs: {
                    $set: null,
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.relizeBlankMediaOutputsSection: {
            const { sectionIndex, template } = action.payload;

            const newMediaOutput = template
                ? {
                      ...template,
                      key: shortid.generate(),
                      _id: createMongoObjectId(),
                  }
                : elementSettings[characteristics.characteristicMediaOutputs].getInitialState(createMongoObjectId());

            if (state.protocolTemplate.protocolSection[sectionIndex].mediaOutputs) {
                newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                    mediaOutputs: {
                        $push: [newMediaOutput],
                    },
                });
            }
            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.moveMediaOutputElement: {
            const { sectionIndex, dragIndex, hoverIndex } = action.payload;

            const dragElement = state.protocolTemplate.protocolSection[sectionIndex].mediaOutputs[dragIndex];

            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                mediaOutputs: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragElement],
                    ],
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.addMediaOutputElement: {
            const { sectionIndex, index, template } = action.payload;

            const newMediaOutput = template
                ? {
                      ...template,
                      key: shortid.generate(),
                      _id: undefined,
                  }
                : elementSettings[characteristics.characteristicMediaOutputs].getInitialState();

            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                mediaOutputs: {
                    $splice: [[index, 0, newMediaOutput]],
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.removeBlankMediaOutputElement: {
            const { sectionIndex } = action.payload;

            const index = state.protocolTemplate.protocolSection[sectionIndex].mediaOutputs.findIndex(el => el._id === undefined);

            if (state.protocolTemplate.protocolSection[sectionIndex].mediaOutputs.length > 1) {
                newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                    mediaOutputs: {
                        $splice: [[index, 1]],
                    },
                });
            } else {
                newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                    mediaOutputs: {
                        $set: null,
                    },
                });
            }
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.relizeBlankMediaOutputElement: {
            const { sectionIndex } = action.payload;

            const index = state.protocolTemplate.protocolSection[sectionIndex].mediaOutputs.findIndex(el => el._id === undefined);

            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                mediaOutputs: {
                    [index]: {
                        _id: { $set: createMongoObjectId() },
                    },
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.addSignaturesSection: {
            const { sectionIndex } = action.payload;
            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                signedBy: {
                    $set: [],
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.removeBlankSignaturesSection: {
            const { sectionIndex } = action.payload;
            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                signedBy: {
                    $set: null,
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.relizeBlankSignaturesSection: {
            const { sectionIndex, template } = action.payload;

            const newSignature = template
                ? {
                      ...template,
                      key: shortid.generate(),
                      _id: createMongoObjectId(),
                  }
                : elementSettings[characteristics.characteristicSignature].getInitialState(createMongoObjectId());

            if (state.protocolTemplate.protocolSection[sectionIndex].signedBy) {
                newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                    signedBy: {
                        $push: [newSignature],
                    },
                });
            }
            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.moveSignatureElement: {
            const { sectionIndex, dragIndex, hoverIndex } = action.payload;

            const dragElement = state.protocolTemplate.protocolSection[sectionIndex].signedBy[dragIndex];

            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                signedBy: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragElement],
                    ],
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.addSignatureElement: {
            const { sectionIndex, index, template } = action.payload;

            const newSignature = template
                ? {
                      ...template,
                      key: shortid.generate(),
                      _id: undefined,
                  }
                : elementSettings[characteristics.characteristicSignature].getInitialState();

            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                signedBy: {
                    $splice: [[index, 0, newSignature]],
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.removeBlankSignatureElement: {
            const { sectionIndex } = action.payload;

            const index = state.protocolTemplate.protocolSection[sectionIndex].signedBy.findIndex(el => el._id === undefined);

            if (state.protocolTemplate.protocolSection[sectionIndex].signedBy.length > 1) {
                newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                    signedBy: {
                        $splice: [[index, 1]],
                    },
                });
            } else {
                newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                    signedBy: {
                        $set: null,
                    },
                });
            }
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.relizeBlankSignatureElement: {
            const { sectionIndex } = action.payload;

            const index = state.protocolTemplate.protocolSection[sectionIndex].signedBy.findIndex(el => el._id === undefined);

            newState.protocolTemplate.protocolSection[sectionIndex] = update(state.protocolTemplate.protocolSection[sectionIndex], {
                signedBy: {
                    [index]: {
                        _id: { $set: createMongoObjectId() },
                    },
                },
            });
            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.handleChangeInputElement: {
            const { sectionIndex, characteristicIndex, index, path, value, allowLanguages, language } = action.payload;

            let newValue = value;

            if (allowLanguages) {
                const oldValue =
                    get(
                        state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[
                            index
                        ],
                        path
                    ) || [];
                newValue = setMultilanguageValue(value, oldValue, language || state.language);
            }

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[index] =
                update.set(
                    state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[
                        index
                    ],
                    path,
                    newValue
                );
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.handleChangeFormattedInputElement: {
            const { sectionIndex, characteristicIndex, index, path, value, formattedValue } = action.payload;

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[index] =
                update(
                    state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[
                        index
                    ],
                    {
                        [path]: { $set: value },
                        formattedValue: { $set: formattedValue },
                    }
                );
            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.handleRadioChangeInputElement: {
            const { sectionIndex, characteristicIndex, index, path, checked } = action.payload;

            state.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement.forEach(
                (item, itemIndex) => {
                    if (item.type === inputElements.radio) {
                        newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[
                            characteristicIndex
                        ].inputElement[itemIndex] = update.set(
                            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex]
                                .inputElement[itemIndex],
                            path,
                            'false'
                        );
                    }
                }
            );

            newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[index] =
                update.set(
                    newState.protocolTemplate.protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[
                        index
                    ],
                    path,
                    checked
                );
            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        case actions.setSelectedElement: {
            const { _id, configurationType } = action.payload;

            const path = configurationTypeSettings[configurationType].id;

            newState = update.set(state, 'selectedElement', {
                [path]: _id,
                configurationType: configurationType,
            });

            newState = update.set(newState, 'selectedElementConfigurations', {});
            break;
        }
        case actions.setSelectedElementConfigurations: {
            const { language } = action.payload;

            newState = update.set(state, 'selectedElementConfigurations', {
                open: true,
                language: language,
            });
            break;
        }
        case actions.handleChangeSelectedElement: {
            const { path, value, allowLanguages, allowGroupChange, language, dependencies } = action.payload;
            const { configurationType } = state.selectedElement;

            const { inputElementIndex, mediaIndex, signatureIndex, documentationCharacteristicIndex, elementIndex } =
                configurationTypeSettings[configurationType].findProtocolTemplateElement(state.protocolTemplate, state.selectedElement);

            if (configurationType === configurationTypeConstant.inputElement && inputElementIndex !== undefined) {
                let newValue = value;

                if (allowLanguages) {
                    const oldValue =
                        get(
                            state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                                documentationCharacteristicIndex
                            ].inputElement[inputElementIndex],
                            path
                        ) || [];
                    newValue = setMultilanguageValue(value, oldValue, language || state.language);
                }

                newState.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                    documentationCharacteristicIndex
                ].inputElement[inputElementIndex] = update.set(
                    state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[documentationCharacteristicIndex]
                        .inputElement[inputElementIndex],
                    path,
                    newValue
                );
            }

            if (
                configurationType === configurationTypeConstant.documentationCharacteristic &&
                documentationCharacteristicIndex !== undefined
            ) {
                let newValue = value;

                if (allowLanguages) {
                    const oldValue =
                        get(
                            state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                                documentationCharacteristicIndex
                            ],
                            path
                        ) || [];
                    newValue = setMultilanguageValue(value, oldValue, language || state.language);
                }

                const documentationCharacteristic =
                    state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[documentationCharacteristicIndex];

                if (documentationCharacteristic.row === 0 && allowGroupChange) {
                    state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic.forEach((dc, dcIndex) => {
                        if (dc.column === documentationCharacteristic.column) {
                            newState.protocolTemplate.protocolSection[elementIndex] = update.set(
                                state.protocolTemplate.protocolSection[elementIndex],
                                `documentationCharacteristic.${dcIndex}.${path}`,
                                newValue
                            );

                            if (isArrayWithItems(dependencies)) {
                                dependencies.forEach(dependency => {
                                    newState.protocolTemplate.protocolSection[elementIndex] = update.set(
                                        state.protocolTemplate.protocolSection[elementIndex],
                                        `documentationCharacteristic.${dcIndex}.${dependency}`,
                                        ''
                                    );
                                });
                            }
                        }
                    });
                } else {
                    newState.protocolTemplate.protocolSection[elementIndex] = update.set(
                        state.protocolTemplate.protocolSection[elementIndex],
                        `documentationCharacteristic.${documentationCharacteristicIndex}.${path}`,
                        newValue
                    );

                    if (isArrayWithItems(dependencies)) {
                        dependencies.forEach(dependency => {
                            newState.protocolTemplate.protocolSection[elementIndex] = update.set(
                                state.protocolTemplate.protocolSection[elementIndex],
                                `documentationCharacteristic.${documentationCharacteristicIndex}.${dependency}`,
                                ''
                            );
                        });
                    }
                }
            }

            if (configurationType === configurationTypeConstant.characteristicMediaOutputs && mediaIndex !== undefined) {
                let newValue = value;

                if (allowLanguages) {
                    const oldValue = get(state.protocolTemplate.protocolSection[elementIndex].mediaOutputs[mediaIndex], path) || [];
                    newValue = setMultilanguageValue(value, oldValue, language || state.language);
                }

                newState.protocolTemplate.protocolSection[elementIndex].mediaOutputs[mediaIndex] = update.set(
                    state.protocolTemplate.protocolSection[elementIndex].mediaOutputs[mediaIndex],
                    path,
                    newValue
                );
            }

            if (configurationType === configurationTypeConstant.characteristicSignature && signatureIndex !== undefined) {
                let newValue = value;

                if (allowLanguages) {
                    const oldValue = get(state.protocolTemplate.protocolSection[elementIndex].signedBy[signatureIndex], path) || [];
                    newValue = setMultilanguageValue(value, oldValue, language || state.language);
                }

                newState.protocolTemplate.protocolSection[elementIndex].signedBy[signatureIndex] = update.set(
                    state.protocolTemplate.protocolSection[elementIndex].signedBy[signatureIndex],
                    path,
                    newValue
                );
            }

            if (configurationType === configurationTypeConstant.protocolSection && elementIndex !== undefined) {
                let newValue = value;

                if (allowLanguages) {
                    const oldValue = get(state.protocolTemplate.protocolSection[elementIndex], path) || [];
                    newValue = setMultilanguageValue(value, oldValue, language || state.language);
                }

                newState.protocolTemplate.protocolSection[elementIndex] = update.set(
                    state.protocolTemplate.protocolSection[elementIndex],
                    path,
                    newValue
                );
            }

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.handleCloseSelectedElement:
            newState = update.set(state, 'selectedElement', {});
            break;
        case actions.handleCloseSelectedElementConfigurations:
            newState = update.set(state, 'selectedElementConfigurations', {});
            break;
        case actions.handleDeleteSelectedElement: {
            const { configurationType } = state.selectedElement;

            const protocolElement =
                configurationType &&
                configurationTypeSettings[configurationType].findProtocolTemplateElement(state.protocolTemplate, state.selectedElement);

            if (protocolElement) {
                const { inputElementIndex, mediaIndex, signatureIndex, documentationCharacteristicIndex, elementIndex, sectionType } =
                    protocolElement;

                if (configurationType === configurationTypeConstant.inputElement) {
                    newState.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[documentationCharacteristicIndex] =
                        update(
                            state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                                documentationCharacteristicIndex
                            ],
                            {
                                inputElement: {
                                    $splice: [[inputElementIndex, 1]],
                                },
                            }
                        );
                }

                if (configurationType === configurationTypeConstant.characteristicMediaOutputs) {
                    if (state.protocolTemplate.protocolSection[elementIndex].mediaOutputs.length === 1) {
                        newState.protocolTemplate.protocolSection[elementIndex] = update(
                            state.protocolTemplate.protocolSection[elementIndex],
                            {
                                mediaOutputs: {
                                    $set: null,
                                },
                            }
                        );
                    } else {
                        newState.protocolTemplate.protocolSection[elementIndex] = update(
                            state.protocolTemplate.protocolSection[elementIndex],
                            {
                                mediaOutputs: {
                                    $splice: [[mediaIndex, 1]],
                                },
                            }
                        );
                    }
                }

                if (configurationType === configurationTypeConstant.characteristicSignature) {
                    if (state.protocolTemplate.protocolSection[elementIndex].signedBy.length === 1) {
                        newState.protocolTemplate.protocolSection[elementIndex] = update(
                            state.protocolTemplate.protocolSection[elementIndex],
                            {
                                signedBy: {
                                    $set: null,
                                },
                            }
                        );
                    } else {
                        newState.protocolTemplate.protocolSection[elementIndex] = update(
                            state.protocolTemplate.protocolSection[elementIndex],
                            {
                                signedBy: {
                                    $splice: [[signatureIndex, 1]],
                                },
                            }
                        );
                    }
                }

                if (configurationType === configurationTypeConstant.documentationCharacteristic) {
                    if (sectionType === groups.table || sectionType === groups.headerGrid) {
                        const { columns } = newState.protocolTemplate.protocolSection[elementIndex];
                        const { row, column } =
                            newState.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                                documentationCharacteristicIndex
                            ];

                        if (row === 0 && columns > 1) {
                            const newDocumentationCharacteristics = [
                                ...newState.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic
                                    .filter(documentationCharacteristic => {
                                        return documentationCharacteristic.column !== column;
                                    })
                                    .map(documentationCharacteristic => {
                                        return {
                                            ...documentationCharacteristic,
                                            column:
                                                documentationCharacteristic.column > column
                                                    ? documentationCharacteristic.column - 1
                                                    : documentationCharacteristic.column,
                                        };
                                    }),
                            ];

                            newState.protocolTemplate.protocolSection[elementIndex] = update(
                                newState.protocolTemplate.protocolSection[elementIndex],
                                {
                                    columns: { $set: columns - 1 },
                                    documentationCharacteristic: { $set: newDocumentationCharacteristics },
                                }
                            );
                        } else {
                            newState.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                                documentationCharacteristicIndex
                            ] = update(
                                state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                                    documentationCharacteristicIndex
                                ],
                                {
                                    inputElement: { $set: [] },
                                }
                            );
                        }
                    }

                    if (sectionType === groups.list || sectionType === groups.imageSection) {
                        if (state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic.length !== 1) {
                            newState.protocolTemplate.protocolSection[elementIndex] = update(
                                state.protocolTemplate.protocolSection[elementIndex],
                                {
                                    documentationCharacteristic: {
                                        $splice: [[documentationCharacteristicIndex, 1]],
                                    },
                                }
                            );
                        } else {
                            newState.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                                documentationCharacteristicIndex
                            ] = update(
                                state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                                    documentationCharacteristicIndex
                                ],
                                {
                                    subtitle: {
                                        $set:
                                            sectionType !== groups.imageSection
                                                ? { key: shortid.generate, _id: createMongoObjectId(), value: [] }
                                                : undefined,
                                    },
                                    inputElement: { $set: [] },
                                }
                            );
                        }
                    }
                }

                if (configurationType === configurationTypeConstant.protocolSection) {
                    newState = update(state, {
                        protocolTemplate: {
                            protocolSection: {
                                $splice: [[elementIndex, 1]],
                            },
                        },
                    });

                    if (sectionType === groups.title) {
                        newState = update.set(newState, 'isHeaderExist', false);
                    }
                    if (sectionType === groups.footer) {
                        newState = update.set(newState, 'isFooterExist', false);
                    }
                }

                newState = update(newState, {
                    selectedElementConfigurations: { $set: {} },
                });

                newState = update.set(newState, 'changesSaved', false);
            }
            break;
        }
        case actions.handleDuplicateSelectedElement: {
            const { configurationType } = state.selectedElement;

            const protocolElement = configurationTypeSettings[configurationType].findProtocolTemplateElement(
                state.protocolTemplate,
                state.selectedElement
            );
            const { inputElementIndex, mediaIndex, signatureIndex, documentationCharacteristicIndex, elementIndex, sectionType } =
                protocolElement;

            if (configurationType === configurationTypeConstant.inputElement) {
                const inputElement =
                    state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[documentationCharacteristicIndex]
                        .inputElement;

                if (configurationTypeSettings[configurationType].canDuplicate(sectionType, state.protocolTemplate, protocolElement)) {
                    const newInputElement = {
                        ...inputElement[inputElementIndex],
                        _id: createMongoObjectId(),
                        key: shortid.generate(),
                    };

                    newState.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[documentationCharacteristicIndex] =
                        update(
                            state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                                documentationCharacteristicIndex
                            ],
                            {
                                inputElement: {
                                    $push: [newInputElement],
                                },
                            }
                        );
                }
            }

            if (configurationType === configurationTypeConstant.characteristicMediaOutputs) {
                const newMediaOutput = {
                    ...state.protocolTemplate.protocolSection[elementIndex].mediaOutputs[mediaIndex],
                    _id: createMongoObjectId(),
                    key: shortid.generate(),
                };

                newState.protocolTemplate.protocolSection[elementIndex] = update(state.protocolTemplate.protocolSection[elementIndex], {
                    mediaOutputs: {
                        $push: [newMediaOutput],
                    },
                });
            }

            if (configurationType === configurationTypeConstant.characteristicSignature) {
                const newSignature = {
                    ...state.protocolTemplate.protocolSection[elementIndex].signedBy[signatureIndex],
                    _id: createMongoObjectId(),
                    key: shortid.generate(),
                };

                newState.protocolTemplate.protocolSection[elementIndex] = update(state.protocolTemplate.protocolSection[elementIndex], {
                    signedBy: {
                        $push: [newSignature],
                    },
                });
            }

            if (configurationType === configurationTypeConstant.documentationCharacteristic) {
                if (configurationTypeSettings[configurationType].canDuplicate(sectionType, state.protocolTemplate, protocolElement)) {
                    const documentationCharacteristic =
                        state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[documentationCharacteristicIndex];
                    const { subtitle, inputElement } = documentationCharacteristic;

                    const newDocumentationCharacteristic = {
                        ...documentationCharacteristic,
                        _id: createMongoObjectId(),
                        subtitle: subtitle
                            ? {
                                  ...subtitle,
                                  _id: createMongoObjectId(),
                                  key: shortid.generate(),
                              }
                            : undefined,
                        inputElement: inputElement.map(input => {
                            return {
                                ...input,
                                _id: createMongoObjectId(),
                                key: shortid.generate(),
                            };
                        }),
                    };

                    newState.protocolTemplate.protocolSection[elementIndex] = update(state.protocolTemplate.protocolSection[elementIndex], {
                        documentationCharacteristic: {
                            $push: [newDocumentationCharacteristic],
                        },
                    });
                }
            }

            if (configurationType === configurationTypeConstant.protocolSection) {
                if (configurationTypeSettings[configurationType].canDuplicate(sectionType, state.protocolTemplate, protocolElement)) {
                    const section = state.protocolTemplate.protocolSection[elementIndex];
                    const newSection = {
                        ...section,
                        _id: createMongoObjectId(),
                        key: shortid.generate(),
                        documentationCharacteristic: section.documentationCharacteristic?.map(characteristic => {
                            const { subtitle, inputElement } = characteristic;

                            return {
                                ...characteristic,
                                _id: createMongoObjectId(),
                                subtitle: subtitle
                                    ? {
                                          ...subtitle,
                                          _id: createMongoObjectId(),
                                          key: shortid.generate(),
                                      }
                                    : undefined,
                                inputElement: inputElement?.map(input => {
                                    return {
                                        ...input,
                                        _id: createMongoObjectId(),
                                        key: shortid.generate(),
                                    };
                                }),
                            };
                        }),
                        mediaOutputs: section.mediaOutputs?.map(mediaOutput => {
                            return {
                                ...mediaOutput,
                                _id: createMongoObjectId(),
                                key: shortid.generate(),
                            };
                        }),
                    };

                    if (state.isFooterExist) {
                        newState = update(state, {
                            protocolTemplate: {
                                protocolSection: {
                                    $splice: [[state.protocolTemplate.protocolSection.length - 1, 0, newSection]],
                                },
                            },
                        });
                        break;
                    }

                    newState = update(state, {
                        protocolTemplate: {
                            protocolSection: {
                                $push: [newSection],
                            },
                        },
                    });
                }
            }

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.handleSaveSelectedElement: {
            const { configurationType } = state.selectedElement;

            const { inputElementIndex, mediaIndex, signatureIndex, documentationCharacteristicIndex, elementIndex, type } =
                configurationTypeSettings[configurationType].findProtocolTemplateElement(state.protocolTemplate, state.selectedElement);

            const { name } = action.payload;

            const path = configurationTypeSettings[configurationType].getStoragePath(type);

            if (configurationType === configurationTypeConstant.protocolSection) {
                newState = update(state, {
                    myTemplates: {
                        [path]: {
                            $push: [
                                {
                                    ...state.protocolTemplate.protocolSection[elementIndex],
                                    templateName: name,
                                },
                            ],
                        },
                    },
                });
            }

            if (configurationType === configurationTypeConstant.characteristicMediaOutputs) {
                newState = update(state, {
                    myTemplates: {
                        [path]: {
                            $push: [
                                {
                                    ...state.protocolTemplate.protocolSection[elementIndex].mediaOutputs[mediaIndex],
                                    templateName: name,
                                    type: characteristics.characteristicMediaOutputs,
                                },
                            ],
                        },
                    },
                });
            }

            if (configurationType === configurationTypeConstant.characteristicSignature) {
                newState = update(state, {
                    myTemplates: {
                        [path]: {
                            $push: [
                                {
                                    ...state.protocolTemplate.protocolSection[elementIndex].signedBy[signatureIndex],
                                    templateName: name,
                                    type: characteristics.characteristicSignature,
                                },
                            ],
                        },
                    },
                });
            }

            if (configurationType === configurationTypeConstant.documentationCharacteristic) {
                newState = update(state, {
                    myTemplates: {
                        [path]: {
                            $push: [
                                {
                                    ...state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                                        documentationCharacteristicIndex
                                    ],
                                    templateName: name,
                                    type: characteristics.documentationCharacteristic,
                                },
                            ],
                        },
                    },
                });
            }

            if (configurationType === configurationTypeConstant.inputElement) {
                newState = update(state, {
                    myTemplates: {
                        [path]: {
                            $push: [
                                {
                                    ...state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                                        documentationCharacteristicIndex
                                    ].inputElement[inputElementIndex],
                                    templateName: name,
                                },
                            ],
                        },
                    },
                });
            }

            localStorage.setItem('myTemplates', JSON.stringify(newState.myTemplates));

            break;
        }

        case actions.setClipboardElement: {
            const { selectedElement } = state;
            if (selectedElement.configurationType === configurationTypeConstant.inputElement) {
                newState = update(state, {
                    clipboard: {
                        $set: { ...selectedElement },
                    },
                });
            }
            break;
        }
        case actions.pasteElementFromClipboard: {
            const { selectedElement, clipboard, protocolTemplate } = state;

            if (selectedElement.configurationType === configurationTypeConstant.documentationCharacteristic && clipboard.inputElementId) {
                const { inputElementId: sourceId, configurationType: sourceConfigurationType } = clipboard;
                const sourceProtocolElement =
                    configurationTypeSettings[sourceConfigurationType].findProtocolTemplateElement(protocolTemplate, {
                        inputElementId: sourceId,
                    }) || {};
                const {
                    inputElementIndex: sourceInputElementIndex,
                    documentationCharacteristicIndex: sourceDocumentationCharacteristicIndex,
                    elementIndex: sourceElementIndex,
                } = sourceProtocolElement;

                if (sourceInputElementIndex !== undefined) {
                    const { documentationCharacteristicId: destinationId, configurationType: destinationConfigurationType } =
                        selectedElement;
                    const destinationProtocolElement =
                        configurationTypeSettings[destinationConfigurationType].findProtocolTemplateElement(protocolTemplate, {
                            documentationCharacteristicId: destinationId,
                        }) || {};
                    const {
                        documentationCharacteristicIndex: destinationDocumentationCharacteristicIndex,
                        elementIndex: destinationElementIndex,
                        sectionType: destinationSectionType,
                    } = destinationProtocolElement;

                    if (destinationDocumentationCharacteristicIndex !== undefined) {
                        const documentationCharacteristicInputs =
                            protocolTemplate.protocolSection[destinationElementIndex].documentationCharacteristic[
                                destinationDocumentationCharacteristicIndex
                            ].inputElement;
                        const inputElement =
                            protocolTemplate.protocolSection[sourceElementIndex].documentationCharacteristic[
                                sourceDocumentationCharacteristicIndex
                            ].inputElement[sourceInputElementIndex];

                        if (
                            newState.protocolTemplate.protocolSection[destinationElementIndex].documentationCharacteristic[
                                destinationDocumentationCharacteristicIndex
                            ].inputElement &&
                            elementSettings[destinationSectionType].canDrop(documentationCharacteristicInputs, inputElement, true)
                        ) {
                            const newInputElement = {
                                ...inputElement,
                                _id: createMongoObjectId(),
                                key: shortid.generate(),
                            };

                            newState.protocolTemplate.protocolSection[destinationElementIndex].documentationCharacteristic[
                                destinationDocumentationCharacteristicIndex
                            ] = update(
                                protocolTemplate.protocolSection[destinationElementIndex].documentationCharacteristic[
                                    destinationDocumentationCharacteristicIndex
                                ],
                                {
                                    inputElement: {
                                        $push: [newInputElement],
                                    },
                                }
                            );
                        }
                    }
                }
            }

            break;
        }

        case actions.addProtocolMedia: {
            const { mediaName } = action.payload;
            const { configurationType } = state.selectedElement;

            const { inputElementIndex, mediaIndex, documentationCharacteristicIndex, elementIndex } = configurationTypeSettings[
                configurationType
            ].findProtocolTemplateElement(state.protocolTemplate, state.selectedElement);

            if (configurationType === configurationTypeConstant.inputElement) {
                newState.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[
                    documentationCharacteristicIndex
                ].inputElement = update(
                    state.protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[documentationCharacteristicIndex]
                        .inputElement,
                    {
                        [inputElementIndex]: {
                            defaultValue: {
                                $set: mediaName,
                            },
                        },
                    }
                );
            }

            if (configurationType === configurationTypeConstant.characteristicMediaOutputs) {
                newState.protocolTemplate.protocolSection[elementIndex].mediaOutputs = update(
                    state.protocolTemplate.protocolSection[elementIndex].mediaOutputs,
                    {
                        [mediaIndex]: {
                            defaultValue: {
                                $set: mediaName,
                            },
                        },
                    }
                );
            }

            break;
        }
        case actions.addProtocolTile: {
            const { tile } = action.payload;

            newState.protocolTileData = update(newState.protocolTileData, {
                $push: [tile],
            });
            break;
        }

        case actions.applyProtocolMetadataChanges: {
            const { metadata } = action.payload;

            newState.protocolTemplate.metadata = update(state.protocolTemplate.metadata, {
                $set: {
                    ...metadata,
                },
            });

            if (newState.isHeaderExist) {
                const sectionIndex = newState.protocolTemplate.protocolSection.findIndex(x => x.type === groups.title);

                const oldValue = get(newState.protocolTemplate.protocolSection[sectionIndex], 'value') || [];

                newState.protocolTemplate.protocolSection[sectionIndex] = update.set(
                    newState.protocolTemplate.protocolSection[sectionIndex],
                    'value',
                    setMultilanguageValue(metadata.templateTitle, oldValue, newState.language)
                );
            } else if (metadata.templateTitle) {
                const newSection = {
                    ...elementSettings[groups.title].getInitialState(createMongoObjectId()),
                    value: [
                        {
                            language: newState.language,
                            value: metadata.templateTitle,
                        },
                    ],
                };

                newState = update(newState, {
                    protocolTemplate: {
                        protocolSection: {
                            $splice: [[0, 0, newSection]],
                        },
                    },
                    isHeaderExist: { $set: true },
                });
            }

            newState = update.set(newState, 'changesSaved', false);
            break;
        }

        default:
            break;
    }

    return newState;
};

export const initProtocolBuilder = payload => {
    return {
        type: actions.initProtocolBuilder,
        payload,
    };
};

export const setProtocolBuilderModel = (model, operation) => {
    return {
        type: actions.setProtocolBuilderModel,
        payload: {
            model: model,
            operation: operation,
        },
    };
};

export const setChangesSavedState = value => {
    return {
        type: actions.setChangesSavedState,
        payload: {
            value: value,
        },
    };
};

export const handleValueChange = (path, value, allowTrackChanges = true) => {
    return {
        type: actions.handleValueChange,
        payload: {
            path,
            value,
            allowTrackChanges,
        },
    };
};

export const handleProtocolValueChange = (path, value, allowLanguages) => {
    return {
        type: actions.handleProtocolValueChange,
        payload: {
            path,
            value,
            allowLanguages,
        },
    };
};

export const createProtocolSection = section => {
    return {
        type: actions.createProtocolSection,
        payload: {
            section,
        },
    };
};
export const createHeaderProtocolSection = section => {
    return {
        type: actions.createHeaderProtocolSection,
        payload: {
            section,
        },
    };
};
export const createFooterProtocolSection = section => {
    return {
        type: actions.createFooterProtocolSection,
        payload: {
            section,
        },
    };
};

export const deleteProtocolSection = section => {
    return {
        type: actions.deleteProtocolSection,
        payload: {
            section,
        },
    };
};
export const deleteProtocolSectionMediaOutput = mediaOutput => {
    return {
        type: actions.deleteProtocolSectionMediaOutput,
        payload: {
            mediaOutput,
        },
    };
};
export const deleteProtocolSectionSignature = signature => {
    return {
        type: actions.deleteProtocolSectionSignature,
        payload: {
            signature,
        },
    };
};
export const deleteProtocolSectionInputElement = inputElement => {
    return {
        type: actions.deleteProtocolSectionInputElement,
        payload: {
            inputElement,
        },
    };
};

export const moveProtocolSection = (dragIndex, hoverIndex) => {
    return {
        type: actions.moveProtocolSection,
        payload: {
            dragIndex,
            hoverIndex,
        },
    };
};
export const addProtocolSection = (index, type, template) => {
    return {
        type: actions.addProtocolSection,
        payload: {
            index,
            type,
            template,
        },
    };
};
export const removeBlankProtocolSection = () => {
    return {
        type: actions.removeBlankProtocolSection,
    };
};
export const relizeBlankProtocolSection = () => {
    return {
        type: actions.relizeBlankProtocolSection,
    };
};
export const handleChangeProtocolSection = (index, path, value, formattedValue, allowLanguages, language = null) => {
    return {
        type: actions.handleChangeProtocolSection,
        payload: {
            index,
            path,
            value,
            formattedValue,
            allowLanguages,
            language,
        },
    };
};

export const gridRowsIncrement = index => {
    return {
        type: actions.gridRowsIncrement,
        payload: {
            index,
        },
    };
};
export const gridRowsDecrement = index => {
    return {
        type: actions.gridRowsDecrement,
        payload: {
            index,
        },
    };
};
export const gridColumnsIncrement = index => {
    return {
        type: actions.gridColumnsIncrement,
        payload: {
            index,
        },
    };
};
export const gridColumnsDecrement = index => {
    return {
        type: actions.gridColumnsDecrement,
        payload: {
            index,
        },
    };
};
export const gridColumnsDelete = (sectionIndex, index) => {
    return {
        type: actions.gridColumnsDelete,
        payload: {
            sectionIndex,
            index,
        },
    };
};
export const gridRowsDelete = (sectionIndex, index) => {
    return {
        type: actions.gridRowsDelete,
        payload: {
            sectionIndex,
            index,
        },
    };
};
export const gridColumnsMove = (sectionIndex, dragIndex, hoverIndex) => {
    return {
        type: actions.gridColumnsMove,
        payload: {
            sectionIndex,
            dragIndex,
            hoverIndex,
        },
    };
};
export const gridRowsMove = (sectionIndex, dragIndex, hoverIndex) => {
    return {
        type: actions.gridRowsMove,
        payload: {
            sectionIndex,
            dragIndex,
            hoverIndex,
        },
    };
};

export const listRowsIncrement = index => {
    return {
        type: actions.listRowsIncrement,
        payload: {
            index,
        },
    };
};
export const listRowsDecrement = index => {
    return {
        type: actions.listRowsDecrement,
        payload: {
            index,
        },
    };
};
export const listRowsDelete = (sectionIndex, index) => {
    return {
        type: actions.listRowsDelete,
        payload: {
            sectionIndex,
            index,
        },
    };
};

export const moveDocumentationCharacteristic = (sectionIndex, dragIndex, hoverIndex) => {
    return {
        type: actions.moveDocumentationCharacteristic,
        payload: {
            sectionIndex,
            dragIndex,
            hoverIndex,
        },
    };
};
export const dropDocumentationCharacteristic = (sectionIndex, characteristicIndex, template) => {
    return {
        type: actions.dropDocumentationCharacteristic,
        payload: {
            sectionIndex,
            characteristicIndex,
            template,
        },
    };
};
export const addDocumentationCharacteristic = (sectionIndex, characteristicIndex, template) => {
    return {
        type: actions.addDocumentationCharacteristic,
        payload: {
            sectionIndex,
            characteristicIndex,
            template,
        },
    };
};
export const removeBlankDocumentationCharacteristic = sectionIndex => {
    return {
        type: actions.removeBlankDocumentationCharacteristic,
        payload: {
            sectionIndex,
        },
    };
};
export const relizeBlankDocumentationCharacteristic = sectionIndex => {
    return {
        type: actions.relizeBlankDocumentationCharacteristic,
        payload: {
            sectionIndex,
        },
    };
};

export const handleChangeDocumentationCharacteristicSubtitle = (
    sectionIndex,
    characteristicIndex,
    path,
    value,
    allowLanguages,
    language = null
) => {
    return {
        type: actions.handleChangeDocumentationCharacteristicSubtitle,
        payload: {
            sectionIndex,
            characteristicIndex,
            path,
            value,
            allowLanguages,
            language,
        },
    };
};

export const moveInputElement = (sectionIndex, characteristicIndex, dragIndex, hoverIndex) => {
    return {
        type: actions.moveInputElement,
        payload: {
            sectionIndex,
            characteristicIndex,
            dragIndex,
            hoverIndex,
        },
    };
};
export const moveInputElementBetweenGroups = (sectionIndex, dragIndex, hoverIndex, dragCharacteristicIndex, dropCharacteristicIndex) => {
    return {
        type: actions.moveInputElementBetweenGroups,
        payload: {
            sectionIndex,
            dragIndex,
            hoverIndex,
            dragCharacteristicIndex,
            dropCharacteristicIndex,
        },
    };
};
export const addInputElement = (sectionIndex, characteristicIndex, index, type, template) => {
    return {
        type: actions.addInputElement,
        payload: {
            sectionIndex,
            characteristicIndex,
            index,
            type,
            template,
        },
    };
};
export const dropInputElement = (sectionIndex, characteristicIndex, type, template) => {
    return {
        type: actions.dropInputElement,
        payload: {
            sectionIndex,
            characteristicIndex,
            type,
            template,
        },
    };
};
export const removeBlankInputElement = (sectionIndex, characteristicIndex) => {
    return {
        type: actions.removeBlankInputElement,
        payload: {
            sectionIndex,
            characteristicIndex,
        },
    };
};
export const relizeBlankInputElement = (sectionIndex, characteristicIndex) => {
    return {
        type: actions.relizeBlankInputElement,
        payload: {
            sectionIndex,
            characteristicIndex,
        },
    };
};

export const addMediaOutputsSection = sectionIndex => {
    return {
        type: actions.addMediaOutputsSection,
        payload: {
            sectionIndex,
        },
    };
};
export const removeBlankMediaOutputsSection = sectionIndex => {
    return {
        type: actions.removeBlankMediaOutputsSection,
        payload: {
            sectionIndex,
        },
    };
};
export const relizeBlankMediaOutputsSection = (sectionIndex, template) => {
    return {
        type: actions.relizeBlankMediaOutputsSection,
        payload: {
            sectionIndex,
            template,
        },
    };
};

export const moveMediaOutputElement = (sectionIndex, dragIndex, hoverIndex) => {
    return {
        type: actions.moveMediaOutputElement,
        payload: {
            sectionIndex,
            dragIndex,
            hoverIndex,
        },
    };
};
export const addMediaOutputElement = (sectionIndex, index, template) => {
    return {
        type: actions.addMediaOutputElement,
        payload: {
            sectionIndex,
            index,
            template,
        },
    };
};
export const removeBlankMediaOutputElement = sectionIndex => {
    return {
        type: actions.removeBlankMediaOutputElement,
        payload: {
            sectionIndex,
        },
    };
};
export const relizeBlankMediaOutputElement = sectionIndex => {
    return {
        type: actions.relizeBlankMediaOutputElement,
        payload: {
            sectionIndex,
        },
    };
};

export const addSignaturesSection = sectionIndex => {
    return {
        type: actions.addSignaturesSection,
        payload: {
            sectionIndex,
        },
    };
};
export const removeBlankSignaturesSection = sectionIndex => {
    return {
        type: actions.removeBlankSignaturesSection,
        payload: {
            sectionIndex,
        },
    };
};
export const relizeBlankSignaturesSection = (sectionIndex, template) => {
    return {
        type: actions.relizeBlankSignaturesSection,
        payload: {
            sectionIndex,
            template,
        },
    };
};

export const moveSignatureElement = (sectionIndex, dragIndex, hoverIndex) => {
    return {
        type: actions.moveSignatureElement,
        payload: {
            sectionIndex,
            dragIndex,
            hoverIndex,
        },
    };
};
export const addSignatureElement = (sectionIndex, index, template) => {
    return {
        type: actions.addSignatureElement,
        payload: {
            sectionIndex,
            index,
            template,
        },
    };
};
export const removeBlankSignatureElement = sectionIndex => {
    return {
        type: actions.removeBlankSignatureElement,
        payload: {
            sectionIndex,
        },
    };
};
export const relizeBlankSignatureElement = sectionIndex => {
    return {
        type: actions.relizeBlankSignatureElement,
        payload: {
            sectionIndex,
        },
    };
};

export const handleChangeInputElement = (sectionIndex, characteristicIndex, index, path, value, allowLanguages, language = null) => {
    return {
        type: actions.handleChangeInputElement,
        payload: {
            sectionIndex,
            characteristicIndex,
            index,
            path,
            value,
            allowLanguages,
            language,
        },
    };
};
export const handleChangeFormattedInputElement = (sectionIndex, characteristicIndex, index, path, value, formattedValue) => {
    return {
        type: actions.handleChangeFormattedInputElement,
        payload: {
            sectionIndex,
            characteristicIndex,
            index,
            path,
            value,
            formattedValue,
        },
    };
};
export const handleRadioChangeInputElement = (sectionIndex, characteristicIndex, index, path, checked) => {
    return {
        type: actions.handleRadioChangeInputElement,
        payload: {
            sectionIndex,
            characteristicIndex,
            index,
            path,
            checked,
        },
    };
};

export const setSelectedElement = (_id, configurationType) => {
    return {
        type: actions.setSelectedElement,
        payload: {
            _id,
            configurationType,
        },
    };
};
export const setSelectedElementConfigurations = (language = null) => {
    return {
        type: actions.setSelectedElementConfigurations,
        payload: {
            language,
        },
    };
};
export const handleChangeSelectedElement = (path, value, allowLanguages, allowGroupChange, language = null, dependencies = []) => {
    return {
        type: actions.handleChangeSelectedElement,
        payload: {
            path,
            value,
            allowLanguages,
            allowGroupChange,
            language,
            dependencies,
        },
    };
};
export const handleCloseSelectedElement = () => {
    return {
        type: actions.handleCloseSelectedElement,
    };
};
export const handleCloseSelectedElementConfigurations = () => {
    return {
        type: actions.handleCloseSelectedElementConfigurations,
    };
};
export const handleDeleteSelectedElement = () => {
    return {
        type: actions.handleDeleteSelectedElement,
    };
};
export const handleDuplicateSelectedElement = () => {
    return {
        type: actions.handleDuplicateSelectedElement,
    };
};
export const handleSaveSelectedElement = name => {
    return {
        type: actions.handleSaveSelectedElement,
        payload: {
            name,
        },
    };
};

export const setClipboardElement = () => {
    return {
        type: actions.setClipboardElement,
    };
};
export const pasteElementFromClipboard = () => {
    return {
        type: actions.pasteElementFromClipboard,
    };
};

export const addProtocolMedia = mediaName => {
    return {
        type: actions.addProtocolMedia,
        payload: {
            mediaName,
        },
    };
};
export const addProtocolTile = tile => {
    return {
        type: actions.addProtocolTile,
        payload: {
            tile,
        },
    };
};

export const applyProtocolMetadataChanges = metadata => {
    return {
        type: actions.applyProtocolMetadataChanges,
        payload: {
            metadata: metadata,
        },
    };
};

export const addMyTemplate = payload => ({
    type: actions.addMyTemplate,
    payload,
});

export const deleteMyTemplate = payload => ({
    type: actions.deleteMyTemplate,
    payload,
});
