import React from "react";

import { ReactComponent as EditIcon } from "../../../../resources/icons/editIcon.svg";
import { ReactComponent as TranslationIcon } from "../../../../resources/icons/translationIcon.svg";
import { ReactComponent as MonitorIcon } from "../../../../resources/icons/monitorIcon.svg";
import { ReactComponent as FileIcon } from "../../../../resources/icons/fileIcon.svg";

export const tabs = {
    edit: "edit",
    translation: "translation",
    preview: "preview",
    pdf: "pdf"
};

export const tabOptions = [
    {
        value: tabs.edit,
        icon: <EditIcon />,
        translation: "protocol-template-builder.top-panel.tabs.edit"
    },
    {
        value: tabs.translation,
        icon: <TranslationIcon />,
        translation: "protocol-template-builder.top-panel.tabs.translation"
    },
    {
        value: tabs.preview,
        icon: <MonitorIcon />,
        translation: "protocol-template-builder.top-panel.tabs.preview"
    },
    {
        value: tabs.pdf,
        icon: <FileIcon />,
        translation: "protocol-template-builder.top-panel.tabs.pdf"
    }
];