import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { changeValueAction } from '../common/reducers/ServiceWorkerReducer';

const withServiceWorkerProvider = ComposedComponent => {
    return React.memo(props => {
        const dispatch = useDispatch();

        const changeValue = useCallback(
            (path, value) => dispatch(changeValueAction(path, value)),
            [dispatch]
        )

        useEffect(() => {
            if (navigator.onLine !== undefined && navigator.onLine !== null) {
                changeValue('isOnline', navigator.onLine);
            }

            window.addEventListener("online", handleNetworkChange);
            window.addEventListener("offline", handleNetworkChange);

            return () => {
                window.removeEventListener('online', handleNetworkChange);
                window.removeEventListener('offline', handleNetworkChange);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const handleNetworkChange = event => {
            if (navigator.onLine) {
                changeValue('isOnline', true);
            } else {
                changeValue('isOnline', false);
            }
        }

        return (
            <ComposedComponent {...props} />
        )
    })
}

export default withServiceWorkerProvider;