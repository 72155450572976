import { globalActionTypes } from '../../../redux/actions';

import { get } from 'lodash';
import shortid from 'shortid';
import update from '../../../redux/update';
import { isArrayWithItems } from '../../../utils/arrayHelper';
import { mapModelToTemplate, findInputElementByLabel } from '../../../utils/protocolHelper';

import { inputElements } from '../template-builder/constants/inputElements';
import { headerGridTranslations } from '../template-builder/constants/translations';
import { groups } from '../template-builder/constants/groups';
import { operationAtomicEnum } from '../../../constants/OperationAtomicEnum';
import createMongoObjectId from '../../../utils/createMongoObjID';

import { viewType } from '../../../constants/ViewType';
import { actionTabs } from './constants';

const actions = {
    initProtocolTemplate: "PRODUCTION_STAND/INIT_PROTOCOL_TEMPLATE",
    initActiveStep: "PRODUCTION_STANT/INIT_ACTIVE_STEP",
    handleValueChange: "PRODUCTION_STAND/HANDLE_VALUE_CHANGE",
    handleManufacturingStepChange: "PRODUCTION_STAND/HANDLE_MANUFACTURING_STEP_CHANGE",
    handleInputElementValueChange: "PRODUCTION_STAND/HANDLE_INPUT_ELEMENT_VALUE_CHANGE",
    handleInputElementFormattedValueChange: "PRODUCTION_STAND/HANDLE_INPUT_ELEMENT_FORMATTED_VALUE_CHANGE",
    handleInputElementRadioChange: "PRODUCTION_STAND/HANDLE_INPUT_ELEMENT_RADIO_CHANGE",
    handleFileValueChange: "PRODUCTION_STAND/HANDLE_FILE_VALUE_CHANGE",
    handleSectionAutofill: "PRODUCTION_STAND/HANDLE_SECTION_AUTOFILL",
    handleSerialNumberAutofill: "PRODUCTION_STAND/HANDLE_SERIAL_NUMBER_AUTOFILL",
    handleFindingValueChange: "PRODUCTION_STAND/HANDLE_FINDING_VALUE_CHANGE",
    handleAddFinding: "PRODUCTION_STAND/HANDLE_ADD_FINDING",
    handleUpdateFinding: "PRODUCTION_STAND/HANDLE_UPDATE_FINDING",
    handleDeleteFinding: "PRODUCTION_STAND/HANDLE_DELETE_FINDING",
};

const initialState = {
    activeTab: actionTabs.execution,
    showToolbar: true,
    findingForm: {},
    confirmationModal: {
        isOpen: false,
        changeManufacturingStep: false,
        nextStepOpen: false,
        nextActiveAssembly: null,
        nextActiveGroup: null,
        nextActiveStep: null,
        changeActiveStep: false,
        closeActiveStep: false
    },
    leftBar: {
        isOpen: false,
        activeAssembly: null,
        activeGroup: null,
        activeStep: null,
        activeFile: null,
        activeFileFolder: null
    },
    rightBar: {
        protocolTemplate: {},
        showWarnings: false,
        changesSaved: true
    },
    signingModal: {
        isOpen: false,
        sectionIndex: null,
        documentationCharacteristicIndex: null,
        inputElementIndex: null
    },
    unsigningModal: {
        isOpen: false,
        sectionIndex: null,
        documentationCharacteristicIndex: null,
        inputElementIndex: null
    },
    fileUploadModal: {
        open: false,
        sectionIndex: null,
        characteristicIndex: null,
        inputElementIndex: null,
        folderPath: null,
        tilesPath: null,
        allowedFileTypes: null,
        maxNumberOfFiles: null
    },
    mediaTileData: [],
    documentsTileData: [],
    updateHandler: []
};

export const atomicProtocolSectionPath = "protocolSections.$[protocolSectionsId]";
export const atomicInputElementPath = "protocolSections.$[protocolSectionsId].documentationCharacteristic.$[documentationCharacteristicId].inputElement.$[inputElementId]";

export const productionStandReducer = (state = initialState, action) => {
    let newState = state;

    switch (action.type) {
        case globalActionTypes.generalClear:
            newState = initialState;
            break;

        case actions.handleValueChange:
            newState = update.set(state, action.payload.path, action.payload.value);
            break;

        case actions.handleManufacturingStepChange:
            newState = update(state, {
                leftBar: {
                    isOpen: { $set: action.payload.isOpen },
                    activeAssembly: { $set: action.payload.assemblyId },
                    activeGroup: { $set: action.payload.groupId },
                    activeStep: { $set: null },
                    activeFile: { $set: null },
                    activeFileFolder: { $set: null }
                }
            })
            break;

        case actions.initProtocolTemplate: {
            const { protocol, disableMapping } = action.payload;
            const { activeStep } = state.leftBar;

            const protocolTemplate = disableMapping ? protocol : mapModelToTemplate(protocol);
            newState = update.set(state, 'rightBar.protocolTemplate', {
                [activeStep]: protocolTemplate
            });

            break;
        };

        case actions.initActiveStep: {
            const { assemblyId, groupId, activeStep } = action.payload;

            newState = update(state, {
                leftBar: {
                    isOpen: { $set: true },
                    activeAssembly: { $set: assemblyId },
                    activeGroup: { $set: groupId },
                    activeStep: { $set: activeStep }
                }
            });

            break;
        };

        case actions.handleInputElementValueChange: {
            const { sectionIndex, characteristicIndex, inputElementIndex, value, modifier } = action.payload;
            const { activeStep } = state.leftBar;

            const rowIndex = newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].row

            newState = handleModifierAutofill(newState, activeStep, sectionIndex, modifier, rowIndex);

            newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[inputElementIndex] =
                update.set(newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[inputElementIndex], 'value', value);

            newState.updateHandler = handleAtomicInputElementChange(newState, activeStep, sectionIndex, characteristicIndex, inputElementIndex, value);

            newState.rightBar = update(newState.rightBar, {
                changesSaved: { $set: false }
            });

            break;
        };

        case actions.handleInputElementFormattedValueChange: {
            const { sectionIndex, characteristicIndex, inputElementIndex, value, formattedValue, modifier } = action.payload;
            const { activeStep } = state.leftBar;

            const rowIndex = newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].row

            newState = handleModifierAutofill(newState, activeStep, sectionIndex, modifier, rowIndex);

            newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[inputElementIndex] =
                update(newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[inputElementIndex], {
                    value: { $set: value },
                    formattedValue: { $set: formattedValue }
                });

            newState.updateHandler = handleAtomicInputElementChange(newState, activeStep, sectionIndex, characteristicIndex, inputElementIndex, value, "value");
            newState.updateHandler = handleAtomicInputElementChange(newState, activeStep, sectionIndex, characteristicIndex, inputElementIndex, formattedValue, "formattedValue");

            newState.rightBar = update(newState.rightBar, {
                changesSaved: { $set: false }
            });

            break;
        };

        case actions.handleInputElementRadioChange: {
            const { sectionIndex, characteristicIndex, inputElementIndex, checked, modifier } = action.payload;
            const { activeStep } = state.leftBar;

            const rowIndex = newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].row

            newState = handleModifierAutofill(newState, activeStep, sectionIndex, modifier, rowIndex);

            newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement.forEach((item, itemIndex) => {
                if (item.type === inputElements.radio) {
                    newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement =
                        update(newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement, {
                            [itemIndex]: {
                                checked: { $set: "false" },
                                initialValidation: { $set: true }
                            }
                        });

                    newState.updateHandler = handleAtomicInputElementChange(newState, activeStep, sectionIndex, characteristicIndex, itemIndex, false, "checked");
                }
            });

            newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement =
                update(newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement,
                    {
                        [inputElementIndex]: {
                            checked: { $set: checked },
                            initialValidation: { $set: true }
                        }
                    });

            newState.updateHandler = handleAtomicInputElementChange(newState, activeStep, sectionIndex, characteristicIndex, inputElementIndex, checked === "true" ? true : false, "checked");

            newState.rightBar = update(newState.rightBar, {
                changesSaved: { $set: false }
            });
            break;
        };

        case actions.handleFileValueChange: {
            const { sectionIndex, characteristicIndex, inputElementIndex, fileName, isDelete, modifier } = action.payload;
            const { activeStep } = state.leftBar;

            const files = state.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[inputElementIndex].mediaInput || [];
            const rowIndex = newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].row

            newState = handleModifierAutofill(newState, activeStep, sectionIndex, modifier, rowIndex);

            let newFiles = files.filter(x => x.mediaName);
            if (!isDelete) {
                newFiles.push({
                    mediaName: fileName
                });
            } else {
                const fileIndex = newFiles.findIndex(x => x.mediaName === fileName);
                newFiles.splice(fileIndex, 1);
            }

            newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[inputElementIndex] =
                update.set(newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[inputElementIndex], 'mediaInput', newFiles);

            newState.updateHandler = handleAtomicInputElementChange(newState, activeStep, sectionIndex, characteristicIndex, inputElementIndex, newFiles, 'mediaInput');

            newState.rightBar = update(newState.rightBar, {
                changesSaved: { $set: false }
            });
            break;
        };

        case actions.handleSectionAutofill: {
            const { sectionIndex, inspectionPlan } = action.payload;

            const { activeStep } = state.leftBar;

            const section = state.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex];

            if (section.type === groups.headerGrid) {
                const serviceNotificationNumber = get(inspectionPlan, 'serviceNotificationNumber') || 'n.a.';
                const originalCustomerOrderNumber = get(inspectionPlan, 'originalCustomerOrderNumber') || 'n.a.';

                const serviceNotificationNumberInput = findInputElementByLabel(section, headerGridTranslations.serviceNotificationNumber);
                const originalCustomerOrderNumberInput = findInputElementByLabel(section, headerGridTranslations.originalOrder);

                if (serviceNotificationNumberInput) {
                    newState = updateInputField(newState, activeStep, sectionIndex, serviceNotificationNumberInput.characteristicIndex, serviceNotificationNumberInput.inputElementIndex, 'value', serviceNotificationNumber);
                }

                if (originalCustomerOrderNumberInput) {
                    newState = updateInputField(newState, activeStep, sectionIndex, originalCustomerOrderNumberInput.characteristicIndex, originalCustomerOrderNumberInput.inputElementIndex, 'value', originalCustomerOrderNumber);
                }
            }

            newState.rightBar = update(newState.rightBar, {
                changesSaved: { $set: false }
            });
            break;
        };

        case actions.handleSerialNumberAutofill: {
            const { inspectionPlan, sectionIndex, characteristicIndex } = action.payload;
            const { activeStep } = state.leftBar;

            if (isArrayWithItems(inspectionPlan?.materials)) {
                const selectedColumn = state.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].column;

                const columnCharacteristics = state.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic
                    .filter(x => x.row !== 0 && x.column === selectedColumn)
                    .sort((a, b) => a.row - b.row);

                inspectionPlan.materials.forEach((material, materialIndex) => {
                    if (materialIndex < columnCharacteristics.length) {
                        const index = state.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic.findIndex(x => x._id === columnCharacteristics[materialIndex]._id);

                        if (index !== -1) {
                            newState = updateInputField(newState, activeStep, sectionIndex, index, 0, 'value', material.serialNumber);
                        }
                    }
                });

                if (inspectionPlan.materials.length > columnCharacteristics.length) {
                    const protocolSection = newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex];
                    const lastRowCharacteristics = protocolSection.documentationCharacteristic.filter(characteristic => characteristic.row === protocolSection.rows);

                    let newCharacteristics = [...protocolSection.documentationCharacteristic];

                    for (let i = 0; i < inspectionPlan.materials.length - columnCharacteristics.length; i++) {
                        lastRowCharacteristics.forEach(characteristic => {
                            newCharacteristics.push({
                                ...characteristic,
                                _id: createMongoObjectId(),
                                row: characteristic.row + i + 1,
                                inputElement: characteristic.inputElement.map(input => {
                                    return {
                                        ...input,
                                        _id: createMongoObjectId(),
                                        value: characteristic.column === selectedColumn ? inspectionPlan.materials[protocolSection.rows + i].serialNumber : input.value
                                    };
                                })
                            });
                        });
                    }

                    newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex] =
                        update.set(newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex], 'rows', inspectionPlan.materials.length);

                    newState = updateProtocolSection(newState, activeStep, sectionIndex, 'documentationCharacteristic', newCharacteristics);
                }
            }

            break;
        }

        case actions.handleFindingValueChange: {
            const { path, value } = action.payload;

            newState.findingForm = update.set(state.findingForm, path, value);
            break;
        }

        case actions.handleAddFinding: {
            const { finding, sectionId } = action.payload;

            const activeStep = state.leftBar.activeStep;

            const sectionIndex = state.rightBar.protocolTemplate[activeStep].protocolSection.findIndex(section => section._id === sectionId);

            if (sectionIndex !== -1) {
                newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex] = update(
                    state.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex],
                    {
                        finding: {
                            $push: [{
                                key: shortid.generate(),
                                ...finding
                            }]
                        }
                    }
                )

                newState.findingForm = update.set(state.findingForm, `${sectionId}`, null);
            }

            break;
        }

        case actions.handleUpdateFinding: {
            const { finding, sectionId, index, path } = action.payload;

            const activeStep = state.leftBar.activeStep;

            const sectionIndex = state.rightBar.protocolTemplate[activeStep].protocolSection.findIndex(section => section._id === sectionId);

            if (sectionIndex !== -1) {
                newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex] = update(
                    state.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex],
                    {
                        finding: {
                            [index]: { $set: finding }
                        }
                    }
                )

                newState.findingForm = update.set(state.findingForm, `${path}`, null);
            }

            break;
        }

        case actions.handleDeleteFinding: {
            const { sectionId, index, path } = action.payload;

            const activeStep = state.leftBar.activeStep;

            const sectionIndex = state.rightBar.protocolTemplate[activeStep].protocolSection.findIndex(section => section._id === sectionId);

            if (sectionIndex !== -1) {
                newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex] = update(
                    state.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex],
                    {
                        finding: {
                            $splice: [[index, 1]]
                        }
                    }
                )

                newState.findingForm = update.set(state.findingForm, `${path}`, null);
            }

            break;
        }

        default: break;
    }

    return newState;
};

const updateProtocolSection = (state, activeStep, sectionIndex, path, value) => {
    let newState = state;

    newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex] =
        update.set(newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex], path, value);

    newState.updateHandler = handleAtomicSectionChange(newState, activeStep, sectionIndex, value, path);

    return newState;
};

const updateInputField = (state, activeStep, sectionIndex, characteristicIndex, inputElementIndex, path, value) => {
    let newState = state;

    newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[inputElementIndex] =
        update.set(newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[inputElementIndex], path, value);

    newState.updateHandler = handleAtomicInputElementChange(newState, activeStep, sectionIndex, characteristicIndex, inputElementIndex, value);

    return newState;
};

const autofillSectionModifier = (state, activeStep, sectionIndex, rowIndex, modifier = '') => {
    let newState = state;

    const protocolSection = newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex];

    if (isArrayWithItems(protocolSection.documentationCharacteristic) && (protocolSection.type === viewType.table || protocolSection.type === viewType.list)) {
        newState = getNewStateWithModifierForTable(newState, protocolSection, activeStep, sectionIndex, rowIndex, modifier);
    } else if (isArrayWithItems(protocolSection.documentationCharacteristic)) {
        for (let documentationCharacteristicIndex = 0; documentationCharacteristicIndex < protocolSection.documentationCharacteristic.length; documentationCharacteristicIndex++) {

            const documentationCharacteristic = protocolSection.documentationCharacteristic[documentationCharacteristicIndex];

            if (isArrayWithItems(documentationCharacteristic.inputElement)) {
                for (let inputElementIndex = 0; inputElementIndex < documentationCharacteristic.inputElement.length; inputElementIndex++) {
                    const inputElement = documentationCharacteristic.inputElement[inputElementIndex];

                    if (inputElement.type === inputElements.person && inputElement.autofillPerson === "true" && !inputElement.defaultValue && !inputElement.value) {
                        newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[documentationCharacteristicIndex].inputElement[inputElementIndex] = update.set(
                            newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[documentationCharacteristicIndex].inputElement[inputElementIndex],
                            "value",
                            modifier
                        );

                        newState.updateHandler = handleAtomicInputElementChange(newState, activeStep, sectionIndex, documentationCharacteristicIndex, inputElementIndex, modifier || "");
                    }
                }
            }
        }
    }

    return {
        protocolSection: newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex],
        updateHandler: newState.updateHandler
    };
};

const getNewStateWithModifierForTable = (state, protocolSection, activeStep, sectionIndex, rowIndex, modifier = '') => {
    let newState = { ...state };

    const documentationCharacteristics = protocolSection.documentationCharacteristic
        .map((documentationCharacteristic, index) => ({ ...documentationCharacteristic, index }))
        .filter(x => !!x.inputElement.length);

    if (isArrayWithItems(documentationCharacteristics)) {
        const personInputElementsWithAutofill = documentationCharacteristics.filter(
            documentationCharacteristic => !!documentationCharacteristic.inputElement.length
                && documentationCharacteristic.inputElement.some(y => y.autofillPerson === "true")
        );

        if (isArrayWithItems(personInputElementsWithAutofill)) {
            const personalInputElement = personInputElementsWithAutofill.find(personInputElementWithAutofill => personInputElementWithAutofill.row === rowIndex);
            const personInputElementIndex = personalInputElement?.inputElement.findIndex(inputElement => inputElement.autofillPerson === "true");

            newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[personalInputElement.index].inputElement[personInputElementIndex] = update.set(
                newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex].documentationCharacteristic[personalInputElement.index].inputElement[personInputElementIndex],
                "value",
                modifier
            );

            newState.updateHandler = handleAtomicInputElementChange(newState, activeStep, sectionIndex, personalInputElement.index, personInputElementIndex, modifier);
        }
    }

    return newState;
};

const findAtomicProtocolSectionIndex = (newState, protocolSectionId) => {
    return newState.updateHandler.findIndex(atomic => {
        return (
            atomic.childrenIds.protocolSectionsId === protocolSectionId &&
            atomic.path === atomicProtocolSectionPath
        );
    });
};

const findAtomicInputElementIndex = (newState, protocolSectionId, documentationCharacteristicId, inputElementId) => {
    return newState.updateHandler.findIndex(atomic => {
        return (
            atomic.childrenIds.protocolSectionsId === protocolSectionId &&
            atomic.childrenIds.documentationCharacteristicId === documentationCharacteristicId &&
            atomic.childrenIds.inputElementId === inputElementId &&
            atomic.path === atomicInputElementPath
        );
    });
};

const getAtomicProtocolSectionChildrenIds = (newState, activeStep, protocolSectionIndex) => {
    const protocolSectionId = newState.rightBar.protocolTemplate[activeStep].protocolSection[protocolSectionIndex]._id;

    return {
        protocolSectionId
    };
};

const getAtomicInputElementChildrenIds = (newState, activeStep, protocolSectionIndex, documentationCharacteristicIndex, inputElementIndex) => {
    const protocolSectionId = newState.rightBar.protocolTemplate[activeStep].protocolSection[protocolSectionIndex]._id;
    const documentationCharacteristicId = newState.rightBar.protocolTemplate[activeStep].protocolSection[protocolSectionIndex].documentationCharacteristic[documentationCharacteristicIndex]._id;
    const inputElementId = newState.rightBar.protocolTemplate[activeStep].protocolSection[protocolSectionIndex].documentationCharacteristic[documentationCharacteristicIndex].inputElement[inputElementIndex]._id;

    return {
        protocolSectionId,
        documentationCharacteristicId,
        inputElementId
    };
};

const handleAtomicSectionChange = (newState, activeStep, protocolSectionIndex, value, path) => {
    const { protocolSectionId } = getAtomicProtocolSectionChildrenIds(newState, activeStep, protocolSectionIndex);
    const atomicIndex = findAtomicProtocolSectionIndex(newState, protocolSectionId);

    if (atomicIndex !== -1) {
        newState.updateHandler = update(newState.updateHandler, {
            [atomicIndex]: {
                values: {
                    protocolSection: {
                        [path]: {
                            $set: value
                        }
                    }
                }
            }
        });
    } else {
        newState.updateHandler = update(newState.updateHandler, {
            $push: [
                {
                    inspectionReportId: activeStep,
                    operation: operationAtomicEnum.update,
                    path: atomicProtocolSectionPath,
                    values: {
                        protocolSection: {
                            [path]: value
                        }
                    },
                    childrenIds: {
                        protocolSectionsId: protocolSectionId
                    }
                }
            ]
        });
    }

    return newState.updateHandler;
};

const handleAtomicInputElementChange = (newState, activeStep, protocolSectionIndex, documentationCharacteristicIndex, inputElementIndex, value, path = "value") => {
    const { protocolSectionId, documentationCharacteristicId, inputElementId } = getAtomicInputElementChildrenIds(newState, activeStep, protocolSectionIndex, documentationCharacteristicIndex, inputElementIndex);
    const atomicIndex = findAtomicInputElementIndex(newState, protocolSectionId, documentationCharacteristicId, inputElementId);

    if (atomicIndex !== -1) {
        newState.updateHandler = update(newState.updateHandler, {
            [atomicIndex]: {
                values: {
                    inputElement: {
                        [path]: {
                            $set: value
                        }
                    }
                }
            }
        });
    } else {
        newState.updateHandler = update(newState.updateHandler, {
            $push: [
                {
                    inspectionReportId: activeStep,
                    operation: operationAtomicEnum.update,
                    path: atomicInputElementPath,
                    values: {
                        inputElement: {
                            [path]: value
                        }
                    },
                    childrenIds: {
                        protocolSectionsId: protocolSectionId,
                        documentationCharacteristicId: documentationCharacteristicId,
                        inputElementId: inputElementId
                    }
                }
            ]
        });
    }

    return newState.updateHandler;
};

const handleModifierAutofill = (newState, activeStep, sectionIndex, modifier, rowIndex) => {
    const { protocolSection, updateHandler } = autofillSectionModifier(newState, activeStep, sectionIndex, rowIndex, modifier);

    newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex] = update(newState.rightBar.protocolTemplate[activeStep].protocolSection[sectionIndex], {
        $set: protocolSection
    });

    newState.updateHandler = updateHandler;

    return newState;
};

export const handleValueChange = (path, value) => {
    return {
        type: actions.handleValueChange,
        payload: {
            path,
            value
        }
    };
};

export const handleManufacturingStepChange = (isOpen, assemblyId, groupId) => {
    return {
        type: actions.handleManufacturingStepChange,
        payload: {
            isOpen,
            assemblyId,
            groupId
        }
    };
};

export const initProtocolTemplate = (protocol, disableMapping = false) => {
    return {
        type: actions.initProtocolTemplate,
        payload: {
            protocol,
            disableMapping
        }
    };
};

export const initActiveStep = (assemblyId, groupId, activeStep) => {
    return {
        type: actions.initActiveStep,
        payload: {
            assemblyId, groupId, activeStep
        }
    };
};

export const handleInputElementValueChange = (sectionIndex, characteristicIndex, inputElementIndex, value, modifier, rowIndex) => {
    return {
        type: actions.handleInputElementValueChange,
        payload: {
            sectionIndex,
            characteristicIndex,
            inputElementIndex,
            value,
            modifier,
            rowIndex
        }
    };
};

export const handleInputElementFormattedValueChange = (sectionIndex, characteristicIndex, inputElementIndex, value, formattedValue, modifier) => {
    return {
        type: actions.handleInputElementFormattedValueChange,
        payload: {
            sectionIndex,
            characteristicIndex,
            inputElementIndex,
            value,
            formattedValue,
            modifier
        }
    };
};

export const handleInputElementRadioChange = (sectionIndex, characteristicIndex, inputElementIndex, checked, modifier) => {
    return {
        type: actions.handleInputElementRadioChange,
        payload: {
            sectionIndex,
            characteristicIndex,
            inputElementIndex,
            checked,
            modifier
        }
    };
};

export const handleFileValueChange = (sectionIndex, characteristicIndex, inputElementIndex, fileName, modifier, isDelete = false) => {
    return {
        type: actions.handleFileValueChange,
        payload: {
            fileName,
            sectionIndex,
            characteristicIndex,
            inputElementIndex,
            modifier,
            isDelete
        }
    };
};

export const handleSectionAutofill = (sectionIndex, inspectionPlan, language) => {
    return {
        type: actions.handleSectionAutofill,
        payload: {
            sectionIndex,
            inspectionPlan,
            language
        }
    }
};

export const handleSerialNumberAutofill = (inspectionPlan, sectionIndex, characteristicIndex) => {
    return {
        type: actions.handleSerialNumberAutofill,
        payload: {
            inspectionPlan,
            sectionIndex,
            characteristicIndex
        }
    }
};

export const handleFindingValueChange = (path, value) => {
    return {
        type: actions.handleFindingValueChange,
        payload: {
            path,
            value
        }
    };
};

export const handleAddFinding = (finding, sectionId) => {
    return {
        type: actions.handleAddFinding,
        payload: {
            finding,
            sectionId
        }
    };
};

export const handleUpdateFinding = (finding, sectionId, index, path) => {
    return {
        type: actions.handleUpdateFinding,
        payload: {
            finding,
            sectionId,
            index,
            path
        }
    };
};

export const handleDeleteFinding = (sectionId, index, path) => {
    return {
        type: actions.handleDeleteFinding,
        payload: {
            sectionId,
            index,
            path
        }
    };
};
