// UTILS
import { isArrayWithItems } from "../../../../utils/arrayHelper";

// CONSTANTS
import { groups } from "./groups";
import { getElementSettings } from "./setup";

export const configurationType = {
    protocolSection: "protocolSection",
    characteristicMediaOutputs: "characteristicMediaOutputs",
    characteristicSignature: "characteristicSignature",
    documentationCharacteristic: "documentationCharacteristic",
    inputElement: "inputElement",
};

export const storagePath = {
    protocolSections: "protocolSections",
    documentationCharacteristics: "documentationCharacteristics",
    inputElements: "inputElements"
};

export const configurationTypeSettings = {
    [configurationType.protocolSection]: {
        id: 'protocolSectionId',
        canDuplicate: sectionType => {
            return sectionType !== groups.title && sectionType !== groups.footer;
        },
        getStoragePath: () => { return storagePath.protocolSections },
        findProtocolTemplateElement: (data, selectedElement) => {
            for (var protocolSectionIndex = 0; protocolSectionIndex < data.protocolSection.length; protocolSectionIndex++) {
                const protocolSection = data.protocolSection[protocolSectionIndex];

                if (protocolSection._id === selectedElement.protocolSectionId) {
                    return {
                        ...protocolSection,
                        elementIndex: protocolSectionIndex,
                        sectionType: protocolSection.type
                    };
                }
            }
        }
    },
    [configurationType.characteristicMediaOutputs]: {
        id: 'mediaOutputId',
        canDuplicate: () => {
            return true;
        },
        getStoragePath: () => { return storagePath.inputElements; },
        findProtocolTemplateElement: (data, selectedElement) => {
            for (var protocolSectionIndex = 0; protocolSectionIndex < data.protocolSection.length; protocolSectionIndex++) {
                const protocolSection = data.protocolSection[protocolSectionIndex];

                if (isArrayWithItems(protocolSection.mediaOutputs)) {
                    for (var mediaIndex = 0; mediaIndex < protocolSection.mediaOutputs.length; mediaIndex++) {
                        const mediaOutput = protocolSection.mediaOutputs[mediaIndex];

                        if (mediaOutput._id === selectedElement.mediaOutputId) {
                            return {
                                ...mediaOutput,
                                mediaIndex,
                                elementIndex: protocolSectionIndex,
                                type: configurationType.characteristicMediaOutputs,
                                sectionType: protocolSection.type
                            }
                        }
                    }
                }
            }
        }
    },
    [configurationType.characteristicSignature]: {
        id: 'signatureElementId',
        canDuplicate: () => {
            return true;
        },
        getStoragePath: () => { return storagePath.inputElements; },
        findProtocolTemplateElement: (data, selectedElement) => {
            for (var protocolSectionIndex = 0; protocolSectionIndex < data.protocolSection.length; protocolSectionIndex++) {
                const protocolSection = data.protocolSection[protocolSectionIndex];

                if (isArrayWithItems(protocolSection.signedBy)) {
                    for (var signatureIndex = 0; signatureIndex < protocolSection.signedBy.length; signatureIndex++) {
                        const signature = protocolSection.signedBy[signatureIndex];

                        if (signature._id === selectedElement.signatureElementId) {
                            return {
                                ...signature,
                                signatureIndex,
                                elementIndex: protocolSectionIndex,
                                type: configurationType.characteristicSignature,
                                sectionType: protocolSection.type
                            }
                        }
                    }
                }
            }
        }
    },
    [configurationType.documentationCharacteristic]: {
        id: 'documentationCharacteristicId',
        canDuplicate: sectionType => {
            return sectionType === groups.list || sectionType === groups.imageSection;
        },
        getStoragePath: () => { return storagePath.documentationCharacteristics; },
        findProtocolTemplateElement: (data, selectedElement) => {
            for (var protocolSectionIndex = 0; protocolSectionIndex < data.protocolSection.length; protocolSectionIndex++) {
                const protocolSection = data.protocolSection[protocolSectionIndex];

                if (isArrayWithItems(protocolSection.documentationCharacteristic)) {
                    for (var documentationCharacteristicIndex = 0; documentationCharacteristicIndex < protocolSection.documentationCharacteristic.length; documentationCharacteristicIndex++) {
                        const documentationCharacteristic = protocolSection.documentationCharacteristic[documentationCharacteristicIndex];

                        if (documentationCharacteristic._id === selectedElement.documentationCharacteristicId) {
                            return {
                                ...documentationCharacteristic,
                                documentationCharacteristicIndex,
                                elementIndex: protocolSectionIndex,
                                type: configurationType.documentationCharacteristic,
                                sectionType: protocolSection.type
                            };
                        }
                    }
                }
            }
        }
    },
    [configurationType.inputElement]: {
        id: 'inputElementId',
        canDuplicate: (sectionType, protocolTemplate, protocolElement) => {
            const { inputElementIndex, documentationCharacteristicIndex, elementIndex } = protocolElement;
            const inputElement = protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[documentationCharacteristicIndex].inputElement;

            return getElementSettings()[sectionType].canDrop(
                inputElement,
                {
                    ...inputElement[inputElementIndex],
                    value: inputElement[inputElementIndex].type
                }
            );
        },
        getStoragePath: () => { return storagePath.inputElements; },
        findProtocolTemplateElement: (data, selectedElement) => {
            for (var protocolSectionIndex = 0; protocolSectionIndex < data.protocolSection.length; protocolSectionIndex++) {
                const protocolSection = data.protocolSection[protocolSectionIndex];

                if (isArrayWithItems(protocolSection.documentationCharacteristic)) {
                    for (var documentationCharacteristicIndex = 0; documentationCharacteristicIndex < protocolSection.documentationCharacteristic.length; documentationCharacteristicIndex++) {
                        const documentationCharacteristic = protocolSection.documentationCharacteristic[documentationCharacteristicIndex];

                        if (isArrayWithItems(documentationCharacteristic.inputElement)) {
                            for (var inputElementIndex = 0; inputElementIndex < documentationCharacteristic.inputElement.length; inputElementIndex++) {
                                const inputElement = documentationCharacteristic.inputElement[inputElementIndex];

                                if (inputElement._id === selectedElement.inputElementId) {
                                    return {
                                        ...inputElement,
                                        inputElementIndex,
                                        documentationCharacteristicIndex,
                                        elementIndex: protocolSectionIndex,
                                        sectionType: protocolSection.type
                                    };
                                }
                            }
                        }
                    }
                }
            }
        }
    }
};