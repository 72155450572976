import update from "../../../redux/update";

const actions = {
    setValue: "IMAGE_UPLOAD_SET_VALUE"
};

const initialState = {
    file: null,
    imagePreview: null
};

// The main purpose is to store form data. 
export const imageUploadReducer = (state = initialState, action) => {
    let newState = state;

    switch (action.type) {
        case actions.setValue:
            newState = update(state, {
                file: { $set: action.payload.file },
                imagePreview: { $set: action.payload.imagePreview }
            });
            break;

        default: break;
    }

    return newState;
};

export const setValueAction = (file, imagePreview) => {
    return {
        type: actions.setValue,
        payload: {
            file,
            imagePreview
        }
    };
};