export const typeOfExport = {
    internal: 'internal',
    external: 'external',
};

export const typeOfExportOptions = [
    {
        value: typeOfExport.internal,
        label: 'common.pdfSettings.typeOfExport.internal',
    },
    {
        value: typeOfExport.external,
        label: 'common.pdfSettings.typeOfExport.external',
    },
];

export const typeOfBulkExport = {
    all: 'all',
    internal: 'internal',
    external: 'external',
};

export const typeOfBulkExportOptions = [
    {
        value: typeOfBulkExport.internal,
        label: 'common.pdfSettings.typeOfExport.internal',
    },
    {
        value: typeOfBulkExport.external,
        label: 'common.pdfSettings.typeOfExport.external',
    },
    {
        value: typeOfBulkExport.all,
        label: 'common.pdfSettings.typeOfExport.all',
    },
];
