import i18next from "i18next";

export const weekdays = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6
};

export const weekdayLabel = {
    [weekdays.monday]: "common.weekdays.monday.label",
    [weekdays.tuesday]: "common.weekdays.tuesday.label",
    [weekdays.wednesday]: "common.weekdays.wednesday.label",
    [weekdays.thursday]: "common.weekdays.thursday.label",
    [weekdays.friday]: "common.weekdays.friday.label",
    [weekdays.saturday]: "common.weekdays.saturday.label",
    [weekdays.sunday]: "common.weekdays.sunday.label"
};

export const weekdayName = {
    [weekdays.monday]: "common.weekdays.monday.name",
    [weekdays.tuesday]: "common.weekdays.tuesday.name",
    [weekdays.wednesday]: "common.weekdays.wednesday.name",
    [weekdays.thursday]: "common.weekdays.thursday.name",
    [weekdays.friday]: "common.weekdays.friday.name",
    [weekdays.saturday]: "common.weekdays.saturday.name",
    [weekdays.sunday]: "common.weekdays.sunday.name"
};

export const getWeekdayOptions = () => [
    {
        label: i18next.t(weekdayLabel[weekdays.monday]),
        value: weekdays.monday
    },
    {
        label: i18next.t(weekdayLabel[weekdays.tuesday]),
        value: weekdays.tuesday
    },
    {
        label: i18next.t(weekdayLabel[weekdays.wednesday]),
        value: weekdays.wednesday
    },
    {
        label: i18next.t(weekdayLabel[weekdays.thursday]),
        value: weekdays.thursday
    },
    {
        label: i18next.t(weekdayLabel[weekdays.friday]),
        value: weekdays.friday
    },
    {
        label: i18next.t(weekdayLabel[weekdays.saturday]),
        value: weekdays.saturday
    },
    {
        label: i18next.t(weekdayLabel[weekdays.sunday]),
        value: weekdays.sunday
    }
];