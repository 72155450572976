import moment from 'moment';
import { tableColumnTypes } from '../../common/templates/constants';

export const waypointBottomOffset = '-200px';

export const columnTextSize = 77;
export const fetchTicketsPageSize = 50;

export const dateRequestFormat = 'YYYY-MM-DDTHH:mm[Z]';
export const fullYearFormat = 'YYYY';
export const shortMonthFormat = 'MMM';
export const convertedMonthFormat = 'MM';
export const ticketScedulingData = 'DD.MM';
export const monthYearFormat = 'MM.YYYY';

export const getIncidentTypeFilterString = (timestampFrom = null, timestampTo = null) => {
    const from = !!timestampFrom && moment(timestampFrom).format(dateRequestFormat);
    const to = !!timestampTo && moment(timestampTo).format(dateRequestFormat);

    if (from && to) {
        return `{"deleted": false, "ticketCreatedBy.createdAt": {"$gte": "${from}","$lt": "${to}"}}`;
    }

    if (!from && to) {
        return `{"deleted": false, "ticketCreatedBy.createdAt": {"$lt": "${to}"}}`;
    }

    if (from && !to) {
        return `{"deleted": false, "ticketCreatedBy.createdAt": {"$gte": "${from}"}}`;
    }

    return `{"deleted": false}`;
};

export const getTicketReportFilterString = (timestampFrom = null, timestampTo = null) => {
    const from = !!timestampFrom && moment(timestampFrom).format(dateRequestFormat);
    const to = !!timestampTo && moment(timestampTo).format(dateRequestFormat);

    if (from && to) {
        return `{"deleted": false, "ticketCreatedBy.createdAt": {"$gte": "${from}","$lt": "${to}"}}`;
    }

    if (!from && to) {
        return `{"deleted": false, "ticketCreatedBy.createdAt": {"$lt": "${to}"}}`;
    }

    if (from && !to) {
        return `{"deleted": false, "ticketCreatedBy.createdAt": {"$gte": "${from}"}}`;
    }

    return `{"deleted": false}`;
};

export const defaultOrderKey = 'plannedIncomingDate';
export const ticketTableSortBy = '{"schedulingData.plannedIncomingDate":-1}';

export const ticketReportsTableColumns = [
    {
        key: 'plannedIncomingDate',
        title: `maintenance-tickets.table.columns.plannedIncomingDate`,
        columnType: tableColumnTypes.date,
        orderBy: 'schedulingData.plannedIncomingDate',
    },
    {
        key: 'serialNo',
        title: `maintenance-tickets.table.columns.serialNo`,
        columnType: tableColumnTypes.string,
        orderBy: 'asset.serialNo',
    },
    {
        key: 'name',
        title: `maintenance-tickets.table.columns.asset`,
        columnType: tableColumnTypes.string,
        orderBy: 'asset.name',
    },
    {
        key: 'description',
        title: `maintenance-tickets.table.columns.description`,
        columnType: tableColumnTypes.string,
        orderBy: 'description',
    },
    {
        key: 'costCenter',
        title: `maintenance-tickets.table.columns.costCenter`,
        columnType: tableColumnTypes.string,
        orderBy: 'asset.costCenter',
    },
    {
        key: 'action',
        title: `maintenance-tickets.table.columns.action`,
        columnType: tableColumnTypes.string,
        orderBy: 'action',
    },
];
