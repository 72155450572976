import i18next from 'i18next';

import { APP_NAME } from '../utils/env';

import { reactAppName } from './Config';

import { ticketStatus } from './TicketStatus';

export const ticketBuckets = {
    new: 'new',
    inShift: 'inShift',
    shiftHandover: 'shiftHandover',
    weekend: 'weekend',
    finished: 'finished',
};

export const ticketBucketsRelatedStatus = {
    [ticketBuckets.new]: ticketStatus.open,
    [ticketBuckets.inShift]: ticketStatus.inWork,
    [ticketBuckets.shiftHandover]: ticketStatus.inWork,
    [ticketBuckets.weekend]: ticketStatus.inWork,
    [ticketBuckets.finished]: ticketStatus.done,
};

export const ticketStatusOptions = [
    {
        title: i18next.t('maintenance-standard-tickets.ticket-template.shiftButtons.open'),
        value: ticketStatus.open,
        bucket: ticketBuckets.new,
    },
    {
        title: i18next.t('maintenance-standard-tickets.ticket-template.shiftButtons.inWork'),
        value: ticketStatus.inWork,
        bucket: ticketBuckets.weekend,
    },
    {
        title: i18next.t('maintenance-standard-tickets.ticket-template.shiftButtons.done'),
        value: ticketStatus.done,
        bucket: ticketBuckets.finished,
    },
];

export const ticketBucketsTranslations = {
    [ticketBuckets.new]:
        APP_NAME === reactAppName.machine_maintenance
            ? 'machine-maintenance-tickets.buckets.new'
            : 'maintenance-standard-tickets.buckets.new',
    [ticketBuckets.inShift]:
        APP_NAME === reactAppName.machine_maintenance
            ? 'machine-maintenance-tickets.buckets.inShift'
            : 'maintenance-standard-tickets.buckets.inShift',
    [ticketBuckets.shiftHandover]:
        APP_NAME === reactAppName.machine_maintenance
            ? 'machine-maintenance-tickets.buckets.shiftHandover'
            : 'maintenance-standard-tickets.buckets.shiftHandover',
    [ticketBuckets.weekend]:
        APP_NAME === reactAppName.machine_maintenance
            ? 'machine-maintenance-tickets.buckets.weekend'
            : 'maintenance-standard-tickets.buckets.weekend',
    [ticketBuckets.finished]:
        APP_NAME === reactAppName.machine_maintenance
            ? 'machine-maintenance-tickets.buckets.finished'
            : 'maintenance-standard-tickets.buckets.finished',
};

export const getBucketOptions = () => [
    {
        color: '#EF7A12',
        title: i18next.t(ticketBucketsTranslations[ticketBuckets.new]),
        value: ticketBuckets.new,
        relatedStatus: ticketBucketsRelatedStatus[ticketBuckets.new],
    },
    {
        color: '#EF7A12',
        title: i18next.t(ticketBucketsTranslations[ticketBuckets.inShift]),
        value: ticketBuckets.inShift,
        relatedStatus: ticketBucketsRelatedStatus[ticketBuckets.inShift],
    },
    {
        color: '#EF7A12',
        title: i18next.t(ticketBucketsTranslations[ticketBuckets.shiftHandover]),
        value: ticketBuckets.shiftHandover,
        relatedStatus: ticketBucketsRelatedStatus[ticketBuckets.shiftHandover],
    },
    {
        color: '#EF7A12',
        title: i18next.t(ticketBucketsTranslations[ticketBuckets.weekend]),
        value: ticketBuckets.weekend,
        relatedStatus: ticketBucketsRelatedStatus[ticketBuckets.weekend],
    },
    {
        color: '#0EBF5A',
        title: i18next.t(ticketBucketsTranslations[ticketBuckets.finished]),
        value: ticketBuckets.finished,
        relatedStatus: ticketBucketsRelatedStatus[ticketBuckets.finished],
    },
];
