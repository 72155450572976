export const routingStepperActions = {
    SET_ACTIVE_STEP_AND_ROUTING_TYPE: 'ROUTING_CREATION/SET_ACTIVE_STEP_AND_ROUTING_TYPE',
    SET_ROUTING_TYPE: 'ROUTING_CREATION/SET_ROUTING_TYPE',
    CHANGE_ROUTING_NAME: 'ROUTING_CREATION/CHANGE_ROUTING_NAME',
    CHANGE_ROUTING_DESCRIPTION: 'ROUTING_CREATION/CHANGE_ROUTING_DESCRIPTION',
    SET_ACTIVE_STEP: 'ROUTING_CREATION/SET_ACTIVE_STEP',
    CHANGE_SELECTED_STEPS: 'ROUTING_CREATION/CHANGE_SELECTED_STEPS',
    CHANGE_SEARCH_TERM: 'ROUTING_CREATION/CHANGE_SEARCH_TERM',
    CHANGE_SELECTED_TYPES: 'ROUTING_CREATION/CHANGE_SELECTED_TYPES',
    RESET_TO_INITIAL_STATE: 'ROUTING_CREATION/RESET_TO_INITIAL_STATE',
    SET_ROUTING_TAG: 'ROUTING_CREATION/SET_ROUTING_TAG',
    RESET_FILTER_TO_INITIAL_STATE: 'ROUTING_CREATION/RESET_FILTER_TO_INITIAL_STATE',
    ADD_SELECTED_STEP: 'ROUTING_CREATION/ADD_SELECTED_STEP',
    SET_ROUTING_STEPPER_MODEL: 'ROUTING_CREATION/SET_ROUTING_STEPPER_MODEL',
    GO_TO_NEXT_STEP: 'ROUTING_CREATION/GO_TO_NEXT_STEP',
    GO_TO_PREV_STEP: 'ROUTING_CREATION/GO_TO_PREV_STEP',
};

export const setActiveStepAndRoutingType = payload => ({
    type: routingStepperActions.SET_ACTIVE_STEP_AND_ROUTING_TYPE,
    payload,
});

export const setActiveStep = payload => ({
    type: routingStepperActions.SET_ACTIVE_STEP,
    payload,
});

export const setRoutingType = payload => ({
    type: routingStepperActions.SET_ROUTING_TYPE,
    payload,
});

export const setRoutingName = payload => ({
    type: routingStepperActions.CHANGE_ROUTING_NAME,
    payload,
});

export const setRoutingDescription = payload => ({
    type: routingStepperActions.CHANGE_ROUTING_DESCRIPTION,
    payload,
});

export const changeSelectedSteps = payload => ({
    type: routingStepperActions.CHANGE_SELECTED_STEPS,
    payload,
});

export const changeSearchTerm = payload => ({
    type: routingStepperActions.CHANGE_SEARCH_TERM,
    payload,
});

export const changeSelectedTypes = payload => ({
    type: routingStepperActions.CHANGE_SELECTED_TYPES,
    payload,
});

export const resetToInitialState = () => ({
    type: routingStepperActions.RESET_TO_INITIAL_STATE,
});

export const setRoutingTag = payload => ({
    type: routingStepperActions.SET_ROUTING_TAG,
    payload,
});

export const resetFiltersToInitialState = () => ({
    type: routingStepperActions.RESET_FILTER_TO_INITIAL_STATE,
});

export const addSelectedSteps = payload => ({
    type: routingStepperActions.ADD_SELECTED_STEP,
    payload,
});

export const setRoutingStepperModel = payload => ({
    type: routingStepperActions.SET_ROUTING_STEPPER_MODEL,
    payload,
});

export const routingStepperGoToNextStep = () => ({
    type: routingStepperActions.GO_TO_NEXT_STEP,
});

export const routingStepperGoToPrevStep = () => ({
    type: routingStepperActions.GO_TO_PREV_STEP,
});
