export const editSchedulerTaskActions = {
    SET_STD_TASK_MODEL: 'EDIT_SCHEDULER_TASK/SET_STD_TASK_MODEL',
    SET_STD_COST_CENTER: 'EDIT_SCHEDULER_TASK/SET_STD_COST_CENTER',
    SET_STD_PARENT_ASSET: 'EDIT_SCHEDULER_TASK/SET_STD_PARENT_ASSET',
    SET_STD_CHILD_ASSET: 'EDIT_SCHEDULER_TASK/SET_STD_CHILD_ASSET',
    SET_STD_FREQUENCY: 'EDIT_SCHEDULER_TASK/SET_STD_FREQUENCY',
    SET_STD_RESPOSIBLE: 'EDIT_SCHEDULER_TASK/SET_STD_RESPOSIBLE',
    SET_STD_DUE_DAY_OPTIONS_DAYS: 'EDIT_SCHEDULER_TASK/SET_STD_DUE_DAY_OPTIONS_DAYS',
    SET_STD_DUE_DAY_OPTIONS_WEEKS: 'EDIT_SCHEDULER_TASK/SET_STD_DUE_DAY_OPTIONS_WEEKS',
    SET_STD_TASK_DESCRIPTION: 'EDIT_SCHEDULER_TASK/SET_STD_TASK_DESCRIPTION',
    SET_STD_JOB_CATEGORY: 'EDIT_SCHEDULER_TASK/SET_STD_JOB_CATEGORY',
    SET_STD_SCHEDULED_END_DATE: 'EDIT_SCHEDULER_TASK/SET_STD_SCHEDULED_END_DATE',
    SET_STD_SCHEDULED_START_DATE: 'EDIT_SCHEDULER_TASK/SET_STD_SCHEDULED_START_DATE',
    SET_STD_IN_ADVANCE_DAYS: 'EDIT_SCHEDULER_TASK/SET_STD_IN_ADVANCE_DAYS',
    UPDATE_STD_CHECK_LIST_ENTRY: 'EDIT_SCHEDULER_TASK/UPDATE_STD_CHECK_LIST_ENTRY',
    UPDATE_STD_CHECK_LIST: 'EDIT_SCHEDULER_TASK/UPDATE_STD_CHECK_LIST',
    ADD_STD_CHECK_LIST_ENTRY: 'EDIT_SCHEDULER_TASK/ADD_STD_CHECK_LIST_ENTRY',
    REMOVE_STD_CHECK_LIST_ENTRY: 'EDIT_SCHEDULER_TASK/REMOVE_STD_CHECK_LIST_ENTRY',
    REMOVE_STD_CHECK_LIST: 'EDIT_SCHEDULER_TASK/REMOVE_STD_CHECK_LIST',
    BULK_STD_ADD_CHECK_LIST: 'EDIT_SCHEDULER_TASK/BULK_STD_ADD_CHECK_LIST',
    ADD_STD_CHECK_LIST: 'EDIT_SCHEDULER_TASK/ADD_STD_CHECK_LIST',
    REMOVE_STD_ATTACHMENT: 'EDIT_SCHEDULER_TASK/REMOVE_STD_ATTACHMENT',
    ADD_STD_ATTACHMENT: 'EDIT_SCHEDULER_TASK/ADD_STD_ATTACHMENT',
    SET_STD_IS_VALID: 'EDIT_SCHEDULER_TASK/SET_STD_IS_VALID',
};

export const setTaskModel = payload => ({
    type: editSchedulerTaskActions.SET_STD_TASK_MODEL,
    payload,
});

export const setCostCenter = payload => ({
    type: editSchedulerTaskActions.SET_STD_COST_CENTER,
    payload,
});

export const setParentAsset = payload => ({
    type: editSchedulerTaskActions.SET_STD_PARENT_ASSET,
    payload,
});

export const setChildAsset = payload => ({
    type: editSchedulerTaskActions.SET_STD_CHILD_ASSET,
    payload,
});

export const setFrequency = payload => ({
    type: editSchedulerTaskActions.SET_STD_FREQUENCY,
    payload,
});

export const setResponsible = payload => ({
    type: editSchedulerTaskActions.SET_STD_RESPOSIBLE,
    payload,
});

export const setDueDayOptionsDays = payload => ({
    type: editSchedulerTaskActions.SET_STD_DUE_DAY_OPTIONS_DAYS,
    payload,
});

export const setDueDayOptionsWeeks = payload => ({
    type: editSchedulerTaskActions.SET_STD_DUE_DAY_OPTIONS_WEEKS,
    payload,
});

export const setTaskDescription = payload => ({
    type: editSchedulerTaskActions.SET_STD_TASK_DESCRIPTION,
    payload,
});

export const setTaskJobCategory = payload => ({
    type: editSchedulerTaskActions.SET_STD_JOB_CATEGORY,
    payload,
});

export const setTaskScheduledEndDate = payload => ({
    type: editSchedulerTaskActions.SET_STD_SCHEDULED_END_DATE,
    payload,
});

export const setTaskScheduledStartDate = payload => ({
    type: editSchedulerTaskActions.SET_STD_SCHEDULED_START_DATE,
    payload,
});

export const setInAdvanceDays = payload => ({
    type: editSchedulerTaskActions.SET_STD_IN_ADVANCE_DAYS,
    payload,
});

export const updateChecklistEntry = payload => ({
    type: editSchedulerTaskActions.UPDATE_STD_CHECK_LIST_ENTRY,
    payload,
});

export const updateChecklist = payload => ({
    type: editSchedulerTaskActions.UPDATE_STD_CHECK_LIST,
    payload,
});

export const addChecklistEntry = payload => ({
    type: editSchedulerTaskActions.ADD_STD_CHECK_LIST_ENTRY,
    payload,
});

export const removeChecklistEntry = payload => ({
    type: editSchedulerTaskActions.REMOVE_STD_CHECK_LIST_ENTRY,
    payload,
});

export const removeChecklist = payload => ({
    type: editSchedulerTaskActions.REMOVE_STD_CHECK_LIST,
    payload,
});

export const bulkAddCheckList = payload => ({
    type: editSchedulerTaskActions.BULK_STD_ADD_CHECK_LIST,
    payload,
});

export const addCheckList = payload => ({
    type: editSchedulerTaskActions.ADD_STD_CHECK_LIST,
    payload,
});

export const removeTicketAttachment = payload => ({
    type: editSchedulerTaskActions.REMOVE_STD_ATTACHMENT,
    payload,
});

export const addTicketAttachment = payload => ({
    type: editSchedulerTaskActions.ADD_STD_ATTACHMENT,
    payload,
});

export const setIsValid = payload => ({
    type: editSchedulerTaskActions.SET_STD_IS_VALID,
    payload,
});
