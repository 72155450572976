export const orderManagementActions = {
    SET_SEARCH_TERM: 'ORDER_MANAGEMENT/SET_SEARCH_TERM',
    CHANGE_FILTER: 'ORDER_MANAGEMENT/CHANGE_FILTER',
    SET_LIST_ITEMS: 'ORDER_MANAGEMENT/SET_LIST_ITEMS',
    TOGGLE_FILTER_VISIBILITY: 'ORDER_MANAGEMENT/TOGGLE_FILTER_VISIBILITY',
    CLEAR_FILTERS: 'ORDER_MANAGEMENT/CLEAR_FILTERS',
    RESET_TO_INITIAL_STATE: 'ORDER_MANAGEMENT/RESET_TO_INITIAL_STATE',
};

export const orderManagementSetSearchTerm = payload => ({
    type: orderManagementActions.SET_SEARCH_TERM,
    payload,
});

export const changeOrderManagementFilter = payload => ({
    type: orderManagementActions.CHANGE_FILTER,
    payload,
});

export const setOrderManagementSetList = payload => ({
    type: orderManagementActions.SET_LIST_ITEMS,
    payload,
});

export const toggleOrderManagementFilterVisibility = payload => ({
    type: orderManagementActions.TOGGLE_FILTER_VISIBILITY,
    payload,
});

export const orderManagementClearFilters = () => ({
    type: orderManagementActions.CLEAR_FILTERS,
});

export const orderManagementResetToInitialState = () => ({
    type: orderManagementActions.RESET_TO_INITIAL_STATE,
});
