export const rigthBarActions = {
    SET_PROTOCOL_TEMPLATE: 'RIGTH_BAR/SET_PROTOCOL_TEMPLATE',
    SET_UNSIGNING_MODAL_STATE: 'RIGTH_BAR/SET_UNSIGNING_MODAL_STATE',
    SET_SIGNING_MODAL_STATE: 'RIGTH_BAR/SET_SIGNING_MODAL_STATE',
    SET_SECTIONS_MODEL_PROTOCOL_TEMPLATE: 'RIGTH_BAR/ADD_SECTIONS_TO_PROTOCOL_TEMPLATE',
    SET_IS_SHOW_WARNINGS: 'RIGTH_BAR/SET_IS_SHOW_WARNINGS',
    ADD_SECTION_MODEL_PROTOCOL_TEMPLATE: 'RIGTH_BAR/ADD_SECTION_MODEL_PROTOCOL_TEMPLATE',
    ADD_FINDING: 'RIGTH_BAR/ADD_FINDING',
    UPDATE_FINDING: 'RIGTH_BAR/UPDATE_FINDING',
    REMOVE_FINDING: 'RIGTH_BAR/REMOVE_FINDING',
    UNSIGN_INSPECTION_REPORT: 'RIGTH_BAR/UNSIGN_INSPECTION_REPORT',
    SIGN_INSPECTION_REPORT: 'RIGTH_BAR/SIGN_INSPECTION_REPORT',
    CHANGE_SIGN_INSPECTION_REPORT_SECTION: 'RIGTH_BAR/SIGN_INSPECTION_REPORT_SECTION',
    SET_ATTACHMENT: 'RIGTH_BAR/SET_ATTACHMENT',
    CHANGE_SIGN_INPUT_ELEMENT: 'RIGTH_BAR/CHANGE_SIGN_INPUT_ELEMENT',
    CHANGE_INPUT: 'RIGTH_BAR/CHANGE_INPUT',
    CHANGE_FORMATTED_INPUT: 'RIGTH_BAR/CHANGE_FORMATTED_INPUT',
    CHANGE_RADIO_INPUT: 'RIGTH_BAR/CHANGE_RADIO_INPUT',
    CHANGE_FILE_INPUT: 'RIGTH_BAR/CHANGE_FILE_INPUT',
    CLEAR_UPDATE_HANDLER: 'RIGTH_BAR/CLEAR_UPDATE_HANDLER',
    SET_INITIAL_STATE_RIGTH_BAR: 'RIGTH_BAR/SET_INITIAL_STATE_RIGTH_BAR',
    AUTOFILL_COLUMN_SERIAL_NUMBER: 'RIGTH_BAR/AUTOFILL_COLUMN_SERIAL_NUMBER',
    CHANGE_CONFIRMATION_MODAL_STATE: 'RIGTH_BAR/CHANGE_CONFIRMATION_MODAL_STATE',
    CHANGE_MEDIA_INPUT: 'RIGTH_BAR/CHANGE_MEDIA_INPUT',
    CHANGE_ISVALID: 'RIGTH_BAR/CHANGE_ISVALID',
};

export const changeIsValid = payload => ({
    type: rigthBarActions.CHANGE_ISVALID,
    payload,
});

export const setProtocolTemplate = payload => ({
    type: rigthBarActions.SET_PROTOCOL_TEMPLATE,
    payload,
});

export const setUnsigningModalState = payload => ({
    type: rigthBarActions.SET_UNSIGNING_MODAL_STATE,
    payload,
});

export const setSigningModalState = payload => ({
    type: rigthBarActions.SET_SIGNING_MODAL_STATE,
    payload,
});

export const setSectionsModelProtocolTemplate = payload => ({
    type: rigthBarActions.SET_SECTIONS_MODEL_PROTOCOL_TEMPLATE,
    payload,
});

export const setIsShowWarnings = payload => ({
    type: rigthBarActions.SET_IS_SHOW_WARNINGS,
    payload,
});

export const addSectionModelProtocolTemplate = payload => ({
    type: rigthBarActions.ADD_SECTION_MODEL_PROTOCOL_TEMPLATE,
    payload,
});

export const addFinding = payload => ({
    type: rigthBarActions.ADD_FINDING,
    payload,
});

export const updateFinding = payload => ({
    type: rigthBarActions.UPDATE_FINDING,
    payload,
});

export const removeFinding = payload => ({
    type: rigthBarActions.REMOVE_FINDING,
    payload,
});

export const unsignInspectionReport = () => ({
    type: rigthBarActions.UNSIGN_INSPECTION_REPORT,
});

export const signInspectionReport = payload => ({
    type: rigthBarActions.SIGN_INSPECTION_REPORT,
    payload,
});

export const changeSignInspectionReportSection = payload => ({
    type: rigthBarActions.CHANGE_SIGN_INSPECTION_REPORT_SECTION,
    payload,
});

export const setAttachment = payload => ({
    type: rigthBarActions.SET_ATTACHMENT,
    payload,
});

export const changeSignInputElement = payload => ({
    type: rigthBarActions.CHANGE_SIGN_INPUT_ELEMENT,
    payload,
});

export const changeInput = payload => ({
    type: rigthBarActions.CHANGE_INPUT,
    payload,
});

export const changeFormattedInput = payload => ({
    type: rigthBarActions.CHANGE_FORMATTED_INPUT,
    payload,
});

export const changeRadioInput = payload => ({
    type: rigthBarActions.CHANGE_RADIO_INPUT,
    payload,
});

export const changeFileInput = payload => ({
    type: rigthBarActions.CHANGE_FILE_INPUT,
    payload,
});

export const clearUpdateHandler = () => ({
    type: rigthBarActions.CLEAR_UPDATE_HANDLER,
});

export const setInitialStateRigthBar = () => ({
    type: rigthBarActions.SET_INITIAL_STATE_RIGTH_BAR,
});

export const autofillColumn = payload => ({
    type: rigthBarActions.AUTOFILL_COLUMN_SERIAL_NUMBER,
    payload,
});

export const changeConfiramationModalState = payload => ({
    type: rigthBarActions.CHANGE_CONFIRMATION_MODAL_STATE,
    payload,
});

export const changeMediaInput = payload => ({
    type: rigthBarActions.CHANGE_MEDIA_INPUT,
    payload,
});
