import { ReactComponent as ExecutionCheckIcon } from '../../../resources/icons/executionCheckIcon.svg';
import { ReactComponent as FindingIcon } from '../../../resources/icons/findingsIcon.svg';
import { ReactComponent as ReportIcon } from '../../../resources/icons/reportIcon.svg';

export const actionTabs = {
    execution: 'execution',
    findings: 'findings',
    report: 'report',
};

export const actionTabsSettings = [
    {
        value: actionTabs.execution,
        label: 'protocol-production-stand.right-bar.tabs.execution',
        icon: <ExecutionCheckIcon />,
    },
    {
        value: actionTabs.findings,
        label: 'protocol-production-stand.right-bar.tabs.findings',
        icon: <FindingIcon />,
    },
    {
        value: actionTabs.report,
        label: 'protocol-production-stand.right-bar.tabs.report',
        icon: <ReportIcon />,
    },
];

export const findingCommentModules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'],
    ],
};

export const findingCommentFormats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link'];

export const findingOperations = {
    delete: 'delete',
    add: 'add',
    update: 'update',
};

export const sectionTypes = {
    title: 'title',
    sectionHeading: 'sectionHeading',
    sectionParagraph: 'sectionParagraph',
    headerGrid: 'headerGrid',
    table: 'table',
    list: 'list',
    imageSection: 'imageSection',
    footer: 'footer',
};

export const expandableSections = [sectionTypes.headerGrid, sectionTypes.table, sectionTypes.list, sectionTypes.imageSection];

export const allowedSectionForFindings = [sectionTypes.list, sectionTypes.table];

export const signatureSteps = {
    oneClick: 'oneClick',
    digital: 'digital',
};

export const optionsForMeasureActionDropdown = [
    'BK_INSP',
    'BK_FINAL_WACHSEN',
    'BK_REP_I_NACHARBEIT_TC-ROHR',
    'BK_REP_II_TC-ROHR_TAUSCH',
    'BK_REP_IV_LIGHT_CRACK_REPAIR',
    'BK_REP_V_SPRING_CLIP_TAUSCH',
    'BK_REP_VI_LEICHTE_REPARATUR',
    'BK_REP_VIII_COOLING RING DEVIATION',
    'BK_REP_WELD_AX_CRACKS_CoolRing',
    'BK_REP_XI_SCALLOPS EXCHANGE',
    'BK_REP_XII_HEAVY_CRACK_REPAIR',
    'BK_REP_XIII_COUPON_REPAIR_LINER',
    'BK_MOD_II_CONVERSION DCR',
    'BK_MOD_III_CENTRAL IGNITER',
    'CP_FS_INSP',
    'CP_FS_INSP_LIGHT',
    'REPAIR PACKAGE COVER PLATE',
    'CP_INSP',
    'FS_INSP',
    'FS_MOD_I_ZENTRALER ZUENDER',
    'FS_REP_III_WELD SEAM FLANGE',
    'FS_REP_VI_CLAMP_REWORK_1.5',
    'FS_REP_IV_WELD REPAIR FLANGE',
    'FS_REP_II_FUEL INLET EXCHANGE_DTC',
    'FS_REP_V_CLAMP_REWORK_DTC',
    'FS_REP_VII_FUEL_INLET_EXCHANGE_PRIO',
    'FS_REP_IV_WELD REPAIR FLANGE',
    'PN_FINALE_INSPEKTION',
    'PN_INSP_EINGANG',
    'PN_KAPPENTAUSCH',
    'PN_REP_STUTZEN_RICHTEN',
    'PN_REP_STUTZEN_TAUSCH',
    'SH_INSP_GO',
    'SH_REP_I_LEICHTE_REPARATUR_GO',
    'SH_MOD_I_ZUSAETZLICHE NACHARBEIT',
    'SH_REP_VI_EXCHANGE_FLAME_ADAPTER',
    'SH_INSP_DF',
    'SH_REP_II_LIGHT REPAIR_DF',
    'SH_REP_III_OIL TUBE REPLACE',
    'SH_MOD_I_ZUSAETZLICHE NACHARBEIT',
    'TP_INSPEKTION',
    'TP_INSPEKTION_FINAL',
    'TP_REP_GASEINLASSKONUS_SMART_LIGHT',
    'TP_REP_GASEINLASSKONUS_SMART',
    'TP_REP_LUG TAUSCH',
    'TP_REP_SEITENDICHTUNG_LEICHT',
    'TP_REP_SEITENDICHTUNG_SCHWER',
    'OL_INSP',
    'OL_REP_I_LIGHT_REPAIR',
    'PCS_Demontage_FullScope',
    'PCS_Demontage_MinScope',
    'PCS_Remontage_FullScope',
    'PCS_Remontage_MinScope',
];
