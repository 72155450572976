export const templatesOverviewActions = {
    SET_SEARCH_TERM: 'TEMPLATES_OVERVIEW/CHANGE_SEARCH_TERM',
    SET_SELECTED_TYPES: 'TEMPLATES_OVERVIEW/SET_SELECTED_TYPES',
    RESET_TO_INITIAL_STATE: 'TEMPLATES_OVERVIEW/RESET_TO_INITIAL_STATE',
    TOOGLE_BULK_DELETE_MODE_ACTIVE: 'TEMPLATES_OVERVIEW/TOOGLE_BULK_DELETE_MODE_ACTIVE',
    SET_TEMPLATES: 'TEMPLATES_OVERVIEW/SET_TEMPLATES',
    REMOVE_ITEM_FROM_LIST: 'TEMPLATES_OVERVIEW/REMOVE_ITEM_FROM_LIST',
    CHECK_ITEM: 'TEMPLATES_OVERVIEW/CHECK_ITEM',
    DELETE_CHECKED_ITEMS: 'TEMPLATES_OVERVIEW/DELETE_CHECKED_ITEMS',
    ADD_TEMPLATE_TO_THE_TOP: 'TEMPLATES_OVERVIEW/ADD_TEMPLATE_TO_THE_TOP',
    UPDATE_TEPLATE: 'TEMPLATES_OVERVIEW/UPDATE_TEPLATE',
};

export const setSearchTerm = payload => ({
    type: templatesOverviewActions.SET_SEARCH_TERM,
    payload,
});

export const setSelectedTypes = payload => ({
    type: templatesOverviewActions.SET_SELECTED_TYPES,
    payload,
});

export const resetTemplateOverviewReducerToInitialState = () => ({
    type: templatesOverviewActions.RESET_TO_INITIAL_STATE,
});

export const toogleBulkDeleteModeActive = () => ({
    type: templatesOverviewActions.TOOGLE_BULK_DELETE_MODE_ACTIVE,
});

export const setTemplates = payload => ({
    type: templatesOverviewActions.SET_TEMPLATES,
    payload,
});

export const removeItemFromList = payload => ({
    type: templatesOverviewActions.REMOVE_ITEM_FROM_LIST,
    payload,
});

export const templateOverviewCheckItem = payload => ({
    type: templatesOverviewActions.CHECK_ITEM,
    payload,
});

export const templateOverviewDeleteCheckedItems = () => ({
    type: templatesOverviewActions.DELETE_CHECKED_ITEMS,
});

export const templateOverviewAddTemplateToTheTop = payload => ({
    type: templatesOverviewActions.ADD_TEMPLATE_TO_THE_TOP,
    payload,
});

export const templateOverviewUpdateTemplate = payload => ({
    type: templatesOverviewActions.UPDATE_TEPLATE,
    payload,
});
