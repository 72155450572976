import React from 'react';
import { useHistory } from 'react-router-dom';

// LIBS
import clsx from 'clsx';
import i18next from 'i18next';

// MATERIAL UI - CORE
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, List, ListItem, Divider, ListItemIcon, ListItemText, Box, Tooltip } from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import SecurityRoundedIcon from '@material-ui/icons/SecurityRounded';

// COMPONENTS
import AuditLeftNavbar from '../components/audit/LeftNavbar';
import ProtocolLeftNavbar from '../components/protocol/LeftNavbar';
import ProgressLeftNavbar from '../components/progress/LeftNavbar';
import ProcessLeftNavbar from '../components/process/LeftNavbar';
import MaintenanceLeftNavbar from '../components/maintenance/LeftNavbar';
import MachineMaintenanceLeftNavbar from '../components/machine-maintenance/LeftNavbar';
import MaintenanceStandardLeftNavbar from '../components/maintenance-standard/LeftNavbar';
import WorkbookLeftNavbar from '../components/workbook/LeftNavbar';

// UTILS
import { signOut } from '../utils/awsAuth';
import { windowOpen } from '../utils/windowHelper';

// CONSTANTS
import { userRole } from '../constants/UserRole';
import { APP_NAME } from '../utils/env';
import { reactAppName } from '../constants/Config';

import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../components/pages/userSettings/store/userSettings.selectors';

const useStyles = makeStyles(theme => ({
    drawer: {
        position: 'fixed',
        width: 300,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        zIndex: 1001,
    },
    drawerHome: {
        backgroundColor: theme.palette.homeScreen.backgroundColor,
        '&.MuiDrawer-paperAnchorDockedLeft': {
            borderTop: `1px solid ${theme.palette.homeScreen.navbarBorderColor}`,
        },
    },
    drawerPaper: {
        marginTop: 56,
        maxHeight: 'calc(100vh - 56px)',
        boxShadow: `0px 4px 4px ${theme.palette.background.shadow}`,
        '&.MuiDrawer-paperAnchorDockedLeft': {
            borderRight: `1px solid ${theme.palette.background.default}`,
        },
        '& .MuiList-root': {
            padding: '8px 4px',
            overflowX: 'hidden',
            '& .MuiDivider-root': {
                margin: '12px 0px',
            },
            '&:first-child': {
                flexGrow: 1,
            },
        },
    },
    drawerOpen: {
        width: 300,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 56,
        [theme.breakpoints.down('xs')]: {
            width: 0,
        },
    },
    drawerHidden: {
        width: 0,
    },
    navigationList: {
        '&::-webkit-scrollbar': {
            display: 'none',
            width: 0,
            background: 'transparent',
        },
        '& .MuiListItemIcon-root': {
            color: theme.palette.icon.color,
            fill: theme.palette.icon.color,
            '& .stroke-background': {
                stroke: theme.palette.background.paper,
            },
            '& .fill-background': {
                fill: theme.palette.background.paper,
            },
            '& .fill-default': {
                fill: theme.palette.icon.color,
            },
        },
        '&.workbook': {
            '& .MuiListItemIcon-root': {
                '& .MuiSvgIcon-root': {
                    fill: theme.palette.icon.color,
                },
                '& svg': {
                    '& .fill-background': {
                        fill: theme.palette.icon.color,
                    },
                    '& .stroke-background': {
                        stroke: theme.palette.icon.color,
                    },
                },
            },
            '& .active': {
                '& .MuiListItemIcon-root': {
                    '& .MuiSvgIcon-root': {
                        fill: theme.palette.colors.green,
                    },
                    '& svg': {
                        '& .fill-background': {
                            fill: theme.palette.colors.green,
                        },
                        '& .stroke-background': {
                            stroke: theme.palette.colors.green,
                        },
                    },
                },
            },
        },
    },
    navigationButton: {
        height: 56,
        cursor: 'pointer',
        '& .MuiListItemIcon-root': {
            '& svg': {
                width: 24,
                height: 24,
            },
        },
        '&.MuiListItem-gutters': {
            paddingLeft: 12,
        },
        '&.active': {
            background: theme.palette.background.activeItem,
            borderRadius: 4,
            '& .MuiListItemIcon-root': {
                '& .MuiSvgIcon-root': {
                    '&#safetyCreateTicket': {
                        fill: theme.palette.colors.alert,
                    },
                    '&#safetyTickets': {
                        fill: theme.palette.colors.blue,
                    },
                    '&#safetyWalks': {
                        fill: theme.palette.colors.purple,
                    },
                    '&#safetyAudits': {
                        fill: theme.palette.colors.green,
                    },
                    '&#safetyQuestions': {
                        fill: theme.palette.colors.whiteVerse,
                    },
                    '&#safetyBuildAudit': {
                        fill: theme.palette.colors.whiteVerse,
                    },
                    '&#admin': {
                        fill: theme.palette.colors.whiteVerse,
                    },
                },
                '& svg': {
                    '&#protocols': {
                        color: theme.palette.colors.green,
                    },
                    '&#plans': {
                        color: theme.palette.colors.yellow,
                    },
                    '&#planTemplates': {
                        color: theme.palette.colors.blue,
                    },
                    '&#templates': {
                        color: theme.palette.colors.purple,
                    },
                    '&#maintenanceTickets': {
                        fill: theme.palette.colors.green,
                    },
                    '&#maintenanceAssets': {
                        fill: theme.palette.colors.blue,
                    },
                    '&#maintenanceShiftbook': {
                        fill: theme.palette.colors.white,
                    },
                    '&#maintenanceReports': {
                        fill: theme.palette.colors.purple,
                    },
                    '&#progressWorkplaces': {
                        color: theme.palette.colors.green,
                    },
                    '&#progressOrders': {
                        color: theme.palette.colors.blue,
                    },
                    '&#progressMaintenance': {
                        color: theme.palette.colors.purple,
                    },
                    '&#machineMaintenanceRepairTicket': {
                        fill: theme.palette.colors.whiteVerse,
                    },
                    '&#machineMaintenanceTickets': {
                        fill: theme.palette.colors.whiteVerse,
                    },
                    '&#machineMaintenanceShiftbook': {
                        fill: theme.palette.colors.whiteVerse,
                    },
                    '&#machineMaintenanceAssets': {
                        fill: theme.palette.colors.whiteVerse,
                    },
                    '&#machineMaintenanceReports': {
                        fill: theme.palette.colors.whiteVerse,
                    },
                    '&#machineMaintenanceScheduleTasks': {
                        fill: theme.palette.colors.whiteVerse,
                    },
                    '&#progressProjects': {
                        fill: theme.palette.colors.whiteVerse,
                    },
                    '&#progressWorkshops': {
                        fill: theme.palette.colors.green,
                    },
                    '&#createProject': {
                        fill: theme.palette.colors.blue,
                    },
                    '&#routingsOverview': {
                        fill: theme.palette.colors.blue,
                    },
                    '&#material': {
                        fill: theme.palette.colors.blue,
                    },
                },
            },
        },
        '&.link': {
            '& .MuiListItemText-root': {
                display: '-webkit-box',
                maxWidth: 200,
                overflow: 'hidden',
                textDecoration: 'underline',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                whiteSpace: 'initial',
            },
        },
        '&.bottom': {
            marginTop: 'auto',
        },
    },
}));

const LeftNavbarProvider = props => {
    const classes = useStyles();
    const history = useHistory();

    const roles = useSelector(selectCurrentUserRoles);

    const isNavigationButtonActive = path => {
        const currentLocation = history?.location?.pathname;
        return currentLocation.startsWith(path);
    };

    const isHomePage = () => {
        const currentLocation = history?.location?.pathname;
        return currentLocation === '/';
    };

    const isNavbarHidden = () => {
        return isHomePage();
    };

    const handleEditButtonClick = () => {
        props.setEditModeActive(!props.editModeActive);
    };

    const getListIconComponent = (text, icon, open) => {
        return (
            <Tooltip arrow title={i18next.t(text)} disableHoverListener={open} disableFocusListener={open} disableTouchListener={open}>
                {icon}
            </Tooltip>
        );
    };

    const stopBubbling = event => {
        event.stopPropagation();
    };

    const application = APP_NAME;

    return (
        <Box displayPrint="none" onClick={stopBubbling}>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerHome]: isHomePage(),
                    [classes.drawerOpen]: props.open,
                    [classes.drawerClose]: !props.open,
                    [classes.drawerHidden]: !props.open && isNavbarHidden(),
                })}
                classes={{
                    paper: clsx(classes.drawerPaper, {
                        [classes.drawerHome]: isHomePage(),
                        [classes.drawerOpen]: props.open,
                        [classes.drawerClose]: !props.open,
                        [classes.drawerHidden]: !props.open && isNavbarHidden(),
                    }),
                }}>
                <List className={clsx(classes.navigationList, application)}>
                    {application === reactAppName.audit && (
                        <AuditLeftNavbar
                            open={props.open}
                            classes={classes}
                            isNavigationButtonActive={isNavigationButtonActive}
                            getListIconComponent={getListIconComponent}
                        />
                    )}

                    {application === reactAppName.workbook && (
                        <WorkbookLeftNavbar
                            open={props.open}
                            classes={classes}
                            isNavigationButtonActive={isNavigationButtonActive}
                            getListIconComponent={getListIconComponent}
                        />
                    )}

                    {application === reactAppName.protocol && (
                        <ProtocolLeftNavbar
                            open={props.open}
                            classes={classes}
                            isNavigationButtonActive={isNavigationButtonActive}
                            getListIconComponent={getListIconComponent}
                        />
                    )}

                    {application === reactAppName.maintenance && (
                        <MaintenanceLeftNavbar
                            open={props.open}
                            classes={classes}
                            isNavigationButtonActive={isNavigationButtonActive}
                            getListIconComponent={getListIconComponent}
                        />
                    )}

                    {application === reactAppName.machine_maintenance && (
                        <MachineMaintenanceLeftNavbar
                            open={props.open}
                            classes={classes}
                            isNavigationButtonActive={isNavigationButtonActive}
                            getListIconComponent={getListIconComponent}
                        />
                    )}

                    {application === reactAppName.maintenance_standard && (
                        <MaintenanceStandardLeftNavbar
                            open={props.open}
                            classes={classes}
                            isNavigationButtonActive={isNavigationButtonActive}
                            getListIconComponent={getListIconComponent}
                        />
                    )}

                    {application === reactAppName.progress && (
                        <ProgressLeftNavbar
                            open={props.open}
                            classes={classes}
                            isNavigationButtonActive={isNavigationButtonActive}
                            getListIconComponent={getListIconComponent}
                        />
                    )}

                    {application === reactAppName.process && (
                        <ProcessLeftNavbar
                            open={props.open}
                            classes={classes}
                            isNavigationButtonActive={isNavigationButtonActive}
                            getListIconComponent={getListIconComponent}
                        />
                    )}

                    <Divider />

                    {roles?.some(x => x === userRole.admin || x === userRole.teamAdmin) && (
                        <ListItem
                            className={clsx(classes.navigationButton, isNavigationButtonActive('/admin') ? 'active' : '')}
                            onClick={() => history.push('/admin')}>
                            <ListItemIcon>
                                {getListIconComponent(
                                    'common.navigation-bar.options.admin',
                                    <SecurityRoundedIcon id="admin" />,
                                    props.open
                                )}
                            </ListItemIcon>
                            <ListItemText primary={i18next.t(`common.navigation-bar.options.admin`)} />
                        </ListItem>
                    )}
                    <ListItem className={clsx(classes.navigationButton)} onClick={() => signOut()}>
                        <ListItemIcon>
                            {getListIconComponent('common.navigation-bar.options.exit', <ExitToAppRoundedIcon />, props.open)}
                        </ListItemIcon>
                        <ListItemText primary={i18next.t(`common.navigation-bar.options.exit`)} />
                    </ListItem>
                    {APP_NAME === reactAppName.workbook && (
                        <ListItem
                            className={clsx(classes.navigationButton, 'link')}
                            onClick={() => windowOpen('https://5thindustry.de/workbook-eula/')}>
                            <ListItemIcon>
                                {getListIconComponent('common.navigation-bar.options.terms', <LinkRoundedIcon />, props.open)}
                            </ListItemIcon>
                            <ListItemText primary={i18next.t(`common.navigation-bar.options.terms`)} />
                        </ListItem>
                    )}
                </List>
                {props.showEditButton && (
                    <List className={clsx(classes.navigationList, application)}>
                        <ListItem
                            className={clsx(classes.navigationButton, 'bottom', props.editModeActive ? 'active' : '')}
                            onClick={handleEditButtonClick}>
                            <ListItemIcon>
                                <Tooltip
                                    arrow
                                    title={i18next.t(`common.navigation-bar.options.edit`)}
                                    disableHoverListener={props.open}
                                    disableFocusListener={props.open}
                                    disableTouchListener={props.open}>
                                    <EditRoundedIcon />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={i18next.t(`common.navigation-bar.options.edit`)} />
                        </ListItem>
                    </List>
                )}
            </Drawer>
        </Box>
    );
};

export default LeftNavbarProvider;
