import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { GET_CURRENT_APP_VERSION } from '../../gqlQueries/queries';
import { updateServiceWorker } from '../../serviceWorkerRegistration';
import client from '../../utils/apolloClient';
import { changeValueAction, selectCurrentAppVersion } from '../common/reducers/ServiceWorkerReducer';
import NewVersionSnackBar from '../pages/appVersion/NewVersionSnackBar';

const withAppVersion = ComposedComponent => {
    return React.memo(props => {
        const dispatch = useDispatch();

        const localAppVersion = useSelector(selectCurrentAppVersion);

        const handleChangeValue = (path, value) => {
            dispatch(changeValueAction(path, value));
        }

        const { data: currentAppVersion } = useQuery(GET_CURRENT_APP_VERSION, {
            pollInterval: 60000
        });

        const [newVersionIsAvailable, setNewVersionIsAvailable] = useState(false);

        useEffect(() => {
            if (currentAppVersion && !localAppVersion) {
                handleChangeValue('currentAppVersion', currentAppVersion.getCurrentAppVersion?.version);
            }

            if (currentAppVersion && localAppVersion && localAppVersion !== currentAppVersion.getCurrentAppVersion?.version) {
                setNewVersionIsAvailable(true);
            }
        }, [currentAppVersion]);

        const handleAppVersionUpdate = useCallback(() => {
            client.resetStore();
            handleChangeValue('currentAppVersion', currentAppVersion.getCurrentAppVersion?.version);
            setNewVersionIsAvailable(false);
            updateServiceWorker();
            window.location.reload(true);
        }, [newVersionIsAvailable]);

        return(
            <React.Fragment>
                <NewVersionSnackBar open={newVersionIsAvailable} handleActionClick={handleAppVersionUpdate} />
                <ComposedComponent {...props} />
            </React.Fragment>
        );
    });
};

export default withAppVersion;