import moment from 'moment';

import { timestampFilterCalculations, timestampFilters } from '../constants';

export const reportsActions = {
    clear: 'MACHINE_MAINTENANCE/REPORTS/CLEAR',
    setMappedParetoChartData: 'MACHINE_MAINTENANCE/REPORTS/SET_MAPPED_PARETO_CHART_DATA',
    setJobCategoryChartData: 'MACHINE_MAINTENANCE/REPORTS/SET_JOB_CATEGORY_CHART_DATA',
    setFailureCategoryChartData: 'MACHINE_MAINTENANCE/REPORTS/SET_FAILURE_CATEGORY_CHART_DATA',
    setIncidentTypeChartData: 'MACHINE_MAINTENANCE/REPORTS/SET_INCIDENT_TYPE_CHART_DATA',
    setReportsTimestampFromFilter: 'MACHINE_MAINTENANCE/REPORTS/SET_TIMESTAMP_FROM_FILTER',
    setReportsTimestampToFilter: 'MACHINE_MAINTENANCE/REPORTS/SET_TIMESTAMP_TO_FILTER',
    setReportsTimestamp: 'MACHINE_MAINTENANCE/REPORTS/SET_REPORTS_TIMESTAMP',
    setIsMachineDowntimeDataLoading: 'MACHINE_MAINTENANCE/REPORTS/SET_IS_MACHINE_DOWNTIME_DATA_LOADING',
    setMachineDowntimeData: 'MACHINE_MAINTENANCE/REPORTS/SET_MACHINE_DOWNTIME_DATA',
    setMachineDowntimeTicketsByAsset: 'MACHINE_MAINTENANCE/REPORTS/SET_MACHINE_DOWNTIME_TICKETS_BY_ASSET',
};

const initialState = {
    paretoChartData: [],
    jobCategoryChartData: [],
    failureCategoryChartData: [],
    incidentTypeChartData: [],
    filters: {
        timestamp: timestampFilters.lastMonth,
        timestampFrom: timestampFilterCalculations[timestampFilters.lastMonth](),
        timestampTo: new Date(),
    },
    isMachineDowntimeDataLoading: true,
    machineDowntimeData: [],
    machineDowntimeTicketsByAsset: [],
};

export const setReportsChartData = (type, payload = []) => {
    return {
        type,
        payload,
    };
};

export const clearReportsChartData = () => {
    return {
        type: reportsActions.clear,
    };
};

export const setReportsTimestampDate = (type, payload = null) => {
    return {
        type,
        payload,
    };
};

export const setReportsTimestamp = (payload = '') => {
    return {
        type: reportsActions.setReportsTimestamp,
        payload,
    };
};

export const setIsMachineDowntimeDataLoading = payload => {
    return {
        type: reportsActions.setIsMachineDowntimeDataLoading,
        payload,
    };
};

export const setMachineDowntimeData = payload => {
    return {
        type: reportsActions.setMachineDowntimeData,
        payload,
    };
};

export const setMachineDowntimeTicketsByAsset = payload => {
    return {
        type: reportsActions.setMachineDowntimeTicketsByAsset,
        payload,
    };
};

export const machineMaintenanceReportsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case reportsActions.clear: {
            return { ...initialState };
        }
        case reportsActions.setMappedParetoChartData: {
            return {
                ...state,
                paretoChartData: payload,
            };
        }
        case reportsActions.setJobCategoryChartData: {
            return {
                ...state,
                jobCategoryChartData: payload,
            };
        }
        case reportsActions.setFailureCategoryChartData: {
            return {
                ...state,
                failureCategoryChartData: payload,
            };
        }
        case reportsActions.setIncidentTypeChartData: {
            return {
                ...state,
                incidentTypeChartData: payload,
            };
        }
        case reportsActions.setReportsTimestamp: {
            const { newTimestamp, timestampTo, timestampFrom } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    timestamp: newTimestamp,
                    timestampFrom,
                    timestampTo,
                },
            };
        }

        case reportsActions.setReportsTimestampFromFilter: {
            if (moment(payload).isBefore(moment(state.filters.timestampTo))) {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        timestampFrom: payload,
                        timestamp: '',
                    },
                };
            }

            if (moment(payload).isAfter(moment(state.filters.timestampTo))) {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        timestampFrom: moment(payload),
                        timestampTo: moment(payload).add(1, 'days'),
                        timestamp: '',
                    },
                };
            }
        }
        case reportsActions.setReportsTimestampToFilter: {
            if (moment(payload).isAfter(moment(state.filters.timestampFrom))) {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        timestampTo: payload,
                        timestamp: '',
                    },
                };
            }

            if (moment(payload).isBefore(moment(state.filters.timestampFrom))) {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        timestampFrom: moment(payload).subtract(1, 'days'),
                        timestampTo: moment(payload),
                        timestamp: '',
                    },
                };
            }
        }

        case reportsActions.setIsMachineDowntimeDataLoading: {
            return {
                ...state,
                isMachineDowntimeDataLoading: payload,
            };
        }
        case reportsActions.setMachineDowntimeData: {
            return {
                ...state,
                machineDowntimeData: payload,
            };
        }
        case reportsActions.setMachineDowntimeTicketsByAsset: {
            return {
                ...state,
                machineDowntimeTicketsByAsset: payload,
            };
        }

        default:
            return state;
    }
};

export const selectMMaintReportsFilters = state => state.machineMaintenance?.reports?.filters;
export const selectIsMachineDowntimeDataLoading = state => state.machineMaintenance?.reports?.isMachineDowntimeDataLoading;
export const selectMachineDowntimeData = state => state.machineMaintenance?.reports?.machineDowntimeData;
export const selectMachineDowntimeTicketsByAsset = state => state.machineMaintenance?.reports?.machineDowntimeTicketsByAsset;

export const selectMMaintReportsDateTo = state => state.machineMaintenance?.reports?.filters?.timestampTo;
export const selectMMaintReportsDateFrom = state => state.machineMaintenance?.reports?.filters?.timestampFrom;
