import update from '../../../redux/update';

const actions = {
    setResultMessage: 'request/SET_RESULT_MESSAGE',
    clearResultMessage: 'request/CLEAR_RESULT_MESSaGE',
};

const initialState = {
    showMessage: false,
    code: null,
    content: null,
};

export const mapDispatch = dispatch => {
    return {
        setResultMessage: (showMessage, code, content = '') => {
            dispatch(setResultMessageAction(showMessage, code, content));
        },
    };
};

// The main purpose is to store form data.
export const requestReducer = (state = initialState, action) => {
    let newState = state;

    switch (action.type) {
        case actions.clearResultMessage: {
            newState = initialState;
            break;
        }

        case actions.setResultMessage: {
            newState = update(state, {
                showMessage: { $set: action.payload.showMessage },
                code: { $set: action.payload.code },
                data: { $set: action.payload.data },
                content: { $set: action.payload.content },
            });
            break;
        }

        default:
            break;
    }

    return newState;
};

export const setResultMessageAction = (showMessage, code, content = '') => {
    return {
        type: actions.setResultMessage,
        payload: {
            code,
            showMessage,
            content,
        },
    };
};

export const setArrayResultMessageAction = (showMessage, data) => {
    return {
        type: actions.setResultMessage,
        payload: {
            showMessage,
            data,
        },
    };
};

export const clearResultMessageAction = () => {
    return {
        type: actions.clearResultMessage,
    };
};
