import React from 'react';
import { useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';

import { createMuiTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/hu';

import { selectCurrentUserLanguage, selectCurrentUserTheme } from '../pages/userSettings/store/userSettings.selectors';

import { themeType } from '../../constants/ThemeType';
import { darkTheme } from '../../design/darkTheme';
import { lightTheme } from '../../design/lightTheme';
import { useCurrentUserBySub } from '../../utils/awsAuth';

const withUserSettingsProvider = ComposedComponent => {
    return React.memo(props => {
        const { loading } = useCurrentUserBySub();

        const userLanguage = useSelector(selectCurrentUserLanguage);

        i18next.changeLanguage(userLanguage);

        moment.locale(userLanguage);

        // Site color theme. If value not stored to the user settings then use the light theme
        const userTheme = useSelector(selectCurrentUserTheme);
        const selectedTheme = (userTheme || themeType.light) === themeType.light ? lightTheme : darkTheme;

        return (
            <React.Fragment>
                <LoadingOverlay active={loading} spinner className="h-100vh" text={i18next.t(`common.userLoadingMessage`)}>
                    <ThemeProvider theme={createMuiTheme(selectedTheme)}>
                        <CssBaseline />
                        <ComposedComponent {...props} />
                    </ThemeProvider>
                </LoadingOverlay>
            </React.Fragment>
        );
    });
};

export default withUserSettingsProvider;
