import { STEP_STATUS_ENUM } from '../common/constants/progress.constants';

import { sharedProjectsDataActions } from './sharedProjectsData.actions';

const projectsInitialState = {
    currentProjects: [],
};

export const sharedProjectsDataReducer = (state = projectsInitialState, { type, payload }) => {
    switch (type) {
        case sharedProjectsDataActions.SET_CURRENT_PROJECTS: {
            return {
                ...state,
                currentProjects: [...payload],
            };
        }

        case sharedProjectsDataActions.SET_PROJECTS_TEMPLATE: {
            return {
                ...state,
                currentProjects: [{ ...payload, productionOrderTemplateId: payload._id }],
            };
        }

        case sharedProjectsDataActions.MERGE_CURRENT_PROJECTS: {
            return {
                ...state,
                currentProjects: [...state.currentProjects, ...payload],
            };
        }

        case sharedProjectsDataActions.RESET_CURRENT_PROJECTS: {
            return {
                ...state,
                currentProjects: projectsInitialState.currentProjects,
            };
        }

        case sharedProjectsDataActions.SET_PROJECT_DATA: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        headerData: {
                            ...project.headerData,
                            salesOrderNumber: payload.headerData.salesOrderNumber,
                            orderStatus: payload.headerData.orderStatus,
                            orderCreatedBy: {
                                ...project.headerData.orderCreatedBy,
                                userID: payload.headerData.orderCreatedBy.userID,
                            },
                        },
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_STEP_NAME: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    const newRouting = payload.productionOrder.processRoutings.find(
                                        x => x._id === payload.processRoutingId
                                    );

                                    if (!newRouting) {
                                        return step;
                                    }

                                    const newStep = newRouting.processSteps.find(x => x._id === payload.processStepId);

                                    if (!newStep) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        stepName: newStep.stepName,
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_ROUTING_CALCULATED_SCHEDULER_DATA: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                calculatedSchedulingData: payload.newRoutingScheduledData.calculatedSchedulingData,
                                processSteps: routing.processSteps.map(step => {
                                    const stepScheduledData = payload.newRoutingScheduledData.processSteps.find(
                                        item => item._id === step._id
                                    )?.schedulingData;

                                    return { ...step, schedulingData: stepScheduledData };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_ROUTING_RESPONSIBLE: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                resolvedResponsible: [payload.newResponsible],
                                processSteps: routing.processSteps.map(step => {
                                    return {
                                        ...step,
                                        resolvedResponsible: [payload.newResponsible],
                                        responsible: [{ responsibleID: payload.newResponsible?._id }],
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_RESPONSIBLE: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    const newRouting = payload.productionOrder.processRoutings.find(
                                        x => x._id === payload.processRoutingId
                                    );

                                    if (!newRouting) {
                                        return step;
                                    }

                                    const newStep = newRouting.processSteps.find(x => x._id === payload.processStepId);

                                    if (!newStep) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        resolvedResponsible: [...newStep.resolvedResponsible],
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_SCHEDULED_RELEASE_DATE: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            const newRouting = payload?.productionOrder?.processRoutings?.find(x => x._id === payload.processRoutingId);

                            let newCalculatedSchedulingData = routing.calculatedSchedulingData;

                            newRouting &&
                                (newCalculatedSchedulingData = {
                                    ...routing.calculatedSchedulingData,
                                    scheduledReleaseDate: newRouting?.calculatedSchedulingData?.scheduledReleaseDate,
                                    scheduledEndDate: newRouting?.calculatedSchedulingData?.scheduledEndDate,
                                });

                            return {
                                ...routing,
                                calculatedSchedulingData: {
                                    ...newCalculatedSchedulingData,
                                },
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        schedulingData: {
                                            ...step.schedulingData,
                                            scheduledReleaseDate: payload.newScheduledReleaseDate,
                                            scheduledEndDate: payload.newScheduledEndDate,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_STEP_STATUS: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    const newProcessRouting = payload?.productionOrder?.processRoutings?.find(
                        routing => routing?._id === payload.processRoutingId
                    );

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            const processStep = newProcessRouting?.processSteps?.find(stepEl => stepEl?._id === payload.processStepId);

                            return {
                                ...routing,
                                progress: {
                                    ...routing.progress,
                                    durationCompletedInSeconds: newProcessRouting?.progress?.durationCompletedInSeconds,
                                },
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        stepStatus: processStep.stepStatus,
                                        progress: {
                                            ...step.progress,
                                            durationCompletedInSeconds: processStep?.progress?.durationCompletedInSeconds,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_CURRENT_PROJECT_DURATION: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        schedulingData: {
                            ...project.schedulingData,
                            scheduledReleaseDate: payload.productionOrder.schedulingData.scheduledReleaseDate,
                            scheduledEndDate: payload.productionOrder.schedulingData.scheduledEndDate,
                        },
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.routingId) {
                                return routing;
                            }

                            const newRouting = payload.productionOrder.processRoutings.find(x => x._id === payload.routingId);

                            if (!newRouting) {
                                return routing;
                            }

                            return {
                                ...routing,
                                calculatedSchedulingData: {
                                    ...routing.calculatedSchedulingData,
                                    scheduledReleaseDate: newRouting.calculatedSchedulingData.scheduledReleaseDate,
                                    scheduledEndDate: newRouting.calculatedSchedulingData.scheduledEndDate,
                                },
                                progress: {
                                    ...routing.progress,
                                    durationCompletedInSeconds: newRouting.progress.durationCompletedInSeconds,
                                    durationTotalInSeconds: newRouting.progress.durationTotalInSeconds,
                                },
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.stepId) {
                                        return step;
                                    }

                                    const newStep = newRouting.processSteps.find(x => x._id === step._id);

                                    if (!newStep) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        schedulingData: {
                                            ...step.schedulingData,
                                            scheduledReleaseDate: newStep.schedulingData.scheduledReleaseDate,
                                            scheduledEndDate: newStep.schedulingData.scheduledEndDate,
                                        },
                                        progress: {
                                            ...step.progress,
                                            durationCompletedInSeconds: newStep.progress.durationCompletedInSeconds,
                                            durationTotalInSeconds: newStep.progress.durationTotalInSeconds,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_CURRENT_PROJECT_ACTUAL_OPERATOR_TIME: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.routingId) {
                                return routing;
                            }

                            const newRouting = payload.productionOrder.processRoutings.find(x => x._id === payload.routingId);

                            if (!newRouting) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.stepId) {
                                        return step;
                                    }

                                    const newStep = newRouting.processSteps.find(x => x._id === step._id);

                                    if (!newStep) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        actualDurations: {
                                            ...(step.actualDurations || {}),
                                            operatorTime: payload.operatorTime,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_PROCESS_STEP_LONG_TEXT: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            const newRouting = payload.productionOrder.processRoutings.find(x => x._id === payload.processRoutingId);

                            if (!newRouting) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    const newStep = newRouting.processSteps.find(x => x._id === payload.processStepId);

                                    if (!newStep) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        stepLongtext: [...newStep.stepLongtext],
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_MEDIA_INPUTS: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    const newRouting = payload.productionOrder.processRoutings.find(
                                        x => x._id === payload.processRoutingId
                                    );

                                    if (!newRouting) {
                                        return step;
                                    }

                                    const newStep = newRouting.processSteps.find(x => x._id === payload.processStepId);

                                    if (!newStep) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        mediaInputs: [...newStep.mediaInputs],
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.UPDATE_RESOLVED_TICKETS: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    if (payload.resolvedTicket.status === STEP_STATUS_ENUM.OPEN) {
                                        const resolvedTicket = step?.resolvedTickets.find(
                                            ticket => ticket._id === payload.resolvedTicket._id
                                        );

                                        return {
                                            ...step,
                                            resolvedTickets: resolvedTicket
                                                ? step?.resolvedTickets.map(ticket => {
                                                      if (ticket._id !== payload.resolvedTicket._id) {
                                                          return ticket;
                                                      }

                                                      return {
                                                          ...ticket,
                                                          ...payload.resolvedTicket,
                                                          responsible: [...payload.resolvedTicket.responsible],
                                                      };
                                                  })
                                                : [...step?.resolvedTickets, payload.resolvedTicket],
                                        };
                                    }

                                    return {
                                        ...step,
                                        resolvedTickets: step?.resolvedTickets?.map(ticket => {
                                            if (ticket._id !== payload.resolvedTicket._id) {
                                                return ticket;
                                            }

                                            return {
                                                ...ticket,
                                                ...payload.resolvedTicket,
                                            };
                                        }),
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.ADD_NEW_PROCESS_STEP: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            const newRouting = payload.newRoutings.find(x => x._id === payload.processRoutingId);

                            if (!newRouting) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.concat(newRouting.processSteps[newRouting.processSteps.length - 1]),
                            };
                        }),
                    };
                }),
            };
        }

        // Procurement Step Inputs
        case sharedProjectsDataActions.SET_MATERIAL_NAME: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project?.processRoutings?.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing?.processSteps?.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        procurementData: {
                                            ...step.procurementData,
                                            materials: [
                                                {
                                                    materialName: payload.newMaterialName,
                                                    materialNumber: payload.newMaterialNumber,
                                                },
                                            ],
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_MATERIAL_NUMBER: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    const newRouting = payload.productionOrder.processRoutings.find(
                                        x => x._id === payload.processRoutingId
                                    );

                                    if (!newRouting) {
                                        return step;
                                    }

                                    const newStep = newRouting.processSteps.find(x => x._id === payload.processStepId);

                                    if (!newStep) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        procurementData: {
                                            ...step.procurementData,
                                            materials: newStep.procurementData.materials,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_SUPPLIER_NAME: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        procurementData: {
                                            ...step.procurementData,
                                            supplier: {
                                                ...step.procurementData?.supplier,
                                                name: payload.newSupplierName,
                                            },
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_ORDER_NO: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        procurementData: {
                                            ...step.procurementData,
                                            orderNo: payload.newOrderNo,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_ORDER_QTY_UNIT: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        procurementData: {
                                            ...step.procurementData,
                                            orderQtyUnit: payload.unit,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_ORDER_QTY: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        procurementData: {
                                            ...step.procurementData,
                                            orderQty: payload.newOrderQty,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_PRICE: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        procurementData: {
                                            ...step.procurementData,
                                            price: payload.newPrice,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_DELIVERY_RECEIPT_NUMBER: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        procurementData: {
                                            ...step.procurementData,
                                            deliveryReceiptNumber: payload.newDeliveryReceiptNumber,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_TOTAL_PRICE: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        procurementData: {
                                            ...step.procurementData,
                                            totalPrice: payload.newTotalPrice,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_INVOICE_NUMBER: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        procurementData: {
                                            ...step.procurementData,
                                            invoiceNumber: payload.newInvoiceNumber,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_INVOICE_DATE: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        procurementData: {
                                            ...step.procurementData,
                                            invoiceDate: payload.newInvoiceDate,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_SCHEDULED_END_DATE: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            const newRouting =
                                payload?.productionOrder?.processRoutings &&
                                payload?.productionOrder?.processRoutings.find(x => x._id === payload.processRoutingId);

                            let newCalculatedSchedulingData = routing.calculatedSchedulingData;
                            newRouting &&
                                (newCalculatedSchedulingData = {
                                    ...routing.calculatedSchedulingData,
                                    scheduledReleaseDate: newRouting?.calculatedSchedulingData?.scheduledReleaseDate,
                                    scheduledEndDate: newRouting?.calculatedSchedulingData?.scheduledEndDate,
                                });

                            return {
                                ...routing,
                                calculatedSchedulingData: {
                                    ...newCalculatedSchedulingData,
                                },
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        progress: {
                                            ...step.progress,
                                            durationTotalInSeconds: payload.durationTotalInSeconds,
                                        },
                                        schedulingData: {
                                            ...step.schedulingData,
                                            scheduledEndDate: payload.newScheduledEndDate,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_CONFIRMED_END_DATE: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.processRoutingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.processStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        schedulingData: {
                                            ...step.schedulingData,
                                            confirmedEndDate: payload.newConfirmedEndDate,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_MATERIAL_ID: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        headerData: {
                            ...project.headerData,
                            materialID: payload.materialID,
                        },
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_SCHEDULED_DATA: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        schedulingData: {
                            ...project.schedulingData,
                            scheduledReleaseDate: payload.scheduledReleaseDate,
                        },
                    };
                }),
            };
        }

        case sharedProjectsDataActions.SET_SERIAL_NUMBERS: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        headerData: {
                            ...project.headerData,
                            serialNumbers: payload.serialNumbers,
                        },
                    };
                }),
            };
        }

        case sharedProjectsDataActions.UPDATE_PROCESS_STEP: {
            return {
                ...state,
                currentProjects: state.currentProjects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        schedulingData: payload?.productionOrderSchedulingData || project?.schedulingData,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing?._id !== payload.routingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                calculatedSchedulingData: payload?.processRoutingSchedulingData || routing?.calculatedSchedulingData,
                                progress: payload?.processRoutingProgress,
                                processSteps: routing?.processSteps?.map(step => {
                                    if (step?._id !== payload._id) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        ...payload,
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        default:
            return state;
    }
};
