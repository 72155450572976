import {
    maintenanceAppRoles,
    machineMaintenanceAppRoles,
    maintenanceStandardAppRoles,
    workbookAppRoles,
    progressAppRoles,
    protocolAppRoles,
    safetyAppRoles,
    processAppRoles,
} from '../constants/UserRole';

import { APP_NAME } from '../utils/env';
import { reactAppName } from '../constants/Config';

export const getUserRoles = props => {
    const application = APP_NAME;

    if (application === reactAppName.audit) {
        return safetyAppRoles;
    }

    if (application === reactAppName.protocol) {
        return protocolAppRoles;
    }

    if (application === reactAppName.progress) {
        return progressAppRoles;
    }

    if (application === reactAppName.maintenance) {
        return maintenanceAppRoles;
    }

    if (application === reactAppName.machine_maintenance) {
        return machineMaintenanceAppRoles;
    }

    if (application === reactAppName.maintenance_standard) {
        return maintenanceStandardAppRoles;
    }

    if (application === reactAppName.workbook) {
        return workbookAppRoles;
    }

    if (application === reactAppName.process) {
        return processAppRoles;
    }

    return [];
};
