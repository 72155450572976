import { userSettingsActions } from './userSettings.actions';
import { getUserRoles } from '../../../../providers/UserRolesProvider';

const userSettingsInitialState = {
    email: null,
    firstName: null,
    mobileNumber: null,
    name: null,
    notifications: null,
    organizations: null,
    profilePic: null,
    roles: null,
    userSettings: null,
    _id: null,
};

export const userSettingsReducer = (state = userSettingsInitialState, { type, payload }) => {
    switch (type) {
        case userSettingsActions.SET_CURRENT_USER_DATA: {
            const currentProjectRoles = getUserRoles();
            return {
                ...state,
                ...payload,
                roles: payload.roles.filter(role => currentProjectRoles.includes(role)),
            };
        }
        case userSettingsActions.SET_CURRENT_USER_SETTINGS_FIELD: {
            if (state[payload.field] === payload.data) {
                return state;
            }

            return {
                ...state,
                userSettings: {
                    ...state.userSettings,
                    [payload.field]: payload.data,
                },
            };
        }
        case userSettingsActions.SET_CURRENT_USER_FIELD: {
            return {
                ...state,
                [payload.field]: payload.data,
            };
        }

        default:
            return state;
    }
};
