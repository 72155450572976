export const auditsActions = {
    SET_STD_AUDITS: 'MAINTENANCE_STANDARD/SET_STD_AUDITS',
    UPDATE_STD_AUDITS_QUESTION_TICKETS: 'MAINTENANCE_STANDARD/UPDATE_STD_AUDITS_QUESTION_TICKETS',

    SET_CURRENT_STD_AUDIT: 'MAINTENANCE_STANDARD/SET_CURRENT_STD_AUDIT',

    RESET_STD_AUDITS_STATE: 'MAINTENANCE_STANDARD/RESET_STD_AUDITS_STATE',
};

export const setAudits = payload => {
    return {
        type: auditsActions.SET_STD_AUDITS,
        payload,
    };
};

export const updateAuditsQuestionTickets = payload => {
    return {
        type: auditsActions.UPDATE_STD_AUDITS_QUESTION_TICKETS,
        payload,
    };
};

export const setCurrentAudit = payload => {
    return {
        type: auditsActions.SET_CURRENT_STD_AUDIT,
        payload,
    };
};

export const resetAuditsState = payload => {
    return {
        type: auditsActions.RESET_STD_AUDITS_STATE,
        payload,
    };
};
