export const fruitPreparation = {
    SET_ORDERS: 'FRUIT_PREPARATION/SET_ORDERS',
    SET_ORDER_ESTIMATED_DELAY: 'FRUIT_PREPARATION/SET_ORDER_ESTIMATED_DELAY',
    SET_ORDER_STATUS: 'FRUIT_PREPARATION/SET_ORDER_STATUS',
};

export const setFruitOrders = payload => {
    return {
        type: fruitPreparation.SET_ORDERS,
        payload,
    };
};

export const setOrdersEstimatedDelay = payload => {
    return {
        type: fruitPreparation.SET_ORDER_ESTIMATED_DELAY,
        payload,
    };
};

export const setOrderStatus = payload => {
    return {
        type: fruitPreparation.SET_ORDER_STATUS,
        payload,
    };
};
