import { routingStepperActions } from './routingStepper.actions';

const routingStepperInitialState = {
    routingType: '',
    model: {
        selectedSteps: [],
    },
    activeStep: 0,
    filters: {
        searchTerm: '',
        selectedTypes: [],
    },
};

export const routingStepperReducer = (state = routingStepperInitialState, { type, payload }) => {
    switch (type) {
        case routingStepperActions.SET_ACTIVE_STEP_AND_ROUTING_TYPE: {
            const { activeStep, routingType, tag } = payload;

            return {
                ...state,
                activeStep,
                routingType,
                model: {
                    ...state.model,
                    tag,
                },
            };
        }
        case routingStepperActions.SET_ACTIVE_STEP: {
            return {
                ...state,
                activeStep: payload,
            };
        }
        case routingStepperActions.SET_ROUTING_TYPE: {
            return {
                ...state,
                routingType: payload,
            };
        }
        case routingStepperActions.CHANGE_ROUTING_NAME: {
            return {
                ...state,
                model: {
                    ...state.model,
                    routingName: payload,
                },
            };
        }
        case routingStepperActions.CHANGE_ROUTING_DESCRIPTION: {
            return {
                ...state,
                model: {
                    ...state.model,
                    description: payload,
                },
            };
        }
        case routingStepperActions.CHANGE_SELECTED_STEPS: {
            return {
                ...state,
                model: {
                    ...state.model,
                    selectedSteps: payload,
                },
            };
        }
        case routingStepperActions.CHANGE_SEARCH_TERM: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    searchTerm: payload,
                },
            };
        }
        case routingStepperActions.CHANGE_SELECTED_TYPES: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedTypes: payload,
                },
            };
        }
        case routingStepperActions.RESET_TO_INITIAL_STATE: {
            return routingStepperInitialState;
        }
        case routingStepperActions.SET_ROUTING_TAG: {
            return {
                ...state,
                model: {
                    ...state.model,
                    tag: payload,
                },
            };
        }
        case routingStepperActions.RESET_FILTER_TO_INITIAL_STATE: {
            return {
                ...state,
                filters: {
                    searchTerm: '',
                    selectedTypes: [],
                },
            };
        }
        case routingStepperActions.ADD_SELECTED_STEP: {
            return {
                ...state,
                model: {
                    ...state.model,
                    selectedSteps: [...state.model.selectedSteps, payload],
                },
            };
        }
        case routingStepperActions.SET_ROUTING_STEPPER_MODEL: {
            return { ...state, routingType: payload.routingType, model: payload };
        }
        case routingStepperActions.GO_TO_PREV_STEP: {
            return {
                ...state,
                activeStep: state.activeStep - 1,
            };
        }
        case routingStepperActions.GO_TO_NEXT_STEP: {
            return {
                ...state,
                activeStep: state.activeStep + 1,
            };
        }

        default:
            return state;
    }
};
