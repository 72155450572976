import React from 'react';

// LIBS
import i18next from "i18next";

// MATERIAL UI
import { makeStyles } from "@material-ui/core/styles";
import BuildIcon from '@material-ui/icons/Build';

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 22,
        padding: 24,

        "& .MuiSvgIcon-root": {
            fontSize: 52,
            marginRight: 20
        }
    }
}));

const UnknownError = ({ ...props }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <BuildIcon />
            {i18next.t("common.unknown-error")}
        </div>
    );
}

export default UnknownError;