import { createSelector } from 'reselect';
import { appLanguage } from '../../../../constants/AppLanguage';
import { userRole } from '../../../../constants/UserRole';
import { AWS_AMPLIFY_DEFAULT_LANGUAGE } from '../../../../utils/env';
import { userThemePallete } from '../constants';

export const currentUserDataSelector = state => state.currentUserData;
export const currentUserSettingsSelector = state => state.currentUserData?.userSettings;
export const currentUserThemeSelector = state => state.currentUserData?.userSettings?.theme;
export const selectCurrentUserOrganizations = state => state.currentUserData?.organizations;
export const selectCurrentUserRoles = state => state.currentUserData?.roles;

export const selectCurrentUserLanguage = createSelector(
    currentUserSettingsSelector,
    userSettings => userSettings?.language || AWS_AMPLIFY_DEFAULT_LANGUAGE
);

export const selectCurrentLanguage = createSelector(currentUserSettingsSelector, userSettings =>
    userSettings?.language ? appLanguage[userSettings.language] : appLanguage[AWS_AMPLIFY_DEFAULT_LANGUAGE]
);

export const selectCurrentUserTheme = createSelector(currentUserSettingsSelector, userSettings => userSettings?.theme);

export const selectCurrentUserId = createSelector(currentUserDataSelector, currentUserData => currentUserData?._id);

export const selectCurrentUserOrganizationId = createSelector(selectCurrentUserOrganizations, organizations =>
    organizations ? organizations[0]?.organizationID : null
);

export const selectCurrentUserProfilePicture = createSelector(currentUserDataSelector, currentUserData => currentUserData?.profilePic);

export const selectCurrentUserFirstName = createSelector(currentUserDataSelector, ({ firstName }) => firstName);

export const selectCurrentUserName = createSelector(currentUserDataSelector, ({ name }) => name);

export const selectCurrentUserFullName = createSelector(currentUserDataSelector, ({ firstName, name }) => `${firstName} ${name}`);

export const selectCheckedRole = allowedRoles =>
    createSelector(selectCurrentUserRoles, roles => !roles?.some(userRole => allowedRoles?.some(role => role === userRole)));

export const selectCurrentThemePallete = createSelector(currentUserThemeSelector, theme => {
    return userThemePallete[theme] || userThemePallete.light;
});

export const selectIsAdminOrTeamAdmin = createSelector(selectCurrentUserRoles, roles =>
    roles?.some(role => [userRole.teamAdmin, userRole.admin].includes(role))
);

export const selectIsUserAllowed = allowedRoles =>
    createSelector(selectCurrentUserRoles, roles => roles?.some(role => allowedRoles.includes(role)));
