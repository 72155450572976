export const ITEMS_LIMIT = 50;
export const PRELOAD_ITEMS = 30;
export const ORDERS_SEARCH_DELAY = 1000;
export const ORDERS_TABLE_POLL_INTERVAL = 100000;
export const ORDERS_BOARD_POLL_INTERVAL = 45000;
export const NOT_APPLICABLE_VALUE = 'N/A';
export const OrdersBoardColumn = {
    name: 'name',
    key: 'key',
    items: 'items',
    capacity: 'capacity',
    isDropDisabled: 'isDropDisabled',
    columnColor: 'columnColor',
};

export const criticalMinutes = 10;

export const HHmm = 'HH:mm';
export const DDMMYY = ' DD.MM.YY';

export const indexOfDefaultExpandedItem = 0;

export const workplaceType = {
    SITE: 'site',
    BUILDING: 'building',
    ROOM: 'room',
    AREA: 'area',
    PRODUCTION_LINE: 'productionLine',
};

export const ProcessStatusEnum = {
    OPEN: 'open',
    IN_WORK: 'inWork',
    DONE: 'done',
    DELAYED: 'delayed',
    WAITING: 'waiting',
};

export const ProcessStatusColors = {
    [ProcessStatusEnum.OPEN]: '#EF7A12',
    [ProcessStatusEnum.IN_WORK]: '#AA00FF',
    [ProcessStatusEnum.DONE]: '#0EBF5A',
    [ProcessStatusEnum.DELAYED]: '#F5325C',
    [ProcessStatusEnum.WAITING]: '#9B95A1',
};

export const WaypointPosition = {
    BELOW: 'below',
    ABOVE: 'above',
    INSIDE: 'inside',
    INVISIBLE: 'invisible',
};

export const ProcessOrderColumnsStatus = {
    [ProcessStatusEnum.IN_WORK]: [ProcessStatusEnum.IN_WORK],
    [ProcessStatusEnum.OPEN]: [ProcessStatusEnum.OPEN, ProcessStatusEnum.DELAYED],
};

export const getWorkplacesVariablesByType = type => JSON.stringify({ workplaceType: type });

export const getProductionOrdersVariablesByWorkplaceId = workplaceId => ({
    'processRoutings.processSteps.workplaces._id': {
        $in: [`${workplaceId}`],
    },
    parent: null,
});

export const getOrdersSearchQueryOrDefault = (searchTerm, filtersEmpty, filterQuery) => {
    if (searchTerm && filtersEmpty) {
        return {
            ...filterQuery,
            'headerData.orderNo': {
                $regex: searchTerm,
                $options: 'i',
            },
        };
    }

    return filterQuery;
};
