export const ordersFiltersActions = {
    SET_SEARCH_TERM: 'ORDERS_FILTERS/SET_SEARCH_TERM',
    SET_ORDERS_TIMESTAMP: 'ORDERS_FILTERS/SET_ORDERS_TIMESTAMP',
    SET_ORDERS_TIMESTAMP_LIST_VIEW: 'ORDERS_FILTERS/SET_ORDERS_TIMESTAMP_LIST_VIEW',
    SET_ORDERS_TIMESTAMP_ORDERS_OVERVIEW: 'ORDERS_FILTERS/SET_ORDERS_TIMESTAMP_ORDERS_OVERVIEW',
    SET_IS_FILTER_BUTTON_ACTIVE: 'ORDERS_FILTERS/SET_IS_FILTER_BUTTON_ACTIVE',
    SET_ORDERS_SELECTED_STATUS: 'ORDERS_FILTERS/SET_ORDERS_SELECTED_STATUS',
    CLEAR_ORDERS_FILTER_BY_STATUS: 'ORDERS_FILTERS/CLEAR_ORDERS_FILTER_BY_STATUS',
    CLEAR_ORDERS_FILTERS: 'ORDERS_FILTERS/CLEAR_ORDERS_FILTERS',
    SET_ORDERS_SELECTED_ZONES: 'ORDERS_FILTERS/SET_ORDERS_SELECTED_ZONES',
    SET_ORDERS_SELECTED_ZONES_CHIPS_FILTERS: 'ORDERS_FILTERS/SET_ORDERS_SELECTED_ZONES_CHIPS_FILTERS',
    SET_ORDERS_ZONES_CHIPS: 'ORDERS_FILTERS/SET_ORDERS_ZONES_CHIPS',
    SET_IS_ZONES_CHIP_FILTER_BUTTON_ACTIVE: 'ORDERS_FILTERS/SET_IS_ZONES_CHIP_FILTER_BUTTON_ACTIVE',
    SET_ORDERS_VIEW: 'ORDERS_FILTERS/SET_ORDERS_VIEW',
    SET_ORDERS_ZONES: 'ORDERS_FILTERS/SET_ORDERS_ZONES',
    SET_IS_DATA_LOADING: 'ORDERS_FILTERS/SET_IS_DATA_LOADING',
};

export const setSearchTerm = payload => {
    return {
        type: ordersFiltersActions.SET_SEARCH_TERM,
        payload,
    };
};

export const setOrdersTimestamp = (payload = '') => {
    return {
        type: ordersFiltersActions.SET_ORDERS_TIMESTAMP,
        payload,
    };
};

export const setOrdersTimestampListView = (payload = '') => {
    return {
        type: ordersFiltersActions.SET_ORDERS_TIMESTAMP_LIST_VIEW,
        payload,
    };
};

export const setOrdersTimestampOrdersOverview = (payload = '') => {
    return {
        type: ordersFiltersActions.SET_ORDERS_TIMESTAMP_ORDERS_OVERVIEW,
        payload,
    };
};

export const setOrdersSelectedStatus = payload => {
    return {
        type: ordersFiltersActions.SET_ORDERS_SELECTED_STATUS,
        payload,
    };
};

export const setOrdersIsFilterButtonActive = payload => {
    return {
        type: ordersFiltersActions.SET_IS_FILTER_BUTTON_ACTIVE,
        payload,
    };
};

export const clearOrdersFilterByStatus = () => {
    return {
        type: ordersFiltersActions.CLEAR_ORDERS_FILTER_BY_STATUS,
    };
};

export const clearOrdersFilters = () => {
    return {
        type: ordersFiltersActions.CLEAR_ORDERS_FILTERS,
    };
};

export const setOrdersSelectedZones = payload => {
    return {
        type: ordersFiltersActions.SET_ORDERS_SELECTED_ZONES,
        payload,
    };
};

export const setOrdersZones = payload => {
    return {
        type: ordersFiltersActions.SET_ORDERS_ZONES,
        payload,
    };
};

export const setOrdersZonesChips = payload => {
    return {
        type: ordersFiltersActions.SET_ORDERS_ZONES_CHIPS,
        payload,
    };
};

export const setOrdersSelectedZonesChipsFilters = payload => {
    return {
        type: ordersFiltersActions.SET_ORDERS_SELECTED_ZONES_CHIPS_FILTERS,
        payload,
    };
};

export const setIsZonesChipFilterButtonActive = payload => {
    return {
        type: ordersFiltersActions.SET_IS_ZONES_CHIP_FILTER_BUTTON_ACTIVE,
        payload,
    };
};

export const setOrdersView = payload => {
    return {
        type: ordersFiltersActions.SET_ORDERS_VIEW,
        payload,
    };
};

export const setIsDataLoading = payload => {
    return {
        type: ordersFiltersActions.SET_IS_DATA_LOADING,
        payload,
    };
};
