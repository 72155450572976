export const ticketDetailsActions = {
    SET_TICKET_DATA: 'TICKET_DETAILS/SET_TICKET_DATA',
    SET_TICKET_OVERLEY_DATA: 'TICKET_DETAILS/SET_TICKET_OVERLEY_DATA',
    SET_ASSET_DATA: 'TICKET_DETAILS/SET_ASSET_DATA',
    SET_VAL_ERROR: 'TICKET_DETAILS/SET_VAL_ERROR',

    SET_IS_DATA_SAVED: 'TICKET_DETAILS/SET_IS_DATA_SAVED',

    SET_CURRENT_TICKET_ENTRY: 'TICKET_DETAILS/SET_CURRENT_TICKET_ENTRY',
    UPDATE_CURRENT_TICKET_ENTRY: 'TICKET_DETAILS/UPDATE_CURRENT_TICKET_ENTRY',

    UPDATE_TICKET_DATA: 'TICKET_DETAILS/UPDATE_TICKET_DATA',
    UPDATE_ASSET_DATA: 'TICKET_DETAILS/UPDATE_ASSET_DATA',

    RESET_TICKET_DETAILS: 'TICKET_DETAILS/RESET_TICKET_DETAILS',
    RESET_CURRENT_ENTRY: 'TICKET_DETAILS/RESET_CURRENT_ENTRY',
};

export const setTicketData = payload => {
    return {
        type: ticketDetailsActions.SET_TICKET_DATA,
        payload,
    };
};

export const setCurrentTicketEntry = payload => {
    return {
        type: ticketDetailsActions.SET_CURRENT_TICKET_ENTRY,
        payload,
    };
};

export const updateCurrentTicketEntry = payload => {
    return {
        type: ticketDetailsActions.UPDATE_CURRENT_TICKET_ENTRY,
        payload,
    };
};

export const resetCurrentEntry = () => {
    return {
        type: ticketDetailsActions.RESET_CURRENT_ENTRY,
    };
};

export const setTicketOverleyData = payload => {
    return {
        type: ticketDetailsActions.SET_TICKET_OVERLEY_DATA,
        payload,
    };
};

export const setValError = payload => {
    return {
        type: ticketDetailsActions.SET_VAL_ERROR,
        payload,
    };
};

export const setAssetData = payload => {
    return {
        type: ticketDetailsActions.SET_ASSET_DATA,
        payload,
    };
};

export const setIsDataSaved = payload => {
    return {
        type: ticketDetailsActions.SET_IS_DATA_SAVED,
        payload,
    };
};

export const updateTicketData = payload => {
    return {
        type: ticketDetailsActions.UPDATE_TICKET_DATA,
        payload,
    };
};

export const updateAssetData = payload => {
    return {
        type: ticketDetailsActions.UPDATE_ASSET_DATA,
        payload,
    };
};

export const resetTicketDetails = () => {
    return {
        type: ticketDetailsActions.RESET_TICKET_DETAILS,
    };
};
