import React from "react";
import { useSelector } from "react-redux";
import { selectCheckedRole, selectCurrentUserRoles } from "../pages/userSettings/store/userSettings.selectors";

const PrivateComponent = props => {
    const roles = useSelector(selectCurrentUserRoles);

    const isHidden = useSelector(selectCheckedRole(props.allowedRoles));

    if (!roles) {
        return null;
    }

    if (isHidden && !props.negative) {
        return null;
    }

    if (!isHidden && props.negative) {
        return null;
    }

    return <React.Fragment>{props.children}</React.Fragment>;
}

export default PrivateComponent;