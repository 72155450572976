export const materialAllocationActions = {
    SET_MATERIAL_ALLOCATION_DATA: 'MATERIAL_ALLOCATION/SET_MATERIAL_ALLOCATION_DATA',
    UPDATE_MATERIAL_ALLOCATION_TABLE_ITEM: 'MATERIAL_ALLOCATION/UPDATE_MATERIAL_ALLOCATION_TABLE_ITEM',

    SET_MATERIAL_ALLOCATION_MODAL_STATE: 'MATERIAL_ALLOCATION/SET_MATERIAL_ALLOCATION_MODAL_STATE',
    SET_MATERIAL_ALLOCATION_DELETE_MODAL_STATE: 'MATERIAL_ALLOCATION/SET_MATERIAL_ALLOCATION_DELETE_MODAL_STATE',

    SET_MATERIAL_ALLOCATION_WITHOUT_ALLOCATION_MODAL_STATE: 'MATERIAL_ALLOCATION/SET_MATERIAL_ALLOCATION_WITHOUT_ALLOCATION_MODAL_STATE',
    SET_MATERIAL_STATUS_MODAL_STATE: 'MATERIAL_ALLOCATION/SET_MATERIAL_STATUS_MODAL_STATE',

    RESET_MATERIAL_ALLOCATION_STATE: 'MATERIAL_ALLOCATION/RESET_STATE',
};

export const setMaterialAllocationData = payload => {
    return {
        type: materialAllocationActions.SET_MATERIAL_ALLOCATION_DATA,
        payload,
    };
};

export const setMaterialAllocationModalState = payload => {
    return {
        type: materialAllocationActions.SET_MATERIAL_ALLOCATION_MODAL_STATE,
        payload,
    };
};

export const setMaterialAllocationWithoutAllocationModalState = payload => {
    return {
        type: materialAllocationActions.SET_MATERIAL_ALLOCATION_WITHOUT_ALLOCATION_MODAL_STATE,
        payload,
    };
};

export const setMaterialStatusModalState = payload => {
    return {
        type: materialAllocationActions.SET_MATERIAL_STATUS_MODAL_STATE,
        payload,
    };
};

export const setMaterialAllocationDeleteModalState = payload => {
    return {
        type: materialAllocationActions.SET_MATERIAL_ALLOCATION_DELETE_MODAL_STATE,
        payload,
    };
};

export const resetMaterialAllocationState = () => {
    return {
        type: materialAllocationActions.RESET_MATERIAL_ALLOCATION_STATE,
    };
};

export const updateMaterialAllocationTableItem = payload => {
    return {
        type: materialAllocationActions.UPDATE_MATERIAL_ALLOCATION_TABLE_ITEM,
        payload,
    };
};
