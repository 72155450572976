export const maintenanceReportsActions = {
    SET_REPORTS_DATA: 'MAINTENANCE_REPORTS/SET_REPORTS_DATA',
    SET_REPORTS_FILTERS: 'MAINTENANCE_REPORTS/SET_REPORTS_FILTERS',
    SET_FILTER_DATA_BY_MONTH: 'MAINTENANCE_REPORTS/SET_FILTER_DATA_BY_MONTH',
};

export const setReportsData = payload => {
    return {
        type: maintenanceReportsActions.SET_REPORTS_DATA,
        payload,
    };
};

export const setReportsFilters = payload => {
    return {
        type: maintenanceReportsActions.SET_REPORTS_FILTERS,
        payload,
    };
};

export const setFilterDataByMonth = payload => {
    return {
        type: maintenanceReportsActions.SET_FILTER_DATA_BY_MONTH,
        payload,
    };
};
