export const sharedProjectsDataActions = {
    SET_CURRENT_PROJECTS: 'PROJECTS/SET_CURRENT_PROJECTS',
    MERGE_CURRENT_PROJECTS: 'PROJECTS/MERGE_CURRENT_PROJECTS',
    RESET_CURRENT_PROJECTS: 'PROJECTS/RESET_CURRENT_PROJECTS',

    // Project Data
    SET_PROJECT_DATA: 'PROJECTS/SET_PROJECT_DATA',
    SET_SCHEDULED_DATA: 'PROJECTS/SET_SCHEDULED_DATE',
    SET_SERIAL_NUMBERS: 'PROJECTS/SET_SERIAL_NUMBERS',

    // Routing
    SET_ROUTING_RESPONSIBLE: 'PROJECTS/SET_ROUTING_RESPONSIBLE',
    SET_ROUTING_CALCULATED_SCHEDULER_DATA: 'SET_ROUTING_CALCULATED_SCHEDULER_DATA',

    // Regular Process Step
    SET_STEP_NAME: 'PROJECTS/SET_STEP_NAME',
    SET_RESPONSIBLE: 'PROJECTS/SET_RESPONSIBLE',
    SET_SCHEDULED_RELEASE_DATE: 'PROJECTS/SET_SCHEDULED_RELEASE_DATE',
    SET_STEP_STATUS: 'PROJECTS/SET_STEP_STATUS',
    SET_CURRENT_PROJECT_DURATION: 'PROJECTS/SET_CURRENT_PROJECT_DURATION',
    SET_PROCESS_STEP_LONG_TEXT: 'PROJECTS/SET_PROCESS_STEP_LONG_TEXT',
    SET_MEDIA_INPUTS: 'PROJECTS/ATACHMENTS/SET_MEDIA_INPUTS',
    UPDATE_RESOLVED_TICKETS: 'PROJECTS/UPDATE_RESOLVED_TICKETS',
    SET_CURRENT_PROJECT_ACTUAL_OPERATOR_TIME: 'PROJECTS/SET_CURRENT_PROJECT_ACTUAL_OPERATOR_TIME',

    ADD_NEW_PROCESS_STEP: 'PROJECTS/CREATE_PROCESS_STEP/ADD_NEW_PROCESS_STEP',

    // Procurement Step Inputs
    SET_MATERIAL_NAME: 'PROJECTS/PROCUREMENT_INFO/SET_MATERIAL_NAME',
    SET_MATERIAL_NUMBER: 'PROJECTS/PROCUREMENT_INFO/SET_MATERIAL_NUMBER',
    SET_SUPPLIER_NAME: 'PROJECTS/PROCUREMENT_INFO/SET_SUPPLIER_NAME',
    SET_ORDER_NO: 'PROJECTS/PROCUREMENT_INFO/SET_ORDER_NO',
    SET_PRICE: 'PROJECTS/PROCUREMENT_INFO/SET_PRICE',
    SET_ORDER_QTY: 'PROJECTS/PROCUREMENT_INFO/SET_ORDER_QTY',
    SET_ORDER_QTY_UNIT: 'PROJECTS/PROCUREMENT_INFO/SET_ORDER_QTY_UNIT',
    SET_DELIVERY_RECEIPT_NUMBER: 'PROJECTS/PROCUREMENT_INFO/SET_DELIVERY_RECEIPT_NUMBER',
    SET_TOTAL_PRICE: 'PROJECTS/PROCUREMENT_INFO/SET_TOTAL_PRICE',
    SET_INVOICE_NUMBER: 'PROJECTS/PROCUREMENT_INFO/SET_INVOICE_NUMBER',
    SET_SCHEDULED_END_DATE: 'PROJECTS/PROCUREMENT_INFO/SET_SCHEDULED_END_DATE',
    SET_INVOICE_DATE: 'PROJECTS/PROCUREMENT_INFO/SET_INVOICE_DATE',
    SET_CONFIRMED_END_DATE: 'PROJECTS/PROCUREMENT_INFO/SET_CONFIRMED_END_DATE',
    SET_PROJECTS_TEMPLATE: 'PROJECTS/SET_PROJECTS_TEMPLATE',

    // Material
    SET_MATERIAL_ID: 'PROJECTS/MATERIAL/SET_MATERIAL_ID',

    UPDATE_PROCESS_STEP: 'PROJECTS/UPDATE_PROCESS_STEP',
};

export const setCurrentProjects = payload => {
    return {
        type: sharedProjectsDataActions.SET_CURRENT_PROJECTS,
        payload,
    };
};

export const setProjectTemplate = payload => {
    return {
        type: sharedProjectsDataActions.SET_PROJECTS_TEMPLATE,
        payload,
    };
};

export const mergeCurrentProjects = payload => {
    return {
        type: sharedProjectsDataActions.MERGE_CURRENT_PROJECTS,
        payload,
    };
};

export const resetCurrentProjects = payload => {
    return {
        type: sharedProjectsDataActions.RESET_CURRENT_PROJECTS,
        payload,
    };
};

export const setProjectData = payload => {
    return {
        type: sharedProjectsDataActions.SET_PROJECT_DATA,
        payload,
    };
};

export const setStepName = payload => {
    return {
        type: sharedProjectsDataActions.SET_STEP_NAME,
        payload,
    };
};

export const setResponsible = payload => {
    return {
        type: sharedProjectsDataActions.SET_RESPONSIBLE,
        payload,
    };
};

export const setProcessStepStatus = payload => {
    return {
        type: sharedProjectsDataActions.SET_STEP_STATUS,
        payload,
    };
};

export const setScheduledReleaseDate = payload => {
    return {
        type: sharedProjectsDataActions.SET_SCHEDULED_RELEASE_DATE,
        payload,
    };
};

export const setCurrentProjectDuration = payload => {
    return {
        type: sharedProjectsDataActions.SET_CURRENT_PROJECT_DURATION,
        payload,
    };
};

export const setProcessStepLongText = payload => {
    return {
        type: sharedProjectsDataActions.SET_PROCESS_STEP_LONG_TEXT,
        payload,
    };
};

export const setProcessStepMediaInputs = payload => {
    return {
        type: sharedProjectsDataActions.SET_MEDIA_INPUTS,
        payload,
    };
};

export const addNewProcessStep = payload => {
    return {
        type: sharedProjectsDataActions.ADD_NEW_PROCESS_STEP,
        payload,
    };
};

export const updateResolvedTicket = payload => {
    return {
        type: sharedProjectsDataActions.UPDATE_RESOLVED_TICKETS,
        payload,
    };
};

// Procurement Step Inputs
export const setProcurementMaterialName = payload => {
    return {
        type: sharedProjectsDataActions.SET_MATERIAL_NAME,
        payload,
    };
};

export const setProcurementMaterialNumber = payload => {
    return {
        type: sharedProjectsDataActions.SET_MATERIAL_NUMBER,
        payload,
    };
};

export const setProcurementSupplierName = payload => {
    return {
        type: sharedProjectsDataActions.SET_SUPPLIER_NAME,
        payload,
    };
};

export const setProcurementOrderNo = payload => {
    return {
        type: sharedProjectsDataActions.SET_ORDER_NO,
        payload,
    };
};

export const setProcurementOrderQtyUnit = payload => {
    return {
        type: sharedProjectsDataActions.SET_ORDER_QTY_UNIT,
        payload,
    };
};

export const setProcurementOrderQty = payload => {
    return {
        type: sharedProjectsDataActions.SET_ORDER_QTY,
        payload,
    };
};

export const setProcurementPrice = payload => {
    return {
        type: sharedProjectsDataActions.SET_PRICE,
        payload,
    };
};

export const setProcurementDeliveryReceiptNumber = payload => {
    return {
        type: sharedProjectsDataActions.SET_DELIVERY_RECEIPT_NUMBER,
        payload,
    };
};

export const setProcurementTotalPrice = payload => {
    return {
        type: sharedProjectsDataActions.SET_TOTAL_PRICE,
        payload,
    };
};

export const setProcurementInvoiceNumber = payload => {
    return {
        type: sharedProjectsDataActions.SET_INVOICE_NUMBER,
        payload,
    };
};

export const setProcurementInvoiceDate = payload => {
    return {
        type: sharedProjectsDataActions.SET_INVOICE_DATE,
        payload,
    };
};

export const setProcurementScheduledEndDate = payload => {
    return {
        type: sharedProjectsDataActions.SET_SCHEDULED_END_DATE,
        payload,
    };
};

export const setProcurementConfirmedEndDate = payload => {
    return {
        type: sharedProjectsDataActions.SET_CONFIRMED_END_DATE,
        payload,
    };
};

export const setRoutingResponsible = payload => {
    return {
        type: sharedProjectsDataActions.SET_ROUTING_RESPONSIBLE,
        payload,
    };
};

export const setRoutingCalculatedSchedulerData = payload => {
    return {
        type: sharedProjectsDataActions.SET_ROUTING_CALCULATED_SCHEDULER_DATA,
        payload,
    };
};

export const setMaterialId = payload => ({
    type: sharedProjectsDataActions.SET_MATERIAL_ID,
    payload,
});

export const setCurrentProjectActualOperatorTime = payload => ({
    type: sharedProjectsDataActions.SET_CURRENT_PROJECT_ACTUAL_OPERATOR_TIME,
    payload,
});

export const setScheduledData = payload => ({
    type: sharedProjectsDataActions.SET_SCHEDULED_DATA,
    payload,
});

export const setSerialNumbers = payload => ({
    type: sharedProjectsDataActions.SET_SERIAL_NUMBERS,
    payload,
});

export const sharedUpdateProcessStep = payload => ({
    type: sharedProjectsDataActions.UPDATE_PROCESS_STEP,
    payload,
});
