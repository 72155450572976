import { viewType } from '../../../../../constants/ViewType';
import { ticketsActions } from './tickets.actions';

const ticketsInitialState = {
    viewType: viewType.table
};

export const ticketsReducer = (state = ticketsInitialState, { type, payload }) => {
    switch (type) { 
        
        case ticketsActions.RESET_TICKETS: {
            return ticketsInitialState;
        } 
        case ticketsActions.SET_TICKETS_VIEW: {
            return {
                ...state,
                viewType: payload,
            };
        }

        default: return state;
    }
}