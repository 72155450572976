import React from "react";

// LIBS
import clsx from "clsx";
import i18next from "i18next";
import { useHistory } from "react-router-dom";

// MATERIAL UI - CORE
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import DirectionsWalkRoundedIcon from '@material-ui/icons/DirectionsWalkRounded';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import ConfirmationNumberRoundedIcon from '@material-ui/icons/ConfirmationNumberRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ImportContactsRoundedIcon from '@material-ui/icons/ImportContactsRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';

const LeftNavbar = ({
    open,
    classes,
    isNavigationButtonActive,
    getListIconComponent
}) => {
    const history = useHistory();

    const isAuditsActive = isNavigationButtonActive('/audits') && !(isNavigationButtonActive('/audits/create') || isNavigationButtonActive('/audits/edit'));
    const isSafetyWalksActive = isNavigationButtonActive('/safetyWalks');
    const isTicketsActive = isNavigationButtonActive('/tickets') && !(isNavigationButtonActive('/tickets/create') || isNavigationButtonActive('/tickets/edit'));
    const isAuditCreateEditActive = isNavigationButtonActive('/audits/create') || isNavigationButtonActive('/audits/edit');
    const isQuestionsActive = isNavigationButtonActive('/questions');
    const isTicketCreateEditActive = isNavigationButtonActive('/tickets/create') || isNavigationButtonActive('/tickets/edit');

    return (
        <React.Fragment>
            <ListItem className={clsx(classes.navigationButton, isTicketCreateEditActive ? "active" : "")} onClick={() => history.push("/tickets/create")}>
                <ListItemIcon>
                    {getListIconComponent('menu.create-ticket', <WarningRoundedIcon id='safetyCreateTicket' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.create-ticket`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isTicketsActive ? "active" : "")} onClick={() => history.push("/tickets")}>
                <ListItemIcon>
                    {getListIconComponent('menu.ehs-tickets', <ConfirmationNumberRoundedIcon id='safetyTickets' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.ehs-tickets`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isSafetyWalksActive ? "active" : "")} onClick={() => history.push("/safetyWalks")}>
                <ListItemIcon>
                    {getListIconComponent('menu.safety-walks', <DirectionsWalkRoundedIcon id='safetyWalks' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.safety-walks`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isAuditsActive ? "active" : "")} onClick={() => history.push("/audits")}>
                <ListItemIcon>
                    {getListIconComponent('menu.audits', <AssignmentTurnedInRoundedIcon id='safetyAudits' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.audits`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isQuestionsActive ? "active" : "")} onClick={() => history.push("/questions")}>
                <ListItemIcon>
                    {getListIconComponent('menu.questions', <ImportContactsRoundedIcon id='safetyQuestions' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.questions`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isAuditCreateEditActive ? "active" : "")} onClick={() => history.push("/audits/create")}>
                <ListItemIcon>
                    {getListIconComponent('menu.create-audit', <AssignmentRoundedIcon id='safetyBuildAudit' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.create-audit`)} />
            </ListItem>
        </React.Fragment>
    );
};

export default LeftNavbar;