export const TIME_DENSITY = {
    DAYS: 'days',
    WEEKS: 'weeks',
    MONTHS: 'months',
    QUARTERS: 'quarter',
};

export const MIN_CELL_COUNT_PER_TIME_DENSITY = {
    [TIME_DENSITY.DAYS]: 180,
    [TIME_DENSITY.WEEKS]: 26,
    [TIME_DENSITY.MONTHS]: 8,
    [TIME_DENSITY.QUARTERS]: 4,
};

export const DAYS_COUNT_IN_QUARTER = {
    1: 90,
    2: 91,
    3: 92,
    4: 92,
};

export const CELL_WIDTH_PER_TIME_DENSITY = {
    [TIME_DENSITY.DAYS]: 50,
    [TIME_DENSITY.WEEKS]: 150,
    [TIME_DENSITY.MONTHS]: 250,
    [TIME_DENSITY.QUARTERS]: 500,
};

export const COUNT_OF_DAYS_IN_CELL_PER_TIME_DENSITY = {
    [TIME_DENSITY.DAYS]: 1,
    [TIME_DENSITY.WEEKS]: 7,
    [TIME_DENSITY.MONTHS]: 31,
    [TIME_DENSITY.QUARTERS]: 91.25,
};

export const MAX_CELL_COUNT_IN_YEAR_PER_TIME_DENSITY = {
    [TIME_DENSITY.DAYS]: 365,
    [TIME_DENSITY.WEEKS]: 53,
    [TIME_DENSITY.MONTHS]: 12,
    [TIME_DENSITY.QUARTERS]: 4,
};

export const maxDaysCountForLeapYear = 366;

export const defaultSortKey = 'headerData.salesOrderNumber';

export const timeLineCellsCountPerTimeDensity = {
    [TIME_DENSITY.DAYS]: 365,
    [TIME_DENSITY.WEEKS]: 365,
    [TIME_DENSITY.MONTHS]: 12,
};

export const sortByOptions = [
    {
        title: 'Project Name',
        value: 'headerData.salesOrderNumber',
    },
    {
        title: 'Start Date',
        value: 'schedulingData.scheduledReleaseDate',
    },
    {
        title: 'End Date',
        value: 'schedulingData.scheduledEndDate',
    },
];

export const timeDensityFilterOptions = [
    {
        title: 'Days',
        value: TIME_DENSITY.DAYS,
    },
    {
        title: 'Calendar Weeks',
        value: TIME_DENSITY.WEEKS,
    },
    {
        title: 'Months',
        value: TIME_DENSITY.MONTHS,
    },
    {
        title: 'Quarters',
        value: TIME_DENSITY.QUARTERS,
    },
];
export const minWidthBarToShowLabelAndProgress = 200;
