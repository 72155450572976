export const DeviceType = {
    mobile: "mobile",
    tablet: "tablet",
    desktop: "desktop",
    other: "other"
}

export const DevicePlatform = {
    windows: "windows",
    macos: "macos",
    ios: "ios",
    android: "android",
    other: "other"
}
