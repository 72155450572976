export const darkTheme = {
    typography: {
        fontFamily: 'Poppins, sans-serif',
    },
    palette: {
        type: 'dark',
        primary: {
            main: '#16ABA1',
            light: '#1acbbf',
            dark: '#128780',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#46404C',
            light: '#665d6f',
            dark: '#403a45',
            contrastText: '#FFFFFF',
            button: '#322D3A',
        },
        buttons: {
            primary: '#52DBCE',
            hover: '#005049',
            lightHover: 'rgba(22, 171, 161, 0.08)',
        },
        background: {
            skeleton: '#403a45',
            paper: '#46404C',
            paperDark: '#322D3A',
            paperSmoke: '#403a45',
            contrastPaper: '#352F3D',
            expansionPanel: '#322D3A',
            active: '#797081',
            default: '#211E26',
            alternative: '#2A272F',
            expand: '#2A272F',
            alternativeButton: '#46404C',
            contrast: '#46404C',
            contrastDark: '#403a45',
            activeItem: '#504A55',
            shadow: 'transparent',
            disabled: 'rgba(255, 255, 255, 0.12)',
            statusBadge: 'rgba(255, 255, 255, 0.3)',
        },
        colors: {
            black: '#000',
            white: '#fff',
            alert: '#F5325C',
            lightAlert: '#FF6E50',
            blue: '#554DEF',
            purple: '#AA00FF',
            grey: '#797081',
            orange: '#FF7F00',
            green: '#16ABA1',
            lightGrey: '#9B95A1',
            darkGrey: '#8D90AF',
            yellow: '#EAB155',
            lightYellow: '#FFAF01',
            lemon: '#EFEF4D',
            lime: '#0EBF5A',
            lightGreen: '#089F14',
            extraLightGreen: '#9BF09B',
            carrot: '#F2994A',
            ligthCarrot: '#FFA04D',
            whiteVerse: '#FFFFFF',
            lightCaramel: '#FFCFA4',
            backgroundGreen: '#DCEBEA',
            flipper: '#1979AF',
            statusGreen: '#41E366',
            statusLightGreen: '#B2FF59',
            statusOrange: '#FFA726',
            statusRed: '#F44336',
            statusBlue: '#29B6F6',
        },
        textColor: {
            primary: '#FFFFFF',
            secondary: '#9B95A1',
            placeholder: '#BCB7C0',
            reverse: '#003732',
            lightHover: '#52DBCE',
        },
        badge: {
            grey: '#797081',
            color: '#FFFFFF',
            textColor: '#00390D',
        },
        input: {
            background: '#211E26',
            borderColor: '#46404C',
            contrastBorder: ' rgba(255, 255, 255, 0.23)',
            labelColor: '#bdbcbe',
            labelErrorColor: '#f44336',
            chipColor: '#616161',
        },
        tagInput: {
            background: '#616161',
            textColor: '#fff',
            iconBackground: '#9B95A1',
        },
        switch: {
            primary: '#16ABA1',
            secondary: '#211E26',
            textPrimary: '#FFFFFF',
            textSecondary: '#FFFFFF',
            textTertiary: '#9B95A1',
        },
        muiSwitch: {
            thumbColor: '#FFFFFF',
            trackColor: '#68626E',
            thumbActiveColor: '#16ABA1',
            trackActiveColor: '#16ABA13A',
        },
        checkbox: {
            default: '#46404C',
            contrast: '#797081',
            light: '#797081',
            dark: '#25222b',
        },
        table: {
            background: '#46404C',
            border: '#211E26',
            paginationBorder: '#DFE3E8',
            paginationColor: '#212B36',
            paginationActiveColor: '#16ABA1',
            disabeled: '#C4CDD5',
            disabeledColor: '#919EAB',
        },
        kanban: {
            background: '#322D3A',
            activeBackground: '#3e3748',
        },
        autocomplete: {
            labelColor: 'rgba(255, 255, 255, 0.7)',
        },
        icon: {
            default: '#FFFFFF',
            color: '#9B95A1',
            activeColor: '#FFFFFF',
            background: '#45424B',
            disabled: '#46404C',
        },
        timePicker: {
            backgroundImage:
                'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjNzk3MDgxIiBkPSJNMTEuOTkgMkM2LjQ3IDIgMiA2LjQ4IDIgMTJzNC40NyAxMCA5Ljk5IDEwQzE3LjUyIDIyIDIyIDE3LjUyIDIyIDEyUzE3LjUyIDIgMTEuOTkgMnpNMTIgMjBjLTQuNDIgMC04LTMuNTgtOC04czMuNTgtOCA4LTggOCAzLjU4IDggOC0zLjU4IDgtOCA4eiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBmaWxsPSIjNzk3MDgxIiBkPSJNMTIuNSA3SDExdjZsNS4yNSAzLjE1Ljc1LTEuMjMtNC41LTIuNjd6Ii8+PC9zdmc+)',
        },
        dateTimePicker: {
            backgroundImage:
                'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjNzk3MDgxIiBkPSJNMjAgM2gtMVYxaC0ydjJIN1YxSDV2Mkg0Yy0xLjEgMC0yIC45LTIgMnYxNmMwIDEuMS45IDIgMiAyaDE2YzEuMSAwIDItLjkgMi0yVjVjMC0xLjEtLjktMi0yLTJ6bTAgMThINFY4aDE2djEzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L3N2Zz4=)',
        },
        border: {
            color: '#46404C',
            lightColor: '#797081',
        },
        warnMessage: {
            background: '#322d3a',
        },
        homeScreen: {
            color: '#F8F8F8',
            backgroundColor: '#46404C',
            containerBoxShadow: '-6px -6px 16px rgba(96, 88, 104, 0.25), 6px 6px 10px rgba(0, 0, 0, 0.13)',
            containerBoxShadowHover: '0px 0px 10px 4px rgb(64, 58, 69, 0.8)',
            navbarBorderColor: '#211E26',
            navbarBoxShadow: '0px 3px 34px #332e38',
            buttonIconWhite: '#FFFFFF',
        },
        confirmationModal: {
            cancelButton: {
                background: '#797081',
                color: '#FFFFFF',
            },
            deleteButton: {
                background: '#F5325C',
                color: '#FFFFFF',
            },
        },
        mentionsInput: {
            textColor: '#FFFFFF',
            suggestionsBackground: '#424242',
            suggestionSelectedItemBackground: '#00B398',
        },
        scroll: {
            track: '#46404C',
            thumb: '#797081',
        },
        template: {
            draggingBackground: '#4b4356',
            highlightColor: '#3e3847',
            borderColor: '#9B95A1',
            iconColor: '#9B95A1',
            inputColor: '#797081',
            inputBackground: '#46404C',
            inputBorderColor: '#9B95A1',
            headerColor: '#FFF',
            headerBackground: '#322D3A',
            sectionBoxShadow: 'none',
            containerBoxShadow: 'none',
            containerBorderColor: '#8D90AF5A',
            containerHoverColor: '#5A5461',
            containerHoverLightColor: '#403a458c',
            supportButtonColor: '#fff',
            supportButtonBackground: '#797081',
            contrastButtonColor: '#fff',
            contrastButtonBackground: '#403a45',
            circleButtonShadow: '0px 0px 4px 3px rgba(0, 0, 0, 0.16)',
            configurationButtonShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.16)',
        },
        link: {
            color: 'rgba(255,255,255,.87)',
        },
        ganttChartBars: {
            done: '#41E366',
            open: 'rgba(255, 255, 255, 0.56)',
            inWork: '#29B6F6',
            delayed: '#F57C00',
            timeLine: 'rgba(255, 255, 255, 0.09)',
        },
    },
};
