import moment from 'moment';

import { projectsOverviewActions } from './projectsOverview.actions';

import { defaultSortKey, TIME_DENSITY } from '../projectsOverview.constants';

const projectsOverviewInitialState = {
    isLoading: false,
    projects: [],
    filters: {
        orderBy: defaultSortKey,
        timeDensity: TIME_DENSITY.WEEKS,
        orderStatus: null,
        from: moment().startOf('year'),
        to: moment().endOf('year'),
    },
    selectedRoutingId: null,
    isProcessRoutingPaperEdit: false,
};

export const projectsOverviewReducer = (state = projectsOverviewInitialState, { type, payload }) => {
    switch (type) {
        case projectsOverviewActions.SET_PROJECTS: {
            return {
                ...state,
                projects: payload,
            };
        }

        case projectsOverviewActions.MERGE_PROJECTS: {
            return {
                ...state,
                projects: [...state.projects, ...payload],
            };
        }

        case projectsOverviewActions.CHANGE_FILTER: {
            const { path, value } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    [path]: value,
                },
            };
        }

        case projectsOverviewActions.CLEAR_FILTERS: {
            return {
                ...state,
                filters: projectsOverviewInitialState.filters,
            };
        }

        case projectsOverviewActions.EXPAND_PROJECT: {
            return {
                ...state,
                projects: state.projects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        isExpanded: !project.isExpanded,
                    };
                }),
            };
        }

        case projectsOverviewActions.SET_DURATION: {
            return {
                ...state,
                projects: state.projects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        schedulingData: {
                            ...project.schedulingData,
                            scheduledReleaseDate: payload.productionOrder.schedulingData.scheduledReleaseDate,
                            scheduledEndDate: payload.productionOrder.schedulingData.scheduledEndDate,
                        },
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.routingId) {
                                return routing;
                            }

                            const newRouting = payload.productionOrder.processRoutings.find(x => x._id === payload.routingId);

                            if (!newRouting) {
                                return routing;
                            }

                            return {
                                ...routing,
                                calculatedSchedulingData: {
                                    ...routing.calculatedSchedulingData,
                                    scheduledReleaseDate: newRouting.calculatedSchedulingData.scheduledReleaseDate,
                                    scheduledEndDate: newRouting.calculatedSchedulingData.scheduledEndDate,
                                },
                                progress: {
                                    ...routing.progress,
                                    durationCompletedInSeconds: newRouting.progress.durationCompletedInSeconds,
                                    durationTotalInSeconds: newRouting.progress.durationTotalInSeconds,
                                },
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.stepId) {
                                        return step;
                                    }

                                    const newStep = newRouting.processSteps.find(x => x._id === step._id);

                                    if (!newStep) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        schedulingData: {
                                            ...step.schedulingData,
                                            scheduledReleaseDate: newStep.schedulingData.scheduledReleaseDate,
                                            scheduledEndDate: newStep.schedulingData.scheduledEndDate,
                                        },
                                        progress: {
                                            ...step.progress,
                                            durationCompletedInSeconds: newStep.progress.durationCompletedInSeconds,
                                            durationTotalInSeconds: newStep.progress.durationTotalInSeconds,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case projectsOverviewActions.SET_ROUTING_WITH_STEPS: {
            return {
                ...state,
                projects: state.projects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        schedulingData: {
                            ...project.schedulingData,
                            scheduledReleaseDate: payload.productionOrder.schedulingData.scheduledReleaseDate,
                            scheduledEndDate: payload.productionOrder.schedulingData.scheduledEndDate,
                        },
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.routingId) {
                                return routing;
                            }

                            const newRouting = payload.productionOrder.processRoutings.find(x => x._id === payload.routingId);

                            if (!newRouting) {
                                return routing;
                            }

                            return {
                                ...routing,
                                calculatedSchedulingData: {
                                    ...routing.calculatedSchedulingData,
                                    scheduledReleaseDate: newRouting.calculatedSchedulingData.scheduledReleaseDate,
                                    scheduledEndDate: newRouting.calculatedSchedulingData.scheduledEndDate,
                                },
                                processSteps: routing.processSteps.map(step => {
                                    const newStep = newRouting.processSteps.find(x => x._id === step._id);

                                    if (!newStep) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        schedulingData: {
                                            ...step.schedulingData,
                                            scheduledReleaseDate: newStep.schedulingData?.scheduledReleaseDate,
                                            scheduledEndDate: newStep.schedulingData?.scheduledEndDate,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case projectsOverviewActions.SET_ROUTING_RESPONSIBLE: {
            return {
                ...state,
                projects: state.projects.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.routingId) {
                                return routing;
                            }

                            const newRouting = payload.newRoutings.find(x => x._id === payload.routingId);

                            if (!newRouting) {
                                return routing;
                            }

                            return {
                                ...routing,
                                resolvedResponsible: [...newRouting.resolvedResponsible],
                                processSteps: routing.processSteps.map(step => {
                                    return {
                                        ...step,
                                        resolvedResponsible: [...newRouting.resolvedResponsible],
                                        responsible: [...newRouting.responsible],
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case projectsOverviewActions.SET_SELECTED_ROUTING_ID: {
            return {
                ...state,
                selectedRoutingId: payload,
            };
        }

        case projectsOverviewActions.UPDATE_PROCESS_STEP: {
            return {
                ...state,
                projects: state.projects.map(project => {
                    if (project._id !== state.selectedRoutingId.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        schedulingData: payload?.productionOrderSchedulingData || project?.schedulingData,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing?._id !== state.selectedRoutingId.routingId) {
                                return routing;
                            }

                            return {
                                ...routing,
                                calculatedSchedulingData: payload?.processRoutingSchedulingData || routing?.calculatedSchedulingData,
                                progress: payload?.processRoutingProgress,
                                processSteps: routing?.processSteps?.map(step => {
                                    if (step?._id !== payload._id) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        ...payload,
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case projectsOverviewActions.SET_LOADING: {
            return {
                ...state,
                isLoading: payload,
            };
        }

        case projectsOverviewActions.RESET_TO_INITIAL_STATE: {
            return projectsOverviewInitialState;
        }

        case projectsOverviewActions.TOGGLE_IS_PROCESS_ROUTING_PAPER_EDIT: {
            return {
                ...state,
                isProcessRoutingPaperEdit: !state.isProcessRoutingPaperEdit,
            };
        }

        default:
            return state;
    }
};
