import { ReactComponent as ExecutionCheckIcon } from '../../../resources/icons/executionCheckIcon.svg';
import { ReactComponent as FindingIcon } from '../../../resources/icons/findingsIcon.svg';
import { ReactComponent as ReportIcon } from '../../../resources/icons/reportIcon.svg';

export const actionTabs = {
    execution: "execution",
    findings: "findings",
    report: "report"
};

export const actionTabsSettings = [
    {
        value: actionTabs.execution,
        label: "protocol-production-stand.right-bar.tabs.execution",
        icon: <ExecutionCheckIcon />
    },
    {
        value: actionTabs.findings,
        label: "protocol-production-stand.right-bar.tabs.findings",
        icon: <FindingIcon />
    },
    {
        value: actionTabs.report,
        label: "protocol-production-stand.right-bar.tabs.report",
        icon: <ReportIcon />
    }
];

export const findingCommentModules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        ["link"],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean']
    ]
};

export const findingCommentFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link"
];