import { ticketsSearchActions } from './ticketsSearch.actions';

const ticketsSearchInitialState = {};

export const ticketsSearchReducer = (state = ticketsSearchInitialState, { type, payload }) => {
    switch (type) { 
        
        case ticketsSearchActions.RESET_SEARCH_TICKETS: {
            return ticketsSearchInitialState;
        }

        default: return state;
    }
}