export const viewType = {
    table: 'table',
    monitorTable: 'monitorTable',
    kanban: 'kanban',
    bucket: 'bucket',
    list: "list",
    card: "card",
    carousel: "carousel",
    cards: "cards",
    graph: "graph"
}

export const viewMode = {
    default: "default",
    bucket: "bucket"
}