import i18next from "i18next";

export const documentStatus = {
    draft: "draft",
    released: "released",
    depreciated: "depreciated"
};

export const documentStatusTranslations = {
    [documentStatus.draft]: "common.document-status.draft",
    [documentStatus.released]: "common.document-status.released",
    [documentStatus.depreciated]: "common.document-status.depreciated"
};

export const getDocumentStatusOptions = () => [
    {
        title: i18next.t(documentStatusTranslations[documentStatus.draft]),
        value: documentStatus.draft,
        colorName: "lightGrey"
    },
    {
        title: i18next.t(documentStatusTranslations[documentStatus.released]),
        value: documentStatus.released,
        colorName: "blue"
    },
    {
        title: i18next.t(documentStatusTranslations[documentStatus.depreciated]),
        value: documentStatus.depreciated,
        colorName: "red"
    }
];