export const baseDataTabActions = {
    SET_ATTACHMENT_TYPES: 'PROJECT_DETAILS_BASE_DATA/SET_ATTACHMENT_TYPES',
    SET_PRODUCTION_ORDER_ID: 'PROJECT_DETAILS_BASE_DATA/SET_PRODUCTION_ORDER_ID',
    SET_MEDIA_INPUTS_ATTACHMENT_TYPE_FILTER: 'PROJECT_DETAILS_BASE_DATA/SET_MEDIA_INPUTS_ATTACHMENT_TYPE_FILTER',
};

export const setBaseDataAttachmentTypes = payload => {
    return {
        type: baseDataTabActions.SET_ATTACHMENT_TYPES,
        payload,
    };
};

export const setBaseDataProductionOrderId = payload => {
    return {
        type: baseDataTabActions.SET_PRODUCTION_ORDER_ID,
        payload,
    };
};

export const setBaseDataAttachmentTypeFilter = payload => {
    return {
        type: baseDataTabActions.SET_MEDIA_INPUTS_ATTACHMENT_TYPE_FILTER,
        payload,
    };
};
