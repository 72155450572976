import moment from 'moment';
import { fruitPreparation } from './fruitPreparation.actions';

const fruitPreparationInitialState = {
    orders: [],
};

export const fruitPreparationReducer = (state = fruitPreparationInitialState, { type, payload }) => {
    switch (type) {
        case fruitPreparation.SET_ORDERS: {
            const { orders, hasPrev } = payload;

            return {
                ...state,
                orders: !hasPrev ? [...orders] : [...state.orders, ...orders],
            };
        }
        case fruitPreparation.SET_ORDER_ESTIMATED_DELAY: {
            const { orderId, estimatedDelay } = payload;

            return {
                ...state,
                orders: state.orders.map(order => {
                    if (order._id !== orderId) {
                        return order;
                    }

                    const scheduledEndDate = order.schedulingData.dueDate || moment();

                    return {
                        ...order,
                        schedulingData: {
                            ...order.schedulingData,
                            scheduledEndDate: moment(scheduledEndDate).add(estimatedDelay, 'minutes'),
                            estimatedDelay: {
                                value: estimatedDelay,
                                unit: 'minutes',
                            },
                        },
                    };
                }),
            };
        }
        case fruitPreparation.SET_ORDER_STATUS: {
            const { orderId, orderStatus, schedulingData } = payload;

            return {
                ...state,
                orders: state.orders.map(order => {
                    if (order._id !== orderId) {
                        return order;
                    }

                    return {
                        ...order,
                        headerData: {
                            ...order.headerData,
                            orderStatus,
                        },
                        schedulingData: {
                            ...order.schedulingData,
                            actualStartDate: schedulingData.actualStartDate,
                            confirmedEndDate: schedulingData.confirmedEndDate,
                        },
                    };
                }),
            };
        }
        default:
            return state;
    }
};
