import { cloneDeep } from 'lodash';
import { get } from 'lodash';
import createObjectID from 'bson-objectid';
import shortid from 'shortid';

// UTILS
import update from '../../../redux/update';
import { mapModelToTemplate, isIuTPlanValid, getConflicts } from '../../../utils/inspectionPlanHelper';
import { setMultilanguageValue } from '../../../utils/protocolHelper';
import { isValueExists } from '../../../utils/valueHelper';
import { filterObject } from '../../../utils/objectHelper';
import { APP_CUSTOMER } from '../../../utils/env';

// CONSTANTS
import { globalActionTypes } from '../../../redux/actions';
import { documentStatus } from '../../../constants/DocumentStatus';
import { revisionNumber } from '../../../constants/Revision';
import {
    inspectionPlanOperations,
    inspectionPlanHandlerOperations,
    inspectionPlanHandlerPaths,
    customerDocLanguageDefaultValue,
    inspectionLocationDefaultValue,
} from './constants';
import { appLanguage } from '../../../constants/AppLanguage';
import { tabs } from './constants';
import { loadingStatus } from '../../../constants/LoadingStatus';
import { store } from '../../../redux/store';
import { reactAppCustomer } from '../../../constants/Config';

const getCurrentUserId = state => state?.currentUserData?._id;
const state = (store || null)?.preloadedState;

const actions = {
    builderInit: 'IUT_PLAN_BUILDER/BUILDER_INIT',
    setInspectionPlanModel: 'IUT_PLAN_BUILDER/SET_INSPECTION_PLAN_MODEL',

    handleValueChange: 'IUT_PLAN_BUILDER/HANDLE_VALUE_CHANGE',

    trackConflicts: 'IUT_PLAN_BUILDER/TRACK_CONFLICTS',
    trackResize: 'IUT_PLAN_BUILDER/TRACK_RESIZE',

    moveAssemblyGroupSchema: 'IUT_PLAN_BUILDER/MOVE_ASSEMBLY_GROUP_SCHEMA',
    addAssemblyGroupSchema: 'IUT_PLAN_BUILDER/ADD_ASSEMBLY_GROUP_SCHEMA',
    cloneAssemblyGroupSchema: 'IUT_PLAN_BUILDER/CLONE_ASSEMBLY_GROUP_SCHEMA',
    deleteAssemblyGroupSchema: 'IUT_PLAN_BUILDER/DELETE_ASSEMBLY_GROUP_SCHEMA',
    handleAssemblyGroupSchemaValueChange: 'IUT_PLAN_BUILDER/HANDLE_ASSEMBLY_GROUP_SCHEMA_VALUE_CHANGE',

    moveManufacturingStepSchema: 'IUT_PLAN_BUILDER/MOVE_MANUFACTURING_STEP_SCHEMA',
    addManufacturingStepSchema: 'IUT_PLAN_BUILDER/ADD_MANUFACTURING_STEP_SCHEMA',
    cloneManufacturingStepSchema: 'IUT_PLAN_BUILDER/CLONE_MANUFACTURING_STEP_SCHEMA',
    deleteManufacturingStepSchema: 'IUT_PLAN_BUILDER/DELETE_MANUFACTURING_STEP_SCHEMA',
    handleManufacturingStepSchemaValueChange: 'IUT_PLAN_BUILDER/HANDLE_MANUFACTURING_STEP_VALUE_CHANGE',

    moveInspectionOrderSchema: 'IUT_PLAN_BUILDER/MOVE_INSPECTION_ORDER_SCHEMA',
    addInspectionOrderSchema: 'IUT_PLAN_BUILDER/ADD_INSPECTION_ORDER_SCHEMA',
    cloneInspectionOrderSchema: 'IUT_PLAN_BUILDER/CLONE_INSPECTION_ORDER_SCHEMA',
    deleteInspectionOrderSchema: 'IUT_PLAN_BUILDER/DELETE_INSPECTION_ORDER_SCHEMA',
    handleInspectionOrderSchemaValueChange: 'IUT_PLAN_BUILDER/HANDLE_INSPECTION_ORDER_VALUE_CHANGE',
};

const emptyInspectionOrderState = () => {
    return {
        key: shortid.generate(),
        _id: createObjectID().toHexString(),
        inspectionCode: [],
        inspectionDescriptionShort: [],
        inspectionDescriptionLong: [],
        inspectionLocation:
            APP_CUSTOMER === reactAppCustomer.dw
                ? [
                      {
                          language: appLanguage.en,
                          value: inspectionLocationDefaultValue,
                      },
                  ]
                : null,
        languageOfCustomerDocumentation:
            APP_CUSTOMER === reactAppCustomer.dw
                ? [
                      {
                          language: appLanguage.en,
                          value: customerDocLanguageDefaultValue,
                      },
                  ]
                : null,
    };
};

const emptyManufacturingStepState = () => {
    return {
        key: shortid.generate(),
        _id: createObjectID().toHexString(),
        manufacturingStepName: [],
        manufacturingStepCode: [],
        inspectionOrders: [cloneDeep(emptyInspectionOrderState())],
    };
};

const emptyAssemblyGroupState = () => {
    return {
        key: shortid.generate(),
        _id: createObjectID().toHexString(),
        assemblyGroupNumber: [],
        assemblyGroupName: [],
        manufacturingStep: [cloneDeep(emptyManufacturingStepState())],
    };
};

const getInitialState = () => {
    return {
        continuousIntegration: false,

        language: appLanguage.en,

        users: {
            loadingStatus: loadingStatus.notStarted,
            data: [],
        },
        templates: {
            loadingStatus: loadingStatus.notStarted,
            data: [],
        },
        tiles: {
            loadingStatus: loadingStatus.notStarted,
            data: [],
        },

        template: {
            customer: '',
            customerOrderNo: '',
            metadata: {
                status: documentStatus.draft,
                revisionHistory: [
                    {
                        editor: { accountID: getCurrentUserId(state) },
                        revisionDate: new Date(),
                        revisionNumber: revisionNumber[0],
                    },
                ],
                revisionNumber: revisionNumber[0],
                revisionEditor: getCurrentUserId(state),
            },
            assemblyGroup: [cloneDeep(emptyAssemblyGroupState())],
        },

        changesSaved: true,
        isValid: false,
        conflictsInfo: {
            count: 0,
            conflicts: {},
        },
        resizeReference: null,
        activeTab: tabs.edit,
        manufacturingStepExpander: {},
        inspectionOrdersExpander: {
            open: false,
            assemblyGroupIndex: null,
            manufacturingStepIndex: null,
            inspectionOrderIndex: null,
            inspectionOrderKey: null,
        },
        updateHandler: [],
        metadataModal: {
            isOpen: false,
            initialOpen: false,
            createPending: false,
        },
        selectedInspectionOrders: {},
    };
};

export const iutPlanBuilderReducer = (state = getInitialState(), action) => {
    let newState = state;

    switch (action.type) {
        // Dispatch generalClear action to make sure, that data was cleared between pages
        case globalActionTypes.generalClear:
            if (!newState.continuousIntegration) {
                newState = getInitialState();
            }
            break;

        case actions.builderInit: {
            state.template.assemblyGroup.forEach(assemblyGroup => {
                assemblyGroup.manufacturingStep.forEach(manufacturingStep => {
                    newState = update.set(newState, `manufacturingStepExpander.${manufacturingStep.key}`, true);
                    manufacturingStep.inspectionOrders.forEach(inspectionOrder => {
                        newState = update.set(newState, `inspectionOrdersExpander.${inspectionOrder.key}`, true);
                    });
                });
            });
            break;
        }

        case actions.setInspectionPlanModel: {
            const template = mapModelToTemplate(
                action.payload.model,
                state.template,
                action.payload.isNew,
                action.payload.isTemplate,
                state.language
            );
            const isValid = isIuTPlanValid(template, state.language);

            const templates = state.templates.loadingStatus === loadingStatus.success ? state.templates.data : null;

            const conflictsInfo = getConflicts(template, templates);

            let updateHandler = [];
            if (template.numberOfConflicts !== conflictsInfo.count) {
                updateHandler.push({
                    operation: inspectionPlanHandlerOperations.update,
                    path: undefined,
                    values: {
                        numberOfConflicts: conflictsInfo.count,
                    },
                });
            }

            newState = update(state, {
                template: { $set: template },
                isValid: { $set: isValid },
                isInited: { $set: true },
                conflictsInfo: {
                    $set: {
                        count: conflictsInfo.count,
                        conflicts: conflictsInfo.conflicts,
                    },
                },
                updateHandler: {
                    $set: updateHandler,
                },
            });
            break;
        }

        case actions.handleValueChange: {
            const { path, value, handler } = action.payload;

            let newValue = value;

            if (handler?.allowLanguages) {
                const oldValue = get(state, path) || [];
                newValue = setMultilanguageValue(value, oldValue, state.language);
            }

            newState = update.set(state, path, newValue);

            if (handler) {
                if (handler.trackChanges) {
                    if (handler.operation && handler.operation === inspectionPlanOperations.edit) {
                        const handlerIndex = newState.updateHandler.findIndex(x => x.path === undefined);

                        if (handlerIndex !== -1) {
                            newState.updateHandler = update.set(newState.updateHandler, `${handlerIndex}.values.${handler.path}`, newValue);
                        } else {
                            newState.updateHandler = update(newState.updateHandler, {
                                $push: [
                                    {
                                        operation: inspectionPlanHandlerOperations.update,
                                        path: undefined,
                                        values: {
                                            [handler.path]: newValue,
                                        },
                                    },
                                ],
                            });
                        }
                    }

                    newState = update.set(newState, 'changesSaved', false);
                    newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));
                }

                if (handler.trackConflicts) {
                    const templates = newState.templates.loadingStatus === loadingStatus.success ? newState.templates.data : null;

                    const conflictsInfo = getConflicts(newState.template, templates);

                    if (newState.conflictsInfo?.count !== conflictsInfo.count) {
                        const handlerIndex = newState.updateHandler.findIndex(x => x.path === undefined);

                        if (handlerIndex !== -1) {
                            newState.updateHandler = update.set(
                                newState.updateHandler,
                                `${handlerIndex}.values.numberOfConflicts`,
                                conflictsInfo.count
                            );
                        } else {
                            newState.updateHandler = update(newState.updateHandler, {
                                $push: [
                                    {
                                        operation: inspectionPlanHandlerOperations.update,
                                        path: undefined,
                                        values: {
                                            numberOfConflicts: conflictsInfo.count,
                                        },
                                    },
                                ],
                            });
                        }
                    }

                    newState = update.set(newState, 'conflictsInfo', {
                        count: conflictsInfo.count,
                        conflicts: conflictsInfo.conflicts,
                    });
                }
            }
            break;
        }

        case actions.trackConflicts: {
            const templates = state.templates.loadingStatus === loadingStatus.success ? state.templates.data : null;

            const conflictsInfo = getConflicts(newState.template, templates);

            if (newState.conflictsInfo?.count !== conflictsInfo.count) {
                const handlerIndex = newState.updateHandler.findIndex(x => x.path === undefined);

                if (handlerIndex !== -1) {
                    newState.updateHandler = update.set(
                        newState.updateHandler,
                        `${handlerIndex}.values.numberOfConflicts`,
                        conflictsInfo.count
                    );
                } else {
                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [
                            {
                                operation: inspectionPlanHandlerOperations.update,
                                path: undefined,
                                values: {
                                    numberOfConflicts: conflictsInfo.count,
                                },
                            },
                        ],
                    });
                }
            }

            newState = update.set(newState, 'conflictsInfo', {
                count: conflictsInfo.count,
                conflicts: conflictsInfo.conflicts,
            });
            break;
        }

        case actions.trackResize:
            newState = update.set(newState, 'resizeReference', {});
            break;

        case actions.moveAssemblyGroupSchema: {
            const { dragIndex, hoverIndex, operation } = action.payload;

            const dragElement = get(newState.template.assemblyGroup, dragIndex);

            newState.template.assemblyGroup = update(newState.template.assemblyGroup, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragElement],
                ],
            });

            if (operation && operation === inspectionPlanOperations.edit) {
                let atomicValue = cloneDeep(newState.template.assemblyGroup);

                //atomicValue = filterObject(atomicValue, '_id');
                atomicValue = filterObject(atomicValue, 'key');

                const newHandler = {
                    operation: inspectionPlanHandlerOperations.update,
                    values: {
                        assemblyGroup: atomicValue,
                    },
                };

                const atomicIndex = newState.updateHandler.findIndex(
                    x => isValueExists(x.values?.assemblyGroup) && !x.path && x.operation === inspectionPlanHandlerOperations.update
                );

                if (atomicIndex !== -1) {
                    newState.updateHandler = update(newState.updateHandler, {
                        [atomicIndex]: { $set: newHandler },
                    });
                } else {
                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [newHandler],
                    });
                }
            }

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.addAssemblyGroupSchema: {
            const { index, operation } = action.payload;

            const newAssemblyGroup = {
                ...cloneDeep(emptyAssemblyGroupState()),
                key: shortid.generate(),
            };

            if (index !== undefined && index !== null) {
                newState.template.assemblyGroup = update(state.template.assemblyGroup, {
                    $splice: [[index, 0, newAssemblyGroup]],
                });
            } else {
                newState.template.assemblyGroup = update(state.template.assemblyGroup, {
                    $push: [newAssemblyGroup],
                });
            }

            if (operation && operation === inspectionPlanOperations.edit) {
                const newHandler = {
                    operation: inspectionPlanHandlerOperations.add,
                    path: inspectionPlanHandlerPaths.assemblyGroupArray,
                    values: newAssemblyGroup,
                    position: isValueExists(index) ? index : undefined,
                };

                newState.updateHandler = update(newState.updateHandler, {
                    $push: [newHandler],
                });
            }

            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));
            break;
        }
        case actions.cloneAssemblyGroupSchema: {
            const { assemblyGroupIndex, operation } = action.payload;
            const newAssemblyGroup = {
                ...cloneDeep({
                    ...state.template.assemblyGroup[assemblyGroupIndex],
                    manufacturingStep: [
                        ...state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep.map(manufacturingStep => {
                            return {
                                ...manufacturingStep,
                                inspectionOrders: [
                                    ...manufacturingStep.inspectionOrders.map(inspectionOrder => {
                                        return {
                                            ...inspectionOrder,
                                            _id: createObjectID().toHexString(),
                                            key: shortid.generate(),
                                            inspectionReportID: inspectionOrder.protocolTemplateId
                                                ? createObjectID().toHexString()
                                                : undefined,
                                        };
                                    }),
                                ],
                                _id: createObjectID().toHexString(),
                                key: shortid.generate(),
                            };
                        }),
                    ],
                }),
                _id: createObjectID().toHexString(),
                key: shortid.generate(),
            };

            newState.template.assemblyGroup = update(state.template.assemblyGroup, {
                $push: [newAssemblyGroup],
            });

            if (operation && operation === inspectionPlanOperations.edit) {
                const newHandler = {
                    operation: inspectionPlanHandlerOperations.add,
                    path: inspectionPlanHandlerPaths.assemblyGroupArray,
                    values: newAssemblyGroup,
                };

                newState.updateHandler = update(newState.updateHandler, {
                    $push: [newHandler],
                });
            }

            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));
            break;
        }
        case actions.deleteAssemblyGroupSchema: {
            const { assemblyGroupIndex, operation } = action.payload;

            if (operation && operation === inspectionPlanOperations.edit) {
                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;

                const filteredHandlers = newState.updateHandler.filter(x => {
                    if (
                        x.childrenIds?.assemblyGroupId === assemblyGroupId &&
                        (x.path === inspectionPlanHandlerPaths.manufacturingStep ||
                            x.path === inspectionPlanHandlerPaths.manufacturingStepArray ||
                            x.path === inspectionPlanHandlerPaths.inspectionOrder ||
                            x.path === inspectionPlanHandlerPaths.inspectionOrderArray)
                    ) {
                        return false;
                    }

                    return true;
                });
                newState.updateHandler = update(newState.updateHandler, {
                    $set: filteredHandlers,
                });

                const handlerIndex = newState.updateHandler.findIndex(
                    x =>
                        (x.path === inspectionPlanHandlerPaths.assemblyGroup && x.childrenIds?.assemblyGroupId === assemblyGroupId) ||
                        (x.path === inspectionPlanHandlerPaths.assemblyGroupArray && x.values?.key === assemblyGroupKey)
                );

                const newHandler = {
                    operation: inspectionPlanHandlerOperations.remove,
                    path: inspectionPlanHandlerPaths.assemblyGroupArray,
                    values: {
                        _id: assemblyGroupId,
                    },
                };

                if (handlerIndex !== -1) {
                    const currentHandler = newState.updateHandler[handlerIndex];
                    if (currentHandler.operation === inspectionPlanHandlerOperations.update) {
                        newState.updateHandler = update.set(newState.updateHandler, `${handlerIndex}`, newHandler);
                    } else {
                        newState.updateHandler = update(newState.updateHandler, {
                            $splice: [[handlerIndex, 1]],
                        });
                    }
                } else {
                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [newHandler],
                    });
                }
            }

            newState.template.assemblyGroup = update(newState.template.assemblyGroup, {
                $splice: [[assemblyGroupIndex, 1]],
            });
            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));

            break;
        }
        case actions.handleAssemblyGroupSchemaValueChange: {
            const { assemblyGroupIndex, path, value, allowLanguages, language, operation } = action.payload;

            let newValue = value;

            if (allowLanguages) {
                const oldValue = get(state.template.assemblyGroup[assemblyGroupIndex], path) || [];
                newValue = setMultilanguageValue(value, oldValue, language || state.language);
            }

            newState.template.assemblyGroup[assemblyGroupIndex] = update.set(
                state.template.assemblyGroup[assemblyGroupIndex],
                path,
                newValue
            );

            if (operation && operation === inspectionPlanOperations.edit) {
                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;

                const handlerIndex = newState.updateHandler.findIndex(
                    x =>
                        (x.path === inspectionPlanHandlerPaths.assemblyGroup &&
                            x.childrenIds?.assemblyGroupId === assemblyGroupId &&
                            get(x.values, path) !== undefined) ||
                        (x.path === inspectionPlanHandlerPaths.assemblyGroupArray && x.values?.key === assemblyGroupKey)
                );

                if (handlerIndex !== -1) {
                    newState.updateHandler = update.set(newState.updateHandler, `${handlerIndex}.values.${path}`, newValue);
                } else {
                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [
                            {
                                operation: inspectionPlanHandlerOperations.update,
                                path: inspectionPlanHandlerPaths.assemblyGroup,
                                childrenIds: {
                                    assemblyGroupId: assemblyGroupId,
                                },
                                values: {
                                    [path]: newValue,
                                },
                            },
                        ],
                    });
                }
            }

            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));
            break;
        }

        case actions.moveManufacturingStepSchema: {
            const { assemblyGroupIndex, dragIndex, hoverIndex, operation } = action.payload;

            const dragElement = get(newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep, dragIndex);

            newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep = update(
                newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep,
                {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragElement],
                    ],
                }
            );

            if (operation && operation === inspectionPlanOperations.edit) {
                let atomicValue = cloneDeep(newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep);

                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;

                if (assemblyGroupId) {
                    //atomicValue = filterObject(atomicValue, '_id');
                    atomicValue = filterObject(atomicValue, 'key');

                    const newHandler = {
                        operation: inspectionPlanHandlerOperations.update,
                        path: inspectionPlanHandlerPaths.assemblyGroup,
                        values: {
                            manufacturingStep: atomicValue,
                        },
                        childrenIds: {
                            assemblyGroupId: assemblyGroupId,
                        },
                    };

                    const atomicIndex = newState.updateHandler.findIndex(
                        x =>
                            isValueExists(x.values?.manufacturingStep) &&
                            x.path === inspectionPlanHandlerPaths.assemblyGroup &&
                            x.operation === inspectionPlanHandlerOperations.update &&
                            x.childrenIds?.assemblyGroupId === assemblyGroupId
                    );

                    if (atomicIndex !== -1) {
                        newState.updateHandler = update(newState.updateHandler, {
                            [atomicIndex]: { $set: newHandler },
                        });
                    } else {
                        newState.updateHandler = update(newState.updateHandler, {
                            $push: [newHandler],
                        });
                    }
                } else {
                    const atomicIndex = newState.updateHandler.findIndex(
                        x =>
                            x.path === inspectionPlanHandlerPaths.assemblyGroupArray &&
                            x.operation === inspectionPlanHandlerOperations.add &&
                            x.values?.key === assemblyGroupKey
                    );

                    newState.updateHandler = update(newState.updateHandler, {
                        [atomicIndex]: {
                            values: {
                                manufacturingStep: { $set: atomicValue },
                            },
                        },
                    });
                }
            }

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.addManufacturingStepSchema: {
            const { assemblyGroupIndex, index, operation } = action.payload;

            const newManufacturingStep = {
                ...cloneDeep(emptyManufacturingStepState()),
                key: shortid.generate(),
            };

            if (operation && operation === inspectionPlanOperations.edit) {
                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;

                const handlerIndex = newState.updateHandler.findIndex(
                    x => x.path === inspectionPlanHandlerPaths.assemblyGroupArray && x.values?.key === assemblyGroupKey
                );
                if (handlerIndex !== -1) {
                    if (index !== undefined && index !== null) {
                        newState.updateHandler = update(newState.updateHandler, {
                            [handlerIndex]: {
                                values: {
                                    manufacturingStep: {
                                        $splice: [[index, 0, newManufacturingStep]],
                                    },
                                },
                            },
                        });
                    } else {
                        newState.updateHandler = update(newState.updateHandler, {
                            [handlerIndex]: {
                                values: {
                                    manufacturingStep: {
                                        $push: [newManufacturingStep],
                                    },
                                },
                            },
                        });
                    }
                } else {
                    const newHandler = {
                        operation: inspectionPlanHandlerOperations.add,
                        childrenIds: {
                            assemblyGroupId: assemblyGroupId,
                        },
                        path: inspectionPlanHandlerPaths.manufacturingStepArray,
                        values: newManufacturingStep,
                        position: isValueExists(index) ? index : undefined,
                    };

                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [newHandler],
                    });
                }
            }

            if (index !== undefined && index !== null) {
                newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep = update(
                    state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep,
                    {
                        $splice: [[index, 0, newManufacturingStep]],
                    }
                );
            } else {
                newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep = update(
                    state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep,
                    {
                        $push: [newManufacturingStep],
                    }
                );
            }

            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));
            break;
        }
        case actions.cloneManufacturingStepSchema: {
            const { assemblyGroupIndex, manufacturingStepIndex, operation } = action.payload;
            const newManufacturingStep = {
                ...cloneDeep({
                    ...state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex],
                    inspectionOrders: [
                        ...state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders.map(
                            inspectionOrder => {
                                return {
                                    ...inspectionOrder,
                                    _id: createObjectID().toHexString(),
                                    key: shortid.generate(),
                                    inspectionReportID: inspectionOrder.protocolTemplateId ? createObjectID().toHexString() : undefined,
                                };
                            }
                        ),
                    ],
                }),
                _id: createObjectID().toHexString(),
                key: shortid.generate(),
            };

            if (operation && operation === inspectionPlanOperations.edit) {
                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;

                const handlerIndex = newState.updateHandler.findIndex(
                    x => x.path === inspectionPlanHandlerPaths.assemblyGroupArray && x.values?.key === assemblyGroupKey
                );

                if (handlerIndex !== -1) {
                    newState.updateHandler = update(newState.updateHandler, {
                        [handlerIndex]: {
                            values: {
                                manufacturingStep: {
                                    $push: [newManufacturingStep],
                                },
                            },
                        },
                    });
                } else {
                    const newHandler = {
                        operation: inspectionPlanHandlerOperations.add,
                        childrenIds: {
                            assemblyGroupId: assemblyGroupId,
                        },
                        path: inspectionPlanHandlerPaths.manufacturingStepArray,
                        values: newManufacturingStep,
                    };

                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [newHandler],
                    });
                }
            }

            newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep = update(
                state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep,
                {
                    $push: [newManufacturingStep],
                }
            );

            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));
            break;
        }
        case actions.deleteManufacturingStepSchema: {
            const { assemblyGroupIndex, manufacturingStepIndex, operation } = action.payload;

            if (operation && operation === inspectionPlanOperations.edit) {
                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;
                const manufacturingStepId =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex]._id;
                const manufacturingStepKey =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].key;

                const filteredHandlers = newState.updateHandler.filter(x => {
                    if (
                        x.childrenIds?.manufacturingStepId === manufacturingStepId &&
                        (x.path === inspectionPlanHandlerPaths.inspectionOrder ||
                            x.path === inspectionPlanHandlerPaths.inspectionOrderArray)
                    ) {
                        return false;
                    }

                    return true;
                });
                newState.updateHandler = update(newState.updateHandler, {
                    $set: filteredHandlers,
                });

                const handlerIndex = newState.updateHandler.findIndex(
                    x =>
                        (x.path === inspectionPlanHandlerPaths.manufacturingStep &&
                            x.childrenIds?.assemblyGroupId === assemblyGroupId &&
                            x.childrenIds?.manufacturingStepId === manufacturingStepId) ||
                        (x.path === inspectionPlanHandlerPaths.assemblyGroupArray && x.values?.key === assemblyGroupKey) ||
                        (x.path === inspectionPlanHandlerPaths.manufacturingStepArray && x.values?.key === manufacturingStepKey)
                );

                const newHandler = {
                    operation: inspectionPlanHandlerOperations.remove,
                    path: inspectionPlanHandlerPaths.manufacturingStepArray,
                    childrenIds: {
                        assemblyGroupId: assemblyGroupId,
                    },
                    values: {
                        _id: manufacturingStepId,
                    },
                };

                if (handlerIndex !== -1) {
                    const currentHandler = newState.updateHandler[handlerIndex];
                    if (currentHandler.operation === inspectionPlanHandlerOperations.update) {
                        newState.updateHandler = update.set(newState.updateHandler, `${handlerIndex}`, newHandler);
                    } else {
                        if (currentHandler.path === inspectionPlanHandlerPaths.assemblyGroupArray) {
                            newState.updateHandler = update(newState.updateHandler, {
                                [handlerIndex]: {
                                    values: {
                                        manufacturingStep: {
                                            $splice: [[manufacturingStepIndex, 1]],
                                        },
                                    },
                                },
                            });
                        } else {
                            newState.updateHandler = update(newState.updateHandler, {
                                $splice: [[handlerIndex, 1]],
                            });
                        }
                    }
                } else {
                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [newHandler],
                    });
                }
            }

            newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep = update(
                newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep,
                {
                    $splice: [[manufacturingStepIndex, 1]],
                }
            );
            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));

            break;
        }
        case actions.handleManufacturingStepSchemaValueChange: {
            const { assemblyGroupIndex, manufacturingStepIndex, path, value, allowLanguages, language, operation } = action.payload;

            let newValue = value;

            if (allowLanguages) {
                const oldValue =
                    get(state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex], path) || [];
                newValue = setMultilanguageValue(value, oldValue, language || state.language);
            }

            newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex] = update.set(
                state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex],
                path,
                newValue
            );

            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));

            if (operation && operation === inspectionPlanOperations.edit) {
                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;
                const manufacturingStepId =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex]._id;
                const manufacturingStepKey =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].key;

                const handlerIndex = newState.updateHandler.findIndex(
                    x =>
                        (x.path === inspectionPlanHandlerPaths.manufacturingStep &&
                            x.childrenIds?.assemblyGroupId === assemblyGroupId &&
                            x.childrenIds?.manufacturingStepId === manufacturingStepId &&
                            get(x.values, path) !== undefined) ||
                        (x.path === inspectionPlanHandlerPaths.assemblyGroupArray && x.values?.key === assemblyGroupKey) ||
                        (x.path === inspectionPlanHandlerPaths.manufacturingStepArray && x.values?.key === manufacturingStepKey)
                );

                if (handlerIndex !== -1) {
                    const currentHandler = newState.updateHandler[handlerIndex];

                    if (
                        currentHandler.operation === inspectionPlanHandlerOperations.add &&
                        currentHandler.path === inspectionPlanHandlerPaths.assemblyGroupArray
                    ) {
                        newState.updateHandler = update.set(
                            newState.updateHandler,
                            `${handlerIndex}.values.manufacturingStep.${manufacturingStepIndex}.${path}`,
                            newValue
                        );
                    } else {
                        newState.updateHandler = update.set(newState.updateHandler, `${handlerIndex}.values.${path}`, newValue);
                    }
                } else {
                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [
                            {
                                operation: inspectionPlanHandlerOperations.update,
                                path: inspectionPlanHandlerPaths.manufacturingStep,
                                childrenIds: {
                                    assemblyGroupId: assemblyGroupId,
                                    manufacturingStepId: manufacturingStepId,
                                },
                                values: {
                                    [path]: newValue,
                                },
                            },
                        ],
                    });
                }
            }
            break;
        }

        case actions.moveInspectionOrderSchema: {
            const { assemblyGroupIndex, manufacturingStepIndex, dragIndex, hoverIndex, operation } = action.payload;

            const dragElement = get(
                newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders,
                dragIndex
            );

            newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders = update(
                state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders,
                {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragElement],
                    ],
                }
            );

            if (operation && operation === inspectionPlanOperations.edit) {
                let atomicValue = cloneDeep(
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders
                );

                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;

                const manufacturingStepId =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex]._id;
                const manufacturingStepKey =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].key;

                if (assemblyGroupId && manufacturingStepId) {
                    //atomicValue = filterObject(atomicValue, '_id');
                    atomicValue = filterObject(atomicValue, 'key');

                    const newHandler = {
                        operation: inspectionPlanHandlerOperations.update,
                        path: inspectionPlanHandlerPaths.manufacturingStep,
                        values: {
                            inspectionOrders: atomicValue,
                        },
                        childrenIds: {
                            assemblyGroupId: assemblyGroupId,
                            manufacturingStepId: manufacturingStepId,
                        },
                    };

                    const atomicIndex = newState.updateHandler.findIndex(
                        x =>
                            isValueExists(x.values?.inspectionOrders) &&
                            x.path === inspectionPlanHandlerPaths.manufacturingStep &&
                            x.operation === inspectionPlanHandlerOperations.update &&
                            x.childrenIds?.assemblyGroupId === assemblyGroupId &&
                            x.childrenIds?.manufacturingStepId === manufacturingStepId
                    );

                    if (atomicIndex !== -1) {
                        newState.updateHandler = update(newState.updateHandler, {
                            [atomicIndex]: { $set: newHandler },
                        });
                    } else {
                        newState.updateHandler = update(newState.updateHandler, {
                            $push: [newHandler],
                        });
                    }
                } else if (assemblyGroupId) {
                    const atomicIndex = newState.updateHandler.findIndex(
                        x =>
                            x.path === inspectionPlanHandlerPaths.manufacturingStepArray &&
                            x.operation === inspectionPlanHandlerOperations.add &&
                            x.values?.key === manufacturingStepKey
                    );

                    newState.updateHandler = update(newState.updateHandler, {
                        [atomicIndex]: {
                            values: {
                                inspectionOrders: { $set: atomicValue },
                            },
                        },
                    });
                } else {
                    const atomicIndex = newState.updateHandler.findIndex(
                        x =>
                            x.path === inspectionPlanHandlerPaths.assemblyGroupArray &&
                            x.operation === inspectionPlanHandlerOperations.add &&
                            x.values?.key === assemblyGroupKey
                    );

                    const manufacturingStepIndex = newState.updateHandler[atomicIndex].values.manufacturingStep.findIndex(
                        x => x.key === manufacturingStepKey
                    );

                    newState.updateHandler = update(newState.updateHandler, {
                        [atomicIndex]: {
                            values: {
                                manufacturingStep: {
                                    [manufacturingStepIndex]: {
                                        inspectionOrders: { $set: atomicValue },
                                    },
                                },
                            },
                        },
                    });
                }
            }

            newState = update.set(newState, 'changesSaved', false);
            break;
        }
        case actions.addInspectionOrderSchema: {
            const { assemblyGroupIndex, manufacturingStepIndex, index, operation } = action.payload;

            const newInspectionOrder = {
                ...cloneDeep(emptyInspectionOrderState()),
                key: shortid.generate(),
            };

            if (operation && operation === inspectionPlanOperations.edit) {
                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;
                const manufacturingStepId =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex]._id;
                const manufacturingStepKey =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].key;

                const handlerIndex = newState.updateHandler.findIndex(
                    x =>
                        (x.path === inspectionPlanHandlerPaths.assemblyGroupArray && x.values?.key === assemblyGroupKey) ||
                        (x.path === inspectionPlanHandlerPaths.manufacturingStepArray && x.values?.key === manufacturingStepKey)
                );

                if (handlerIndex !== -1) {
                    const handler = newState.updateHandler[handlerIndex];

                    if (handler.path === inspectionPlanHandlerPaths.assemblyGroupArray) {
                        if (index !== undefined && index !== null) {
                            newState.updateHandler = update(newState.updateHandler, {
                                [handlerIndex]: {
                                    values: {
                                        manufacturingStep: {
                                            [manufacturingStepIndex]: {
                                                inspectionOrders: {
                                                    $splice: [[index, 0, newInspectionOrder]],
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        } else {
                            newState.updateHandler = update(newState.updateHandler, {
                                [handlerIndex]: {
                                    values: {
                                        manufacturingStep: {
                                            [manufacturingStepIndex]: {
                                                inspectionOrders: {
                                                    $push: [newInspectionOrder],
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    } else {
                        if (index !== undefined && index !== null) {
                            newState.updateHandler = update(newState.updateHandler, {
                                [handlerIndex]: {
                                    values: {
                                        inspectionOrders: {
                                            $splice: [[index, 0, newInspectionOrder]],
                                        },
                                    },
                                },
                            });
                        } else {
                            newState.updateHandler = update(newState.updateHandler, {
                                [handlerIndex]: {
                                    values: {
                                        inspectionOrders: {
                                            $push: [newInspectionOrder],
                                        },
                                    },
                                },
                            });
                        }
                    }
                } else {
                    const newHandler = {
                        operation: inspectionPlanHandlerOperations.add,
                        path: inspectionPlanHandlerPaths.inspectionOrderArray,
                        values: newInspectionOrder,
                        childrenIds: {
                            assemblyGroupId: assemblyGroupId,
                            manufacturingStepId: manufacturingStepId,
                        },
                        position: isValueExists(index) ? index : undefined,
                    };

                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [newHandler],
                    });
                }
            }

            if (index !== undefined && index !== null) {
                newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders = update(
                    state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders,
                    {
                        $splice: [[index, 0, newInspectionOrder]],
                    }
                );
            } else {
                newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders = update(
                    state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders,
                    {
                        $push: [newInspectionOrder],
                    }
                );
            }

            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));

            break;
        }
        case actions.cloneInspectionOrderSchema: {
            const { assemblyGroupIndex, manufacturingStepIndex, inspectionOrderIndex, operation } = action.payload;
            const newInspectionOrder = {
                ...cloneDeep(
                    state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders[
                        inspectionOrderIndex
                    ]
                ),
                _id: createObjectID().toHexString(),
                key: shortid.generate(),
                inspectionReportID: undefined,
            };

            if (newInspectionOrder.protocolTemplateId) {
                newInspectionOrder.inspectionReportID = createObjectID().toHexString();
            }

            if (operation && operation === inspectionPlanOperations.edit) {
                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;
                const manufacturingStepId =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex]._id;
                const manufacturingStepKey =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].key;

                const handlerIndex = newState.updateHandler.findIndex(
                    x =>
                        (x.path === inspectionPlanHandlerPaths.assemblyGroupArray && x.values?.key === assemblyGroupKey) ||
                        (x.path === inspectionPlanHandlerPaths.manufacturingStepArray && x.values?.key === manufacturingStepKey)
                );

                if (handlerIndex !== -1) {
                    const handler = newState.updateHandler[handlerIndex];

                    if (handler.path === inspectionPlanHandlerPaths.assemblyGroupArray) {
                        newState.updateHandler = update(newState.updateHandler, {
                            [handlerIndex]: {
                                values: {
                                    manufacturingStep: {
                                        [manufacturingStepIndex]: {
                                            inspectionOrders: {
                                                $push: [newInspectionOrder],
                                            },
                                        },
                                    },
                                },
                            },
                        });
                    } else {
                        newState.updateHandler = update(newState.updateHandler, {
                            [handlerIndex]: {
                                values: {
                                    inspectionOrders: {
                                        $push: [newInspectionOrder],
                                    },
                                },
                            },
                        });
                    }
                } else {
                    const newHandler = {
                        operation: inspectionPlanHandlerOperations.add,
                        path: inspectionPlanHandlerPaths.inspectionOrderArray,
                        values: newInspectionOrder,
                        childrenIds: {
                            assemblyGroupId: assemblyGroupId,
                            manufacturingStepId: manufacturingStepId,
                        },
                    };

                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [newHandler],
                    });
                }
            }

            newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders = update(
                state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders,
                {
                    $push: [newInspectionOrder],
                }
            );

            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));
            break;
        }
        case actions.deleteInspectionOrderSchema: {
            const { assemblyGroupIndex, manufacturingStepIndex, inspectionOrderIndex, operation } = action.payload;

            if (operation && operation === inspectionPlanOperations.edit) {
                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;
                const manufacturingStepId =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex]._id;
                const manufacturingStepKey =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].key;
                const inspectionOrderId =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders[
                        inspectionOrderIndex
                    ]._id;
                const inspectionOrderKey =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders[
                        inspectionOrderIndex
                    ].key;

                const handlerIndex = newState.updateHandler.findIndex(
                    x =>
                        (x.path === inspectionPlanHandlerPaths.inspectionOrder &&
                            x.childrenIds?.assemblyGroupId === assemblyGroupId &&
                            x.childrenIds?.manufacturingStepId === manufacturingStepId &&
                            x.childrenIds?.inspectionOrderId === inspectionOrderId) ||
                        (x.path === inspectionPlanHandlerPaths.assemblyGroupArray && x.values?.key === assemblyGroupKey) ||
                        (x.path === inspectionPlanHandlerPaths.manufacturingStepArray && x.values?.key === manufacturingStepKey) ||
                        (x.path === inspectionPlanHandlerPaths.inspectionOrderArray && x.values?.key === inspectionOrderKey)
                );

                const newHandler = {
                    operation: inspectionPlanHandlerOperations.remove,
                    path: inspectionPlanHandlerPaths.inspectionOrderArray,
                    childrenIds: {
                        assemblyGroupId: assemblyGroupId,
                        manufacturingStepId: manufacturingStepId,
                    },
                    values: {
                        _id: inspectionOrderId,
                    },
                };

                if (handlerIndex !== -1) {
                    const currentHandler = newState.updateHandler[handlerIndex];
                    if (currentHandler.operation === inspectionPlanHandlerOperations.update) {
                        newState.updateHandler = update.set(newState.updateHandler, `${handlerIndex}`, newHandler);
                    } else {
                        if (currentHandler.path === inspectionPlanHandlerPaths.assemblyGroupArray) {
                            newState.updateHandler = update(newState.updateHandler, {
                                [handlerIndex]: {
                                    values: {
                                        manufacturingStep: {
                                            [manufacturingStepIndex]: {
                                                inspectionOrders: {
                                                    $splice: [[handlerIndex, 1]],
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        } else if (currentHandler.path === inspectionPlanHandlerPaths.manufacturingStepArray) {
                            newState.updateHandler = update(newState.updateHandler, {
                                [handlerIndex]: {
                                    values: {
                                        inspectionOrders: {
                                            $splice: [[handlerIndex, 1]],
                                        },
                                    },
                                },
                            });
                        } else {
                            newState.updateHandler = update(newState.updateHandler, {
                                $splice: [[handlerIndex, 1]],
                            });
                        }
                    }
                } else {
                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [newHandler],
                    });
                }
            }

            newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders = update(
                newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders,
                {
                    $splice: [[inspectionOrderIndex, 1]],
                }
            );

            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));
            break;
        }
        case actions.handleInspectionOrderSchemaValueChange: {
            const {
                assemblyGroupIndex,
                manufacturingStepIndex,
                inspectionOrderIndex,
                path,
                value,
                allowLanguages,
                language,
                operation,
                skipConflicts,
            } = action.payload;

            let newValue = value;

            if (allowLanguages) {
                const oldValue =
                    get(
                        state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders[
                            inspectionOrderIndex
                        ],
                        path
                    ) || [];
                newValue = setMultilanguageValue(value, oldValue, language || state.language);
            }

            newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders[
                inspectionOrderIndex
            ] = update.set(
                state.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders[
                    inspectionOrderIndex
                ],
                path,
                newValue
            );

            newState = update.set(newState, 'changesSaved', false);
            newState = update.set(newState, 'isValid', isIuTPlanValid(newState.template, newState.language));

            if (!skipConflicts) {
                const templates = newState.templates.loadingStatus === loadingStatus.success ? newState.templates.data : null;

                const conflictsInfo = getConflicts(newState.template, templates);

                if (newState.conflictsInfo?.count !== conflictsInfo.count) {
                    const handlerIndex = newState.updateHandler.findIndex(x => x.path === undefined);

                    if (handlerIndex !== -1) {
                        newState.updateHandler = update.set(
                            newState.updateHandler,
                            `${handlerIndex}.values.numberOfConflicts`,
                            conflictsInfo.count
                        );
                    } else {
                        newState.updateHandler = update(newState.updateHandler, {
                            $push: [
                                {
                                    operation: inspectionPlanHandlerOperations.update,
                                    path: undefined,
                                    values: {
                                        numberOfConflicts: conflictsInfo.count,
                                    },
                                },
                            ],
                        });
                    }
                }

                newState = update.set(newState, 'conflictsInfo', {
                    count: conflictsInfo.count,
                    conflicts: conflictsInfo.conflicts,
                });
            }

            if (operation && operation === inspectionPlanOperations.edit) {
                const assemblyGroupId = newState.template.assemblyGroup[assemblyGroupIndex]._id;
                const assemblyGroupKey = newState.template.assemblyGroup[assemblyGroupIndex].key;
                const manufacturingStepId =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex]._id;
                const manufacturingStepKey =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].key;
                const inspectionOrderId =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders[
                        inspectionOrderIndex
                    ]._id;
                const inspectionOrderKey =
                    newState.template.assemblyGroup[assemblyGroupIndex].manufacturingStep[manufacturingStepIndex].inspectionOrders[
                        inspectionOrderIndex
                    ].key;

                const handlerIndex = newState.updateHandler.findIndex(
                    x =>
                        (x.path === inspectionPlanHandlerPaths.inspectionOrder &&
                            x.childrenIds?.assemblyGroupId === assemblyGroupId &&
                            x.childrenIds?.manufacturingStepId === manufacturingStepId &&
                            x.childrenIds?.inspectionOrderId === inspectionOrderId &&
                            get(x.values, path) !== undefined) ||
                        (x.path === inspectionPlanHandlerPaths.assemblyGroupArray && x.values?.key === assemblyGroupKey) ||
                        (x.path === inspectionPlanHandlerPaths.manufacturingStepArray && x.values?.key === manufacturingStepKey) ||
                        (x.path === inspectionPlanHandlerPaths.inspectionOrderArray && x.values?.key === inspectionOrderKey)
                );

                if (handlerIndex !== -1) {
                    const handler = newState.updateHandler[handlerIndex];

                    if (
                        handler.operation === inspectionPlanHandlerOperations.add &&
                        handler.path === inspectionPlanHandlerPaths.assemblyGroupArray
                    ) {
                        newState.updateHandler = update.set(
                            newState.updateHandler,
                            `${handlerIndex}.values.manufacturingStep.${manufacturingStepIndex}.inspectionOrders.${inspectionOrderIndex}.${path}`,
                            newValue
                        );
                    } else if (
                        handler.operation === inspectionPlanHandlerOperations.add &&
                        handler.path === inspectionPlanHandlerPaths.manufacturingStepArray
                    ) {
                        newState.updateHandler = update.set(
                            newState.updateHandler,
                            `${handlerIndex}.values.inspectionOrders.${inspectionOrderIndex}.${path}`,
                            newValue
                        );
                    } else {
                        newState.updateHandler = update.set(newState.updateHandler, `${handlerIndex}.values.${path}`, newValue);
                    }
                } else {
                    newState.updateHandler = update(newState.updateHandler, {
                        $push: [
                            {
                                operation: inspectionPlanHandlerOperations.update,
                                path: inspectionPlanHandlerPaths.inspectionOrder,
                                childrenIds: {
                                    assemblyGroupId: assemblyGroupId,
                                    manufacturingStepId: manufacturingStepId,
                                    inspectionOrderId: inspectionOrderId,
                                },
                                values: {
                                    [path]: newValue,
                                },
                            },
                        ],
                    });
                }
            }
            break;
        }
        default:
            break;
    }

    return newState;
};

export const builderInit = () => {
    return {
        type: actions.builderInit,
    };
};
export const setInspectionPlanModel = (model, isNew = false, isTemplate = false) => {
    return {
        type: actions.setInspectionPlanModel,
        payload: {
            model,
            isNew,
            isTemplate,
        },
    };
};

export const handleValueChange = (path, value, handler = null) => {
    return {
        type: actions.handleValueChange,
        payload: {
            path,
            value,
            handler,
        },
    };
};

export const trackConflicts = () => {
    return {
        type: actions.trackConflicts,
        payload: {},
    };
};

export const trackResize = () => {
    return {
        type: actions.trackResize,
        payload: {},
    };
};

export const moveAssemblyGroupSchema = (dragIndex, hoverIndex, operation) => {
    return {
        type: actions.moveAssemblyGroupSchema,
        payload: {
            dragIndex,
            hoverIndex,
            operation,
        },
    };
};
export const addAssemblyGroupSchema = (index = null, operation = null) => {
    return {
        type: actions.addAssemblyGroupSchema,
        payload: {
            index,
            operation,
        },
    };
};
export const cloneAssemblyGroupSchema = (assemblyGroupIndex, operation = null) => {
    return {
        type: actions.cloneAssemblyGroupSchema,
        payload: {
            assemblyGroupIndex,
            operation,
        },
    };
};
export const deleteAssemblyGroupSchema = (assemblyGroupIndex, operation = null) => {
    return {
        type: actions.deleteAssemblyGroupSchema,
        payload: {
            assemblyGroupIndex,
            operation,
        },
    };
};
export const handleAssemblyGroupSchemaValueChange = (
    assemblyGroupIndex,
    path,
    value,
    allowLanguages = false,
    language = null,
    operation = null
) => {
    return {
        type: actions.handleAssemblyGroupSchemaValueChange,
        payload: {
            assemblyGroupIndex,
            path,
            value,
            operation,
            language,
            allowLanguages,
        },
    };
};

export const moveManufacturingStepSchema = (assemblyGroupIndex, dragIndex, hoverIndex, operation) => {
    return {
        type: actions.moveManufacturingStepSchema,
        payload: {
            assemblyGroupIndex,
            dragIndex,
            hoverIndex,
            operation,
        },
    };
};
export const addManufacturingStepSchema = (assemblyGroupIndex, index = null, operation = null) => {
    return {
        type: actions.addManufacturingStepSchema,
        payload: {
            assemblyGroupIndex,
            index,
            operation,
        },
    };
};
export const cloneManufacturingStepSchema = (assemblyGroupIndex, manufacturingStepIndex, operation = null) => {
    return {
        type: actions.cloneManufacturingStepSchema,
        payload: {
            assemblyGroupIndex,
            manufacturingStepIndex,
            operation,
        },
    };
};
export const deleteManufacturingStepSchema = (assemblyGroupIndex, manufacturingStepIndex, operation = null) => {
    return {
        type: actions.deleteManufacturingStepSchema,
        payload: {
            assemblyGroupIndex,
            manufacturingStepIndex,
            operation,
        },
    };
};
export const handleManufacturingStepSchemaValueChange = (
    assemblyGroupIndex,
    manufacturingStepIndex,
    path,
    value,
    allowLanguages = false,
    language = null,
    operation = null
) => {
    return {
        type: actions.handleManufacturingStepSchemaValueChange,
        payload: {
            assemblyGroupIndex,
            manufacturingStepIndex,
            path,
            value,
            allowLanguages,
            language,
            operation,
        },
    };
};

export const moveInspectionOrderSchema = (assemblyGroupIndex, manufacturingStepIndex, dragIndex, hoverIndex, operation) => {
    return {
        type: actions.moveInspectionOrderSchema,
        payload: {
            assemblyGroupIndex,
            manufacturingStepIndex,
            dragIndex,
            hoverIndex,
            operation,
        },
    };
};
export const addInspectionOrderSchema = (assemblyGroupIndex, manufacturingStepIndex, index = null, operation = null) => {
    return {
        type: actions.addInspectionOrderSchema,
        payload: {
            assemblyGroupIndex,
            manufacturingStepIndex,
            index,
            operation,
        },
    };
};
export const cloneInspectionOrderSchema = (assemblyGroupIndex, manufacturingStepIndex, inspectionOrderIndex, operation = null) => {
    return {
        type: actions.cloneInspectionOrderSchema,
        payload: {
            assemblyGroupIndex,
            manufacturingStepIndex,
            inspectionOrderIndex,
            operation,
        },
    };
};
export const deleteInspectionOrderSchema = (assemblyGroupIndex, manufacturingStepIndex, inspectionOrderIndex, operation = null) => {
    return {
        type: actions.deleteInspectionOrderSchema,
        payload: {
            assemblyGroupIndex,
            manufacturingStepIndex,
            inspectionOrderIndex,
            operation,
        },
    };
};
export const handleInspectionOrderSchemaValueChange = (
    assemblyGroupIndex,
    manufacturingStepIndex,
    inspectionOrderIndex,
    path,
    value,
    allowLanguages = false,
    language = null,
    operation = null,
    skipConflicts = false
) => {
    return {
        type: actions.handleInspectionOrderSchemaValueChange,
        payload: {
            assemblyGroupIndex,
            manufacturingStepIndex,
            inspectionOrderIndex,
            path,
            value,
            allowLanguages,
            language,
            operation,
            skipConflicts,
        },
    };
};
