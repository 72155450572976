import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { setValueAction } from '../reducers/ImageUploadReducer';
import { getFileExt, getImagePreview } from '../../../utils/fileManagement';
import createMongoObjectId from '../../../utils/createMongoObjID';

import Cropper from 'react-cropper';
import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import 'cropperjs/dist/cropper.css';

const useStyles = makeStyles(theme => ({
    imageCont: {
        padding: 20,
        '& * img': {
            objectFit: 'cover',
            height: '100%',
            width: '100%',
        },
    },
    typography: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const ImageCropperContainer = props => {
    const classes = useStyles();

    const cropper = useRef(null);

    const handleClose = () => {
        props.handleCropperClose();
    };

    const handleConfirm = () => {
        cropper.current.getCroppedCanvas().toBlob(blob => {
            props.uppy.addFile({
                ...props.file,
                data: blob,
                handledByCropper: true,
                name: `${createMongoObjectId()}.${getFileExt(props.file.name)}`,
                size: blob.size,
                type: blob.type,
                preview: getImagePreview({ data: blob }),
            });
            props.handleCropperClose();
        });
    };

    useEffect(() => {
        const $imageUpload = document.getElementById('file-image-upload');

        if ($imageUpload) {
            $imageUpload.style.display = !!props.imagePreview ? 'none' : 'block';
        }
    }, [props.imagePreview]);

    const aspectRation = !props.freeCropping ? props.aspectRation || 1 : null;

    return (
        <Dialog open={!!props.imagePreview} style={{ zIndex: 100000 }}>
            <MuiDialogTitle disableTypography className={classes.typography}>
                <Typography variant="h6">{i18next.t('common.file-upload.cropper.title')}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                <div className={classes.imageCont}>
                    <Cropper
                        checkCrossOrigin={false}
                        ref={cropper}
                        src={props.imagePreview}
                        aspectRatio={aspectRation}
                        guides={false}
                        zoomable={false}
                    />
                </div>
            </MuiDialogContent>
            <MuiDialogActions className={classes.actionButtons}>
                <Button onClick={handleClose}>{i18next.t('common.file-upload.cropper.cancel-btn')}</Button>
                <Button onClick={handleConfirm}>{i18next.t('common.file-upload.cropper.confirm-btn')}</Button>
            </MuiDialogActions>
        </Dialog>
    );
};

const mapState = state => {
    return {
        file: state.imageUpload.file,
        imagePreview: state.imageUpload.imagePreview,
    };
};

const mapDispatch = dispatch => {
    return {
        handleCropperClose() {
            dispatch(setValueAction());
        },
    };
};

const ImageCropper = connect(mapState, mapDispatch)(ImageCropperContainer);
export default ImageCropper;
