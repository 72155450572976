import { get } from 'lodash';
import moment from 'moment';
import i18next from 'i18next';

import { isArrayWithItems } from '../../../../utils/arrayHelper';

import { config } from '../../../../constants/Config';

export const tableColumnTypes = {
    chevron: 'chevron',
    selectCheckbox: 'selectCheckbox',
    action: 'action',
    number: 'number',
    string: 'string',
    date: 'date',
    array: 'array',
    image: 'image',
};

export const tableColumnComparers = {
    [tableColumnTypes.number]: {
        cmp: (obj1, obj2, key) => {
            const val1 = get(obj1, key);
            const val2 = get(obj2, key);
            return val2 - val1;
        },
    },
    [tableColumnTypes.string]: {
        cmp: (obj1, obj2, key) => {
            const val1 = get(obj1, key);
            const val2 = get(obj2, key);

            if (!val1) {
                return 1;
            }

            if (!val2) {
                return -1;
            }

            return val1.localeCompare(val2);
        },
    },
    [tableColumnTypes.date]: {
        cmp: (obj1, obj2, key) => {
            const val1 = get(obj1, key);
            const val2 = get(obj2, key);

            if (!val1) {
                return 1;
            }

            if (!val2) {
                return -1;
            }

            return moment(val2).unix() - moment(val1).unix();
        },
    },
    [tableColumnTypes.array]: {
        cmp: (obj1, obj2, key) => {
            const val1 = get(obj1, key);
            const val2 = get(obj2, key);
            return val2.length - val1.length;
        },
    },
};

export const defaultGroups = [
    {
        key: 'default',
        label: '',
        operator: () => true,
    },
];

export const filterTypes = {
    boolean: 'boolean',
    string: 'string',
    array: 'array',
    objectArray: 'objectArray',
    dateArray: 'dateArray',
    nestedObjectArray: 'nestedObjectArray',
};

export const filterComparerType = {
    single: 'single',
    between: 'between',
    some: 'some',
    nested: 'nested',
};

export const filterTypeSettings = {
    [filterTypes.boolean]: {
        isVisible: value => {
            return value !== null && value !== undefined;
        },
        getValue: value => {
            return `${value}`;
        },
    },
    [filterTypes.string]: {
        isVisible: value => {
            return Boolean(value);
        },
        getValue: value => {
            return value;
        },
    },
    [filterTypes.array]: {
        isVisible: value => {
            return isArrayWithItems(value);
        },
        getValue: value => {
            return value.filter(x => x).join('; ');
        },
    },
    [filterTypes.objectArray]: {
        isVisible: value => {
            return value && value.some(x => x && Object.keys(x).length !== 0);
        },
        getValue: value => {
            return value
                .filter(x => x)
                .map(x => x.title)
                .join('; ');
        },
    },
    [filterTypes.dateArray]: {
        isVisible: value => {
            const date1 = get(value, '0');
            const date2 = get(value, '1');

            const isDate1Valid = date1 && moment(date1).isValid();
            const isDate2Valid = date2 && moment(date2).isValid();

            return isDate1Valid || isDate2Valid;
        },
        getValue: value => {
            const date1 = get(value, '0');
            const date2 = get(value, '1');

            const isDate1Valid = date1 && moment(date1).isValid();
            const isDate2Valid = date2 && moment(date2).isValid();

            const date1Formatted = isDate1Valid ? moment(date1).format(config.DEFAULT_DATE_FORMAT) : '';
            const date2Formatted = isDate2Valid ? moment(date2).format(config.DEFAULT_DATE_FORMAT) : '';

            if (isDate1Valid && isDate2Valid) {
                return `${date1Formatted} - ${date2Formatted}`;
            }

            if (isDate1Valid) {
                return `${i18next.t('common.templates.dataView.filter.afterDate')} ${date1Formatted}`;
            }

            if (isDate2Valid) {
                return `${i18next.t('common.templates.dataView.filter.beforeDate')} ${date2Formatted}`;
            }

            return '';
        },
    },
};

export const loadingTilesNumber = 3;
export const loadingTilesNumberForServerPagination = 10;
