import { createProductionOrderActions } from './createProductionOrder.actions';

const createProductionOrderInitialState = {
    showOverviewTab: true,
    showAttachmentsTab: false,
    productionOrderTemplate: null,
    isProductionOrderCreated: false,
    disableInputs: false,
};

export const createProductionOrderReducer = (state = createProductionOrderInitialState, { type, payload }) => {
    switch (type) {
        case createProductionOrderActions.SET_IS_OVERVIEW_CONTENT: {
            return {
                ...state,
                showOverviewTab: payload,
                showAttachmentsTab: !payload,
            };
        }
        case createProductionOrderActions.SET_IS_ATTACHMENTS_CONTENT: {
            return {
                ...state,
                showAttachmentsTab: payload,
                showOverviewTab: !payload,
            };
        }
        case createProductionOrderActions.SET_TEMPLATE_PRODUCTION_ORDER: {
            return {
                ...state,
                productionOrderTemplate: {
                    ...payload,
                    schedulingData: {
                        ...payload.schedulingData,
                        scheduledReleaseDate: new Date(),
                    },
                },
            };
        }

        case createProductionOrderActions.SET_IS_PRODUCTION_ORDER_CREATED: {
            return {
                ...state,
                isProductionOrderCreated: payload,
            };
        }

        case createProductionOrderActions.UPDATE_TEMPLATE_PRODUCTION_ORDER_PROPERTY: {
            return {
                ...state,
                productionOrderTemplate: {
                    ...state.productionOrderTemplate,
                    [payload.path]: payload.value,
                },
            };
        }
        case createProductionOrderActions.SET_DISABLE_INPUTS: {
            return {
                ...state,
                disableInputs: payload,
            };
        }
        case createProductionOrderActions.RESET_CREATE_PRODUCTION_ORDER: {
            return {
                ...createProductionOrderInitialState,
            };
        }

        default:
            return state;
    }
};
