import shortid from 'shortid';
import { revisionNumber } from '../../../../../constants/Revision';
import update from '../../../../../redux/update';
import { isArrayWithItems } from '../../../../../utils/arrayHelper';
import createMongoObjectId from '../../../../../utils/createMongoObjID';
import {
    getColumnsNumber,
    getRowsNumber,
    mapBooleanToString,
    mapFormattedValue,
    mapProtocolSectionToSaveModel,
    mapValueModel,
} from '../../../../../utils/protocolHelper';
import { isValueExists } from '../../../../../utils/valueHelper';
import { groups } from '../../../template-builder/constants/groups';
import { inputElements } from '../../../template-builder/constants/inputElements';
import { sectionTypes } from '../../constants';

export const mapModelToTemplate = (model, operation) => {
    const title = {
        _id: createMongoObjectId(),
        key: shortid.generate(),
        sectionLayoutType: groups.title,
        value: mapValueModel(model.templateTitle),
    };
    return {
        _id: model._id,
        protocolSection: [title, ...model.protocolSections],
        metadata: {
            templateName: model.templateName,
            documentStatus: model.documentStatus,
            technicalReference: model.technicalReference,
            responsibleDepartment: model.responsibleDepartment?.name,
            templateApprover: model?.approvalPersons?.map(x => x.approverID),
            tags: model?.tags?.map(x => x.name),
            materialType: model.materialType?.map(x => x.name),
            legalOwner: model.legalOwner,
            documentType: model.documentType,
            documentNumber: model.documentNumber,
            legalOwnerLogo: model.legalOwnerLogo?.mediaName,
            dateOfIssue: model.dateOfIssue,
            revisionHistory: model.revisionHistory.map(item => {
                return {
                    editor: item.editor?.accountID,
                    revisionNumber: item.revisionNumber || undefined,
                    revisionDate: item.revisionDate || undefined,
                    revisionComment: item.revisionComment || undefined,
                };
            }),
            revisionEditor: isArrayWithItems(model.revisionHistory)
                ? model.revisionHistory[model.revisionHistory.length - 1].editor?.accountID
                : null,
            revisionNumber: isArrayWithItems(model.revisionHistory)
                ? model.revisionHistory[model.revisionHistory.length - 1].revisionNumber
                : revisionNumber[0],
            revisionComment: isArrayWithItems(model.revisionHistory)
                ? model.revisionHistory[model.revisionHistory.length - 1].revisionComment
                : null,
            signedBy:
                model?.signedBy?.map(signedBy => {
                    return {
                        signedByID: signedBy.signedByID,
                        signedAt: signedBy.signedAt,
                        requestedSignatureRole: signedBy.requestedSignatureRole,
                        signatureImage: {
                            mediaName: signedBy.signatureImage?.mediaName,
                            username: signedBy.signatureImage?.username,
                        },
                    };
                }) || null,
            modifiedBy: model.protocolTemplateModifiedBy,
        },
    };
};

export const mapSectionResponseToViewModel = section => {
    return {
        _id: section._id,
        key: shortid.generate(),
        sectionLayoutType: section.sectionLayoutType,
        sectionHeading: section.sectionHeading,
        value:
            (section.sectionLayoutType === sectionTypes.sectionParagraph &&
                isArrayWithItems(section.sectionParagraph) &&
                mapValueModel(section.sectionParagraph)) ||
            (isArrayWithItems(section.sectionHeading) && mapValueModel(section.sectionHeading)) ||
            undefined,
        columns: getColumnsNumber(section?.documentationCharacteristic),
        rows: getRowsNumber(section?.documentationCharacteristic),
        documentationCharacteristic: section?.documentationCharacteristic?.map(documentationCharacteristic => {
            const { _id, column, row, header, columnWidth, color, documentationCharacteristicName, inputElement } =
                documentationCharacteristic;

            return {
                _id: _id,
                key: shortid.generate(),
                column: column !== undefined && column !== null ? parseInt(column, 10) : null,
                row: row !== undefined && row !== null ? parseInt(row, 10) : null,
                value: mapValueModel(header),
                width: columnWidth,
                highlighted: color,
                subtitle:
                    section.sectionLayoutType === groups.list
                        ? { key: shortid.generate, _id: createMongoObjectId(), value: mapValueModel(documentationCharacteristicName) }
                        : null,
                alignment: documentationCharacteristic.imageAlignment || undefined,
                autoFillValue: mapBooleanToString(documentationCharacteristic.autoFillValue),
                gtXlsExport: documentationCharacteristic.gtXlsExport
                    ? {
                          templateFile: documentationCharacteristic.gtXlsExport.templateFile || undefined,
                          worksheet: documentationCharacteristic.gtXlsExport.worksheet || undefined,
                          columnName: documentationCharacteristic.gtXlsExport.columnName || undefined,
                      }
                    : undefined,
                inputElement: inputElement?.map(input => {
                    const {
                        _id,
                        label,
                        inputElementType,
                        checked,
                        defaultValue,
                        formattedValue,
                        helperText,
                        relevantForValidation,
                        endAdornment,
                        startAdornment,
                        isTargetElement,
                        value,
                        measurand,
                        unit,
                        targetValue,
                        lowerToleranceAbsolut,
                        upperToleranceAbsolut,
                        placeholder,
                        dropDown,
                        signedBy,
                        autofillPerson,
                        mediaInput,
                    } = input;

                    return {
                        _id: _id || createMongoObjectId(),
                        key: shortid.generate(),
                        type: inputElementType,
                        label: mapValueModel(label),
                        checked: mapBooleanToString(checked),
                        initialValidation: false,
                        defaultValue: defaultValue,
                        formattedValue: formattedValue,
                        helperText: mapValueModel(helperText),
                        relevantForValidation: mapBooleanToString(relevantForValidation),
                        endAdornment: endAdornment,
                        startAdornment: startAdornment,
                        isTargetElement: isTargetElement,
                        value: value,
                        measurand: measurand,
                        unit: unit,
                        targetValue: targetValue,
                        lowerToleranceAbsolut: lowerToleranceAbsolut,
                        upperToleranceAbsolut: upperToleranceAbsolut,
                        placeholder: mapValueModel(placeholder),
                        dropdown: {
                            defaultValue: mapValueModel(dropDown?.defaultValue),
                            options: dropDown?.options && dropDown.options.map(opt => mapValueModel(opt)),
                        },
                        signedBy: {
                            requestedSignatureRole: isArrayWithItems(signedBy?.requestedSignatureRole)
                                ? signedBy.requestedSignatureRole[0]
                                : '',
                            signatureRequired: mapBooleanToString(signedBy?.signatureRequired),
                            signedByID: signedBy?.signedByID,
                            signedAt: signedBy?.signedAt,
                            signatureImage: {
                                mediaName: signedBy?.signatureImage?.mediaName,
                                username: signedBy?.signatureImage?.username,
                            },
                        },
                        autofillPerson: mapBooleanToString(autofillPerson),
                        mediaInput:
                            mediaInput?.map(media => {
                                return {
                                    _id: media._id,
                                    mediaName: media.mediaName,
                                    mediaLabel: media.mediaLabel,
                                    mediaType: media.mediaType,
                                    mediaDescription: media.mediaDescription,
                                    pinned: media.pinned,
                                    printInExport: media.printInExport,
                                };
                            }) || [],
                    };
                }),
            };
        }),
        mediaOutputs: section?.mediaOutputs?.map(mediaOutput => {
            return {
                _id: mediaOutput._id,
                key: shortid.generate(),
                defaultValue: mediaOutput.mediaName,
                label: mediaOutput.mediaLabel,
                type: inputElements.mediaOutput,
            };
        }),
        signedBy: section?.signedBy?.map(signature => {
            return {
                key: shortid.generate(),
                requestedSignatureRole: isArrayWithItems(signature?.requestedSignatureRole) ? signature.requestedSignatureRole[0] : '',
                signatureRequired: mapBooleanToString(signature.signatureRequired),
                signedByID: signature?.signedByID,
                signedAt: signature?.signedAt,
                signatureImage: {
                    mediaName: signature?.signatureImage?.mediaName,
                    username: signature?.signatureImage?.username,
                },
            };
        }),
        resolvedTickets: section.resolvedTickets.map(ticket => {
            return {
                _id: ticket._id,
                key: shortid.generate(),
                finding: ticket.finding,
                description: ticket.description,
                action: ticket.action,
                affectedSerialNumbers: ticket.affectedSerialNumbers,
                mediaInput: ticket.mediaInput.map(media => {
                    return {
                        mediaName: media.mediaName,
                    };
                }),
                references: ticket.references,
            };
        }),
    };
};

export const mapSimpleSectionResponseToViewModel = section => {
    return {
        _id: section._id,
        key: shortid.generate(),
        sectionLayoutType: section.sectionLayoutType,
        sectionHeading: section.sectionHeading,
        value:
            (section.sectionLayoutType === sectionTypes.sectionParagraph &&
                isArrayWithItems(section.sectionParagraph) &&
                mapValueModel(section.sectionParagraph)) ||
            (isArrayWithItems(section.sectionHeading) && mapValueModel(section.sectionHeading)) ||
            undefined,
        mediaOutputs: section?.mediaOutputs?.map(mediaOutput => {
            return {
                _id: mediaOutput._id,
                key: shortid.generate(),
                defaultValue: mediaOutput.mediaName,
                label: mediaOutput.mediaLabel,
                type: inputElements.mediaOutput,
            };
        }),
        signedBy: section?.signedBy?.map(signature => {
            return {
                _id: createMongoObjectId(),
                key: shortid.generate(),
                requestedSignatureRole: isArrayWithItems(signature?.requestedSignatureRole) ? signature.requestedSignatureRole[0] : '',
                signatureRequired: mapBooleanToString(signature.signatureRequired),
                signedByID: signature?.signedByID,
                signedAt: signature?.signedAt,
                signatureImage: {
                    mediaName: signature?.signatureImage?.mediaName,
                    username: signature?.signatureImage?.username,
                },
            };
        }),
    };
};

export const getUncompletedInputElementsCount = (protocol, sectionId = null, characteristicId = null) => {
    let count = 0;

    if (isArrayWithItems(protocol?.protocolSection)) {
        protocol.protocolSection.forEach((protocolSection, protocolSectionIndex) => {
            if (
                isValueExists(sectionId)
                    ? protocolSection._id === sectionId
                    : true && isArrayWithItems(protocolSection?.documentationCharacteristic)
            ) {
                protocolSection.documentationCharacteristic.forEach((documentationCharacteristic, documentationCharacteristicIndex) => {
                    if (
                        isValueExists(characteristicId)
                            ? documentationCharacteristic._id === characteristicId
                            : true && isArrayWithItems(documentationCharacteristic?.inputElement)
                    ) {
                        documentationCharacteristic.inputElement.forEach((inputElement, index, self) => {
                            if (
                                inputElement.type === inputElements.textInput ||
                                inputElement.type === inputElements.mediaInput ||
                                inputElement.type === inputElements.document ||
                                inputElement.type === inputElements.person ||
                                inputElement.type === inputElements.date
                            ) {
                                if (!inputElement.defaultValue && !inputElement.value && inputElement.relevantForValidation === 'true') {
                                    count++;
                                }
                            }

                            if (
                                inputElement.type === inputElements.radio &&
                                self.findIndex(item => item.type === inputElement.type) === index
                            ) {
                                const inputs = documentationCharacteristic.inputElement.filter(x => x.type === inputElements.radio);
                                if (
                                    inputs.every(x => !x.checked || x.checked === 'false') &&
                                    inputs.some(x => x.relevantForValidation === 'true')
                                ) {
                                    count++;
                                }
                            }
                        });
                    }
                });
            }
        });
    }

    return count;
};

export const mapHandlerToSaveModal = (handler, tiles, modifierId) => {
    if (!isArrayWithItems(handler)) {
        return [];
    }

    return handler.map(atomic => {
        if (atomic.values.protocolSection) {
            const newAtomic = update(atomic, {
                values: {
                    protocolSection: { $set: mapProtocolSectionToSaveModel(atomic.values.protocolSection, tiles) },
                },
            });

            return newAtomic;
        }

        if (atomic.values.inputElement?.formattedValue) {
            const newAtomic = update(atomic, {
                values: {
                    inputElement: {
                        formattedValue: { $set: JSON.stringify(mapFormattedValue(atomic.values.inputElement.formattedValue, tiles)) },
                    },
                },
            });

            return newAtomic;
        }

        return atomic;
    });
};
