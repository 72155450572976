import { baseDataTabActions } from './baseData.actions';

const baseDataInitialState = {
    attachmentTypes: [],
    productionOrderId: "",
    attachmentTypeFilter: "all",
};

export const baseDataReducer = (state = baseDataInitialState, { type, payload }) => {
    switch (type) {
        case baseDataTabActions.SET_ATTACHMENT_TYPES: {
            return {
                ...state,
                attachmentTypes: [...payload],
            };
        }
        case baseDataTabActions.SET_PRODUCTION_ORDER_ID: {
            return {
                ...state,
                productionOrderId: String(payload),
            };
        }
        case baseDataTabActions.SET_MEDIA_INPUTS_ATTACHMENT_TYPE_FILTER: {
            return {
                ...state,
                attachmentTypeFilter: String(payload),
            };
        }

        default:
            return state;
    }
};
