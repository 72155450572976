// LIBS
import { ApolloClient, InMemoryCache, split } from '@apollo/client';
// import { ApolloClient, HttpLink, ApolloLink } from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';
// import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from 'apollo-link-context';
// import { WebSocketLink } from 'apollo-link-ws';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

//UTILS
import { getAwsToken } from './awsAuth';
import { GATEWAY_URL } from './env';
import { getMainDefinition } from '@apollo/client/utilities';

const cache = new InMemoryCache();
const httpLink = createUploadLink({
    // const link = new HttpLink({
    uri: GATEWAY_URL,
});

const authLink = setContext(async (_, { headers }) => {
    const token = await getAwsToken();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: GATEWAY_URL.replace('http', 'ws'),
        options: {
            reconnect: true,
            lazy: true,
            connectionParams: async () => {
                const token = await getAwsToken();
                // return the headers to the context so websocketLink can read them
                return {
                    Authorization: token ? `Bearer ${token}` : '',
                };
            },
        },
    })
);

const link = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

// const cleanTypeName = new ApolloLink((operation, forward) => {
//   if (operation.variables) {
//     const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
//     operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
//   }
//   return forward(operation).map((data) => {
//     return data;
//   });
// });

const client = new ApolloClient({
    link: authLink.concat(link),
    cache,
    // clientState: { defaults: {}, resolvers: {} }
});

export default client;
