export const projectsOverviewActions = {
    SET_PROJECTS: 'PROJECTS_OVERVIEW_NEW/SET_PROJECTS',
    MERGE_PROJECTS: 'PROJECTS_OVERVIEW_NEW/MERGE_PROJECTS',
    CHANGE_FILTER: 'PROJECTS_OVERVIEW_NEW/CHANGE_FILTER',
    CLEAR_FILTERS: 'PROJECTS_OVERVIEW_NEW/CLEAR_FILTERS',
    EXPAND_PROJECT: 'PROJECTS_OVERVIEW_NEW/EXPAND_PROJECT',
    SET_SELECTED_ROUTING_ID: 'PROJECTS_OVERVIEW_NEW/SET_SELECTED_ROUTING_ID',
    SET_DURATION: 'PROJECTS_OVERVIEW_NEW/SET_DURATION',
    SET_ROUTING_WITH_STEPS: 'PROJECTS_OVERVIEW_NEW/SET_ROUTING_WITH_STEPS',
    SET_ROUTING_RESPONSIBLE: 'PROJECTS_OVERVIEW_NEW/SET_ROUTING_RESPONSIBLE',
    SET_LOADING: 'PROJECTS_OVERVIEW_NEW/TOGGLE_LOADING',
    UPDATE_PROCESS_STEP: 'PROJECTS_OVERVIEW_NEW/UPDATE_PROCESS_STEP',
    RESET_TO_INITIAL_STATE: 'RESET_TO_INITIAL_STATE/PROJECTS_OVERVIEW_NEW',
    TOGGLE_IS_PROCESS_ROUTING_PAPER_EDIT: 'PROJECTS_OVERVIEW_NEW/TOGGLE_IS_PROCESS_ROUTING_PAPER_EDIT',
};

export const projectsOverviewSetProjects = payload => ({
    type: projectsOverviewActions.SET_PROJECTS,
    payload,
});

export const projectsOverviewMergeProjects = payload => ({
    type: projectsOverviewActions.MERGE_PROJECTS,
    payload,
});

export const projectsOverviewChangeFilters = payload => ({
    type: projectsOverviewActions.CHANGE_FILTER,
    payload,
});

export const projectOverviewClearFilters = () => ({
    type: projectsOverviewActions.CLEAR_FILTERS,
});

export const projectOverviewExpandProject = payload => ({
    type: projectsOverviewActions.EXPAND_PROJECT,
    payload,
});

export const projectOverviewSetSelectedRoutingId = payload => ({
    type: projectsOverviewActions.SET_SELECTED_ROUTING_ID,
    payload,
});

export const projectOverviewSetDuration = payload => ({
    type: projectsOverviewActions.SET_DURATION,
    payload,
});

export const projectOverviewSetRoutingWithStep = payload => ({
    type: projectsOverviewActions.SET_ROUTING_WITH_STEPS,
    payload,
});

export const projectOverviewSetRoutingResponsible = payload => ({
    type: projectsOverviewActions.SET_ROUTING_RESPONSIBLE,
    payload,
});

export const projectOverviewsSetLoading = payload => ({
    type: projectsOverviewActions.SET_LOADING,
    payload,
});

export const projectOverviewUpdateProcessStep = payload => ({
    type: projectsOverviewActions.UPDATE_PROCESS_STEP,
    payload,
});

export const projectOverviewResetToInitialState = () => ({
    type: projectsOverviewActions.RESET_TO_INITIAL_STATE,
});

export const projectOverviewToggleIsProcessRoutingPaperEdit = () => ({
    type: projectsOverviewActions.TOGGLE_IS_PROCESS_ROUTING_PAPER_EDIT,
});
