import { get } from 'lodash';
import { isWindowExists } from './windowHelper';
import { DevicePlatform, DeviceType } from '../constants/DeviceEnums';

export class DeviceHelper {
    static getDeviceType = () => {
        if (isWindowExists()) {
            const device = require('current-device').default;
            const type = get(device, 'type');
            const deviceType = localStorage.getItem('userDevice') || DeviceType[type];

            return deviceType ? deviceType : DeviceType.other;
        }
        return DeviceType.other;
    }

    static getDevicePlatform = () => {
        if (isWindowExists()) {
            if (DeviceHelper.isMac()) {
                return DevicePlatform.macos;
            }

            const device = require('current-device').default;
            const platform = get(device, 'os');
            const devicePlatform = DevicePlatform[platform];

            return devicePlatform ? devicePlatform : DevicePlatform.other;
        }
        return DevicePlatform.other;
    }

    static isMac = () => {
        return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    }

    static isRunningStandalone = () => {
        const isInWebAppiOS = window.navigator.standalone === true;
        const isInWebAppChrome = window.matchMedia('(display-mode: standalone)').matches;

        return isInWebAppChrome || isInWebAppiOS;
    }
} 
