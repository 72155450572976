export const tagActions = {
    SET_TAGS: 'TAG/SET_TAGS',
    ADD_TAG: 'TAG/ADD_TAG',
    REMOVE_TAG: 'TAG/REMOVE_TAG',
    UPDATE_TAG: 'TAG/UPDATE_TAG',
    SET_SEARCH_TERM: 'TAG/SET_SEARCH_TERM',
};

export const setTags = payload => ({
    type: tagActions.SET_TAGS,
    payload,
});

export const addTag = payload => ({
    type: tagActions.ADD_TAG,
    payload,
});

export const updateTag = payload => ({
    type: tagActions.UPDATE_TAG,
    payload,
});

export const removeTag = payload => ({
    type: tagActions.REMOVE_TAG,
    payload,
});

export const setSearchTerm = payload => ({
    type: tagActions.SET_SEARCH_TERM,
    payload,
});
