import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import i18next from "i18next";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 22,
        padding: 24,

        "& .MuiSvgIcon-root": {
            fontSize: 52,
            marginRight: 20
        }
    }
}));

const BrowserWarning = ({ ...props }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ErrorOutlineIcon />
            {i18next.t("common.browser-warning")}
        </div>
    );
}

export default BrowserWarning;