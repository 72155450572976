import { assignAsssetModalActions } from './assignAssetModal.actions';
import { isArrayWithItems } from '../../../../../utils/arrayHelper';

const initialState = {
    isParentOpened: false,
    isChildOpened: false,
    assetId: null,
    checkedAssets: [],
    isEditPage: false,
    filters: {
        searchTerm: '',
        selectedCostCenters: []
    },
}

export const assignAssetModalReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case assignAsssetModalActions.HANDLE_FILTERS_CHANGE: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [payload.path]: payload.value
                }
            }
        }
        case assignAsssetModalActions.SET_CHILD_ASSET_MODAL_VISIBILITY: {
            return {
                ...state,
                isChildOpened: !state.isChildOpened,
                assetId: payload
            }
        }
        case assignAsssetModalActions.SET_PARENT_ASSET_MODAL_VISIBILITY: {
            return {
                ...state,
                isParentOpened: !state.isParentOpened,
                assetId: payload
            }
        }
        case assignAsssetModalActions.SET_ASSET_ID: {
            return {
                ...state,
                assetId: payload
            }
        }
        case assignAsssetModalActions.SET_IS_EDIT_PAGE: {
            return {
                ...state,
                isEditPage: payload
            }
        }
        case assignAsssetModalActions.SET_ASSIGN_ASSET_MODAL_CHECKED_ITEM: {
            if (state.isParentOpened) {
                return {
                    ...state,
                    checkedAssets: [payload?.id]
                }
            }

            if (isArrayWithItems(state.checkedAssets)) {
                const isExist = state.checkedAssets.find(a => a.id === payload.id)

                const checkedAssets = isExist ? state.checkedAssets.map(asset => {
                    if (asset.id !== payload.id) {
                        return asset;
                    }

                    return {
                        ...asset,
                        checked: !asset.checked
                    };
                }) : [...state.checkedAssets, payload]

                return {
                    ...state,
                    checkedAssets
                }

            }

            return {
                ...state,
                checkedAssets: [payload]
            }
        }
        case assignAsssetModalActions.RESET_ASSIGN_ASSET_MODAL_TO_INITIAL_STATE: {
            return initialState;
        }

        default:
            return state;
    }
}