import React, { useState } from 'react';

// LIBS
import { Auth } from 'aws-amplify';
import i18next from 'i18next';

// MATERIAL UI
import { InputAdornment, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ErrorIcon from '@material-ui/icons/Error';

// COMPONENTS
import LinkParagraph from '../common/LinkParagraph';
import CompleteRegister from '../common/authentication/CompleteRegister';

// UTILS
import update from '../../redux/update';

const SignIn = props => {
    const [model, setModel] = useState({
        email: "",
        password: "",
        newPassword: "",
        consent: false
    });

    const [user, setUser] = useState(null);
    const [exception, setException] = useState(null);

    const signIn = async (model) => {
        try {
            const email = model.email && model.email.trim();
            const password = model.password && model.password.trim();

            if (email && password && model.consent) {
                const user = await Auth.signIn(email, password);

                if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                    setUser(user);
                    setModel({
                        email: "",
                        password: "",
                        newPassword: ""
                    });
                } else {
                    props.onStateChange("signedIn", {});
                }

                setException(null);
            }
        } catch (error) {
            setException(error);
        }
    };

    const completeNewPassword = async (model, userModel) => {
        try {
            const newPassword = model.newPassword && model.newPassword.trim();

            if (userModel && newPassword) {
                await Auth.completeNewPassword(userModel, newPassword);
                props.onStateChange("signedIn", {});
                setException(null);
            }
        } catch (error) {
            setException(error);
        }
    };

    const handleValueChange = (path, event) => {
        const value = event.target?.value || "";

        setModel(prev => {
            const newModel = update.set(prev, path, value);
            return newModel;
        });
    };

    const handleCheckboxValueChange = (path, event) => {
        const value = event.target?.checked || false;

        setModel(prev => {
            const newModel = update.set(prev, path, value);
            return newModel;
        });
    };

    const getException = exception => {
        if (exception) {
            if (exception.code === "UserNotFoundException") {
                return {
                    emailException: exception.message
                };
            }

            if (exception.code === "InvalidPasswordException") {
                return {
                    passwordException: i18next.t(`authentication.validation.password.invalidFormat`)
                };
            }

            return {
                generalException: exception.message
            };
        }
        return null;
    };

    const handleForgotPasswordClick = () => {
        props.onStateChange("forgotPassword", {});
    };

    const handleBackClick = () => {
        setUser(null);
        setModel({
            email: "",
            password: "",
            newPassword: ""
        });
        setException(null);
    };

    const handleSubmit = e => {
        if (e.charCode === 13) {
            if (user && user.challengeName === "NEW_PASSWORD_REQUIRED") {
                completeNewPassword(model, user);
            } else {
                signIn(model);
            }
        }
    };

    const isChallenge = user && user.challengeName === "NEW_PASSWORD_REQUIRED";
    const exceptionMessage = getException(exception);

    return (
        <div className="login-info-outer-container">
            <div className="login-info-inner-container" onKeyPress={handleSubmit}>
                {
                    !isChallenge &&
                    <React.Fragment>
                        <div className={`input ${exceptionMessage ? "error" : ""}`}>
                            <TextField
                                label={i18next.t(`authentication.signIn.email`)}
                                value={model.email || ""}
                                onChange={handleValueChange.bind({}, "email")}
                            />
                            {
                                exceptionMessage?.emailException &&
                                <div className="c-error"><ErrorIcon />{exceptionMessage.emailException}</div>
                            }
                        </div>
                        <div className={`input ${exceptionMessage?.generalException ? "error" : ""}`}>
                            <TextField
                                label={i18next.t(`authentication.signIn.password`)}
                                type="password"
                                autoComplete="current-password"
                                value={model.password || ""}
                                onChange={handleValueChange.bind({}, "password")}
                                InputProps={{
                                    endAdornment:
                                        model.email && model.email.trim() &&
                                        model.password && model.password.trim() &&
                                        model.consent &&
                                        <InputAdornment
                                            position="end"
                                            className="login-button"
                                            onClick={signIn.bind({}, model)}>
                                            <ArrowForwardRoundedIcon />
                                        </InputAdornment>
                                }}
                            />
                        </div>
                        {
                            exceptionMessage?.generalException &&
                            <div className="c-error"><ErrorIcon />{exceptionMessage.generalException}</div>
                        }
                        <div className="forgot-password" onClick={handleForgotPasswordClick}>
                            {i18next.t(`authentication.signIn.forgotPassword`)}
                        </div>
                        <div className="consent-checkbox">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onClick={handleCheckboxValueChange.bind({}, "consent")}
                                        checked={model.consent || false}
                                    />
                                }
                                label={
                                    <LinkParagraph
                                        link="https://5thindustry.de/workbook-eula/"
                                        textBefore={i18next.t(`authentication.signIn.consent.textBefore`)}
                                        textLink={i18next.t(`authentication.signIn.consent.textLink`)}
                                        textAfter={i18next.t(`authentication.signIn.consent.textAfter`)}
                                    />
                                }
                            />
                        </div>
                    </React.Fragment>
                }
                {
                    isChallenge &&
                    <CompleteRegister
                        user={user}
                        model={model}
                        exceptionMessage={exceptionMessage}
                        handleBackClick={handleBackClick}
                        handleValueChange={handleValueChange}
                        completeNewPassword={completeNewPassword}
                    />
                }
            </div>
        </div>
    );
}

export default SignIn;