import React from "react";

// LIBS
import clsx from "clsx";
import i18next from "i18next";
import { useHistory } from "react-router-dom";

// MATERIAL UI - CORE
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

// ICONS
import { ReactComponent as TicketsIcon } from "../../resources/icons/ticketsIcon.svg";
import { ReactComponent as MaintenanceIcon } from "../../resources/icons/maintenanceIcon.svg";
import { ReactComponent as AssetsIcon } from "../../resources/icons/assetsIcon.svg";
import { ReactComponent as ShiftbookIcon } from "../../resources/icons/shiftbookIcon.svg";
import { ReactComponent as ReportsIcon } from "../../resources/icons/statisticIcon.svg";
import { ReactComponent as ScheduleIcon } from "../../resources/icons/bellIcon.svg";

const LeftNavbar = ({
    open,
    classes,
    isNavigationButtonActive,
    getListIconComponent
}) => {
    const history = useHistory();

    const isRepairTicketActive = isNavigationButtonActive('/tickets/create') || isNavigationButtonActive('/tickets/edit');
    const isTicketsActive = isNavigationButtonActive('/tickets') && !(isNavigationButtonActive('/tickets/create') || isNavigationButtonActive('/tickets/edit'));
    const isShiftbookActive = isNavigationButtonActive('/shiftbook');
    const isMachinesActive = isNavigationButtonActive('/machines');
    const isReportsActive = isNavigationButtonActive('/reports');
    const isScheduleActive = isNavigationButtonActive('/scheduler');

    return (
        <React.Fragment>
            <ListItem className={clsx(classes.navigationButton, isRepairTicketActive ? "active" : "")} onClick={() => history.push("/tickets/create")}>
                <ListItemIcon>
                    {getListIconComponent('menu.repairTicket', <TicketsIcon id='machineMaintenanceRepairTicket' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.repairTicket`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isTicketsActive ? "active" : "")} onClick={() => history.push("/tickets")}>
                <ListItemIcon>
                    {getListIconComponent('menu.ticketsOverview', <MaintenanceIcon id='machineMaintenanceTickets' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.ticketsOverview`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isShiftbookActive ? "active" : "")} onClick={() => history.push("/shiftbook")}>
                <ListItemIcon>
                    {getListIconComponent('menu.shiftbook', <ShiftbookIcon id='machineMaintenanceShiftbook' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.shiftbook`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isMachinesActive ? "active" : "")} onClick={() => history.push("/machines")}>
                <ListItemIcon>
                    {getListIconComponent('menu.machines', <AssetsIcon id='machineMaintenanceAssets' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.machines`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isReportsActive ? "active" : "")} onClick={() => history.push("/reports")}>
                <ListItemIcon>
                    {getListIconComponent('menu.reports', <ReportsIcon id='machineMaintenanceReports' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.reports`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isScheduleActive ? "active" : "")} onClick={() => history.push("/scheduler")}>
                <ListItemIcon>
                    {getListIconComponent('menu.scheduledTasks', <ScheduleIcon id='machineMaintenanceScheduleTasks' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.scheduledTasks`)} />
            </ListItem>
        </React.Fragment>
    );
};

export default LeftNavbar;