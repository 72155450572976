export const alignment = {
    left: "left",
    middle: "middle",
    right: "right"
};

export const alignmentOptions = [
    {
        key: alignment.left,
        value: alignment.left,
        translation: "protocol-template-builder.configurations.alignment-options.left"
    },
    {
        key: alignment.middle,
        value: alignment.middle,
        translation: "protocol-template-builder.configurations.alignment-options.middle"
    },
    {
        key: alignment.right,
        value: alignment.right,
        translation: "protocol-template-builder.configurations.alignment-options.right"
    }
];