import shortid from 'shortid';
import i18next from 'i18next';
import { get, uniqBy } from 'lodash';
import moment from 'moment';
import htmlToPdfmake from 'html-to-pdfmake';

import update from '../redux/update';
import { isArrayWithItems } from './arrayHelper';
import { findTileElement, getThumbFileName } from './fileManagement';
import { findExistingProtocolValue, mapAttachmentModel, mapValueModel } from './protocolHelper';

import { documentStatus } from '../constants/DocumentStatus';
import { revisionNumber } from '../constants/Revision';
import { inspectionPlanHandlerOperations } from '../components/protocol/iut-plan-builder/constants';
import { typeOfExport } from '../constants/ExportPDF';
import { config, reactAppCustomer } from '../constants/Config';
import { APP_CUSTOMER } from './env';

import { siemensPDFLogo } from '../resources/images/SiemensPDFLogo';

export const isInspectionPlanDisabled = status => {
    return status === documentStatus.released || status === documentStatus.depreciated;
};

const getPlanInspectionTitleData = (data, language) => {
    const { metadata } = data;

    const { title } = metadata;

    const templateTitle = findExistingProtocolValue(title, language);

    return `${templateTitle}
        Project no.: ${data.metadata.customerOrderNo || ''}
        Type: ${data.metadata.materialType.name || ''}
        Serial no.: ${data.metadata.materials[0]?.serialNumber || ''}
    `;
};

export const mapTemplateToSaveModal = template => {
    let templateRevisionHistory = template.metadata?.revisionHistory || [{ revisionNumber: revisionNumber[0], revisionDate: new Date() }];

    const revision = templateRevisionHistory.findIndex(x => x.revisionNumber === template.metadata?.revisionNumber);
    const newRevision = {
        editor: template.metadata.revisionEditor ? { accountID: template.metadata.revisionEditor } : undefined,
        revisionNumber: template.metadata.revisionNumber || undefined,
        revisionDate: new Date(),
        revisionComment: template.metadata.revisionComment || undefined,
    };

    if (revision !== -1) {
        templateRevisionHistory[revision] = newRevision;
    } else {
        if (template.metadata.revisionNumber || template.metadata.revisionEditor || template.metadata.revisionComment) {
            templateRevisionHistory.push(newRevision);
        }
    }

    return {
        _id: template._id || undefined,
        templateId: template.templateId || undefined,
        title: template.metadata.title || undefined,
        customer: template.metadata.customer || undefined,
        customerOrderNo: template.metadata.customerOrderNo || undefined,
        jobName: template.metadata.jobName || undefined,
        technicalReference: template.metadata.technicalReference || undefined,
        documentType: template.metadata.documentType || undefined,
        documentNumber: template.metadata.documentNumber || undefined,
        legalOwnerLogo: template.metadata.legalOwnerLogo || undefined,
        businessType: template.metadata.businessType || undefined,
        materials: template.metadata.materials || undefined,
        materialType: template.metadata.materialType || undefined,
        serviceNotificationNumber: template.metadata.serviceNotificationNumber || undefined,
        originalCustomerOrderNumber: template.metadata.originalCustomerOrderNumber || undefined,
        productUsedIn: template.metadata.productUsedIn || undefined,
        numberOfProducts: template.metadata.numberOfProducts || undefined,
        approvedBy: template.metadata.approvedBy || undefined,
        responsibleDepartment: template.metadata.responsibleDepartment || undefined,
        legalOwner: template.metadata.legalOwner || undefined,
        inspectors: template.metadata.inspectors || undefined,
        follower: template.metadata.follower || undefined,
        responsible: template.metadata.responsible || undefined,
        revisionHistory: templateRevisionHistory || [],
        status: template.metadata.status || documentStatus.draft,
        numberOfConflicts: template.metadata.numberOfConflicts || 0,
        inspectionPlanComment: !!template.metadata.inspectionPlanComment
            ? mapValueModel(template.metadata.inspectionPlanComment)
            : undefined,
        assemblyGroup: template.assemblyGroup
            ? template.assemblyGroup.map(assemblyGroup => {
                  return {
                      _id: assemblyGroup._id || undefined,
                      assemblyGroupNumber: assemblyGroup.assemblyGroupNumber || undefined,
                      assemblyGroupName: assemblyGroup.assemblyGroupName || undefined,
                      procurementCode: assemblyGroup.procurementCode || undefined,
                      manufacturingStep: assemblyGroup.manufacturingStep
                          ? assemblyGroup.manufacturingStep.map(manufacturingStep => {
                                return {
                                    _id: manufacturingStep._id || undefined,
                                    manufacturingStepName: manufacturingStep.manufacturingStepName || undefined,
                                    manufacturingStepCode: manufacturingStep.manufacturingStepCode || undefined,
                                    inspectionOrders: manufacturingStep.inspectionOrders
                                        ? manufacturingStep.inspectionOrders.map(inspectionOrder => {
                                              return {
                                                  _id: inspectionOrder._id || undefined,
                                                  inspectionCode: inspectionOrder.inspectionCode || undefined,
                                                  inspectionDescriptionShort: inspectionOrder.inspectionDescriptionShort || undefined,
                                                  inspectionDescriptionLong: inspectionOrder.inspectionDescriptionLong || undefined,
                                                  protocolTemplateId: inspectionOrder.protocolTemplateId || undefined,
                                                  inspectionRegulations: inspectionOrder.inspectionRegulations || [],
                                                  internationalInspectionNorms: inspectionOrder.internationalInspectionNorms || [],
                                                  acceptanceCriteria: inspectionOrder.acceptanceCriteria || undefined,
                                                  inspectionLotSize: inspectionOrder.inspectionLotSize || undefined,
                                                  customerRelevance: inspectionOrder.customerRelevance || undefined,
                                                  inspectionApprovers: inspectionOrder.inspectionApprovers
                                                      ? inspectionOrder.inspectionApprovers.map(x => {
                                                            return {
                                                                _id: x._id,
                                                                inspectionApproverID: x.inspectionApproverID,
                                                                approvalType: x.approvalType,
                                                            };
                                                        })
                                                      : [],
                                                  customerInspectionPossible: inspectionOrder.customerInspectionPossible || undefined,
                                                  customerDocumentationRelevant: inspectionOrder.customerDocumentationRelevant || undefined,
                                                  customerSpecification: inspectionOrder.customerSpecification || undefined,
                                                  certificationType: inspectionOrder.certificationType || undefined,
                                                  inspectionLocation: inspectionOrder.inspectionLocation || undefined,
                                                  numberOfApprovals: inspectionOrder.numberOfApprovals
                                                      ? parseInt(inspectionOrder.numberOfApprovals, 10)
                                                      : undefined,
                                                  numberOfInspectionLots: inspectionOrder.numberOfInspectionLots || undefined,
                                                  approvalAssemblyGroup: inspectionOrder.approvalAssemblyGroup || undefined,
                                                  supplierCertificationType: inspectionOrder.supplierCertificationType || undefined,
                                                  languageOfCustomerDocumentation:
                                                      inspectionOrder.languageOfCustomerDocumentation || undefined,
                                                  revisionStatusPosition: inspectionOrder.revisionStatusPosition || undefined,
                                                  inspectionSeverity: inspectionOrder.inspectionSeverity || undefined,
                                                  drawings: inspectionOrder.drawings || [],
                                                  inspectionReportID: inspectionOrder.inspectionReportID || undefined,
                                              };
                                          })
                                        : [],
                                };
                            })
                          : [],
                  };
              })
            : [],
    };
};

export const mapHandlerToSaveModal = (handler, templateId, modifierId) => {
    if (isArrayWithItems(handler)) {
        let atomics = [...handler];

        const modifiedBy = {
            modifierID: modifierId,
            modifiedAt: new Date(),
        };

        const rootIndex = atomics.findIndex(x => !x.path);
        if (rootIndex !== -1) {
            atomics = update(atomics, {
                [rootIndex]: {
                    values: {
                        modifiedBy: {
                            $set: modifiedBy,
                        },
                    },
                },
            });
        } else {
            atomics = update(atomics, {
                $push: [
                    {
                        operation: inspectionPlanHandlerOperations.update,
                        values: {
                            modifiedBy: modifiedBy,
                        },
                    },
                ],
            });
        }

        return atomics.map(item => {
            return {
                ...item,
                inspectionPlanId: templateId,
                values: removeInspectionPlanKeys(item.values),
            };
        });
    }

    return [];
};

export const mapModelToTemplate = (model, prevModel, isNew = false, isTemplate = false) => {
    return {
        key: get(prevModel, 'key') || shortid.generate(),
        _id: !isNew ? model._id : undefined,
        templateId: isTemplate ? model._id : undefined,
        numberOfConflicts: model.numberOfConflicts || 0,
        metadata: {
            title: mapValueModel(model.title),
            customer: model.customer,
            customerOrderNo: model.customerOrderNo,
            jobName: model.jobName,
            technicalReference: model.technicalReference,
            documentType: !isTemplate ? model.documentType : undefined,
            documentNumber: model.documentNumber || undefined,
            legalOwnerLogo: model.legalOwnerLogo,
            businessType: model.businessType,
            materials: model.materials
                ? model.materials.map(item => {
                      return {
                          materialNumber: item.materialNumber,
                          materialName: item.materialName,
                          serialNumber: item.serialNumber,
                      };
                  })
                : [],
            materialType: model.materialType,
            inspectionPlanComment: mapValueModel(model.inspectionPlanComment),
            serviceNotificationNumber: model.serviceNotificationNumber,
            originalCustomerOrderNumber: model.originalCustomerOrderNumber,
            productUsedIn: mapValueModel(model.productUsedIn),
            numberOfProducts: model.numberOfProducts,
            approvedBy: model.approvedBy
                ? model.approvedBy.map(item => {
                      return {
                          approverID: item.approverID,
                          approvedAt: item.approvedAt,
                      };
                  })
                : [],
            responsibleDepartment: model.responsibleDepartment,
            legalOwner: model.legalOwner,
            inspectors: model.inspectors
                ? model.inspectors.map(item => {
                      return {
                          _id: item._id,
                          name: item.name,
                      };
                  })
                : [],
            follower: model.follower || [],
            responsible: model.responsible
                ? model.responsible.map(item => {
                      return {
                          _id: item._id,
                          responsibleID: item.responsibleID,
                          assignedAt: item.assignedAt,
                      };
                  })
                : [],
            createdBy: model.createdBy,
            modifiedBy: isArrayWithItems(model.modifiedBy)
                ? model.modifiedBy[model.modifiedBy.length - 1]
                : { modifiedAt: model.createdBy?.createdAt || new Date() },
            revisionHistory:
                !isNew && isArrayWithItems(model.revisionHistory)
                    ? model.revisionHistory.map(item => {
                          return {
                              editor: item.editor?.accountID
                                  ? {
                                        accountID: item.editor.accountID,
                                    }
                                  : undefined,
                              revisionNumber: item.revisionNumber || undefined,
                              revisionDate: item.revisionDate || undefined,
                              revisionComment: item.revisionComment || undefined,
                          };
                      })
                    : [
                          {
                              revisionNumber: revisionNumber[0],
                              revisionDate: new Date(),
                          },
                      ],
            revisionEditor:
                !isNew && isArrayWithItems(model.revisionHistory)
                    ? model.revisionHistory[model.revisionHistory.length - 1].editor?.accountID
                    : null,
            revisionNumber:
                !isNew && isArrayWithItems(model.revisionHistory)
                    ? model.revisionHistory[model.revisionHistory.length - 1].revisionNumber
                    : revisionNumber[0],
            revisionComment:
                !isNew && isArrayWithItems(model.revisionHistory)
                    ? model.revisionHistory[model.revisionHistory.length - 1].revisionComment
                    : null,
            status: isNew ? documentStatus.draft : model.status || documentStatus.draft,
        },
        assemblyGroup: model.assemblyGroup
            ? model.assemblyGroup.map((assemblyGroup, assemblyGroupIndex) => {
                  return {
                      key: get(prevModel, `assemblyGroup.${assemblyGroupIndex}.key`) || shortid.generate(),
                      _id: !isNew ? assemblyGroup._id : undefined,
                      assemblyGroupNumber: mapValueModel(assemblyGroup.assemblyGroupNumber),
                      assemblyGroupName: mapValueModel(assemblyGroup.assemblyGroupName),
                      procurementCode: assemblyGroup.procurementCode || undefined,
                      manufacturingStep: assemblyGroup.manufacturingStep
                          ? assemblyGroup.manufacturingStep.map((manufacturingStep, manufacturingStepIndex) => {
                                return {
                                    key:
                                        get(
                                            prevModel,
                                            `assemblyGroup.${assemblyGroupIndex}.manufacturingStep.${manufacturingStepIndex}.key`
                                        ) || shortid.generate(),
                                    _id: !isNew ? manufacturingStep._id : undefined,
                                    manufacturingStepName: mapValueModel(manufacturingStep.manufacturingStepName),
                                    manufacturingStepCode: mapValueModel(manufacturingStep.manufacturingStepCode),
                                    inspectionOrders: manufacturingStep.inspectionOrders
                                        ? manufacturingStep.inspectionOrders.map((inspectionOrder, inspectionOrderIndex) => {
                                              return {
                                                  key:
                                                      get(
                                                          prevModel,
                                                          `assemblyGroup.${assemblyGroupIndex}.manufacturingStep.${manufacturingStepIndex}.inspectionOrders.${inspectionOrderIndex}.key`
                                                      ) || shortid.generate(),
                                                  _id: !isNew ? inspectionOrder._id : undefined,
                                                  inspectionCode: mapValueModel(inspectionOrder.inspectionCode),
                                                  inspectionDescriptionShort: mapValueModel(inspectionOrder.inspectionDescriptionShort),
                                                  inspectionDescriptionLong: mapValueModel(inspectionOrder.inspectionDescriptionLong),
                                                  protocolTemplateId: inspectionOrder.protocolTemplateId || undefined,
                                                  inspectionRegulations: mapAttachmentModel(inspectionOrder.inspectionRegulations),
                                                  internationalInspectionNorms: mapAttachmentModel(
                                                      inspectionOrder.internationalInspectionNorms
                                                  ),
                                                  acceptanceCriteria: mapValueModel(inspectionOrder.acceptanceCriteria),
                                                  inspectionLotSize: mapValueModel(inspectionOrder.inspectionLotSize),
                                                  customerRelevance: mapValueModel(inspectionOrder.customerRelevance),
                                                  inspectionApprovers: inspectionOrder.inspectionApprovers
                                                      ? inspectionOrder.inspectionApprovers.map(item => {
                                                            return {
                                                                _id: item._id,
                                                                inspectionApproverID: item.inspectionApproverID,
                                                                approvalType: mapValueModel(item.approvalType),
                                                            };
                                                        })
                                                      : [],
                                                  customerInspectionPossible: inspectionOrder.customerInspectionPossible,
                                                  customerDocumentationRelevant: inspectionOrder.customerDocumentationRelevant,
                                                  customerSpecification: mapValueModel(inspectionOrder.customerSpecification),
                                                  certificationType: mapValueModel(inspectionOrder.certificationType),
                                                  inspectionLocation: mapValueModel(inspectionOrder.inspectionLocation),
                                                  numberOfApprovals: inspectionOrder.numberOfApprovals || undefined,
                                                  numberOfInspectionLots: mapValueModel(inspectionOrder.numberOfInspectionLots),
                                                  approvalAssemblyGroup: mapValueModel(inspectionOrder.approvalAssemblyGroup),
                                                  supplierCertificationType: mapValueModel(inspectionOrder.supplierCertificationType),
                                                  languageOfCustomerDocumentation: mapValueModel(
                                                      inspectionOrder.languageOfCustomerDocumentation
                                                  ),
                                                  revisionStatusPosition: inspectionOrder.revisionStatusPosition || undefined,
                                                  inspectionSeverity: inspectionOrder.inspectionSeverity || undefined,
                                                  drawings: mapAttachmentModel(inspectionOrder.drawings),
                                                  inspectionReportID: !isNew ? inspectionOrder.inspectionReportID || undefined : undefined,
                                              };
                                          })
                                        : [],
                                };
                            })
                          : [],
                  };
              })
            : [],
    };
};

export const isIuTPlanValid = (iutPlan, language) => {
    if (iutPlan) {
        return iutPlan.assemblyGroup.every(assemblyGroup => {
            const assemblyGroupNumberValid = Boolean(findExistingProtocolValue(assemblyGroup.assemblyGroupNumber, language));
            const assemblyGroupNameValid = Boolean(findExistingProtocolValue(assemblyGroup.assemblyGroupName, language));
            const manufacturingStepValid = assemblyGroup.manufacturingStep
                ? assemblyGroup.manufacturingStep.every(manufacturingStep => {
                      const manufacturingStepNameValid = Boolean(
                          findExistingProtocolValue(manufacturingStep.manufacturingStepName, language)
                      );
                      const manufacturingStepCodeValid = Boolean(
                          findExistingProtocolValue(manufacturingStep.manufacturingStepCode, language)
                      );
                      const inspectionOrdersValid = manufacturingStep.inspectionOrders
                          ? manufacturingStep.inspectionOrders.every(inspectionOrder => {
                                const inspectionCodeValid = Boolean(findExistingProtocolValue(inspectionOrder.inspectionCode, language));
                                const inspectionDescriptionShortValid = Boolean(
                                    findExistingProtocolValue(inspectionOrder.inspectionDescriptionShort, language)
                                );

                                return inspectionCodeValid && inspectionDescriptionShortValid;
                            })
                          : true;

                      return manufacturingStepNameValid && manufacturingStepCodeValid && inspectionOrdersValid;
                  })
                : true;

            return assemblyGroupNumberValid && assemblyGroupNameValid && manufacturingStepValid;
        });
    } else {
        return false;
    }
};

export const getConflicts = (plan, templates) => {
    let conflicts = {};
    let conflictsCount = 0;

    if (plan) {
        plan.assemblyGroup.forEach((assemblyGroup, assemblyGroupIndex) => {
            assemblyGroup.manufacturingStep.forEach((manufacturingStep, manufacturingStepIndex) => {
                manufacturingStep.inspectionOrders.forEach((inspectionOrder, inspectionOrderIndex) => {
                    let inspectionOrderConflictsCount = 0;

                    if (templates && inspectionOrder.protocolTemplateId) {
                        if (!templates.some(x => x._id === inspectionOrder.protocolTemplateId)) {
                            inspectionOrderConflictsCount++;
                            conflictsCount++;
                        }
                    }

                    if (inspectionOrderConflictsCount > 0) {
                        conflicts = update.set(
                            conflicts,
                            `${assemblyGroupIndex}.${manufacturingStepIndex}.${inspectionOrderIndex}`,
                            inspectionOrderConflictsCount
                        );
                    }
                });
            });
        });
    }
    return {
        count: conflictsCount,
        conflicts: conflicts,
    };
};

export const getInspectionLocations = (plan, language) => {
    let inspectionLocations = [];

    if (isArrayWithItems(plan.assemblyGroup)) {
        plan.assemblyGroup.forEach(assemblyGroup => {
            if (isArrayWithItems(assemblyGroup.manufacturingStep)) {
                assemblyGroup.manufacturingStep.forEach(manufacturingStep => {
                    if (isArrayWithItems(manufacturingStep.inspectionOrders)) {
                        manufacturingStep.inspectionOrders.forEach(inspectionOrder => {
                            const inspectionLocation = findExistingProtocolValue(inspectionOrder.inspectionLocation, language);
                            if (inspectionLocation && !inspectionLocations.some(x => x === inspectionOrder.inspectionLocation)) {
                                inspectionLocations.push(inspectionLocation);
                            }
                        });
                    }
                });
            }
        });
    }

    return uniqBy(inspectionLocations, x => x);
};

export const getInspectionPlanProgress = model => {
    const total = model.progress?.total || 1.0;
    const completed = model.progress?.completed;

    return (completed / total) * 100;
};

export const removeInspectionPlanKeys = model => {
    if (model) {
        let newModel = model;

        newModel = update.unset(newModel, 'key');

        if (isArrayWithItems(newModel.manufacturingStep)) {
            newModel.manufacturingStep.forEach((manufacturingStep, manufacturingStepIndex) => {
                newModel = update.unset(newModel, `manufacturingStep.${manufacturingStepIndex}.key`);

                if (isArrayWithItems(manufacturingStep.inspectionOrders)) {
                    manufacturingStep.inspectionOrders.forEach((inspectionOrder, inspectionOrderIndex) => {
                        newModel = update.unset(
                            newModel,
                            `manufacturingStep.${manufacturingStepIndex}.inspectionOrders.${inspectionOrderIndex}.key`
                        );
                    });
                }
            });
        }

        if (isArrayWithItems(model.inspectionOrders)) {
            model.inspectionOrders.forEach((inspectionOrder, inspectionOrderIndex) => {
                newModel = update.unset(newModel, `inspectionOrders.${inspectionOrderIndex}.key`);
            });
        }

        return newModel;
    }
};

export const getRevisionHistoryDocumentDefinition = (language, plan, users, tileData, obj) => {
    const translate = i18next.getFixedT(language.toLowerCase());

    return {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [0, 110, 0, 160],
        header: (currentPage, pageCount, pageSize) => {
            if (currentPage === 1) {
                obj.pages = pageCount - 1;
            }
        },
        content: getDocumentRevisionHistory(translate, plan.metadata.revisionHistory, users),
        footer: getDocumentFooter(translate, plan, users, tileData, language),
        styles: getDocumentStyles(),
    };
};

export const getDocumentDefinition = (language, plan, templates, users, tileData, exportType, name = null, revisionHistoryPages = 1) => {
    const translate = i18next.getFixedT(language.toLowerCase());

    let content = [];

    const inspectionPlanComment = findExistingProtocolValue(plan.metadata.inspectionPlanComment, language);

    const companyInfo = [
        {
            text: translate('protocol-iut-plan-builder.make-pdf.titlePage.companyName'),
            style: 'subHeader',
            alignment: 'center',
        },
        {
            text: translate('protocol-iut-plan-builder.make-pdf.titlePage.companyLocation'),
            style: 'subHeader',
            alignment: 'center',
        },
    ];

    // Page 1
    content.push({
        stack: [
            {
                text: translate('protocol-iut-plan-builder.make-pdf.titlePage.header'),
                style: 'header',
                alignment: 'center',
            },
            companyInfo,
            { text: '\n\n\n\n' },
            {
                text: [
                    {
                        text: `${translate('protocol-iut-plan-builder.make-pdf.titlePage.customerLabel')}\n`,
                        style: 'metadataLabel',
                    },
                    {
                        text: plan.metadata.customer || '',
                        style: 'metadataValue',
                    },
                    { text: '\n\n' },
                    {
                        text: `${translate('protocol-iut-plan-builder.make-pdf.titlePage.inspectorLabel')}\n`,
                        style: 'metadataLabel',
                    },
                    {
                        text: plan.metadata.inspectors.map((inspector, inspectorIndex) => {
                            return `${String.fromCharCode(97 + inspectorIndex).toUpperCase()} ${inspector.name}\n`;
                        }),
                        style: 'metadataValue',
                    },
                    { text: '\n\n' },
                    {
                        text: `${translate('protocol-iut-plan-builder.make-pdf.titlePage.useLabel')}\n`,
                        style: 'metadataLabel',
                    },
                    {
                        text: findExistingProtocolValue(plan.metadata.productUsedIn, language) || '',
                        style: 'metadataValue',
                    },
                    { text: '\n\n' },
                    {
                        text: `${translate('protocol-iut-plan-builder.make-pdf.titlePage.quantityLabel')}\n`,
                        style: 'metadataLabel',
                    },
                    {
                        text: plan.metadata.numberOfProducts || '',
                        style: 'metadataValue',
                    },
                ],
                style: 'metadata',
            },
        ],
        pageBreak: 'after',
    });

    //Page 2
    if (APP_CUSTOMER === reactAppCustomer.dw) {
        content.push({
            stack: [
                {
                    text: translate('protocol-iut-plan-builder.make-pdf.descriptionPage.label'),
                    style: 'label',
                },
                {
                    table: {
                        widths: [75, 30, 25, '*', 140],
                        body: [
                            [
                                {
                                    rowSpan: 3,
                                    text: translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate.label'),
                                },
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._2_1.label'),
                                '=',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._2_1.name'),
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._2_1.description'),
                            ],
                            [
                                '',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._2_2.label'),
                                '=',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._2_2.name'),
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._2_2.description'),
                            ],
                            [
                                '',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._3_1.label'),
                                '=',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._3_1.name'),
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._3_1.description'),
                            ],
                            [
                                '',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._3_2.label'),
                                '=',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._3_2.name'),
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate._3_2.description'),
                            ],
                            [
                                '',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate.IPZ.label'),
                                '=',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate.IPZ.name'),
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate.IPZ.description'),
                            ],
                            [
                                '',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate.IPB.label'),
                                '=',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate.IPB.name'),
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfCertificate.IPB.description'),
                            ],
                        ],
                    },
                    layout: 'noBorders',
                    margin: [8, 24, 0, 0],
                    style: 'descriptionTable',
                },
                {
                    table: {
                        widths: [75, 30, 25, 80, '*'],
                        body: [
                            [
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.label'),
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.H.label'),
                                '=',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.H.name'),
                                `${translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.H.description')}\n\n`,
                            ],
                            [
                                '',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.W.label'),
                                '=',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.W.name'),
                                `${translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.W.description')}\n\n`,
                            ],
                            [
                                '',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.AS.label'),
                                '=',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.AS.name'),
                                `${translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.AS.description')}\n\n`,
                            ],
                            [
                                '',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.RC.label'),
                                '=',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.RC.name'),
                                `${translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.RC.description')}\n\n`,
                            ],
                            [
                                '',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.RS.label'),
                                '=',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.RS.name'),
                                `${translate('protocol-iut-plan-builder.make-pdf.descriptionPage.typeOfApproval.RS.description')}\n\n`,
                            ],
                        ],
                    },
                    layout: 'noBorders',
                    margin: [8, 24, 0, 0],
                    style: 'descriptionTable',
                },
                {
                    table: {
                        widths: [75, 30, 25, '*'],
                        body: [
                            [
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.locationOfInspection.label'),
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.locationOfInspection.DW.label'),
                                '=',
                                `${translate(
                                    'protocol-iut-plan-builder.make-pdf.descriptionPage.locationOfInspection.DW.description'
                                )}\n\n`,
                            ],
                            [
                                '',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.locationOfInspection.ZL.label'),
                                '=',
                                `${translate(
                                    'protocol-iut-plan-builder.make-pdf.descriptionPage.locationOfInspection.ZL.description'
                                )}\n\n`,
                            ],
                            [
                                '',
                                translate('protocol-iut-plan-builder.make-pdf.descriptionPage.locationOfInspection.AK.label'),
                                '=',
                                `${translate(
                                    'protocol-iut-plan-builder.make-pdf.descriptionPage.locationOfInspection.AK.description'
                                )}\n\n`,
                            ],
                        ],
                    },
                    layout: 'noBorders',
                    margin: [8, 24, 0, 0],
                    style: 'descriptionTable',
                },
            ],
            margin: [32, 0, 32, 0],
            pageBreak: 'after',
        });
    }

    // Page 3 (Comments)
    let pdfComments = htmlToPdfmake(inspectionPlanComment, {
        defaultStyles: {
            h1: { fontSize: 24, bold: true, marginBottom: 0 },
            h2: { fontSize: 22, bold: true, marginBottom: 0 },
            h3: { fontSize: 20, bold: true, marginBottom: 0 },
            h4: { fontSize: 18, bold: true, marginBottom: 0 },
            h5: { fontSize: 16, bold: true, marginBottom: 0 },
            h6: { fontSize: 14, bold: true, marginBottom: 0 },
            p: { margin: [0, 0, 0, 0] },
            ul: { marginBottom: 0 },
        },
    })
    if(!Array.isArray(pdfComments)) {
        
        pdfComments = [pdfComments];
    }

    content.push({
        stack: [
            {
                text: translate('protocol-iut-plan-builder.make-pdf.comments.label'),
                style: 'label',
            },
            ...pdfComments,
        ],
        margin: [32, 0, 32, 20],
        pageBreak: 'after',
    });

    // Page 4 (Revision History)
    content = [...content, ...getDocumentRevisionHistory(translate, plan.metadata.revisionHistory, users)];

    // Content
    if (isArrayWithItems(plan.assemblyGroup)) {
        const size = plan.assemblyGroup.length - 1;

        plan.assemblyGroup.forEach((assemblyGroup, index) => {
            const pageBreakType = index < size ? 'after' : 'none';

            content.push({
                pageBreak: `${pageBreakType}`,
                margin: [20, 20, 20, 0],
                table: {
                    widths: [70, 466],
                    body: [
                        [
                            {
                                text: findExistingProtocolValue(assemblyGroup.assemblyGroupNumber, language),
                                style: 'documentHeader1',
                            },
                            {
                                text: findExistingProtocolValue(assemblyGroup.assemblyGroupName, language),
                                style: 'documentHeader1',
                            },
                        ],
                        ...assemblyGroup.manufacturingStep.map(manufacturingStep => {
                            let manufacturingStepBody = [];

                            manufacturingStepBody.push([
                                {
                                    text: findExistingProtocolValue(manufacturingStep.manufacturingStepCode, language),
                                    style: 'documentHeader2',
                                },
                                {
                                    colSpan: 7,
                                    text: findExistingProtocolValue(manufacturingStep.manufacturingStepName, language),
                                    style: 'documentHeader2',
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                            ]);

                            manufacturingStep.inspectionOrders.forEach(inspectionOrder => {
                                const inspectionNorm = isArrayWithItems(inspectionOrder.internationalInspectionNorms)
                                    ? inspectionOrder.internationalInspectionNorms.map(x => x.name || x.filename)
                                    : [];
                                const inspectionRegulation = isArrayWithItems(inspectionOrder.inspectionRegulations)
                                    ? inspectionOrder.inspectionRegulations.map(x => x.name || x.filename)
                                    : [];
                                const drawings = isArrayWithItems(inspectionOrder.drawings)
                                    ? inspectionOrder.drawings.map(x => x.name || x.filename).join('; ')
                                    : '';
                                const inspectionNorms = [...inspectionNorm, ...inspectionRegulation].join('; ');

                                const protocolTemplate = isArrayWithItems(templates)
                                    ? templates.find(x => x._id === inspectionOrder.protocolTemplateId)
                                    : null;
                                const protocolTemplateRevisionHistory = protocolTemplate ? protocolTemplate.revisionHistory : null;
                                const protocolTemplateCurrentRevision = isArrayWithItems(protocolTemplateRevisionHistory)
                                    ? protocolTemplateRevisionHistory[protocolTemplateRevisionHistory.length - 1].revisionNumber
                                    : revisionNumber[0];
                                const protocolTemplateTitle = protocolTemplate
                                    ? `${protocolTemplate.templateName} | ${translate(
                                          'protocol-iut-plan-builder.right-panel.assembly-group.manufacturing-step.inspection-orders.protocol-template.revision'
                                      )} ${protocolTemplateCurrentRevision}`
                                    : '';

                                const inspectors = plan.metadata.inspectors || [];

                                const approvalIndex1 = inspectors.findIndex(
                                    x => x._id === get(inspectionOrder, 'inspectionApprovers.0.inspectionApproverID')
                                );
                                const approvalIndex2 = inspectors.findIndex(
                                    x => x._id === get(inspectionOrder, 'inspectionApprovers.1.inspectionApproverID')
                                );
                                const approvalIndex3 = inspectors.findIndex(
                                    x => x._id === get(inspectionOrder, 'inspectionApprovers.2.inspectionApproverID')
                                );
                                const approvalIndex4 = inspectors.findIndex(
                                    x => x._id === get(inspectionOrder, 'inspectionApprovers.3.inspectionApproverID')
                                );

                                const approvalType1 =
                                    findExistingProtocolValue(get(inspectionOrder, 'inspectionApprovers.0.approvalType'), language) || '-';
                                const approvalType2 =
                                    findExistingProtocolValue(get(inspectionOrder, 'inspectionApprovers.1.approvalType'), language) || '-';
                                const approvalType3 =
                                    findExistingProtocolValue(get(inspectionOrder, 'inspectionApprovers.2.approvalType'), language) || '-';
                                const approvalType4 =
                                    findExistingProtocolValue(get(inspectionOrder, 'inspectionApprovers.3.approvalType'), language) || '-';

                                const customerDocumentationRelevant = inspectionOrder.customerDocumentationRelevant
                                    ? translate('protocol-iut-plan-builder.make-pdf.data.customerDocumentationRelevant.yes')
                                    : translate('protocol-iut-plan-builder.make-pdf.data.customerDocumentationRelevant.no');

                                manufacturingStepBody.push([
                                    {
                                        text: `> ${findExistingProtocolValue(inspectionOrder.inspectionCode, language)}`,
                                        style: 'documentHeader3',
                                    },
                                    {
                                        text: findExistingProtocolValue(inspectionOrder.inspectionDescriptionShort, language),
                                        style: 'documentHeader4',
                                    },
                                    { text: inspectionNorms, style: 'documentHeader3' },
                                    {
                                        text:
                                            exportType === typeOfExport.internal
                                                ? findExistingProtocolValue(inspectionOrder.acceptanceCriteria, language)
                                                : '',
                                        style: 'documentHeader3',
                                    },
                                    {
                                        text: findExistingProtocolValue(inspectionOrder.numberOfInspectionLots, language),
                                        style: 'documentHeader3',
                                    },
                                    {
                                        text: exportType === typeOfExport.internal ? customerDocumentationRelevant : '',
                                        style: 'documentHeader3',
                                    },
                                    {
                                        table: {
                                            body: [
                                                [
                                                    {
                                                        text:
                                                            approvalIndex1 !== -1
                                                                ? String.fromCharCode(97 + approvalIndex1).toUpperCase()
                                                                : '-',
                                                        style: 'documentHeader3',
                                                    },
                                                    {
                                                        text:
                                                            approvalIndex2 !== -1
                                                                ? String.fromCharCode(97 + approvalIndex2).toUpperCase()
                                                                : '-',
                                                        style: 'documentHeader3',
                                                    },
                                                    {
                                                        text:
                                                            approvalIndex3 !== -1
                                                                ? String.fromCharCode(97 + approvalIndex3).toUpperCase()
                                                                : '-',
                                                        style: 'documentHeader3',
                                                    },
                                                    {
                                                        text:
                                                            approvalIndex4 !== -1
                                                                ? String.fromCharCode(97 + approvalIndex4).toUpperCase()
                                                                : '-',
                                                        style: 'documentHeader3',
                                                    },
                                                ],
                                            ],
                                        },
                                        layout: 'noBorders',
                                    },
                                    {
                                        text: findExistingProtocolValue(inspectionOrder.certificationType, language),
                                        style: 'documentHeader3',
                                    },
                                ]);

                                manufacturingStepBody.push([
                                    { text: '', style: 'documentHeader3' },
                                    {
                                        stack: [
                                            {
                                                text: findExistingProtocolValue(inspectionOrder.inspectionDescriptionLong, language),
                                                style: 'documentHeader4',
                                            },
                                            {
                                                text: findExistingProtocolValue(inspectionOrder.customerSpecification, language),
                                                style: 'documentHeader5',
                                            },
                                        ],
                                    },
                                    { text: drawings, style: 'documentHeader3' },
                                    { text: protocolTemplateTitle, style: 'documentHeader3' },
                                    {
                                        text: findExistingProtocolValue(inspectionOrder.inspectionLocation, language),
                                        style: 'documentHeader3',
                                    },
                                    { text: `${inspectionOrder.numberOfApprovals || ''}`, style: 'documentHeader3' },
                                    {
                                        table: {
                                            body: [
                                                [
                                                    { text: approvalType1, style: 'documentHeader3' },
                                                    { text: approvalType2, style: 'documentHeader3' },
                                                    { text: approvalType3, style: 'documentHeader3' },
                                                    { text: approvalType4, style: 'documentHeader3' },
                                                ],
                                            ],
                                        },
                                        layout: 'noBorders',
                                    },
                                    {
                                        text: findExistingProtocolValue(inspectionOrder.languageOfCustomerDocumentation, language),
                                        style: 'documentHeader3',
                                    },
                                ]);
                            });

                            return [
                                {
                                    colSpan: 2,
                                    margin: [12, 0, 0, 0],
                                    table: {
                                        widths: [50, 130, 60, 45, 45, 40, 65, 40],
                                        body: manufacturingStepBody,
                                    },
                                    layout: {
                                        hLineWidth: function (i, node) {
                                            return i !== node.table.body.length && i % 2 !== 0 ? 1 : 0;
                                        },
                                        vLineWidth: function (i, node) {
                                            return 0;
                                        },

                                        hLineStyle: function (i, node) {
                                            return { dash: { length: 3, space: 2 } };
                                        },
                                    },
                                },
                            ];
                        }),
                    ],
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i === 1 ? 1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                },
            });
        });
    }

    return {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [0, 110, 0, 160],
        info: {
            title: name || findExistingProtocolValue(plan.title, language) || '',
        },
        header: getDocumentHeader(translate, exportType, revisionHistoryPages),
        content: content,
        footer: getDocumentFooter(translate, plan, users, tileData, language),
        styles: getDocumentStyles(),
    };
};

export const getDocumentHeader = (translate, exportType, revisionHistoryPages = 1) => {
    return (currentPage, pageCount, pageSize) => {
        if (currentPage <= 3 + revisionHistoryPages) {
            return [];
        }

        return [
            {
                margin: [20, 20, 20, 0],
                table: {
                    widths: [70, '*'],
                    body: [
                        [
                            {
                                text: translate('protocol-iut-plan-builder.make-pdf.header.assemblyNo'),
                                style: 'documentHeader1',
                            },
                            {
                                text: translate('protocol-iut-plan-builder.make-pdf.header.assemblyName'),
                                style: 'documentHeader1',
                            },
                        ],
                        [
                            {
                                colSpan: 2,
                                margin: [12, 0, 0, 0],
                                table: {
                                    widths: [50, '*', 70, 45, 45, 40, 65, 40],
                                    body: [
                                        [
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.manufacturingStepNo'),
                                                style: 'documentHeader2',
                                            },
                                            {
                                                colSpan: 7,
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.manufacturingStepName'),
                                                style: 'documentHeader2',
                                            },
                                            {},
                                            {},
                                            {},
                                            {},
                                            {},
                                            {},
                                        ],
                                        [
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.inspectionOrderNo'),
                                                style: 'documentHeader3',
                                            },
                                            {
                                                text: translate(
                                                    'protocol-iut-plan-builder.make-pdf.header.inspectionOrderShortDescription'
                                                ),
                                                style: 'documentHeader4',
                                            },
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.internationalInspectionNorm'),
                                                style: 'documentHeader3',
                                            },
                                            {
                                                text:
                                                    exportType === typeOfExport.internal
                                                        ? translate('protocol-iut-plan-builder.make-pdf.header.acceptanceCriteria')
                                                        : '',
                                                style: 'documentHeader3',
                                            },
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.quantityOfInspectionLotes'),
                                                style: 'documentHeader3',
                                            },
                                            {
                                                text:
                                                    exportType === typeOfExport.internal
                                                        ? translate(
                                                              'protocol-iut-plan-builder.make-pdf.header.customerDocumentationRelevant'
                                                          )
                                                        : '',
                                                style: 'documentHeader3',
                                            },
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.approvalParties'),
                                                style: 'documentHeader3',
                                            },
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.typeOfCertificate'),
                                                style: 'documentHeader3',
                                            },
                                        ],
                                        [
                                            { text: '', style: 'documentHeader3' },
                                            {
                                                stack: [
                                                    {
                                                        text: translate(
                                                            'protocol-iut-plan-builder.make-pdf.header.inspectionOrderLongDescription'
                                                        ),
                                                        style: 'documentHeader4',
                                                    },
                                                    {
                                                        text: translate('protocol-iut-plan-builder.make-pdf.header.customerSpecification'),
                                                        style: 'documentHeader5',
                                                    },
                                                ],
                                            },
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.drawings'),
                                                style: 'documentHeader3',
                                            },
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.templateName'),
                                                style: 'documentHeader3',
                                            },
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.location'),
                                                style: 'documentHeader3',
                                            },
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.quantityOfApprovals'),
                                                style: 'documentHeader3',
                                            },
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.typeOfApproval'),
                                                style: 'documentHeader3',
                                            },
                                            {
                                                text: translate('protocol-iut-plan-builder.make-pdf.header.language'),
                                                style: 'documentHeader3',
                                            },
                                        ],
                                    ],
                                },
                                layout: {
                                    hLineWidth: function (i, node) {
                                        return i === 1 ? 1 : 0;
                                    },
                                    vLineWidth: function (i, node) {
                                        return 0;
                                    },

                                    hLineStyle: function (i, node) {
                                        if (i === 0 || i === node.table.body.length) {
                                            return null;
                                        }
                                        return { dash: { length: 3, space: 2 } };
                                    },
                                },
                            },
                        ],
                    ],
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i === 0 || i === 1 || i === node.table.body.length ? 1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 0;
                    },
                },
            },
        ];
    };
};

export const getDocumentFooter = (translate, plan, users, tileData, language) => {
    const technicalReferenceUser = users.find(x => x._id === plan.metadata.technicalReference);
    const technicalReferenceName = plan.metadata.technicalReference ? (technicalReferenceUser ? technicalReferenceUser.name : 'N/A') : '';

    const approverUser = users.find(x => x._id === get(plan.metadata, 'approvedBy.0.approverID'));
    const approverUserName = isArrayWithItems(plan.metadata.approvedBy) ? (approverUser ? approverUser.name : 'N/A') : '';

    const revisionEditorUser = users.find(x => x._id === plan.metadata.revisionEditor);
    const revisionEditorUserName = plan.metadata.revisionEditor ? (revisionEditorUser ? revisionEditorUser.name : 'N/A') : '';

    const editionDate =
        plan.metadata.status === documentStatus.released
            ? moment(plan.metadata.modifiedBy?.modifiedAt || new Date()).format(config.DEFAULT_DATE_FORMAT)
            : '';

    const legalOwnerLogo = plan.metadata.legalOwnerLogo?.mediaName
        ? tileData.find(
              x =>
                  x.mediaName === plan.metadata.legalOwnerLogo.mediaName ||
                  x.mediaName === getThumbFileName(plan.metadata.legalOwnerLogo.mediaName)
          )
        : null;
    const defaultLegalOwnerLogo =
        APP_CUSTOMER === reactAppCustomer.dw
            ? siemensPDFLogo
            : {
                  text: [],
                  colSpan: 3,
                  rowSpan: 2,
              };

    return (currentPage, pageCount, pageSize) => [
        {
            style: 'footer',
            color: '#444',
            table: {
                widths: [30, 30, 30, 30, 30, 30, 30, 30, 30, '*', 30, 30],
                heights: [20, 20, 20, 20],
                body: [
                    [
                        {
                            text: [
                                {
                                    text: `${translate('protocol-iut-plan-builder.make-pdf.footer.responsibleDepartment')}\n`,
                                    style: 'footerSmall',
                                },
                                { text: get(plan.metadata, 'responsibleDepartment.0') || '', style: 'footerNormal' },
                            ],
                            colSpan: 3,
                            rowSpan: 1,
                        },
                        {},
                        {},
                        {
                            text: [
                                {
                                    text: `${translate('protocol-iut-plan-builder.make-pdf.footer.technicalReference')}\n`,
                                    style: 'footerSmall',
                                },
                                { text: technicalReferenceName || '', style: 'footerNormal' },
                            ],
                            colSpan: 2,
                            rowSpan: 1,
                        },
                        {},
                        {
                            text: [
                                { text: `${translate('protocol-iut-plan-builder.make-pdf.footer.createdBy')}\n`, style: 'footerSmall' },
                                { text: revisionEditorUserName || '', style: 'footerNormal' },
                            ],
                            colSpan: 2,
                            rowSpan: 1,
                        },
                        {},
                        {
                            text: [
                                { text: `${translate('protocol-iut-plan-builder.make-pdf.footer.approvedBy')}\n`, style: 'footerSmall' },
                                { text: approverUserName || '', style: 'footerNormal' },
                            ],
                            colSpan: 2,
                            rowSpan: 1,
                        },
                        {},
                        {
                            text: [
                                { text: `${translate('protocol-iut-plan-builder.make-pdf.footer.project')}\n`, style: 'footerSmall' },
                                { text: plan.metadata.jobName || '', style: 'footerNormal' },
                            ],
                            colSpan: 3,
                            rowSpan: 1,
                        },
                        {},
                        {},
                    ],
                    [
                        legalOwnerLogo
                            ? {
                                  image: legalOwnerLogo.imageBase64,
                                  margin: [4, 0, 0, 0],
                                  fit: [100, 50],
                                  width: 100,
                                  height: 50,
                                  colSpan: 3,
                                  rowSpan: 2,
                              }
                            : defaultLegalOwnerLogo,
                        {},
                        {},
                        {
                            text: [
                                { text: `${translate('protocol-iut-plan-builder.make-pdf.footer.documentType')}\n`, style: 'footerSmall' },
                                { text: plan.metadata.documentType, style: 'footerNormal' },
                            ],
                            colSpan: 5,
                        },
                        {},
                        {},
                        {},
                        {},
                        {
                            text: [
                                {
                                    text: `${translate('protocol-iut-plan-builder.make-pdf.footer.documentStatus')}\n`,
                                    style: 'footerSmall',
                                },
                                { text: translate(`common.document-status.${plan.metadata.status}`), style: 'footerNormal' },
                            ],
                            colSpan: 2,
                        },
                        {},
                        {
                            text: [
                                { text: `${translate('protocol-iut-plan-builder.make-pdf.footer.customer')}\n`, style: 'footerSmall' },
                                { text: plan.metadata.customer || '', style: 'footerNormal' },
                            ],
                            colSpan: 2,
                            rowSpan: 2,
                        },
                        {},
                    ],
                    [
                        {},
                        {},
                        {},
                        {
                            text: [
                                { text: `${translate('protocol-iut-plan-builder.make-pdf.footer.title')}\n`, style: 'footerSmall' },
                                { text: getPlanInspectionTitleData(plan, language) || '', style: 'footerNormal' },
                            ],
                            colSpan: 5,
                            rowSpan: 2,
                        },
                        {},
                        {},
                        {},
                        {},
                        {
                            text: [
                                {
                                    text: `${translate('protocol-iut-plan-builder.make-pdf.footer.documentNumber')}\n`,
                                    style: 'footerSmall',
                                },
                                { text: plan.metadata.documentNumber, style: 'footerNormal' },
                            ],
                            colSpan: 2,
                        },
                        {},
                        {},
                        {},
                    ],
                    [
                        {
                            text: plan.metadata.legalOwner,
                            style: 'footerMedium',
                            colSpan: 3,
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {
                            text: [
                                { text: `${translate('protocol-iut-plan-builder.make-pdf.footer.revision')}\n`, style: 'footerSmall' },
                                { text: plan.metadata.revisionNumber, style: 'footerNormal' },
                            ],
                        },
                        {
                            text: [
                                { text: `${translate('protocol-iut-plan-builder.make-pdf.footer.date')}\n`, style: 'footerSmall' },
                                { text: editionDate || '', style: 'footerNormal' },
                            ],
                        },
                        {
                            text: [
                                { text: `${translate('protocol-iut-plan-builder.make-pdf.footer.language')}\n`, style: 'footerSmall' },
                                { text: language, style: 'footerNormal' },
                            ],
                        },
                        {
                            text: [
                                { text: `${translate('protocol-iut-plan-builder.make-pdf.footer.page.title')}\n`, style: 'footerSmall' },
                                {
                                    text: translate('protocol-iut-plan-builder.make-pdf.footer.page.number', {
                                        page: currentPage,
                                        count: pageCount,
                                    }),
                                    style: 'footerNormal',
                                },
                            ],
                        },
                    ],
                    [
                        {
                            text: translate('protocol-iut-plan-builder.make-pdf.footer.confidential'),
                            style: 'footerSmall',
                            alignment: 'center',
                            colSpan: 12,
                            border: [false, true, false, false],
                        },
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                ],
            },
            margin: [20, 20, 20, 20],
        },
    ];
};

export const getDocumentRevisionHistory = (translate, revisionHistory, users) => {
    return [
        {
            text: translate('protocol-template-builder.make-pdf.revisionHistory.label'),
            style: 'revisionHistoryLabel',
            margin: [32, 0, 32, 20],
        },
        {
            style: 'revisionHistoryTable',
            table: {
                widths: [50, 75, '*', 100],
                headerRows: 0,
                body: [
                    [
                        {
                            text: translate('protocol-iut-plan-builder.make-pdf.revisionHistory.revisionNumber'),
                            style: 'revisionHistoryTableHeader',
                        },
                        {
                            text: translate('protocol-iut-plan-builder.make-pdf.revisionHistory.revisionDate'),
                            style: 'revisionHistoryTableHeader',
                        },
                        {
                            text: translate('protocol-iut-plan-builder.make-pdf.revisionHistory.revisionComment'),
                            style: 'revisionHistoryTableHeader',
                        },
                        {
                            text: translate('protocol-iut-plan-builder.make-pdf.revisionHistory.editor'),
                            style: 'revisionHistoryTableHeader',
                        },
                    ],
                    ['', '', translate('protocol-iut-plan-builder.make-pdf.revisionHistory.zero-revision.comment'), ''],
                    ...revisionHistory.map(item => {
                        const revisionHistoryEditorUser = users.find(x => x._id === item.editor?.accountID);
                        const revisionHistoryEditorName = item.editor?.accountID ? revisionHistoryEditorUser?.name || 'N/A' : '';

                        const revisionDate = item.revisionDate ? moment(item.revisionDate).format(config.DEFAULT_DATE_FORMAT) : '';

                        return [item.revisionNumber || '', revisionDate, item.revisionComment || '', revisionHistoryEditorName];
                    }),
                ],
            },
            layout: 'lightHorizontalLines',
            margin: [32, 0, 32, 0],
            pageBreak: 'after',
        },
    ];
};

export const getDocumentStyles = () => {
    return {
        header: {
            fontSize: 24,
            bold: true,
            margin: [0, 0, 0, 24],
        },
        subHeader: {
            fontSize: 14,
            bold: true,
        },
        label: {
            fontSize: 12,
            bold: true,
        },
        documentHeader1: {
            fontSize: 10,
            bold: true,
        },
        documentHeader2: {
            fontSize: 9,
            bold: true,
        },
        documentHeader3: {
            fontSize: 8,
            bold: true,
        },
        documentHeader4: {
            fontSize: 8,
        },
        documentHeader5: {
            fontSize: 8,
            italics: true,
        },
        tableIutPlan: {
            margin: [20, 0, 20, 0],
            fontSize: 11,
            alignment: 'left',
        },
        metadata: {
            margin: [72, 0, 72, 0],
        },
        metadataLabel: {
            fontSize: 10,
        },
        metadataValue: {
            fontSize: 12,
        },
        descriptionTable: {
            fontSize: 10,
        },
        revisionHistoryLabel: {
            fontSize: 11,
            bold: true,
            alignment: 'left',
            margin: [0, 0, 0, 15],
        },
        revisionHistoryTable: {
            margin: [20, 15, 15, 20],
            fontSize: 11,
            alignment: 'left',
        },
        revisionHistoryTableHeader: {
            bold: false,
            fontSize: 11,
            color: 'black',
            fillColor: 'grey',
            fillOpacity: 0.1,
            alignment: 'left',
        },
        footerNormal: {
            fontSize: 10,
        },
        footerSmall: {
            fontSize: 6,
        },
        footerMedium: {
            fontSize: 9,
        },
    };
};

export const getProtocolFilteredTileData = (plan, tileData) => {
    let filteredTileData = [];

    if (plan.metadata.legalOwnerLogo?.mediaName) {
        const tile = findTileElement(plan.metadata.legalOwnerLogo?.mediaName, tileData);
        if (tile) {
            filteredTileData.push(tile);
        }
    }

    return filteredTileData;
};

export const getFilteredProtocolDocumentDefinition = (plan, exportType, selectedItems = null) => {
    return {
        ...plan,
        assemblyGroup: plan.assemblyGroup
            .map(assemblyGroup => {
                return {
                    ...assemblyGroup,
                    manufacturingStep: assemblyGroup.manufacturingStep
                        .map(manufacturingStep => {
                            return {
                                ...manufacturingStep,
                                inspectionOrders: manufacturingStep.inspectionOrders
                                    .filter(inspectionOrder => {
                                        return exportType === typeOfExport.external ? inspectionOrder.customerDocumentationRelevant : true;
                                    })
                                    .filter(inspectionOrder => {
                                        return selectedItems ? selectedItems[inspectionOrder.key] : true;
                                    }),
                            };
                        })
                        .filter(manufacturingStep => isArrayWithItems(manufacturingStep.inspectionOrders)),
                };
            })
            .filter(assemblyGroup => isArrayWithItems(assemblyGroup.manufacturingStep)),
    };
};
