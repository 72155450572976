import React from 'react';

// LIBS
import shortid from 'shortid';

// MATERIAL UI
import { ReactComponent as DocumentationCharacteristicOutlinedIcon } from '../../../../resources/icons/documentationCharacteristicOutlinedIcon.svg';

// UTILS
import { truncateString } from '../../../../utils/truncateHelper';

// CONSTANTS
import { groups } from './groups';
import { maxTemplateNameSize } from './setup';
import { configurations } from './configurations';
import { inputElements, inputElementSettings } from './inputElements';
import { reactAppCustomer } from '../../../../constants/Config';
import { APP_CUSTOMER } from '../../../../utils/env';

export const characteristics = {
    documentationCharacteristic: 'documentationCharacteristic',
    characteristicMediaOutputs: 'characteristicMediaOutputs',
    characteristicSignature: 'characteristicSignature',
};

export const characteristicSettings = {
    [characteristics.documentationCharacteristic]: {
        getConfigurations: (protocolTemplate, protocolElement) => {
            const { sectionType, elementIndex, documentationCharacteristicIndex } = protocolElement;
            const documentationCharacteristic =
                protocolTemplate.protocolSection[elementIndex].documentationCharacteristic[documentationCharacteristicIndex];

            let options = [];

            if (documentationCharacteristic.row === 0) {
                options.push(configurations.value);
                options.push(configurations.width);
            }

            if (sectionType === groups.table) {
                options.push(configurations.highlighted);

                if (APP_CUSTOMER === reactAppCustomer.gtcb) {
                    options.push(configurations.autoFillValue);
                    options.push(configurations.ddcExportFile);
                    options.push(configurations.ddcExportWorksheet);
                    options.push(configurations.ddcExportColumn);
                }
            }

            if (sectionType === groups.imageSection) {
                options.push(configurations.alignment);
            }

            return options;
        },
        icon: <DocumentationCharacteristicOutlinedIcon />,
        getOption: option => {
            const { templateName, templateId, type, row, column, ...template } = option;
            return {
                _id: templateId,
                value: characteristics.documentationCharacteristic,
                icon: characteristicSettings[characteristics.documentationCharacteristic].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [characteristics.characteristicMediaOutputs]: {
        getConfigurations: () => [configurations.stringLabel, configurations.mediaUpload],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                value: '',
            };
        },
        getOption: option => {
            const { templateName, type, row, column, ...template } = option;
            return {
                value: inputElements.mediaOutput,
                icon: inputElementSettings[inputElements.mediaOutput].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [characteristics.characteristicSignature]: {
        getConfigurations: () => [configurations.characteristicRequestedSignatureRole, configurations.characteristicSignatureRequired],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                requestedSignatureRole: '',
                signatureRequired: 'true',
            };
        },
        getOption: option => {
            const { templateName, templateId, type, row, column, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.sign,
                icon: inputElementSettings[inputElements.sign].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
};
