import { workshopsActions } from './workshops.actions';

const emptyFilters = {
    filters: {
        statusFilter: [],
        productionOrdersFilter: [],
        periodFilter: null,
        criticalTaskFilter: false,
        workshopsChips: [],
        isActiveWorkshopsChipsFilter: false,
    },
};

const workshopsInitialState = {
    ...emptyFilters,
    openedTicketModalIndex: false,
};

export const workshopsReducer = (state = workshopsInitialState, { type, payload }) => {
    switch (type) {
        case workshopsActions.SET_WORKSHOPS_CHIPS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    workshopsChips: payload,
                },
            };
        }

        case workshopsActions.SET_STATUS_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    statusFilter: payload,
                },
            };
        }
        case workshopsActions.SET_PROCESS_ORDERS_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    productionOrdersFilter: payload,
                },
            };
        }
        case workshopsActions.SET_PERIOD_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    periodFilter: payload,
                },
            };
        }
        case workshopsActions.SET_CRITICAL_TASK_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    criticalTaskFilter: payload,
                },
            };
        }
        case workshopsActions.SET_WORKSHOPS_CHIPS_FILTER: {
            const updatedWorkshopsChipsFilters = state.filters.workshopsChips.map(item => {
                if (item.id !== payload) {
                    return item;
                }

                return {
                    ...item,
                    isActive: !item.isActive,
                };
            });

            localStorage.setItem('workshopsChipsFilter', JSON.stringify(updatedWorkshopsChipsFilters));

            return {
                ...state,
                filters: {
                    ...state.filters,
                    workshopsChips: updatedWorkshopsChipsFilters,
                },
            };
        }
        case workshopsActions.SET_IS_ACTIVE_WORKSHOPS_CHIPS_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    isActiveWorkshopsChipsFilter: payload,
                },
            };
        }

        case workshopsActions.RESET_WORKSHOPS_FILTERS: {
            return {
                ...state,
                ...workshopsInitialState,
            };
        }

        case workshopsActions.TOOGLE_TICKET_MODAL: {
            return {
                ...state,
                openedTicketModalIndex: payload,
            };
        }

        default:
            return state;
    }
};
