import Compressor from 'compressorjs';

export const compressImage = (blob, quality, width, height) => {
    return new Promise((resolve, reject) => {
        new Compressor(
            blob,
            {
                quality: quality,
                width: width,
                height: height,
                success: result => {
                    return resolve(result)
                },
                error: err => {
                    return reject(err)
                }
            }
        )
    })
}