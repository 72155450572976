export const templateCreationActions = {
    CHANGE_SEARCH_TERM: 'TEMPLATE_CREATION/CHANGE_SEARCH_TERM',
    CHANGE_SELECTED_TYPES: 'TEMPLATE_CREATION/CHANGE_SELECTED_TYPES',
    SET_MODEL: 'TEMPLATE_CREATION/SET_MODEL',
    SET_SHOW_FILTER: 'TEMPLATE_CREATION/SET_SHOW_FILTER',
    CHANGE_DESCRIPTION_INPUT: 'TEMPLATE_CREATION/CHANGE_DESCRIPTION_INPUT',
    SET_SELECTED_ROUTINGS: 'TEMPLATE_CREATION/SET_SELECTED_ROUTINGS',
    SET_TEMPLATE_IMAGE_TILE_DATA: 'TEMPLATE_CREATION/SET_TEMPLATE_IMAGE_TILE_DATA',
    SET_TEMPLATE_IMAGE_FILE_NAME: 'TEMPLATE_CREATION/SET_TEMPLATE_IMAGE_FILE_NAME',
    RESET_TO_INITIAL_STATE: 'TEMPLATE_CREATION/RESET_TO_INITIAL_STATE',
    TOOGLE_DISABLE_OF_INPUTS: 'TEMPLATE_CREATION/TOOGLE_DISABLE_OF_INPUTS',
    SET_MATERIAL_ID: 'TEMPLATE_CREATION/SET_MATERIAL_ID',
    SET_PROJECT_ATTACHMENTS_ACTIVE_TAB: 'TEMPLATE_CREATION/SET_PROJECT_ATTACHMENTS_ACTIVE_TAB',
    SET_ATTACHMENT_TYPES: 'TEMPLATE_CREATION/SET_ATTACHMENT_TYPES',
    ADD_MEDIA: 'TEMPLATE_CREATION/ADD_MEDIA',
    CHANGE_MEDIA_ATTACHMENT_TYPE: 'TEMPLATE_CREATION/CHANGE_MEDIA_ATTACHMENT_TYPE',
    DELETE_MEDIA: 'TEMPLATE_CREATION/DELETE_MEDIA',
};

export const changeSearchTerm = payload => ({
    type: templateCreationActions.CHANGE_SEARCH_TERM,
    payload,
});

export const changeSelectedTypes = payload => ({
    type: templateCreationActions.CHANGE_SELECTED_TYPES,
    payload,
});

export const setModel = payload => ({
    type: templateCreationActions.SET_MODEL,
    payload,
});

export const changeDescriptionInput = payload => ({
    type: templateCreationActions.CHANGE_DESCRIPTION_INPUT,
    payload,
});

export const setSelectedRouting = payload => ({
    type: templateCreationActions.SET_SELECTED_ROUTINGS,
    payload,
});

export const setShowFilter = payload => ({
    type: templateCreationActions.SET_SHOW_FILTER,
    payload,
});

export const setTemplateImageFileName = payload => ({
    type: templateCreationActions.SET_TEMPLATE_IMAGE_FILE_NAME,
    payload,
});

export const setTemplateImageTileData = payload => ({
    type: templateCreationActions.SET_TEMPLATE_IMAGE_TILE_DATA,
    payload,
});

export const resetToInitialState = () => ({
    type: templateCreationActions.RESET_TO_INITIAL_STATE,
});

export const toogleDisableOfInputs = payload => ({
    type: templateCreationActions.TOOGLE_DISABLE_OF_INPUTS,
    payload,
});

export const setMaterialId = payload => ({
    type: templateCreationActions.SET_MATERIAL_ID,
    payload,
});

export const setProjectAttachmentsActiveTab = payload => ({
    type: templateCreationActions.SET_PROJECT_ATTACHMENTS_ACTIVE_TAB,
    payload,
});

export const setAttachmentTypes = payload => ({
    type: templateCreationActions.SET_ATTACHMENT_TYPES,
    payload,
});

export const addProjectTemplateMedia = payload => ({
    type: templateCreationActions.ADD_MEDIA,
    payload,
});

export const projectTemplateChangeMediaAttachmentType = payload => ({
    type: templateCreationActions.CHANGE_MEDIA_ATTACHMENT_TYPE,
    payload,
});

export const projectTemplateDeleteMedia = payload => ({
    type: templateCreationActions.DELETE_MEDIA,
    payload,
});
