import { projectDetailsActions } from './projectDetails.actions';

const projectDetailsInitialState = {
    searchTerm: null,
    activeProjectDetailsTabIndex: 0,
};

export const projectDetailsReducer = (state = projectDetailsInitialState, { type, payload }) => {
    switch (type) {
        case projectDetailsActions.SET_TAB_INDEX: {
            return {
                ...state,
                activeProjectDetailsTabIndex: payload,
            };
        }

        case projectDetailsActions.RESET_STATE: {
            return projectDetailsInitialState;
        }

        default:
            return state;
    }
};
