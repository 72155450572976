import React from 'react';

// LIBS
import i18next from 'i18next';
import shortid from 'shortid';

// ICONS
import { ReactComponent as TitleOutlinedIcon } from '../../../../resources/icons/titleOutlinedIcon.svg';
import { ReactComponent as HeadingOutlinedIcon } from '../../../../resources/icons/headingOutlinedIcon.svg';
import { ReactComponent as ListOutlinedIcon } from '../../../../resources/icons/listOutlinedIcon.svg';
import { ReactComponent as TableOutlinedIcon } from '../../../../resources/icons/tableOutlinedIcon.svg';
import { ReactComponent as HeaderGridOutlinedIcon } from '../../../../resources/icons/headerGridOutlinedIcon.svg';
import { ReactComponent as ParagraphOutlinedIcon } from '../../../../resources/icons/paragraphOutlinedIcon.svg';
import { ReactComponent as ImageSectionOutlinedIcon } from '../../../../resources/icons/imageSectionOutlinedIcon.svg';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';

// UTILS
import createMongoObjectId from '../../../../utils/createMongoObjID';
import { isArrayWithItems } from '../../../../utils/arrayHelper';
import { truncateString } from '../../../../utils/truncateHelper';

//CONSTANTS
import { inputElements } from './inputElements';
import { maxTemplateNameSize } from './setup';
import { characteristics } from './characteristics';
import { alignment } from './alignment';
import { appLanguage } from '../../../../constants/AppLanguage';
import { headerGridTranslations } from './translations';

export const groups = {
    title: 'title',
    sectionHeading: 'sectionHeading',
    sectionParagraph: 'sectionParagraph',
    headerGrid: 'headerGrid',
    table: 'table',
    list: 'list',
    imageSection: 'imageSection',
    footer: 'footer',
};

export const groupSettings = {
    [groups.title]: {
        icon: <TitleOutlinedIcon />,
        getConfigurations: () => [],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: groups.title,
                value: [],
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: groups.title,
                icon: groupSettings[groups.title].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
        doNotExport: true,
    },
    [groups.sectionHeading]: {
        icon: <HeadingOutlinedIcon />,
        getConfigurations: () => [],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: groups.sectionHeading,
                value: [],
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: groups.sectionHeading,
                icon: groupSettings[groups.sectionHeading].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [groups.sectionParagraph]: {
        icon: <ParagraphOutlinedIcon />,
        getConfigurations: () => [],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: groups.sectionParagraph,
                value: [],
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: groups.sectionParagraph,
                icon: groupSettings[groups.sectionParagraph].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [groups.headerGrid]: {
        allowedInputElements: [inputElements.staticText, inputElements.textInput],
        allowDropDocumentCharacteristic: true,
        icon: <HeaderGridOutlinedIcon />,
        getConfigurations: () => [],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: groups.headerGrid,
                rows: 3,
                columns: 3,
                documentationCharacteristic: [
                    {
                        _id: createMongoObjectId(),
                        row: 1,
                        column: 1,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.textInput,
                                label: [
                                    {
                                        language: appLanguage.en,
                                        value: headerGridTranslations.drawingNo[appLanguage.en],
                                    },
                                    {
                                        language: appLanguage.de,
                                        value: headerGridTranslations.drawingNo[appLanguage.de],
                                    },
                                ],
                                defaultValue: '',
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                    {
                        _id: createMongoObjectId(),
                        row: 1,
                        column: 2,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.textInput,
                                label: [
                                    {
                                        language: appLanguage.en,
                                        value: headerGridTranslations.drawingIndex[appLanguage.en],
                                    },
                                    {
                                        language: appLanguage.de,
                                        value: headerGridTranslations.drawingIndex[appLanguage.de],
                                    },
                                ],
                                defaultValue: '',
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                    {
                        _id: createMongoObjectId(),
                        row: 1,
                        column: 3,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.textInput,
                                label: [
                                    {
                                        language: appLanguage.en,
                                        value: headerGridTranslations.supplier[appLanguage.en],
                                    },
                                    {
                                        language: appLanguage.de,
                                        value: headerGridTranslations.supplier[appLanguage.de],
                                    },
                                ],
                                defaultValue: '',
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                    {
                        _id: createMongoObjectId(),
                        row: 2,
                        column: 1,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.textInput,
                                label: [
                                    {
                                        language: appLanguage.en,
                                        value: headerGridTranslations.purchaseOrderNo[appLanguage.en],
                                    },
                                    {
                                        language: appLanguage.de,
                                        value: headerGridTranslations.purchaseOrderNo[appLanguage.de],
                                    },
                                ],
                                defaultValue: '',
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                    {
                        _id: createMongoObjectId(),
                        row: 2,
                        column: 2,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.textInput,
                                label: [
                                    {
                                        language: appLanguage.en,
                                        value: headerGridTranslations.purchasePositionNo[appLanguage.en],
                                    },
                                    {
                                        language: appLanguage.de,
                                        value: headerGridTranslations.purchasePositionNo[appLanguage.de],
                                    },
                                ],
                                defaultValue: '',
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                    {
                        _id: createMongoObjectId(),
                        row: 2,
                        column: 3,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.textInput,
                                label: [
                                    {
                                        language: appLanguage.en,
                                        value: headerGridTranslations.projectNoSupplier[appLanguage.en],
                                    },
                                    {
                                        language: appLanguage.de,
                                        value: headerGridTranslations.projectNoSupplier[appLanguage.de],
                                    },
                                ],
                                defaultValue: '',
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                    {
                        _id: createMongoObjectId(),
                        row: 3,
                        column: 1,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.textInput,
                                label: [
                                    {
                                        language: appLanguage.en,
                                        value: headerGridTranslations.itemIdentification[appLanguage.en],
                                    },
                                    {
                                        language: appLanguage.de,
                                        value: headerGridTranslations.itemIdentification[appLanguage.de],
                                    },
                                ],
                                defaultValue: '',
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                    {
                        _id: createMongoObjectId(),
                        row: 3,
                        column: 2,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.textInput,
                                label: [
                                    {
                                        language: appLanguage.en,
                                        value: headerGridTranslations.serviceNotificationNumber[appLanguage.en],
                                    },
                                    {
                                        language: appLanguage.de,
                                        value: headerGridTranslations.serviceNotificationNumber[appLanguage.de],
                                    },
                                ],
                                defaultValue: '',
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                    {
                        _id: createMongoObjectId(),
                        row: 3,
                        column: 3,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.textInput,
                                label: [
                                    {
                                        language: appLanguage.en,
                                        value: headerGridTranslations.originalOrder[appLanguage.en],
                                    },
                                    {
                                        language: appLanguage.de,
                                        value: headerGridTranslations.originalOrder[appLanguage.de],
                                    },
                                ],
                                defaultValue: '',
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                ],
            };
        },
        canDrop: (inputs, item, isAlreadyExist = false) => {
            const itemType = isAlreadyExist ? item.type : item.value;

            if (groupSettings[groups.headerGrid].allowedInputElements.some(element => element === itemType)) {
                if (!isArrayWithItems(inputs)) {
                    return true;
                }
            }

            if (itemType === characteristics.documentationCharacteristic) {
                if (!isArrayWithItems(inputs) && isArrayWithItems(item.template?.inputElement)) {
                    const allInputsAllowed = item.template.inputElement.every(inputElement =>
                        groupSettings[groups.headerGrid].allowedInputElements.some(element => element === inputElement.type)
                    );

                    if (allInputsAllowed) {
                        if (item.template.inputElement.length === 1) {
                            return true;
                        }
                    }
                }
            }

            return false;
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: groups.headerGrid,
                icon: groupSettings[groups.headerGrid].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [groups.table]: {
        allowedInputElements: [
            inputElements.staticText,
            inputElements.person,
            inputElements.date,
            inputElements.textInput,
            inputElements.radio,
            inputElements.mediaInput,
            inputElements.document,
            inputElements.dropdown,
            inputElements.formattedTextInput,
        ],
        allowDropDocumentCharacteristic: true,
        icon: <TableOutlinedIcon />,
        getConfigurations: () => [],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: groups.table,
                rows: 1,
                columns: 2,
                documentationCharacteristic: [
                    {
                        _id: createMongoObjectId(),
                        row: 0,
                        column: 1,
                        value: [],
                    },
                    {
                        _id: createMongoObjectId(),
                        row: 0,
                        column: 2,
                        value: [],
                    },
                    {
                        _id: createMongoObjectId(),
                        row: 1,
                        column: 1,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.textInput,
                                label: [],
                                defaultValue: '',
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                    {
                        _id: createMongoObjectId(),
                        row: 1,
                        column: 2,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.radio,
                                label: [],
                                checked: undefined,
                                relevantForValidation: 'true',
                            },
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.radio,
                                label: [],
                                checked: undefined,
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                ],
                mediaOutputs: null,
                signedBy: null,
            };
        },
        canDrop: (inputs, item, isAlreadyExist = false) => {
            const itemType = isAlreadyExist ? item.type : item.value;

            if (groupSettings[groups.table].allowedInputElements.some(element => element === itemType)) {
                if (!isArrayWithItems(inputs)) {
                    return true;
                }

                const type = inputs[0].type;
                if (itemType === inputElements.radio && type === inputElements.radio) {
                    return true;
                }
            }

            if (itemType === characteristics.documentationCharacteristic) {
                if (!isArrayWithItems(inputs) && isArrayWithItems(item.template?.inputElement)) {
                    const allInputsAllowed = item.template.inputElement.every(inputElement =>
                        groupSettings[groups.table].allowedInputElements.some(element => element === inputElement.type)
                    );

                    if (allInputsAllowed) {
                        const type = item.template.inputElement[0].type;
                        const allInputsSameType = item.template.inputElement.every(inputElement => inputElement.type === type);

                        if (allInputsSameType) {
                            if (item.template.inputElement.length === 1 || type === inputElements.radio) {
                                return true;
                            }
                        }
                    }
                }
            }

            return false;
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: groups.table,
                icon: groupSettings[groups.table].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [groups.list]: {
        allowedInputElements: [
            inputElements.staticText,
            inputElements.person,
            inputElements.date,
            inputElements.textInput,
            inputElements.radio,
            inputElements.mediaInput,
            inputElements.document,
            inputElements.mediaOutput,
            inputElements.dropdown,
            inputElements.sign,
            inputElements.formattedTextInput,
        ],
        icon: <ListOutlinedIcon />,
        allowOverflow: true,
        getConfigurations: () => [],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: groups.list,
                mediaOutputs: null,
                documentationCharacteristic: [
                    {
                        _id: createMongoObjectId(),
                        subtitle: { key: shortid.generate, _id: createMongoObjectId(), value: [] },
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.textInput,
                                label: [],
                                defaultValue: '',
                                relevantForValidation: 'true',
                            },
                        ],
                    },
                ],
            };
        },
        canDrop: (inputs, item, isAlreadyExist = false) => {
            const itemType = isAlreadyExist ? item.type : item.value;

            if (groupSettings[groups.list].allowedInputElements.some(element => element === itemType)) {
                return true;
            }

            if (itemType === characteristics.documentationCharacteristic) {
                if (!isArrayWithItems(inputs)) {
                    const allInputsAllowed = isArrayWithItems(item.template?.inputElement)
                        ? item.template.inputElement.every(inputElement =>
                              groupSettings[groups.list].allowedInputElements.some(element => element === inputElement.type)
                          )
                        : true;

                    if (allInputsAllowed) {
                        return true;
                    }
                }
            }

            return false;
        },
        getDefaultMessage: () => {
            return i18next.t('protocol-template-builder.right-panel.groups.list.default-message');
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: groups.list,
                icon: groupSettings[groups.list].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [groups.imageSection]: {
        allowedInputElements: [inputElements.mediaOutput],
        icon: <ImageSectionOutlinedIcon />,
        allowOverflow: true,
        getConfigurations: () => [],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: groups.imageSection,
                mediaOutputs: null,
                documentationCharacteristic: [
                    {
                        _id: createMongoObjectId(),
                        alignment: alignment.left,
                        inputElement: [
                            {
                                key: shortid.generate(),
                                _id: createMongoObjectId(),
                                type: inputElements.mediaOutput,
                                label: [],
                                checked: undefined,
                            },
                        ],
                    },
                ],
            };
        },
        canDrop: (inputs, item, isAlreadyExist = false) => {
            const itemType = isAlreadyExist ? item.type : item.value;

            if (groupSettings[groups.imageSection].allowedInputElements.some(element => element === itemType)) {
                return true;
            }

            if (itemType === characteristics.documentationCharacteristic) {
                if (!isArrayWithItems(inputs)) {
                    const allInputsAllowed = isArrayWithItems(item.template?.inputElement)
                        ? item.template.inputElement.every(inputElement =>
                              groupSettings[groups.imageSection].allowedInputElements.some(element => element === inputElement.type)
                          )
                        : true;

                    if (allInputsAllowed) {
                        return true;
                    }
                }
            }

            return false;
        },
        getDefaultMessage: () => {
            return i18next.t('protocol-template-builder.right-panel.groups.imageSection.default-message');
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: groups.imageSection,
                icon: groupSettings[groups.imageSection].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [groups.footer]: {
        icon: <VerticalAlignBottomIcon />,
        getConfigurations: () => [],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: groups.footer,
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: groups.footer,
                icon: groupSettings[groups.footer].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
};
