import update from '../../../redux/update';
import { viewMode, viewType } from '../../../constants/ViewType';

const actions = {
    initDataViewAction: 'DYNAMIC_DATA/INIT_DATA_VIEW',
    clearFilter: 'DYNAMIC_DATA/CLEAR_FILTER',
    handleValueChange: 'DYNAMIC_DATA/HANDLE_VALUE_CHANGE',
};

const getInitialState = () => {
    return {
        // Safety
        ehsTickets: {
            viewType: viewType.table,
            viewMode: viewMode.default,
            searchTerm: '',
            isFilterButtonActive: false,
            isMyItemsButtonActive: false,
            filters: {
                archived: false,
                deleted: false,
            },
            editModeActive: false,
            selectedItems: [],
            carouselSettings: {
                defaultActiveItem: null,
            },
        },
        ehsTicketsWorkbook: {
            viewType: viewType.table,
            viewMode: viewMode.default,
            searchTerm: '',
            isFilterButtonActive: false,
            isMyItemsButtonActive: true,
            filters: {
                archived: false,
                deleted: false,
            },
            editModeActive: false,
            selectedItems: [],
            carouselSettings: {
                defaultActiveItem: null,
            },
        },
        safetyWalks: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            isMyItemsButtonActive: false,
            filters: {
                archived: false,
            },
            editModeActive: false,
            selectedItems: [],
        },
        questions: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        audits: {
            viewType: viewType.list,
            sortKey: 'dueDate',
            searchTerm: '',
            isFilterButtonActive: false,
            isMyItemsButtonActive: false,
            activeTab: 'active',
            filters: {},
        },

        // Maintenance
        maintenanceTickets: {
            viewType: viewType.kanban,
            viewMode: viewMode.default,
            searchTerm: '',
            isFilterButtonActive: false,
            isMyItemsButtonActive: false,
            filters: {
                archived: false,
                deleted: false,
            },
            editModeActive: false,
            selectedItems: [],
        },
        maintenanceTicketsVWPWK: {
            viewType: viewType.kanban,
            viewMode: viewMode.bucket,
            searchTerm: '',
            isFilterButtonActive: false,
            isMyItemsButtonActive: false,
            filters: {
                archived: false,
                deleted: false,
            },
            editModeActive: false,
            selectedItems: [],
        },
        maintenanceTicketAttachments: {
            viewType: viewType.card,
            activeTab: 'all',
            searchTerm: '',
            filters: {},
        },
        maintenanceAssets: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        maintenanceAssetTickets: {
            viewType: viewType.card,
            activeTab: 'open',
        },
        maintenanceAssetAttachments: {
            viewType: viewType.table,
            activeTab: 'all',
            activeContainer: 'all',
            searchTerm: '',
            filters: {},
        },
        maintenanceFailureCategories: {
            viewType: viewType.table,
        },
        maintenanceFailureCategoriesSelect: {
            viewType: viewType.table,
        },
        maintenanceShiftbookTickets: {
            viewType: viewType.list,
            filters: {
                archived: false,
                deleted: false,
            },
        },
        ticketChecklistsSelect: {
            viewType: viewType.table,
        },

        // Machine Maintenance
        machineMaintenanceTickets: {
            viewType: viewType.table,
            viewMode: viewMode.bucket,
            searchTerm: '',
            isFilterButtonActive: false,
            isMyItemsButtonActive: false,
            filters: {
                archived: false,
                deleted: false,
            },
            editModeActive: false,
            selectedItems: [],
        },
        machineMaintenanceTicketAttachments: {
            viewType: viewType.card,
            searchTerm: '',
            filters: {},
        },
        machineMaintenanceAssets: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        machineMaintenanceAssetParts: {
            viewType: viewType.card,
        },
        machineMaintenanceAssetTickets: {
            viewType: viewType.card,
            activeTab: 'open',
        },
        machineMaintenanceAssetAttachments: {
            viewType: viewType.card,
            activeTab: 'all',
            activeContainer: 'all',
            searchTerm: '',
            filters: {},
        },
        machineMaintenanceFailureCategories: {
            viewType: viewType.table,
        },
        machineMaintenanceFailureCategoriesSelect: {
            viewType: viewType.table,
        },
        machineMaintenanceShiftbookTickets: {
            viewType: viewType.list,
            filters: {
                archived: false,
                deleted: false,
            },
        },
        machineMaintenanceSchedulers: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },

        // Maintenance Standard
        maintenanceStandardTickets: {
            viewType: viewType.kanban,
            viewMode: viewMode.default,
            searchTerm: '',
            isFilterButtonActive: false,
            isMyItemsButtonActive: false,
            filters: {
                archived: false,
                deleted: false,
            },
            editModeActive: false,
            selectedItems: [],
        },
        maintenanceStandardTicketAttachments: {
            viewType: viewType.card,
            searchTerm: '',
            filters: {},
        },
        maintenanceStandardAssets: {
            viewType: viewType.card,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        maintenanceStandardAssetParts: {
            viewType: viewType.card,
        },
        maintenanceStandardAssetTickets: {
            viewType: viewType.card,
            activeTab: 'open',
        },
        maintenanceStandardAssetAttachments: {
            viewType: viewType.card,
            activeTab: 'all',
            activeContainer: 'all',
            searchTerm: '',
            filters: {},
        },
        maintenanceStandardFailureCategories: {
            viewType: viewType.table,
        },
        maintenanceStandardFailureCategoriesSelect: {
            viewType: viewType.table,
        },
        maintenanceStandardShiftbookTickets: {
            viewType: viewType.list,
            filters: {
                archived: false,
                deleted: false,
            },
        },
        maintenanceStandardSchedulers: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },

        // Protocol
        protocols: {
            viewType: viewType.kanban,
            viewMode: viewMode.bucket,
            searchTerm: '',
            isFilterButtonActive: false,
            activeTab: 'all',
            filters: {
                archived: false,
            },
            editModeActive: false,
            selectedItems: [],
        },
        templates: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        plans: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: { archived: false },
            editModeActive: false,
            selectedItems: [],
        },
        planTemplatesSelect: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
        },
        planProtocolsSelect: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
        },
        planTemplates: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        planTemplateProtocolsSelect: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
        },

        // Other
        appVersions: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
        },
        users: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        categories: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        locations: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        departments: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        failureCategories: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        ticketChecklists: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        attachmentTypes: {
            viewType: viewType.table,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        adminEhsTickets: {
            viewType: viewType.table,
            viewMode: viewMode.default,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            editModeActive: false,
            selectedItems: [],
        },
        adminMaintenanceTickets: {
            viewType: viewType.table,
            viewMode: viewMode.default,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {
                archived: false,
                deleted: false,
            },
            editModeActive: false,
            selectedItems: [],
        },
        adminMachineMaintenanceTickets: {
            viewType: viewType.table,
            viewMode: viewMode.default,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {
                archived: false,
                deleted: false,
            },
            editModeActive: false,
            selectedItems: [],
        },
        adminMaintenanceStandardTickets: {
            viewType: viewType.table,
            viewMode: viewMode.default,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {
                archived: false,
                deleted: false,
            },
            editModeActive: false,
            selectedItems: [],
        },
        adminTags: {
            filters: { searchTerm: '' },
            tags: [],
        },
        workplaces: {
            viewType: viewType.card,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
            activeTab: 'Hall 1',
        },
        workplaceOrders: {
            viewType: viewType.list,
            searchTerm: '',
            isFilterButtonActive: false,
            filters: {},
        },
    };
};

export const dynamicDataReducer = (state = getInitialState(), action) => {
    let newState = state;

    switch (action.type) {
        case actions.initDataViewAction: {
            const { entity } = action.payload;
            const initialState = getInitialState();

            newState = update.set(state, entity, initialState[entity]);
            break;
        }

        case actions.clearFilter: {
            const { entity } = action.payload;
            const initialState = getInitialState();

            newState = update.set(state, `${entity}.filters`, initialState[entity].filters);
            newState = update.set(newState, `${entity}.isMyItemsButtonActive`, false);
            newState = update.set(newState, `${entity}.searchTerm`, '');
            newState = update.set(newState, `${entity}.selectedItems`, []);
            break;
        }

        case actions.handleValueChange: {
            const { entity, path, value } = action.payload;
            newState = update.set(state, `${entity}.${path}`, value);
            break;
        }

        default:
            break;
    }

    return newState;
};

export const initDataViewAction = entity => {
    return {
        type: actions.initDataViewAction,
        payload: {
            entity,
        },
    };
};

export const handleValueChangeAction = (entity, path, value) => {
    return {
        type: actions.handleValueChange,
        payload: {
            entity,
            path,
            value,
        },
    };
};

export const clearFilterAction = entity => {
    return {
        type: actions.clearFilter,
        payload: {
            entity,
        },
    };
};
