const RejectedIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.72998 21.0005H21.26C22.03 21.0005 22.51 20.1705 22.13 19.5005L12.86 3.50055C12.47 2.83055 11.51 2.83055 11.13 3.50055L1.85998 19.5005C1.47998 20.1705 1.95998 21.0005 2.72998 21.0005ZM13 18.0005H11V16.0005H13V18.0005ZM12 14.0005C11.45 14.0005 11 13.5505 11 13.0005V11.0005C11 10.4505 11.45 10.0005 12 10.0005C12.55 10.0005 13 10.4505 13 11.0005V13.0005C13 13.5505 12.55 14.0005 12 14.0005Z"
                fill="#F5325C"
            />
        </svg>
    );
};

export default RejectedIcon;
