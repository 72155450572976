import { AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER } from "../utils/env";

const DDCExportFiles = {

    //Version 2 of DDC starts here, V3 , new is V4
    DDC_Basket: {
        label: "BK_V4",
        value: `${AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER}/BK_V4.xlsx`
    },
    DDC_PilotNozzle: {
        label: "Pilot Nozzle.xlsx",
        value: `${AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER}/Pilot Nozzle.xlsx`
    },
    DDC_CPFS: {
        label: "CPFS_V4.xls",
        value: `${AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER}/CPFS_V4.xls`
    },
    DDC_SupportHousing: {
        label: "SH_V4.xlsx",
        value: `${AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER}/SH_V4.xls`
    },
    DDC_Transitions: {
        label: "TP_V4.xlsx",
        value: `${AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER}/TP_V4.xlsx`
    }


};

const DDCExportWorksheets = {
    QRR0010: "QRR0010",
    QRR0020: "QRR0020",
    QRR0030: "QRR0030",
    QRR0040: "QRR0040",
    QRR0045: "QRR0045",
    QRR0046: "QRR0046",
    QRR0050: "QRR0050",
    QRR0055: "QRR0055",
    QRR0060: "QRR0060",
    QRR0070: "QRR0070",
    QRR0080: "QRR0080",
    QRR0090: "QRR0090",
    QRR0100: "QRR0100",
    QRR0110: "QRR0110",
    QRR0130: "QRR0130"
};

export const DDCExportOptions = [
  
    // DDC Version 2 starts here
    //Baskets
    {
        translation: DDCExportFiles.DDC_Basket.label,
        value: DDCExportFiles.DDC_Basket.value,
        worksheets: [
            {
                translation: DDCExportWorksheets.QRR0010,
                value: DDCExportWorksheets.QRR0010,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_SPECIALFINDINGS", value: "IA_SPECIALFINDINGS" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0020,
                value: DDCExportWorksheets.QRR0020,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_02_HEAD_END", value: "IA_02_HEAD_END" },
                    { translation: "IA_04_FLOW_CONDITIONER", value: "IA_04_FLOW_CONDITIONER" },
                    { translation: "IA_05_PILOT_ANNULUS_WEAR", value: "IA_05_PILOT_ANNULUS_WEAR" },
                    { translation: "IA_06_ANNULUS_CUPS", value: "IA_06_ANNULUS_CUPS" },
                    { translation: "IA_07_BASE_PLATE", value: "IA_07_BASE_PLATE" },
                    { translation: "IA_08_PILOT_CONE", value: "IA_08_PILOT_CONE" },
                    { translation: "IA_09_TC_TUBE", value: "IA_09_TC_TUBE" },
                    { translation: "IA_10_DP_TUBE", value: "IA_10_DP_TUBE" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0030,
                value: DDCExportWorksheets.QRR0030,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_11_CRACKS", value: "IA_11_CRACKS"},
                    {translation: "IA_11_GENERAL", value: "IA_11_GENERAL"}

                ]
            },
            {
                translation: DDCExportWorksheets.QRR0040,
                value: DDCExportWorksheets.QRR0040,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_12_CRACKS", value: "IA_12_CRACKS"},
                    {translation: "IA_12_GENERAL", value: "IA_12_GENERAL"}
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0050,
                value: DDCExportWorksheets.QRR0050,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_13_CRACKS", value: "IA_13_CRACKS"},
                    {translation: "IA_13_GENERAL", value: "IA_13_GENERAL"},
                    {translation: "IA_13_MATERIAL_LOSS", value: "IA_13_MATERIAL_LOSS"},
                    {translation: "IA_13_RUNOUT_POS_1", value: "IA_13_RUNOUT_POS_1"},
                    {translation: "IA_13_RUNOUT_POS_2", value: "IA_13_RUNOUT_POS_2"},
                    {translation: "IA_13_RUNOUT_POS_3", value: "IA_13_RUNOUT_POS_3"},
                    {translation: "IA_13_RUNOUT_POS_4", value: "IA_13_RUNOUT_POS_4"}
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0060,
                value: DDCExportWorksheets.QRR0060,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_13_MATERIAL_THICKNESS_COOLINGRING", value: "IA_13_MATERIAL_THICKNESS_COOLINGRING"},
                    {translation: "IA_13_MATERIAL_THICKNESS_LINER", value: "IA_13_MATERIAL_THICKNESS_LINER"},
                    {translation: "IA_13_MATERIAL_THICKNESS_WELDSEAM", value: "IA_13_MATERIAL_THICKNESS_WELDSEAM"}
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0070,
                value: DDCExportWorksheets.QRR0070,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_16_CRACKS", value: "IA_16_CRACKS"},
                    {translation: "IA_16_GENERAL", value: "IA_16_GENERAL"},
                    {translation: "IA_16_MATERIAL_THICKNESS_MIN", value: "IA_16_MATERIAL_THICKNESS_MIN"}
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0080,
                value: DDCExportWorksheets.QRR0080,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_REPAIR_EVALUATION", value: "IA_REPAIR_EVALUATION"}
                    
                ]
            }

        ]
    },
    // Pilot Nozzle
    {
        translation: DDCExportFiles.DDC_PilotNozzle.label,
        value: DDCExportFiles.DDC_PilotNozzle.value,
        worksheets: [
            {
                translation: DDCExportWorksheets.QRR0010,
                value: DDCExportWorksheets.QRR0010,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_INCOMING_DONE",
                        value: "IA_INCOMING_DONE"
                    },
                    {
                        translation: "IA_SPECIALFINDINGS",
                        value: "IA_SPECIALFINDINGS"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0020,
                value: DDCExportWorksheets.QRR0020,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_VT_FINDINGS",
                        value: "IA_VT_FINDINGS"
                    },
                    {
                        translation: "IA_FPI_FINDINGS",
                        value: "IA_FPI_FINDINGS"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0030,
                value: DDCExportWorksheets.QRR0030,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_CMM_PIL_SEALING_SURF_FLATNESS_DATUM_B",
                        value: "IA_CMM_PIL_SEALING_SURF_FLATNESS_DATUM_B"
                    },
                    {
                        translation: "IA_CMM_POS-DIFF-GAS",
                        value: "IA_CMM_POS-DIFF-GAS"
                    },
                    {
                        translation: "IA_CMM_POS_PREMIX_GAS",
                        value: "IA_CMM_POS_PREMIX_GAS"
                    },
                    {
                        translation: "IA_CMM_PILOT_SWIRLER_RUN_OUT",
                        value: "IA_CMM_PILOT_SWIRLER_RUN_OUT"
                    },
                    {
                        translation: "IA_CMM_TIP_HOUSING_INNER_DIAMETER",
                        value: "IA_CMM_TIP_HOUSING_INNER_DIAMETER"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0040,
                value: DDCExportWorksheets.QRR0040,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_AIRFLOW_DIFFUSION_INCOMING_CORRECTED",
                        value: "IA_AIRFLOW_DIFFUSION_INCOMING_CORRECTED"
                    },
                    {
                        translation: "IA_AIRFLOW_DIFFUSION_INCOMING_ORIFICE",
                        value: "IA_AIRFLOW_DIFFUSION_INCOMING_ORIFICE"
                    },
                    {
                        translation: "IA_AIRFLOW_PREMIX_INCOMING_CORRECTED",
                        value: "IA_AIRFLOW_PREMIX_INCOMING_CORRECTED"
                    },
                    {
                        translation: "IA_AIRFLOW_PREMIX_INCOMING_ORIFICE",
                        value: "IA_AIRFLOW_PREMIX_INCOMING_ORIFICE"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0050,
                value: DDCExportWorksheets.QRR0050,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_ENDOSCOPY",
                        value: "IA_ENDOSCOPY"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0060,
                value: DDCExportWorksheets.QRR0060,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_FUNCTIONAL_FIT_CHECK_OIL",
                        value: "IA_FUNCTIONAL_FIT_CHECK_OIL"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0070,
                value: DDCExportWorksheets.QRR0070,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_REPAIR_EVALUATION", value: "IA_REPAIR_EVALUATION" },
                    { translation: "FINAL_INSPECTION_2000099066", value: "FINAL_INSPECTION_2000099066" },
                    { translation: "TIPCOVER_CHANGE_ONLY_2000099042", value: "TIPCOVER_CHANGE_ONLY_2000099042" },
                    { translation: "STUTZEN_RICHTEN_2000093000", value: "STUTZEN_RICHTEN_2000093000" },
                    { translation: "STUTZEN_TAUSCH_2000098200", value: "STUTZEN_TAUSCH_2000098200" },
                    { translation: "REP_ALLGEMEIN_2000000003", value: "REP_ALLGEMEIN_2000000003" },
                ]
            }
        ]
    },
    //CPFS
    {
        translation: DDCExportFiles.DDC_CPFS.label,
        value: DDCExportFiles.DDC_CPFS.value,
        worksheets: [
            {
                translation: DDCExportWorksheets.QRR0010,
                value: DDCExportWorksheets.QRR0010,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    { translation: "IA_SPECIALFINDINGS", value: "IA_SPECIALFINDINGS" },
                    { translation: "IA_THREAD_CHECK", value: "IA_THREAD_CHECK" },
                    { translation: "IA_FUEL_STAGE (C-Stage)", value: "IA_FUEL_STAGE (C-Stage)" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0020,
                value: DDCExportWorksheets.QRR0020,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_PRESSURE_TEST", value: "IA_PRESSURE_TEST" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0030,
                value: DDCExportWorksheets.QRR0030,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_AIRFLOW_MEASURE_GAS", value: "IA_AIRFLOW_MEASURE_GAS" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0040,
                value: DDCExportWorksheets.QRR0040,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_TRHEAD_CHECK", value: "IA_TRHEAD_CHECK" },
                    { translation: "IA_OTHER_FINDINGS", value: "IA_OTHER_FINDINGS" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0050,
                value: DDCExportWorksheets.QRR0050,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_REPAIR_EVALUATION", value: "IA_REPAIR_EVALUATION" }
                ]
            }

        ]
    },
    //Support Housing
    {
        translation: DDCExportFiles.DDC_SupportHousing.label,
        value: DDCExportFiles.DDC_SupportHousing.value,
        worksheets: [
            {
                translation: DDCExportWorksheets.QRR0010,
                value: DDCExportWorksheets.QRR0010,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_SPECIALFINDINGS", value: "IA_SPECIALFINDINGS" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0020,
                value: DDCExportWorksheets.QRR0020,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_AIRFLOW_GAS_A", value: "IA_AIRFLOW_GAS_A" },
                    { translation: "IA_AIRFLOW_GAS_B", value: "IA_AIRFLOW_GAS_B" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0030,
                value: DDCExportWorksheets.QRR0030,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_AIRFLOW_OIL_A", value: "IA_AIRFLOW_OIL_A" },
                    { translation: "IA_AIRFLOW_OIL_B", value: "IA_AIRFLOW_OIL_B" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0040,
                value: DDCExportWorksheets.QRR0040,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_AIRFLOW_WATER_A", value: "IA_AIRFLOW_WATER_A" },
                    { translation: "IA_AIRFLOW_WATER_B", value: "IA_AIRFLOW_WATER_B" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0050,
                value: DDCExportWorksheets.QRR0050,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    { translation: "IA_CMM_SEALING_SURFACE_A", value: "IA_CMM_SEALING_SURFACE_A" },
                    { translation: "IA_CMM_DIAMETER_MANIFOLD", value: "IA_CMM_DIAMETER_MANIFOLD" },
                    { translation: "IA_CMM_DISTANCE_R1", value: "IA_CMM_DISTANCE_R1" },
                    { translation: "IA_CMM_DISTANCE_R2", value: "IA_CMM_DISTANCE_R2" },
                    { translation: "IA_CMM_DISTANCE_R3", value: "IA_CMM_DISTANCE_R3" },
                    { translation: "IA_CMM_DISTANCE_R4", value: "IA_CMM_DISTANCE_R4" },
                    { translation: "IA_CMM_DISTANCE_R5", value: "IA_CMM_DISTANCE_R5" },
                    { translation: "IA_CMM_DISTANCE_R6", value: "IA_CMM_DISTANCE_R6" },
                    { translation: "IA_CMM_DISTANCE_R7", value: "IA_CMM_DISTANCE_R7" },
                    { translation: "IA_CMM_DISTANCE_R8", value: "IA_CMM_DISTANCE_R8" },
                    { translation: "IA_CMM_POSITION_R1", value: "IA_CMM_POSITION_R1" },
                    { translation: "IA_CMM_POSITION_R2", value: "IA_CMM_POSITION_R2" },
                    { translation: "IA_CMM_POSITION_R3", value: "IA_CMM_POSITION_R3" },
                    { translation: "IA_CMM_POSITION_R4", value: "IA_CMM_POSITION_R4" },
                    { translation: "IA_CMM_POSITION_R5", value: "IA_CMM_POSITION_R5" },
                    { translation: "IA_CMM_POSITION_R6", value: "IA_CMM_POSITION_R6" },
                    { translation: "IA_CMM_POSITION_R7", value: "IA_CMM_POSITION_R7" },
                    { translation: "IA_CMM_POSITION_R8", value: "IA_CMM_POSITION_R8" },
                    { translation: "IA_CMM_POSITION_GAS_A", value: "IA_CMM_POSITION_GAS_A" },
                    { translation: "IA_CMM_DISTANCE_GAS_A", value: "IA_CMM_DISTANCE_GAS_A" },
                    { translation: "IA_CMM_PARALISM_GAS_A", value: "IA_CMM_PARALISM_GAS_A" },
                    { translation: "IA_CMM_POSITION_GAS_B", value: "IA_CMM_POSITION_GAS_B" },
                    { translation: "IA_CMM_DISTANCE_GAS_B", value: "IA_CMM_DISTANCE_GAS_B" },
                    { translation: "IA_CMM_PARALISM_GAS_B", value: "IA_CMM_PARALISM_GAS_B" },
                    { translation: "IA_CMM_DISTANCE_OIL_B", value: "IA_CMM_DISTANCE_OIL_B" },
                    { translation: "IA_CMM_POSITION_OIL_B", value: "IA_CMM_POSITION_OIL_B" },
                    { translation: "IA_CMM_DISTANCE_OIL_A", value: "IA_CMM_DISTANCE_OIL_A" },
                    { translation: "IA_CMM_POSITION_OIL_A", value: "IA_CMM_POSITION_OIL_A" },
                    { translation: "IA_CMM_DISTANCE_PILOT_TO_CP", value: "IA_CMM_DISTANCE_PILOT_TO_CP" },
                    { translation: "IA_CMM_PARALISM_PILOT", value: "IA_CMM_PARALISM_PILOT" },
                    { translation: "IA_CMM_DIAMETER_PILOT_SEALING", value: "IA_CMM_DIAMETER_PILOT_SEALING" },
                    { translation: "IA_CMM_CIRCULARITY_PILOT", value: "IA_CMM_CIRCULARITY_PILOT" },
                    { translation: "IA_CMM_DIAMETER_PILOT", value: "IA_CMM_DIAMETER_PILOT" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0060,
                value: DDCExportWorksheets.QRR0060,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    { translation: "IA_2.2_AVIT_SURFACE_A", value: "IA_2.2_AVIT_SURFACE_A" },
                    { translation: "IA_2.2_AVIT_SURFACE_B", value: "IA_2.2_AVIT_SURFACE_B" },
                    { translation: "IA_3.3_INNER_SEALING", value: "IA_3.3_INNER_SEALING" },
                    { translation: "IA_3.3_OUTER_SEALING", value: "IA_3.3_OUTER_SEALING" },
                    { translation: "IA_3.4_SEALING_COLD_PLANAR", value: "IA_3.4_SEALING_COLD_PLANAR" },
                    { translation: "IA_3.4_SEALING_COLD_CYLINDRICAL", value: "IA_3.4_SEALING_COLD_CYLINDRICAL" },
                    { translation: "IA_5.4_SWIRLER_ALL", value: "IA_5.4_SWIRLER_ALL" },
                    { translation: "IA_10.0_POSITION_CHECK_FLAME", value: "IA_10.0_POSITION_CHECK_FLAME" },
                    { translation: "IA_10.1_THREAD_FLAME", value: "IA_10.1_THREAD_FLAME" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0070,
                value: DDCExportWorksheets.QRR0070,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    { translation: "IA_7.0_INLET_A_SURFACE", value: "IA_7.0_INLET_A_SURFACE" },
                    { translation: "IA_7.0_INLET_A_THREAD", value: "IA_7.0_INLET_A_THREAD" },
                    { translation: "IA_7.0_INLET_B_SURFACE", value: "IA_7.0_INLET_B_SURFACE" },
                    { translation: "IA_7.0_INLET_B_THREAD", value: "IA_7.0_INLET_B_THREAD" },
                    { translation: "IA_8.0_OIL_TUBING_A", value: "IA_8.0_OIL_TUBING_A" },
                    { translation: "IA_8.0_OIL_TUBING_B", value: "IA_8.0_OIL_TUBING_B" },
                    { translation: "IA_9_CLAMPS", value: "IA_9_CLAMPS" }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0080,
                value: DDCExportWorksheets.QRR0080,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    { translation: "IA_REPAIR_EVALUATION", value: "IA_REPAIR_EVALUATION" }
                ]
            }
        ]
    },
    // Transitions
    {
        translation: DDCExportFiles.DDC_Transitions.label,
        value: DDCExportFiles.DDC_Transitions.value,
        worksheets: [
            {
                translation: DDCExportWorksheets.QRR0010,
                value: DDCExportWorksheets.QRR0010,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_SPECIALFINDINGS",
                        value: "IA_SPECIALFINDINGS"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0020,
                value: DDCExportWorksheets.QRR0020,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_01_INLETRING_WEAR_POS_1", value: "IA_01_INLETRING_WEAR_POS_1"},
                    {translation: "IA_01_INLETRING_WEAR_POS_2", value: "IA_01_INLETRING_WEAR_POS_2"},
                    {translation: "IA_01_INLETRING_WEAR_POS_3", value: "IA_01_INLETRING_WEAR_POS_3"},
                    {translation: "IA_01_INLETRING_WEAR_POS_4", value: "IA_01_INLETRING_WEAR_POS_4"},
                    {translation: "IA_01_INLETRING_WEAR_POS_5", value: "IA_01_INLETRING_WEAR_POS_5"},
                    {translation: "IA_01_INLETRING_WEAR_POS_6", value: "IA_01_INLETRING_WEAR_POS_6"},
                    {translation: "IA_01_INLETRING_WEAR_POS_7", value: "IA_01_INLETRING_WEAR_POS_7"},
                    {translation: "IA_01_INLETRING_WEAR_POS_8", value: "IA_01_INLETRING_WEAR_POS_8"},
                    {translation: "IA_01_INLETRING_WEAR_POS_9", value: "IA_01_INLETRING_WEAR_POS_9"},
                    {translation: "IA_01_INLETRING_WEAR_POS_10", value: "IA_01_INLETRING_WEAR_POS_10"},
                    {translation: "IA_01_INLETRING_WEAR_POS_11", value: "IA_01_INLETRING_WEAR_POS_11"},
                    {translation: "IA_01_INLETRING_WEAR_POS_12", value: "IA_01_INLETRING_WEAR_POS_12"},
                    {translation: "IA_01_INLETRING_WEAR_POS_13", value: "IA_01_INLETRING_WEAR_POS_13"},
                    {translation: "IA_01_INLETRING_WEAR_POS_14", value: "IA_01_INLETRING_WEAR_POS_14"},
                    {translation: "IA_01_INLETRING_WEAR_POS_15", value: "IA_01_INLETRING_WEAR_POS_15"},
                    {translation: "IA_01_INLETRING_WEAR_POS_16", value: "IA_01_INLETRING_WEAR_POS_16"},
                    {translation: "IA_01_INLETRING_WEAR_POS_17", value: "IA_01_INLETRING_WEAR_POS_17"},
                    {translation: "IA_01_INLETRING_WEAR_POS_18", value: "IA_01_INLETRING_WEAR_POS_18"},
                    {translation: "IA_01_INLETRING_WEAR_POS_19", value: "IA_01_INLETRING_WEAR_POS_19"},
                    {translation: "IA_01_INLETRING_WEAR_POS_20", value: "IA_01_INLETRING_WEAR_POS_20"},
                    {translation: "IA_01_INLETRING_WEAR_POS_21", value: "IA_01_INLETRING_WEAR_POS_21"},
                    {translation: "IA_01_INLETRING_WEAR_POS_22", value: "IA_01_INLETRING_WEAR_POS_22"},
                    {translation: "IA_01_INLETRING_WEAR_POS_23", value: "IA_01_INLETRING_WEAR_POS_23"},
                    {translation: "IA_01_INLETRING_WEAR_POS_24", value: "IA_01_INLETRING_WEAR_POS_24"},
                    {translation: "IA_01_INLETRING_WEAR_POS_25", value: "IA_01_INLETRING_WEAR_POS_25"},
                    {translation: "IA_01_INLETRING_WEAR_POS_26", value: "IA_01_INLETRING_WEAR_POS_26"},
                    {translation: "IA_01_INLETRING_WEAR_POS_27", value: "IA_01_INLETRING_WEAR_POS_27"},
                    {translation: "IA_01_INLETRING_WEAR_POS_28", value: "IA_01_INLETRING_WEAR_POS_28"},
                    {translation: "IA_01_INLETRING_DIAMETER_MAX", value: "IA_01_INLETRING_DIAMETER_MAX"},
                    {translation: "IA_01_INLETRING_DIAMETER_MIN", value: "IA_01_INLETRING_DIAMETER_MIN"},
                    {translation: "IA_01_INLETRING_DIAMETER_ANG_MAX", value: "IA_01_INLETRING_DIAMETER_ANG_MAX"},
                    {translation: "IA_01_INLETRING_DIAMETER_ANG_MIN", value: "IA_01_INLETRING_DIAMETER_ANG_MIN"},
                    {translation: "IA_01_INLETRING_MAX_DIFFERENCE", value: "IA_01_INLETRING_MAX_DIFFERENCE"},
                    {translation: "IA_01_INLETRING_OVALITY", value: "IA_01_INLETRING_OVALITY"}   
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0030,
                value: DDCExportWorksheets.QRR0030,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_02_LUG_THICKNESS_RIGHT", value: "IA_02_LUG_THICKNESS_RIGHT"},
                    {translation: "IA_02_LUG_THICKNESS_LEFT", value: "IA_02_LUG_THICKNESS_LEFT"},
                    {translation: "IA_02_LUG_DISTANCE_INLETRING_RIGHT_UPPER", value: "IA_02_LUG_DISTANCE_INLETRING_RIGHT_UPPER"},
                    {translation: "IA_02_LUG_DISTANCE_INLETRING_RIGHT_LOW", value: "IA_02_LUG_DISTANCE_INLETRING_RIGHT_LOW"},
                    {translation: "IA_02_LUG_DISTANCE_INLETRING_LEFT_UPPER", value: "IA_02_LUG_DISTANCE_INLETRING_LEFT_UPPER"},
                    {translation: "IA_02_LUG_DISTANCE_INLETRING_LEFT_LOW", value: "IA_02_LUG_DISTANCE_INLETRING_LEFT_LOW"},
                    {translation: "IA_02_LUG_DISTANCE_RADIAL_RIGHT", value: "IA_02_LUG_DISTANCE_RADIAL_RIGHT"},
                    {translation: "IA_02_LUG_DISTANCE_RADIAL_LEFT", value: "IA_02_LUG_DISTANCE_RADIAL_LEFT"},
                    {translation: "IA_02_LUG_ANGLE_TDC_RIGHT", value: "IA_02_LUG_ANGLE_TDC_RIGHT"},
                    {translation: "IA_02_LUG_ANGLE_TDC_LEFT", value: "IA_02_LUG_ANGLE_TDC_LEFT"}
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0040,
                value: DDCExportWorksheets.QRR0040,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_05_MOUNTING_PLATE_FLATNESS", value: "IA_05_MOUNTING_PLATE_FLATNESS"},
                    {translation: "IA_IA_05_MOUNTING_PLATE_TICKNESS_NECK", value: "IA_IA_05_MOUNTING_PLATE_TICKNESS_NECK"},
                    {translation: "IA_05_ MOUNTING_PLATE_P1-Z", value: "IA_05_ MOUNTING_PLATE_P1-Z"},
                    {translation: "IA_05_ MOUNTING_PLATE_P2-Z", value: "IA_05_ MOUNTING_PLATE_P2-Z"},
                    {translation: "IA_05_ MOUNTING_PLATE_P3-Z", value: "IA_05_ MOUNTING_PLATE_P3-Z"},
                    {translation: "IA_05_ MOUNTING_PLATE_P4-Z", value: "IA_05_ MOUNTING_PLATE_P4-Z"}
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0050,
                value: DDCExportWorksheets.QRR0050,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_1", value: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_1"},
                    {translation: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_2", value: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_2"},
                    {translation: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_3", value: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_3"},
                    {translation: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_4", value: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_4"},
                    {translation: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_5", value: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_5"},
                    {translation: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_6", value: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_6"},
                    {translation: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_7", value: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_7"},
                    {translation: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_8", value: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_8"},
                    {translation: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_9", value: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_9"},
                    {translation: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_10", value: "IA_07_SIDES_SEAL_MIN_THICKNISS_GROOVE_10"},
                    {translation: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_1", value: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_1"},
                    {translation: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_2", value: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_2"},
                    {translation: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_3", value: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_3"},
                    {translation: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_4", value: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_4"},
                    {translation: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_5", value: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_5"},
                    {translation: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_6", value: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_6"},
                    {translation: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_7", value: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_7"},
                    {translation: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_8", value: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_8"},
                    {translation: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_9", value: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_9"},
                    {translation: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_10", value: "IA_07_SIDES_SEAL_AVG_THICKNISS_GROOVE_10"}
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0060,
                value: DDCExportWorksheets.QRR0060,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_1", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_1"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_2", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_2"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_3", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_3"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_4", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_4"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_5", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_5"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_6", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_6"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_7", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_7"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_8", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_8"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_9", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_9"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_10", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_10"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_11", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_11"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_12", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_12"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_13", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_13"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_14", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_14"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_15", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_15"},
                    {translation: "IA_08_EXIT_FRAME_OVERHEATING_POS_16", value: "IA_08_EXIT_FRAME_OVERHEATING_POS_16"}
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0070,
                value: DDCExportWorksheets.QRR0070,
                columns: [
                    {translation: "Serialnummer", value: "Serialnummer"},
                    {translation: "IA_09_DISTORTION_TDC_EXITFRAME_RIGHT", value: "IA_09_DISTORTION_TDC_EXITFRAME_RIGHT"},
                    {translation: "IA_09_DISTORTION_TDC_EXITFRAME_LEFT", value: "IA_09_DISTORTION_TDC_EXITFRAME_LEFT"},
                    {translation: "IA_09_DISTORTION_COMPLETE_PART", value: "IA_09_DISTORTION_COMPLETE_PART"},]
            },
            {
                translation: DDCExportWorksheets.QRR0080,
                value: DDCExportWorksheets.QRR0080,
                columns: [
                    { translation: "Serialnummer", value: "Serialnummer" },
                    { translation: "IA_REPAIR_EVALUATION", value: "IA_REPAIR_EVALUATION" }
                ]
            },


        ]
    },

];