import { combineReducers } from 'redux';

import { productionStandReducer } from '../components/protocol/production-stand/ProductionStandReducer';
import { templateBuilderReducer } from '../components/protocol/template-builder/reducer';
import { iutPlanBuilderReducer } from '../components/protocol/iut-plan-builder/reducer';
import { iutPlanTemplateBuilderReducer } from '../components/protocol/iut-plan-template-builder/reducer';
import { templateReducer } from '../components/common/reducers/TemplateReducer';

import { machineMaintenanceReducer } from '../components/machine-maintenance/MachineMaintenance.reducers';

import { dynamicDataReducer } from '../components/common/templates/reducer';
import { requestReducer } from '../components/common/reducers/RequestReducer';
import { imageUploadReducer } from '../components/common/reducers/ImageUploadReducer';
import { serviceWorkerReducer } from '../components/common/reducers/ServiceWorkerReducer';

import { processReducer } from './../components/process/Process.reducers';
import { userSettingsReducer } from '../components/pages/userSettings/store/userSettings.reducer';
import { maintenanceReportsReducer } from '../components/maintenance/reports/store/maintenanceReports.reducer';
import { progressReducer } from './../components/progress/Progress.reducers';
import { protocolReducer } from '../components/protocol/Protocol.reducers';
import { maintenanceStandardReducer } from '../components/maintenance-standard/maintenance-standard.reducers';
import { adminReducer } from '../components/admin/reducer';

const maintenanceReducer = combineReducers({
    reports: maintenanceReportsReducer,
});

export const rootReducer = combineReducers({
    productionStand: productionStandReducer,
    protocol: protocolReducer,
    templateBuilder: templateBuilderReducer,
    iutPlanBuilder: iutPlanBuilderReducer,
    iutPlanTemplateBuilder: iutPlanTemplateBuilderReducer,
    template: templateReducer, // TODO REMOVE

    machineMaintenance: machineMaintenanceReducer,
    maintenanceStandard: maintenanceStandardReducer,
    maintenance: maintenanceReducer,

    dynamicData: dynamicDataReducer,
    request: requestReducer,
    imageUpload: imageUploadReducer,
    serviceWorker: serviceWorkerReducer,

    process: processReducer,

    progress: progressReducer,

    admin: adminReducer,

    currentUserData: userSettingsReducer,
});
