export const machineMaintenanceAssetsFiltersActions = {
    SET_CURRENT_COST_CENTER: 'MACHINE_MAINTENANCE_ASSETS/SET_CURRENT_COST_CENTER',
    SET_IS_SEARCH_BY_CHILD_ASSET: 'MAINTENANCE_STANDARD_ASSETS/SET_IS_SEARCH_BY_CHILD_ASSET',
    SET_SEARCH_VALUE: 'MACHINE_MAINTENANCE_ASSETS/SET_SEARCH_VALUE',
    SET_IS_LOADING_DATA: 'MACHINE_MAINTENANCE_ASSETS/SET_IS_LOADING_DATA',
    SET_PAGINATOR_PAGE: 'MACHINE_MAINTENANCE_ASSETS/SET_PAGINATOR_PAGE',
    RESET_ASSETS_FILTERS: 'MACHINE_MAINTENANCE_ASSETS/RESET_ASSETS_FILTERS',
};

export const setCurrentCostCenter = payload => {
    return {
        type: machineMaintenanceAssetsFiltersActions.SET_CURRENT_COST_CENTER,
        payload,
    };
};

export const setIsSearchByChildAsset = payload => {
    return {
        type: machineMaintenanceAssetsFiltersActions.SET_IS_SEARCH_BY_CHILD_ASSET,
        payload,
    };
};

export const setSearchValue = payload => {
    return {
        type: machineMaintenanceAssetsFiltersActions.SET_SEARCH_VALUE,
        payload,
    };
};

export const setIsLoadingData = payload => {
    return {
        type: machineMaintenanceAssetsFiltersActions.SET_IS_LOADING_DATA,
        payload,
    };
};

export const setPaginatorPage = payload => {
    return {
        type: machineMaintenanceAssetsFiltersActions.SET_PAGINATOR_PAGE,
        payload,
    };
};

export const resetAssetsFilters = () => {
    return {
        type: machineMaintenanceAssetsFiltersActions.RESET_ASSETS_FILTERS,
    };
};
