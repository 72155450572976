import { groupSettings, groups } from './groups';
import { characteristicSettings, characteristics } from './characteristics';
import { inputElementSettings, inputElements } from './inputElements';
import { elementTypes } from './elementTypes';

export const getElementSettings = () => {
    return {
        ...groupSettings,
        ...characteristicSettings,
        ...inputElementSettings,
    };
};

export const getDropZoneAllowedTypes = () => [
    groups.title,
    groups.sectionHeading,
    groups.sectionParagraph,
    groups.headerGrid,
    groups.table,
    groups.list,
    groups.imageSection,
    groups.footer,
];
export const getDeleteZoneAllowedTypes = () => [
    elementTypes.group,
    elementTypes.mediaOutputElement,
    elementTypes.signatureElement,
    elementTypes.inputElement,
];

export const getGroupWrapperAllowedTypes = () => [
    groups.title,
    groups.sectionHeading,
    groups.sectionParagraph,
    groups.headerGrid,
    groups.table,
    groups.list,
    groups.imageSection,
    groups.footer,
    elementTypes.group,
];
export const getFieldWrapperAllowedTypes = () => [
    inputElements.staticText,
    inputElements.person,
    inputElements.date,
    inputElements.textInput,
    inputElements.radio,
    inputElements.mediaInput,
    inputElements.document,
    inputElements.mediaOutput,
    inputElements.dropdown,
    inputElements.sign,
    inputElements.formattedTextInput,
    elementTypes.inputElement,
    characteristics.documentationCharacteristic,
];

export const getSectionDropzoneAllowedTypes = () => [
    inputElements.staticText,
    inputElements.person,
    inputElements.date,
    inputElements.textInput,
    inputElements.radio,
    inputElements.mediaInput,
    inputElements.document,
    inputElements.mediaOutput,
    inputElements.dropdown,
    inputElements.sign,
    inputElements.formattedTextInput,
    elementTypes.inputElement,
];

export const maxTemplateNameSize = 12;

export const protocolTemplateOperations = {
    build: 'build',
    edit: 'edit',
    clone: 'clone',
};
