import i18next from 'i18next';

export const userRole = {
    admin: 'admin',
    auditResponsible: 'auditResponsible',
    safetyAdmin: 'safetyAdmin',
    safetyAuditResponsible: 'safetyAuditResponsible',
    protocolAdmin: 'protocolAdmin',
    protocolSupervisor: 'protocolSupervisor',
    protocolOperator: 'protocolOperator',
    protocolPlanner: 'protocolPlanner',
    maintenanceOperator: 'maintenanceOperator',
    teamAdmin: 'teamAdmin',
    purchaser: 'purchaser',
    productionPlanner: 'productionPlanner',
    orderManager: 'orderManager',
    processOperator: 'processOperator',
};

export const safetyAppRoles = [
    userRole.admin,
    userRole.auditResponsible,
    userRole.safetyAdmin,
    userRole.safetyAuditResponsible,
    userRole.teamAdmin,
];
export const protocolAppRoles = [
    userRole.admin,
    userRole.protocolAdmin,
    userRole.protocolSupervisor,
    userRole.protocolOperator,
    userRole.protocolPlanner,
];
export const maintenanceAppRoles = [userRole.admin, userRole.maintenanceOperator, userRole.teamAdmin];
export const machineMaintenanceAppRoles = [userRole.admin, userRole.maintenanceOperator, userRole.teamAdmin];
export const maintenanceStandardAppRoles = [userRole.admin, userRole.maintenanceOperator, userRole.teamAdmin];
export const progressAppRoles = [
    userRole.admin,
    userRole.purchaser,
    userRole.orderManager,
    userRole.processOperator,
    userRole.productionPlanner,
];
export const workbookAppRoles = [userRole.admin, userRole.safetyAdmin, userRole.safetyAuditResponsible];
export const processAppRoles = [userRole.admin];

export const getUserRoleBadges = () => {
    return {
        [userRole.admin]: {
            key: userRole.admin,
            label: i18next.t('common.roles.badges.admin'),
        },
        [userRole.safetyAdmin]: {
            key: userRole.safetyAdmin,
            label: i18next.t('common.roles.badges.safetyAdmin'),
        },
        [userRole.protocolAdmin]: {
            key: userRole.protocolAdmin,
            label: i18next.t('common.roles.badges.protocolAdmin'),
        },
        [userRole.auditResponsible]: {
            key: userRole.auditResponsible,
            label: i18next.t('common.roles.badges.auditResponsible'),
        },
        [userRole.safetyAuditResponsible]: {
            key: userRole.safetyAuditResponsible,
            label: i18next.t('common.roles.badges.safetyAuditResponsible'),
        },
        [userRole.protocolSupervisor]: {
            key: userRole.protocolSupervisor,
            label: i18next.t('common.roles.badges.protocolSupervisor'),
        },
        [userRole.protocolOperator]: {
            key: userRole.protocolOperator,
            label: i18next.t('common.roles.badges.protocolOperator'),
        },
        [userRole.protocolPlanner]: {
            key: userRole.protocolPlanner,
            label: i18next.t('common.roles.badges.protocolPlanner'),
        },
        [userRole.maintenanceOperator]: {
            key: userRole.maintenanceOperator,
            label: i18next.t('common.roles.badges.maintenanceOperator'),
        },
        [userRole.teamAdmin]: {
            key: userRole.teamAdmin,
            label: i18next.t('common.roles.badges.teamAdmin'),
        },
        [userRole.purchaser]: {
            key: userRole.purchaser,
            label: i18next.t('common.roles.badges.purchaser'),
        },
        [userRole.productionPlanner]: {
            key: userRole.productionPlanner,
            label: i18next.t('common.roles.badges.productionPlanner'),
        },
        [userRole.orderManager]: {
            key: userRole.orderManager,
            label: i18next.t('common.roles.badges.orderManager'),
        },
        [userRole.processOperator]: {
            key: userRole.processOperator,
            label: i18next.t('common.roles.badges.operator'),
        },
    };
};

export const protocolRole = {
    manufacturer: 'manufacturer',
    quality: 'quality',
    customer: 'customer',
};

export const getProtocolRoleTranslation = () => {
    return {
        [protocolRole.manufacturer]: i18next.t('common.protocol-roles.manufacturer'),
        [protocolRole.quality]: i18next.t('common.protocol-roles.quality'),
        [protocolRole.customer]: i18next.t('common.protocol-roles.customer'),
    };
};
