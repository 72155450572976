import React from 'react';

//LIBS
import i18next from 'i18next';

// MATERIAL UI
import RedoIcon from '@material-ui/icons/Redo';
import EditIcon from '@material-ui/icons/Edit';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';

// CONSTANT
import { tableColumnTypes, filterTypes, filterComparerType } from '../../common/templates/constants';
import { ticketStatus } from '../../../constants/TicketStatus';
import { viewMode, viewType } from '../../../constants/ViewType';
import { ticketBuckets } from '../../../constants/MachineBucket';

export const ticketTableColumns = [
    {
        key: 'selectCheckbox',
        padding: 'checkbox',
        columnType: tableColumnTypes.selectCheckbox,
        minWidth: 50,
    },
    {
        key: 'createdAt',
        title: `maintenance-standard-tickets.table.columns.createdAt`,
        columnType: tableColumnTypes.date,
        orderBy: 'ticketCreatedBy.createdAt',
    },
    {
        key: 'asset',
        title: `maintenance-standard-tickets.table.columns.asset`,
        columnType: tableColumnTypes.string,
        orderBy: 'asset.name',
    },
    {
        key: 'description',
        title: `maintenance-standard-tickets.table.columns.description`,
        columnType: tableColumnTypes.string,
        orderBy: 'description',
    },
    {
        key: 'repairAt',
        title: `maintenance-standard-tickets.table.columns.repairAt`,
        columnType: tableColumnTypes.date,
        orderBy: 'repairAt',
    },
    {
        key: 'responsible',
        title: `maintenance-standard-tickets.table.columns.responsible`,
        align: 'center',
        columnType: tableColumnTypes.string,
        orderBy: 'responsible.name',
    },
    {
        key: 'follower',
        title: `maintenance-standard-tickets.table.columns.follower`,
        columnType: tableColumnTypes.array,
        orderBy: 'follower',
    },
    {
        key: 'jobCategory',
        title: `maintenance-standard-tickets.table.columns.jobCategory`,
        columnType: tableColumnTypes.array,
        orderBy: 'jobCategory',
    },
    {
        key: 'contextMenu',
        padding: 'checkbox',
        columnType: tableColumnTypes.action,
        minWidth: 50,
    },
];

export const ticketStatusTabs = [
    {
        key: 'open',
        label: 'maintenance-standard-tickets.status.open',
        operator: item => {
            return item.status === 'open';
        },
    },
    {
        key: 'inWork',
        label: 'maintenance-standard-tickets.status.inWork',
        operator: item => {
            return item.status === 'inWork';
        },
    },
    {
        key: 'done',
        label: 'maintenance-standard-tickets.status.done',
        operator: item => {
            return item.status === 'done';
        },
    },
];

export const ticketStatusGroups = [
    {
        key: ticketStatus.open,
        label: `maintenance-standard-tickets.table.tableStatusGroups.toDo`,
        operator: item => {
            return item.status === ticketStatus.open;
        },
    },
    {
        key: ticketStatus.inWork,
        label: `maintenance-standard-tickets.table.tableStatusGroups.inProgress`,
        operator: item => {
            return item.status === ticketStatus.inWork;
        },
    },
    {
        key: ticketStatus.done,
        label: `maintenance-standard-tickets.table.tableStatusGroups.complete`,
        operator: item => {
            return item.status === ticketStatus.done;
        },
    },
];

export const ticketBucketGroups = [
    {
        key: ticketBuckets.new,
        label: `maintenance-standard-tickets.table.tableBucketGroups.new`,
        operator: item => {
            return !item.bucket || item.bucket?.name === ticketBuckets.new;
        },
    },
    {
        key: ticketBuckets.inShift,
        label: `maintenance-standard-tickets.table.tableBucketGroups.inShift`,
        operator: item => {
            return item.bucket?.name === ticketBuckets.inShift;
        },
    },
    {
        key: ticketBuckets.shiftHandover,
        label: `maintenance-standard-tickets.table.tableBucketGroups.shiftHandover`,
        operator: item => {
            return item.bucket?.name === ticketBuckets.shiftHandover;
        },
    },
    {
        key: ticketBuckets.weekend,
        label: `maintenance-standard-tickets.table.tableBucketGroups.weekend`,
        operator: item => {
            return item.bucket?.name === ticketBuckets.weekend;
        },
    },
    {
        key: ticketBuckets.finished,
        label: `maintenance-standard-tickets.table.tableBucketGroups.finished`,
        operator: item => {
            return item.bucket?.name === ticketBuckets.finished;
        },
    },
];

export const ticketViewModes = {
    [viewType.kanban]: [
        {
            label: 'common.templates.dataView.groupBy.default',
            value: viewMode.default,
        },
        {
            label: 'common.templates.dataView.groupBy.bucket',
            value: viewMode.bucket,
        },
    ],
    [viewType.table]: [
        {
            label: 'common.templates.dataView.groupBy.default',
            value: viewMode.default,
        },
        {
            label: 'common.templates.dataView.groupBy.bucket',
            value: viewMode.bucket,
        },
    ],
};

export const ticketKanbanColumns = [
    {
        key: ticketStatus.open,
        label: `maintenance-standard-tickets.table.kanbanGroups.toDo`,
        operator: item => {
            return item.status === ticketStatus.open;
        },
    },
    {
        key: ticketStatus.inWork,
        label: `maintenance-standard-tickets.table.kanbanGroups.inProgress`,
        operator: item => {
            return item.status === ticketStatus.inWork;
        },
    },
    {
        key: ticketStatus.done,
        label: `maintenance-standard-tickets.table.kanbanGroups.complete`,
        operator: item => {
            return item.status === ticketStatus.done;
        },
    },
];

export const ticketBucketColumns = [
    {
        key: ticketBuckets.new,
        label: `maintenance-standard-tickets.table.bucketGroups.new`,
        operator: item => {
            return !item.bucket || item.bucket?.name === ticketBuckets.new;
        },
    },
    {
        key: ticketBuckets.inShift,
        label: `maintenance-standard-tickets.table.bucketGroups.inShift`,
        operator: item => {
            return item.bucket?.name === ticketBuckets.inShift;
        },
    },
    {
        key: ticketBuckets.shiftHandover,
        label: `maintenance-standard-tickets.table.bucketGroups.shiftHandover`,
        operator: item => {
            return item.bucket?.name === ticketBuckets.shiftHandover;
        },
    },
    {
        key: ticketBuckets.weekend,
        label: `maintenance-standard-tickets.table.bucketGroups.weekend`,
        operator: item => {
            return item.bucket?.name === ticketBuckets.weekend;
        },
    },
    {
        key: ticketBuckets.finished,
        label: `maintenance-standard-tickets.table.bucketGroups.finished`,
        operator: item => {
            return item.bucket?.name === ticketBuckets.finished;
        },
    },
];

export const ticketFilters = [
    {
        key: 'asset',
        type: filterTypes.objectArray,
        filterKey: 'value',
        filterComparerType: filterComparerType.single,
        getValue: x => {
            return x.asset?._id;
        },
    },
    {
        key: 'status',
        type: filterTypes.objectArray,
        filterKey: 'value',
        filterComparerType: filterComparerType.single,
        getValue: x => {
            return x.status;
        },
    },
    {
        key: 'priority',
        type: filterTypes.objectArray,
        filterKey: 'value',
        filterComparerType: filterComparerType.single,
        getValue: x => {
            return x.priority;
        },
    },
    {
        key: 'responsible',
        type: filterTypes.objectArray,
        filterKey: '_id',
        filterComparerType: filterComparerType.single,
        getValue: x => {
            return x.responsible?._id;
        },
    },
    {
        key: 'follower',
        type: filterTypes.objectArray,
        filterKey: 'value',
        filterComparerType: filterComparerType.some,
        getValue: x => {
            return x.resolvedFollower.filter(x => x).map(x => x._id);
        },
    },
    {
        key: 'jobCategory',
        type: filterTypes.objectArray,
        filterKey: 'value',
        filterComparerType: filterComparerType.some,
        getValue: x => {
            return x.jobCategory.map(x => x.name);
        },
    },
    {
        key: 'createdAt',
        type: filterTypes.dateArray,
        filterKey: 'value',
        filterComparerType: filterComparerType.between,
        getValue: x => {
            return x.ticketCreatedBy.createdAt;
        },
    },
];

export const ticketSearchCriteries = [
    {
        key: 'asset',
        getValue: x => {
            return x.asset?.name || '';
        },
    },
    {
        key: 'description',
        getValue: x => {
            return x.description || '';
        },
    },
    {
        key: 'responsible',
        getValue: x => {
            return x.responsible?.name || '';
        },
    },
];

export const myTicketsFilter = (ticket, filterValue) => {
    return (
        ticket.responsible?._id === filterValue ||
        ticket.ticketCreatedBy.userID === filterValue ||
        ticket.follower.some(item => item === filterValue)
    );
};

export const ticketContextMenuOptions = {
    moveToDo: {
        key: '',
        icon: <RedoIcon />,
        label: 'maintenance-standard-tickets.table.contextMenu.moveToToDo',
    },
    moveToInReview: {
        key: '',
        icon: <RedoIcon />,
        label: 'maintenance-standard-tickets.table.contextMenu.moveToInProgress',
    },
    moveToComplete: {
        key: '',
        icon: <RedoIcon />,
        label: 'maintenance-standard-tickets.table.contextMenu.moveToComplete',
    },
    edit: {
        key: '',
        icon: <EditIcon />,
        label: 'maintenance-standard-tickets.table.contextMenu.edit',
    },
    unarchive: {
        key: '',
        icon: <UnarchiveIcon />,
        label: 'maintenance-standard-tickets.table.contextMenu.unarchive',
    },
    archive: {
        key: '',
        icon: <ArchiveIcon />,
        label: 'maintenance-standard-tickets.table.contextMenu.archive',
    },
    delete: {
        key: '',
        icon: <DeleteIcon />,
        label: 'maintenance-standard-tickets.table.contextMenu.delete',
    },
    link: {
        key: '',
        icon: <LinkRoundedIcon />,
        label: 'maintenance-standard-tickets.table.contextMenu.link',
    },
};

export const getStatusOptions = () => {
    return [
        {
            color: '#3A358B',
            title: i18next.t('maintenance-standard-tickets.status.open'),
            value: 'open',
        },
        {
            color: '#904E13',
            title: i18next.t('maintenance-standard-tickets.status.inWork'),
            value: 'inWork',
        },
        {
            color: '#1B653A',
            title: i18next.t('maintenance-standard-tickets.status.done'),
            value: 'done',
        },
    ];
};

export const getArchiveOptions = () => {
    return [
        {
            title: i18next.t('maintenance-standard-tickets.archive.only-active'),
            value: false,
        },
        {
            title: i18next.t('maintenance-standard-tickets.archive.only-archived'),
            value: true,
        },
        {
            title: i18next.t('maintenance-standard-tickets.archive.all'),
            value: undefined,
        },
    ];
};

export const getShiftOptions = () => {
    return [
        {
            title: i18next.t('maintenance-standard-tickets.ticket-template.actionList.shiftOptions.shift1'),
            value: i18next.t('maintenance-standard-tickets.ticket-template.actionList.shiftOptions.shift1'),
        },
        {
            title: i18next.t('maintenance-standard-tickets.ticket-template.actionList.shiftOptions.shift2'),
            value: i18next.t('maintenance-standard-tickets.ticket-template.actionList.shiftOptions.shift2'),
        },
        {
            title: i18next.t('maintenance-standard-tickets.ticket-template.actionList.shiftOptions.shift3'),
            value: i18next.t('maintenance-standard-tickets.ticket-template.actionList.shiftOptions.shift3'),
        },
    ];
};

export const shiftValues = {
    shift1: i18next.t('maintenance-standard-tickets.ticket-template.actionList.shiftOptions.shift1'),
    shift2: i18next.t('maintenance-standard-tickets.ticket-template.actionList.shiftOptions.shift2'),
    shift3: i18next.t('maintenance-standard-tickets.ticket-template.actionList.shiftOptions.shift3'),
};

export const defaultDatePicketFormat = 'YYYY-MM-DDTHH:mm';

export const ticketAtomics = {
    responsible: {
        valuePath: 'responsible',
        path: 'responsible',
    },
    follower: {
        valuePath: 'follower',
        path: 'follower',
    },
    tags: {
        valuePath: 'tag',
        path: 'tags',
    },
};

export const getRequestFilters = (archived, deleted) => {
    return JSON.stringify({
        archived: archived,
        deleted: deleted,
    });
};

export const ticketMaxNumberOfAttachments = 100;
export const mobileMediaQuery = 800;
export const fetchTicketsPageSize = 50;
export const ticketAssetTitleSize = 25;

export const ticketCreationSteps = {
    asset: 'asset',
    details: 'details',
};
