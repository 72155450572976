import { concatArrays, isArrayWithItems } from "./arrayHelper";
import { textFieldType } from "../constants/TextFieldType";

//Function to map fields from the store to the array simplified model
export const mapTemplateToArrayModel = (state, isEdit = false, isDefault = false) => {
  if (!state) return [];

  const ids = Object.keys(state);
  return ids
    .filter(
      id =>
        !isDefault || !state[id].isDefault
    )
    .filter(
      id =>
        !isEdit || state[id].type !== textFieldType.radio || state[id].isEdited
    )
    .map(id => {
      const value = state[id].value;

      return {
        _id: id,
        value: value
      };
    });
};

//Function to map JSON model to array of inputs
export const mapJsonModelToArrayModel = json => {
  return json && json.protocolSection
    .map(protocolSection => {
      if (!isArrayWithItems(protocolSection.documentationCharacteristic)) {
        return [];
      }
      return (
        protocolSection.documentationCharacteristic
          .map(documentationCharacteristic => {
            return (
              documentationCharacteristic.inputElement
                .filter(x => x.type === textFieldType.radio || (x.defaultValue && !x.value))
                .map(input => {
                  let value = null;

                  if (input.type === textFieldType.textInput) {
                    value = input.defaultValue;
                  }

                  if (input.type === textFieldType.radio) {
                    value = input.checked;
                  }

                  return {
                    id: input._id,
                    value: value,
                    type: input.type,
                    groupId: documentationCharacteristic._id,
                    isDefault: value === input.defaultValue
                  };
                })
            );
          })
          .reduce(concatArrays, [])
      );
    })
    .reduce(concatArrays, []);
};

//Function to map JSON model input to template model
export const mapJsonArrayModelToTemplateModel = inputs => {
  let model = {};

  inputs &&
    inputs.forEach(input => {
      const { id, ...props } = input;
      model = {
        ...model,
        [id]: {
          ...props
        }
      };
    });

  return model;
};

export const getResponsible = responsibles => {
  return responsibles && responsibles.length > 0 ? responsibles.slice(-1)[0].responsibleID : "";
}