import { auditsActions } from './audits.actions';

const auditsInitialState = {
    audits: [],
    currentAudit: null,
};

export const auditsReducer = (state = auditsInitialState, { type, payload }) => {
    switch (type) {
        case auditsActions.SET_STD_AUDITS: {
            return {
                ...state,
                audits: payload,
            };
        }
        case auditsActions.UPDATE_STD_AUDITS_QUESTION_TICKETS: {
            const currentEhsAuditQuestionId = payload?.ehsAuditQuestionId;

            return {
                ...state,
                audits: state.audits?.map(audit => {
                    return {
                        ...audit,
                        auditQuestions: audit?.auditQuestions?.map(auditQuestion => {
                            if (auditQuestion?._id !== currentEhsAuditQuestionId) {
                                return auditQuestion;
                            }

                            return {
                                ...auditQuestion,
                                resolvedTickets: [
                                    ...auditQuestion?.resolvedTickets,
                                    {
                                        _id: payload?._id,
                                    },
                                ],
                            };
                        }),
                    };
                }),
                currentAudit: {
                    ...state.currentAudit,
                    auditQuestions: state?.currentAudit?.auditQuestions?.map(auditQuestion => {
                        if (auditQuestion?._id !== currentEhsAuditQuestionId) {
                            return auditQuestion;
                        }

                        return {
                            ...auditQuestion,
                            resolvedTickets: [
                                ...auditQuestion?.resolvedTickets,
                                {
                                    ...payload,
                                },
                            ],
                        };
                    }),
                },
            };
        }
        case auditsActions.SET_CURRENT_STD_AUDIT: {
            return {
                ...state,
                currentAudit: payload,
            };
        }
        case auditsActions.RESET_STD_AUDITS_STATE: {
            return auditsInitialState;
        }

        default:
            return state;
    }
};
