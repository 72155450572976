import React, { useEffect, useState, useCallback } from 'react';

//UTILS
import { AWS_AMPLIFY_DEFAULT_LANGUAGE, APP_NAME } from '../../../utils/env';
import { getFileName } from '../../../utils/fileManagement';
import { DeviceHelper } from '../../../utils/deviceHelper';
import { getPushNotificationModel, askNotificationPermission } from '../../../utils/pushNotificationHelper';
import { subscribeUser, unSubscribeUser, checkSubscription } from '../../../serviceWorkerRegistration';
import update from '../../../redux/update';

//CONSTANTS
import { themeType } from '../../../constants/ThemeType';
import { reactAppName } from '../../../constants/Config';
import { DeviceType } from '../../../constants/DeviceEnums';

import { filterObject } from '../../../utils/objectHelper';

const userSettingsHandler = ComposedComponent => {
    return props => {
        const [model, setModel] = useState({
            _id: undefined,
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            mobileNumber: undefined,
            userSettings: {
                theme: undefined,
                language: undefined,
            },
            profilePic: {
                thumbnail: undefined,
            },
            notifications: {
                dailyTicketOverview: undefined,
                email: undefined,
                overdueTicketsMail: { weekdays: [] },
                push: undefined,
            },
        });

        const [userRoles, setUserRoles] = useState([]);
        const [userImage, setUserImage] = useState(null);
        const [userDevice, setUserDevice] = useState(
            localStorage.getItem('userDevice') || DeviceHelper.getDeviceType() || DeviceType.desktop
        );

        const [pushSubscription, setPushSubscription] = useState(null);
        const [pushNotificationWarning, setPushNotificationWarning] = useState(false);

        const getUserImage = (imageName, userId) => {
            props.fetchUserImage({
                variables: {
                    accessRootFolder: `users/${userId}`,
                    file: imageName,
                },
            });
        };

        useEffect(() => {
            if (props.user) {
                setModel({
                    _id: props.user._id,
                    firstName: props.user.firstName || undefined,
                    name: props.user.name || undefined,
                    email: props.user.email || undefined,
                    mobileNumber: props.user.mobileNumber || undefined,
                    userSettings: {
                        theme: props.user.userSettings?.theme || themeType.dark,
                        language: props.user.userSettings?.language || AWS_AMPLIFY_DEFAULT_LANGUAGE,
                    },
                    profilePic: {
                        thumbnail: props.user.profilePic?.thumbnail || undefined,
                    },
                    notifications: {
                        dailyTicketOverview: props.user.notifications?.dailyTicketOverview,
                        email: props.user.notifications?.email || false,
                        push: getPushNotificationModel(props.user.notifications?.push),
                        overdueTicketsMail: {
                            weekdays: props.user.notifications.overdueTicketsMail.weekdays,
                            active: props.user.notifications.overdueTicketsMail.active,
                        },
                    },
                });

                setUserRoles(props.user.roles || []);

                if (!userImage && props.user.profilePic?.thumbnail) {
                    getUserImage(props.user.profilePic.thumbnail, props.user._id);
                }

                checkSubscription(setPushSubscription);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.user]);

        useEffect(() => {
            if (props.userImageData) {
                setUserImage(props.userImageData?.createSingleSignedGetURL?.signedRequest);
            } else {
                setUserImage(null);
            }
        }, [props.userImageData]);

        const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);

        const openImageUploadModal = useCallback(() => {
            setImageUploadModalOpen(true);
        }, []);

        const closeImageUploadModal = useCallback(() => {
            setImageUploadModalOpen(false);
        }, []);

        const handleSave = newModel => {
            props.updateUser({
                variables: {
                    user: filterObject(newModel, 'weeklyMail'),
                },
            });
        };

        const changeUserAvatar = imageUrl => {
            const imageName = getFileName(imageUrl);

            const newModel = {
                ...model,
                profilePic: {
                    thumbnail: imageName,
                },
            };

            setModel(newModel);
            handleSave(newModel);

            getUserImage(imageName, model._id);
        };

        const deleteUserAvatar = () => {
            const newModel = {
                ...model,
                profilePic: {},
            };

            setModel(newModel);
            handleSave(newModel);
        };

        const handleInputChange = (path, event) => {
            const newModel = update.set(model, path, event.target.value);

            setModel(newModel);
            handleSave(newModel);
        };

        const handleSwitchChange = (path, value) => {
            const newModel = update.set(model, path, value);

            setModel(newModel);
            handleSave(newModel);
        };

        const handleThemeChange = value => {
            const newModel = value
                ? update.set(model, 'userSettings.theme', themeType.dark)
                : update.set(model, 'userSettings.theme', themeType.light);

            setModel(newModel);
            handleSave(newModel);
        };

        const handlePushNotificationChange = value => {
            if (value !== isPushNotificationChecked()) {
                askNotificationPermission().then(permissionResult => {
                    if (permissionResult === 'granted') {
                        if (value) {
                            subscribeUser(subscribePushNotificationCallback.bind({}, true));
                        } else {
                            unSubscribeUser(subscribePushNotificationCallback.bind({}, false));
                        }
                    } else {
                        setPushNotificationWarning(true);
                    }
                });
            }
        };

        const subscribePushNotificationCallback = (active, data) => {
            const subscription = data ? JSON.parse(data) : {};

            const deviceName = `${DeviceHelper.getDeviceType()}/${DeviceHelper.getDevicePlatform()}`;

            const pushData = getPushNotificationModel(model.notifications?.push);

            const getSubscriptionData = () => {
                return {
                    active: active,
                    deviceName: deviceName,
                    subscription: {
                        endpoint: subscription.endpoint,
                        keys: {
                            p256dh: subscription.keys?.p256dh,
                            auth: subscription.keys?.auth,
                        },
                    },
                };
            };

            if (data) {
                if (active) {
                    pushData.push(getSubscriptionData());
                    const newModel = update.set(model, 'notifications.push', pushData);
                    setModel(newModel);
                    handleSave(newModel);
                    checkSubscription(setPushSubscription);
                } else {
                    const index = pushData.findIndex(item => {
                        return item.subscription?.endpoint === subscription.endpoint;
                    });

                    if (index !== -1) {
                        pushData.splice(index, 1);
                        const newModel = update.set(model, 'notifications.push', pushData);
                        setModel(newModel);
                        handleSave(newModel);
                        checkSubscription(setPushSubscription);
                    }
                }
            }
        };

        const isPushNotificationChecked = () => {
            if (pushSubscription) {
                return model.notifications?.push.some(x => x.subscription?.endpoint === pushSubscription.endpoint);
            }
            return false;
        };

        const closePushNotificationWarning = () => {
            setPushNotificationWarning(false);
        };

        const [confirmDeleteModal, setConfirmDeleteModal] = useState({
            open: false,
        });

        const handleConfirmationModalOpenChange = open => {
            setConfirmDeleteModal({
                open: open,
            });
        };

        const triggerDeleteImage = () => {
            deleteUserAvatar();
            setConfirmDeleteModal({
                open: false,
            });
        };

        const showDailyEmail = APP_NAME === reactAppName.audit || APP_NAME === reactAppName.maintenance;
        const showWeeklyEmail = APP_NAME === reactAppName.audit;
        const showDailyTicketReport = APP_NAME === reactAppName.maintenance || APP_NAME === reactAppName.machine_maintenance;

        return (
            <ComposedComponent
                {...props}
                showWeeklyEmail={showWeeklyEmail}
                model={model}
                userImage={userImage}
                userRoles={userRoles}
                userDevice={userDevice}
                setUserDevice={setUserDevice}
                imageUploadModalOpen={imageUploadModalOpen}
                openImageUploadModal={openImageUploadModal}
                changeUserAvatar={changeUserAvatar}
                triggerDeleteImage={triggerDeleteImage}
                closeImageUploadModal={closeImageUploadModal}
                showDailyEmail={showDailyEmail}
                showDailyTicketReport={showDailyTicketReport}
                isPushNotificationChecked={isPushNotificationChecked}
                handleInputChange={handleInputChange}
                handleThemeChange={handleThemeChange}
                handleSwitchChange={handleSwitchChange}
                handlePushNotificationChange={handlePushNotificationChange}
                confirmDeleteModal={confirmDeleteModal}
                handleConfirmationModalOpenChange={handleConfirmationModalOpenChange}
                pushNotificationWarning={pushNotificationWarning}
                closePushNotificationWarning={closePushNotificationWarning}
            />
        );
    };
};

export default userSettingsHandler;
