import React from "react";

// LIBS
import clsx from "clsx";
import i18next from "i18next";
import { useHistory } from "react-router-dom";

// MATERIAL UI - CORE
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ImportContactsRoundedIcon from '@material-ui/icons/ImportContactsRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';

// ICONS
import { ReactComponent as NewIdeaIcon } from "../../resources/icons/newIdeaIcon.svg";
import { ReactComponent as CompassIcon } from "../../resources/icons/compassIcon.svg";
import { ReactComponent as IdeaListIcon } from "../../resources/icons/ideaListIcon.svg";
import { ReactComponent as Share2PerformIcon } from "../../resources/icons/share2PerformIcon.svg";
import { ReactComponent as SurveyIcon } from "../../resources/icons/surveyIcon.svg";
import { windowOpen } from "../../utils/windowHelper";

const LeftNavbar = ({
    open,
    classes,
    isNavigationButtonActive,
    getListIconComponent
}) => {
    const history = useHistory();

    const isAuditsActive = isNavigationButtonActive('/audits') && !(isNavigationButtonActive('/audits/create') || isNavigationButtonActive('/audits/edit'));
    const isTicketsActive = isNavigationButtonActive('/tickets') && !(isNavigationButtonActive('/tickets/create') || isNavigationButtonActive('/tickets/edit'));
    const isAuditCreateEditActive = isNavigationButtonActive('/audits/create') || isNavigationButtonActive('/audits/edit');
    const isQuestionsActive = isNavigationButtonActive('/questions');
    const isTicketCreateEditActive = isNavigationButtonActive('/tickets/create') || isNavigationButtonActive('/tickets/edit');

    return (
        <React.Fragment>
            <ListItem className={clsx(classes.navigationButton, isTicketCreateEditActive ? "active" : "")} onClick={() => history.push("/tickets/create")}>
                <ListItemIcon>
                    {getListIconComponent('menu.create-ticket', <NewIdeaIcon id='workbookCreateTicket' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.create-ticket`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isAuditsActive ? "active" : "")} onClick={() => history.push("/audits")}>
                <ListItemIcon>
                    {getListIconComponent('menu.audits', <CompassIcon id='workbookAudits' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.audits`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isQuestionsActive ? "active" : "")} onClick={() => history.push("/questions")}>
                <ListItemIcon>
                    {getListIconComponent('menu.questions', <ImportContactsRoundedIcon id='workbookQuestions' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.questions`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isAuditCreateEditActive ? "active" : "")} onClick={() => history.push("/audits/create")}>
                <ListItemIcon>
                    {getListIconComponent('menu.create-audit', <AssignmentRoundedIcon id='workbookBuildAudit' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.create-audit`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isTicketsActive ? "active" : "")} onClick={() => history.push("/tickets")}>
                <ListItemIcon>
                    {getListIconComponent('menu.ehs-tickets', <IdeaListIcon id='workbookTickets' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.ehs-tickets`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton)} onClick={() => windowOpen("https://www.share2perform.com/")}>
                <ListItemIcon>
                    {getListIconComponent('menu.share2perform', <Share2PerformIcon />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.share2perform`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton)} onClick={() => windowOpen("https://app.sli.do/event/1fzwsfqt/live/questions")}>
                <ListItemIcon>
                    {getListIconComponent('menu.survey', <SurveyIcon />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.survey`)} />
            </ListItem>
        </React.Fragment>
    );
};

export default LeftNavbar;