export const editSchedulerTaskActions = {
    SET_TASK_MODEL: 'EDIT_SCHEDULER_TASK/SET_TASK_MODEL',
    SET_COST_CENTER: 'EDIT_SCHEDULER_TASK/SET_COST_CENTER',
    SET_PARENT_ASSET: 'EDIT_SCHEDULER_TASK/SET_PARENT_ASSET',
    SET_CHILD_ASSET: 'EDIT_SCHEDULER_TASK/SET_CHILD_ASSET',
    SET_FREQUENCY: 'EDIT_SCHEDULER_TASK/SET_FREQUENCY',
    SET_RESPOSIBLE: 'EDIT_SCHEDULER_TASK/SET_RESPOSIBLE',
    SET_DUE_DAY_OPTIONS_DAYS: 'EDIT_SCHEDULER_TASK/SET_DUE_DAY_OPTIONS_DAYS',
    SET_DUE_DAY_OPTIONS_WEEKS: 'EDIT_SCHEDULER_TASK/SET_DUE_DAY_OPTIONS_WEEKS',
    SET_TASK_DESCRIPTION: 'EDIT_SCHEDULER_TASK/SET_TASK_DESCRIPTION',
    SET_JOB_CATEGORY: 'EDIT_SCHEDULER_TASK/SET_JOB_CATEGORY',
    SET_SCHEDULED_END_DATE: 'EDIT_SCHEDULER_TASK/SET_SCHEDULED_END_DATE',
    SET_SCHEDULED_START_DATE: 'EDIT_SCHEDULER_TASK/SET_SCHEDULED_START_DATE',
    SET_IN_ADVANCE_DAYS: 'EDIT_SCHEDULER_TASK/SET_IN_ADVANCE_DAYS',
    UPDATE_CHECK_LIST_ENTRY: 'EDIT_SCHEDULER_TASK/UPDATE_CHECK_LIST_ENTRY',
    UPDATE_CHECK_LIST: 'EDIT_SCHEDULER_TASK/UPDATE_CHECK_LIST',
    ADD_CHECK_LIST_ENTRY: 'EDIT_SCHEDULER_TASK/ADD_CHECK_LIST_ENTRY',
    REMOVE_CHECK_LIST_ENTRY: 'EDIT_SCHEDULER_TASK/REMOVE_CHECK_LIST_ENTRY',
    REMOVE_CHECK_LIST: 'EDIT_SCHEDULER_TASK/REMOVE_CHECK_LIST',
    BULK_ADD_CHECK_LIST: 'EDIT_SCHEDULER_TASK/BULK_ADD_CHECK_LIST',
    ADD_CHECK_LIST: 'EDIT_SCHEDULER_TASK/ADD_CHECK_LIST',
    REMOVE_ATTACHMENT: 'EDIT_SCHEDULER_TASK/REMOVE_ATTACHMENT',
    ADD_ATTACHMENT: 'EDIT_SCHEDULER_TASK/ADD_ATTACHMENT',
    SET_IS_VALID: 'EDIT_SCHEDULER_TASK/SET_IS_VALID',
};

export const setTaskModel = payload => ({
    type: editSchedulerTaskActions.SET_TASK_MODEL,
    payload,
});

export const setCostCenter = payload => ({
    type: editSchedulerTaskActions.SET_COST_CENTER,
    payload,
});

export const setParentAsset = payload => ({
    type: editSchedulerTaskActions.SET_PARENT_ASSET,
    payload,
});

export const setChildAsset = payload => ({
    type: editSchedulerTaskActions.SET_CHILD_ASSET,
    payload,
});

export const setFrequency = payload => ({
    type: editSchedulerTaskActions.SET_FREQUENCY,
    payload,
});

export const setResponsible = payload => ({
    type: editSchedulerTaskActions.SET_RESPOSIBLE,
    payload,
});

export const setDueDayOptionsDays = payload => ({
    type: editSchedulerTaskActions.SET_DUE_DAY_OPTIONS_DAYS,
    payload,
});

export const setDueDayOptionsWeeks = payload => ({
    type: editSchedulerTaskActions.SET_DUE_DAY_OPTIONS_WEEKS,
    payload,
});

export const setTaskDescription = payload => ({
    type: editSchedulerTaskActions.SET_TASK_DESCRIPTION,
    payload,
});

export const setTaskJobCategory = payload => ({
    type: editSchedulerTaskActions.SET_JOB_CATEGORY,
    payload,
});

export const setTaskScheduledEndDate = payload => ({
    type: editSchedulerTaskActions.SET_SCHEDULED_END_DATE,
    payload,
});

export const setTaskScheduledStartDate = payload => ({
    type: editSchedulerTaskActions.SET_SCHEDULED_START_DATE,
    payload,
});

export const setInAdvanceDays = payload => ({
    type: editSchedulerTaskActions.SET_IN_ADVANCE_DAYS,
    payload,
});

export const updateChecklistEntry = payload => ({
    type: editSchedulerTaskActions.UPDATE_CHECK_LIST_ENTRY,
    payload,
});

export const updateChecklist = payload => ({
    type: editSchedulerTaskActions.UPDATE_CHECK_LIST,
    payload,
});

export const addChecklistEntry = payload => ({
    type: editSchedulerTaskActions.ADD_CHECK_LIST_ENTRY,
    payload,
});

export const removeChecklistEntry = payload => ({
    type: editSchedulerTaskActions.REMOVE_CHECK_LIST_ENTRY,
    payload,
});

export const removeChecklist = payload => ({
    type: editSchedulerTaskActions.REMOVE_CHECK_LIST,
    payload,
});

export const bulkAddCheckList = payload => ({
    type: editSchedulerTaskActions.BULK_ADD_CHECK_LIST,
    payload,
});

export const addCheckList = payload => ({
    type: editSchedulerTaskActions.ADD_CHECK_LIST,
    payload,
});

export const removeTicketAttachment = payload => ({
    type: editSchedulerTaskActions.REMOVE_ATTACHMENT,
    payload,
});

export const addTicketAttachment = payload => ({
    type: editSchedulerTaskActions.ADD_ATTACHMENT,
    payload,
});

export const setIsValid = payload => ({
    type: editSchedulerTaskActions.SET_IS_VALID,
    payload,
});
