import { combineReducers } from 'redux';
import { leftBarReducer } from './leftBar/store/leftBar.reducer';
import { rigthBarReducer } from './rigthBar/store/rigthBar.reducer';
import { topBarReducer } from './topBar/store/topBar.reducer';
import { productionStandSharedReducer } from './store/productionStandShared.reducer';

export const productionStandReducer = combineReducers({
    rigthBar: rigthBarReducer,
    leftBar: leftBarReducer,
    topBar: topBarReducer,
    shared: productionStandSharedReducer,
});
