import { orderActions } from './orders.actions';

const ordersInitialState = {
    orders: [],
    workplaceTabs: [],
    activeWorkplaceTab: null,
};

export const ordersReducer = (state = ordersInitialState, { type, payload }) => {
    switch (type) {
        case orderActions.CHANGE_WORKPLACE_TAB: {
            return {
                ...state,
                activeWorkplaceTab: payload
            }
        }
        case orderActions.SET_WORKPLACE_TABS: {
            let activeWorkplaceTab = state.activeWorkplaceTab;

            if (!activeWorkplaceTab) {
                activeWorkplaceTab = payload[0]?._id;
            }

            return {
                ...state,
                workplaceTabs: payload,
                activeWorkplaceTab
            }
        }
        case orderActions.SET_ORDERS: {
            return {
                ...state,
                orders: payload
            }
        }

        default: return state;
    }
}