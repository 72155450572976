import { makeStyles } from '@material-ui/core/styles';

export const commonStyles = makeStyles(theme => ({
    customScrollContainer: {
        overflow: 'auto',

        '&::-webkit-scrollbar-track': {
            borderRadius: 3,
            backgroundColor: theme.palette.scroll.track,
        },
        '&::-webkit-scrollbar': {
            width: 6,
            height: 6,
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 3,
            backgroundColor: theme.palette.scroll.thumb,
        },
    },
}));
