import { isArrayWithItems } from "./arrayHelper";

export const getPushNotificationModel = push => {
    if (isArrayWithItems(push)) {
        return push.map(item => {
            return {
                active: item.active,
                browserID: item.browserID,
                deviceName: item.deviceName,
                subscription: {
                    endpoint: item.subscription?.endpoint,
                    expirationTime: item.subscription?.expirationTime,
                    keys: {
                        p256dh: item.subscription?.keys?.p256dh,
                        auth: item.subscription?.keys?.auth
                    }
                }
            };
        })
    }
    return [];
};

export const showPushNotificationOption = () => {
    if (!('serviceWorker' in navigator)) {
        return false;
    }

    if (!('PushManager' in window)) {
        return false;
    }

    return true;
};

export const askNotificationPermission = () => {
    return new Promise((resolve, reject) => {
        const permissionResult = Notification.requestPermission(result => {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    });
};
