import React from 'react';

// NOTE: Material-UI is using ForwardRef (Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?)
const LineOperationsMenuIcon = React.forwardRef(() => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 10V22H2V10L9 7V9L14 7V10H22ZM17.2 8.5L18 2H21L21.8 8.5H17.2ZM11 18H13V14H11V18ZM7 18H9V14H7V18ZM17 14H15V18H17V14Z"
                fill="#B5BECC"
            />
        </svg>
    );
});

export default LineOperationsMenuIcon;
