import { addHours, addDays } from 'date-fns';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import { ProcessStatusEnum } from '../common/constants/processContants';

export const ordersTimestampFilters = {
    getAll2h: 'getAll2h',
    getAll8h: 'getAll8h',
    getAll24h: 'getAll24h',
    fromLast2h: 'fromLast2h',
    fromLast2hTo2h: 'fromLast2hTo2h',
    fromLast2hTo8h: 'fromLast2hTo8h',
    fromLast2hTo12h: 'fromLast2hTo12h',
    fromLast2hTo24h: 'fromLast2hTo24h',
    fromLast2hTo7d: 'fromLast2hTo7d',
    fromLast8hTo24h: 'fromLast8hTo24h',
    getOpenAll2h: 'getOpenAll2h',
    getOpenAll8h: 'getOpenAll8h',
    getOpenAll24h: 'openGetAll24h',
    openFromLast2h: 'openFromLast2h',
    getCompletedLast24h: 'completedLast24h',
    completed: 'completed',
    inWork: 'inWork',
};

export const getFruitPreparationBoardOptions = () => {
    return [
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.getAll2h'),
            value: ordersTimestampFilters.getAll2h,
        },
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.getAll8h'),
            value: ordersTimestampFilters.getAll8h,
        },
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.getAll24h'),
            value: ordersTimestampFilters.getAll24h,
        },
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.fromLast2hTo2h'),
            value: ordersTimestampFilters.fromLast2hTo2h,
        },
    ];
};

export const getFruitPreparationTableOptions = () => {
    return [
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.openFromLast2h'),
            value: ordersTimestampFilters.openFromLast2h,
        },
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.getOpenAll24h'),
            value: ordersTimestampFilters.getOpenAll24h,
        },
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.getOpenAll2h'),
            value: ordersTimestampFilters.getOpenAll2h,
        },
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.getOpenAll8h'),
            value: ordersTimestampFilters.getOpenAll8h,
        },
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.completed'),
            value: ordersTimestampFilters.completed,
        },
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.inWork'),
            value: ordersTimestampFilters.inWork,
        },
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.getCompletedLast24h'),
            value: ordersTimestampFilters.getCompletedLast24h,
        },
    ];
};

export const getOrdersOverviewTimestampFilterOptions = () => {
    return [
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.fromLast2hTo12h'),
            value: ordersTimestampFilters.fromLast2hTo12h,
        },
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.fromLast2hTo24h'),
            value: ordersTimestampFilters.fromLast2hTo24h,
        },
        {
            title: i18next.t('ordersOverview.filters.timestamp.options.fromLast2hTo7d'),
            value: ordersTimestampFilters.fromLast2hTo7d,
        },
    ];
};

export const getOrdersStatusOptions = () => {
    return [
        {
            title: i18next.t('ordersOverview.filters.processStatus.options.open'),
            value: ProcessStatusEnum.OPEN,
        },
        {
            title: i18next.t('ordersOverview.filters.processStatus.options.inWork'),
            value: ProcessStatusEnum.IN_WORK,
        },
        {
            title: 'Done',
            value: ProcessStatusEnum.DONE,
        },
    ];
};

export const timestampFilterCalculations = {
    now: new Date(),

    [ordersTimestampFilters.getAll2h]: () => [null, addHours(new Date(), 2)],
    [ordersTimestampFilters.getAll8h]: () => [null, addHours(new Date(), 8)],
    [ordersTimestampFilters.getAll24h]: () => [null, addHours(new Date(), 24)],
    [ordersTimestampFilters.fromLast2h]: () => [addHours(new Date(), -2), addHours(new Date(), 2)],
    [ordersTimestampFilters.getAll]: () => [null, null],
    [ordersTimestampFilters.fromLast2hTo2h]: () => [addHours(new Date(), -2), addHours(new Date(), 2)],
    [ordersTimestampFilters.fromLast2hTo8h]: () => [addHours(new Date(), -2), addHours(new Date(), 8)],
    [ordersTimestampFilters.fromLast2hTo12h]: () => [addHours(new Date(), -2), addHours(new Date(), 12)],
    [ordersTimestampFilters.fromLast2hTo24h]: () => [addHours(new Date(), -2), addHours(new Date(), 24)],
    [ordersTimestampFilters.fromLast2hTo7d]: () => [addHours(new Date(), -2), addDays(new Date(), 7)],
    [ordersTimestampFilters.fromLast8hTo24h]: () => [addHours(new Date(), -8), addHours(new Date(), 24)],
};

export const timestampFilterCalculationsListView = {
    now: new Date(),

    [ordersTimestampFilters.openFromLast2h]: () => {
        return {
            timestamp: [addHours(new Date(), -2), addHours(new Date(), 2)],
            selectedStatus: ProcessStatusEnum.OPEN,
        };
    },
    [ordersTimestampFilters.fromLast2hTo2h]: () => {
        return {
            timestamp: [addHours(new Date(), -2), addHours(new Date(), 2)],
            selectedStatus: ProcessStatusEnum.OPEN,
        };
    },
    [ordersTimestampFilters.fromLast2hTo12h]: () => {
        return {
            timestamp: [addHours(new Date(), -2), addHours(new Date(), 12)],
            selectedStatus: ProcessStatusEnum.OPEN,
        };
    },
    [ordersTimestampFilters.fromLast2hTo24h]: () => {
        return {
            timestamp: [addHours(new Date(), -2), addHours(new Date(), 24)],
            selectedStatus: ProcessStatusEnum.OPEN,
        };
    },
    [ordersTimestampFilters.fromLast2hTo7d]: () => {
        return {
            timestamp: [addHours(new Date(), -2), addDays(new Date(), 7)],
            selectedStatus: ProcessStatusEnum.OPEN,
        };
    },
    [ordersTimestampFilters.getOpenAll24h]: () => {
        return {
            timestamp: [null, addHours(new Date(), 24)],
            selectedStatus: ProcessStatusEnum.OPEN,
        };
    },
    [ordersTimestampFilters.getOpenAll2h]: () => {
        return {
            timestamp: [null, addHours(new Date(), 2)],
            selectedStatus: ProcessStatusEnum.OPEN,
        };
    },
    [ordersTimestampFilters.getOpenAll8h]: () => {
        return {
            timestamp: [null, addHours(new Date(), 8)],
            selectedStatus: ProcessStatusEnum.OPEN,
        };
    },
    [ordersTimestampFilters.getCompletedLast24h]: () => {
        return {
            timestamp: [addHours(new Date(), -24), new Date()],
            selectedStatus: ProcessStatusEnum.DONE,
        };
    },
    [ordersTimestampFilters.inWork]: () => {
        return {
            timestamp: [null, null],
            selectedStatus: ProcessStatusEnum.IN_WORK,
        };
    },
    [ordersTimestampFilters.completed]: () => {
        return {
            timestamp: [null, null],
            selectedStatus: ProcessStatusEnum.DONE,
        };
    },
};

export const getOrdersByWorkplaceId = workplaceId => ({
    'processRoutings.processSteps.workplaces._id': {
        $in: [`${workplaceId}`],
    },
    parent: null,
});

export const getOrdersFilterQueryString = (searchTerm, filters, defaultFilterQuery, showDoneItems, isListView) => {
    let filterQuery = {
        ...defaultFilterQuery,
        deleted: { $ne: true },
    };

    const filtersEmpty = isEmpty(filters) || isEmpty(Object.values(filters));

    if (isListView) {
        filterQuery = {
            ...filterQuery,
            ...getFilterQueryListView(filters, showDoneItems),
        };
    }

    if (!filtersEmpty && !isListView) {
        filterQuery = {
            ...filterQuery,
            ...getFilterQuery(filters, { showDoneItems }),
        };
    }

    const filterModel = getSearchAndFilterQueryOrDefault(searchTerm, filtersEmpty, filterQuery, showDoneItems);

    return JSON.stringify(filterModel);
};

const getFilterQuery = ({ timestamp, zones, selectedStatus }, { showDoneItems }) => {
    let filterQuery = {};

    if (!isEmpty(timestamp)) {
        const [from, to] = timestampFilterCalculations[timestamp]();

        if (!from && to) {
            filterQuery = showDoneItems
                ? {
                      $or: [
                          { 'headerData.orderStatus': ProcessStatusEnum.IN_WORK },
                          { 'headerData.orderStatus': ProcessStatusEnum.DONE },
                          {
                              $and: [
                                  {
                                      'headerData.orderStatus': ProcessStatusEnum.OPEN,
                                  },
                                  {
                                      'schedulingData.latestStartDate': {
                                          $lte: to,
                                      },
                                  },
                              ],
                          },
                      ],
                  }
                : {
                      $or: [
                          { 'headerData.orderStatus': ProcessStatusEnum.IN_WORK },
                          {
                              $and: [
                                  {
                                      'headerData.orderStatus': ProcessStatusEnum.OPEN,
                                  },
                                  {
                                      'schedulingData.latestStartDate': {
                                          $lte: to,
                                      },
                                  },
                              ],
                          },
                      ],
                  };
        }

        if (from && to) {
            filterQuery = showDoneItems
                ? {
                      $or: [
                          { 'headerData.orderStatus': ProcessStatusEnum.IN_WORK },
                          { 'headerData.orderStatus': ProcessStatusEnum.DONE },
                          {
                              $and: [
                                  {
                                      'headerData.orderStatus': ProcessStatusEnum.OPEN,
                                  },

                                  {
                                      'schedulingData.latestStartDate': {
                                          $gte: from,
                                          $lte: to,
                                      },
                                  },
                              ],
                          },
                      ],
                  }
                : {
                      $or: [
                          { 'headerData.orderStatus': ProcessStatusEnum.IN_WORK },
                          {
                              $and: [
                                  {
                                      'headerData.orderStatus': ProcessStatusEnum.OPEN,
                                  },
                                  {
                                      'schedulingData.latestStartDate': {
                                          $gte: from,
                                          $lte: to,
                                      },
                                  },
                              ],
                          },
                      ],
                  };
        }
    }

    if (!isEmpty(zones)) {
        filterQuery = {
            ...filterQuery,
            'processRoutings.processSteps.workplaces._id': {
                $in: zones.map(zone => [`${zone.id}`]),
            },
        };
    }

    if (!!selectedStatus) {
        filterQuery = {
            ...filterQuery,
            'headerData.orderStatus': selectedStatus,
        };
    }

    return filterQuery;
};

const getFilterQueryListView = (filter, { showDoneItems }) => {
    let filterQuery = {};

    const { timestamp, selectedStatus } = timestampFilterCalculationsListView[filter.timestamp]();

    if (!isEmpty(timestamp)) {
        const [from, to] = timestamp;

        if (!from && to) {
            filterQuery = showDoneItems
                ? {
                      $or: [
                          { 'headerData.orderStatus': ProcessStatusEnum.IN_WORK },
                          { 'headerData.orderStatus': ProcessStatusEnum.DONE },
                          {
                              $and: [
                                  {
                                      'headerData.orderStatus': ProcessStatusEnum.OPEN,
                                  },
                                  {
                                      'schedulingData.latestStartDate': {
                                          $lte: to,
                                      },
                                  },
                              ],
                          },
                      ],
                  }
                : {
                      $or: [
                          { 'headerData.orderStatus': ProcessStatusEnum.IN_WORK },
                          {
                              $and: [
                                  {
                                      'headerData.orderStatus': ProcessStatusEnum.OPEN,
                                  },
                                  {
                                      'schedulingData.latestStartDate': {
                                          $lte: to,
                                      },
                                  },
                              ],
                          },
                      ],
                  };
        }

        if (from && to) {
            filterQuery = showDoneItems
                ? {
                      $and: [
                          {
                              'headerData.orderStatus': ProcessStatusEnum.OPEN,
                          },

                          {
                              'schedulingData.latestStartDate': {
                                  $gte: from,
                                  $lte: to,
                              },
                          },
                      ],
                  }
                : {
                      $and: [
                          {
                              'headerData.orderStatus': ProcessStatusEnum.OPEN,
                          },
                          {
                              'schedulingData.latestStartDate': {
                                  $gte: from,
                                  $lte: to,
                              },
                          },
                      ],
                  };
        }
    }

    if (!isEmpty(filter.zones)) {
        filterQuery = {
            ...filterQuery,
            'processRoutings.processSteps.workplaces._id': {
                $in: filter.zones.map(zone => [`${zone.id}`]),
            },
        };
    }

    if (!!selectedStatus) {
        if (showDoneItems) {
            filterQuery = {
                ...filterQuery,
                'headerData.orderStatus': selectedStatus,
            };
        }
    }

    return filterQuery;
};

const getSearchAndFilterQueryOrDefault = (searchTerm, filtersEmpty, filterQuery) => {
    if (searchTerm && filtersEmpty) {
        return getOrdersSearchQueryOrDefault(searchTerm, filterQuery);
    }

    if (searchTerm && !filtersEmpty) {
        return {
            $and: [
                {
                    $or: [
                        {
                            'headerData.orderNo': {
                                $regex: searchTerm,
                                $options: 'i',
                            },
                        },
                        {
                            'headerData.customFields.value': {
                                $regex: searchTerm,
                                $options: 'i',
                            },
                        },
                        {
                            'processRoutings.materials.materialNumber': {
                                $regex: searchTerm,
                                $options: 'i',
                            },
                        },
                        { 'headerData.orderStatus': ProcessStatusEnum.IN_WORK },
                    ],
                },
                {
                    ...filterQuery,
                },
            ],
        };
    }

    return filterQuery;
};

export const getOrdersSearchQueryOrDefault = searchTerm => {
    return {
        $and: [
            {
                $or: [
                    {
                        'headerData.orderNo': {
                            $regex: searchTerm,
                            $options: 'i',
                        },
                    },
                    {
                        'headerData.customFields.value': {
                            $regex: searchTerm,
                            $options: 'i',
                        },
                    },
                    {
                        'processRoutings.materials.materialNumber': {
                            $regex: searchTerm,
                            $options: 'i',
                        },
                    },
                    { 'headerData.orderStatus': ProcessStatusEnum.IN_WORK },
                ],
            },
        ],
    };
};
