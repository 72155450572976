import React, { useState } from 'react';

// LIBS
import i18next from 'i18next';

// MATERIAL UI
import { Button, InputAdornment, TextField, IconButton } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// UTILS
import { getValidationMessages, isPasswordValid } from './passwordValidation';

// RESOURCES
import { ReactComponent as CheckIcon } from "../../../resources/icons/checkIcon.svg";

const CompleteRegister = ({
    user,
    model,
    exceptionMessage,
    handleBackClick,
    handleValueChange,
    completeNewPassword
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    return (
        <React.Fragment>
            <div className="login-info-container">
                <div className="login-info-label">
                    {i18next.t(`authentication.registerComplete.label`)}
                </div>
                {
                    React.Children.toArray(
                        getValidationMessages()
                            .map(message => {
                                return (
                                    <div className="login-info-validation">
                                        <div className={`login-info-validation-icon ${!message.isValid(model.newPassword.trim()) ? 'hidden' : ''}`}>
                                            <CheckIcon />
                                        </div>
                                        <div className="login-info-validation-message">
                                            {message.label}
                                        </div>
                                    </div>
                                );
                            })
                    )
                }
            </div>
            <div className={`input password ${exceptionMessage?.passwordException ? "error" : ""}`}>
                <TextField
                    label={i18next.t(`authentication.registerComplete.password`)}
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    value={model.newPassword || ""}
                    onChange={handleValueChange.bind({}, "newPassword")}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {
                    exceptionMessage?.passwordException &&
                    <div className="c-error"><ErrorIcon />{exceptionMessage.passwordException}</div>
                }
            </div>
            {
                exceptionMessage?.generalException &&
                <div className="c-error"><ErrorIcon />{exceptionMessage.generalException}</div>
            }
            <div className="login-info-actions">
                <div className="action-link" onClick={handleBackClick}>
                    {i18next.t(`authentication.registerComplete.back`)}
                </div>
                <div className="action-button">
                    <Button disabled={!isPasswordValid(model.newPassword.trim())} onClick={completeNewPassword.bind({}, model, user)}>
                        {i18next.t(`authentication.registerComplete.submit`)}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CompleteRegister;