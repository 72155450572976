import React from 'react';
import { useHistory } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import i18next from 'i18next';

import FruitPreparationMenuIcon from './common/icons/menu/FruitPreparationMenuIcon';
import LineOperationsMenuIcon from './common/icons/menu/LineOperationsMenuIcon';
import MaterialAllocationMenuIcon from './common/icons//menu/MaterialAllocationMenuIcon';

const LeftNavbar = ({ open, classes, isNavigationButtonActive, getListIconComponent }) => {
    const history = useHistory();

    return (
        <React.Fragment>
            <ListItem
                className={clsx(classes.navigationButton, isNavigationButtonActive('/fruit-preparation') ? 'active' : '')}
                onClick={() => history.push('/fruit-preparation')}>
                <ListItemIcon>
                    {getListIconComponent('menu.orders', <FruitPreparationMenuIcon id="order-overview-link" />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.orders`)} />
            </ListItem>

            <ListItem
                className={clsx(classes.navigationButton, isNavigationButtonActive('/orders-overview') ? 'active' : '')}
                onClick={() => history.push('/orders-overview')}>
                <ListItemIcon>
                    {getListIconComponent('menu.fruitPreparation', <LineOperationsMenuIcon id="order-overview-link" />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.fruitPreparation`)} />
            </ListItem>

            <ListItem
                className={clsx(classes.navigationButton, isNavigationButtonActive('/material-allocation') ? 'active' : '')}
                onClick={() => history.push('/material-allocation')}>
                <ListItemIcon>
                    {getListIconComponent('menu.materialAllocation', <MaterialAllocationMenuIcon id="order-overview-link" />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.materialAllocation`)} />
            </ListItem>
        </React.Fragment>
    );
};

export default LeftNavbar;
