import React from 'react';

// LIBS
import shortid from 'shortid';

// ICONS
import { ReactComponent as TextOutlinedIcon } from '../../../../resources/icons/textOutlinedIcon.svg';
import { ReactComponent as PictureOutlinedIcon } from '../../../../resources/icons/pictureOutlinedIcon.svg';
import { ReactComponent as DocumentOutlinedIcon } from '../../../../resources/icons/documentOutlinedIcon.svg';
import { ReactComponent as RadioOutlinedIcon } from '../../../../resources/icons/radioOutlinedIcon.svg';
import { ReactComponent as SignOutlinedIcon } from '../../../../resources/icons/signOutlinedIcon.svg';
import { ReactComponent as InputOutlinedInput } from '../../../../resources/icons/inputOutlinedInput.svg';
import { ReactComponent as DropdownOutlinedInput } from '../../../../resources/icons/dropdownOutlinedInput.svg';
import { ReactComponent as DateOutlinedInput } from '../../../../resources/icons/dateOutlinedInput.svg';
import { ReactComponent as PersonOutlinedIcon } from '../../../../resources/icons/personOutlinedIcon.svg';
import { ReactComponent as PhotoIcon } from '../../../../resources/icons/photoIcon.svg';
import { ReactComponent as RichTextOutlinedIcon } from '../../../../resources/icons/richTextOutlinedIcon.svg';

// UTILS
import { truncateString } from '../../../../utils/truncateHelper';

// CONSTANTS
import { configurations } from './configurations';
import { maxTemplateNameSize } from './setup';
import { appLanguage } from '../../../../constants/AppLanguage';

export const inputElements = {
    staticText: 'staticText',
    textInput: 'textInput',
    radio: 'radio',
    mediaInput: 'mediaInput',
    mediaOutput: 'mediaOutput',
    document: 'document',
    dropdown: 'dropdown',
    sign: 'signatureField',
    person: 'person',
    date: 'date',
    formattedTextInput: 'formattedTextInput',
};

export const inputElementSettings = {
    [inputElements.textInput]: {
        icon: <InputOutlinedInput />,
        getConfigurations: () => [
            configurations.label,
            configurations.measurand,
            configurations.unit,
            configurations.defaultValue,
            configurations.helperText,
            configurations.placeholder,
            configurations.startAdornment,
            configurations.endAdornment,
            configurations.lowerTolerance,
            configurations.upperTolerance,
            configurations.targetValue,
            configurations.relevantForValidation,
        ],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: inputElements.textInput,
                defaultValue: '',
                relevantForValidation: 'true',
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.textInput,
                icon: inputElementSettings[inputElements.textInput].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [inputElements.mediaInput]: {
        icon: <PhotoIcon />,
        getConfigurations: () => [configurations.label, configurations.relevantForValidation],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: inputElements.mediaInput,
                defaultValue: '',
                relevantForValidation: 'true',
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.mediaInput,
                icon: inputElementSettings[inputElements.mediaInput].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [inputElements.document]: {
        icon: <DocumentOutlinedIcon />,
        getConfigurations: () => [configurations.label, configurations.relevantForValidation],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: inputElements.document,
                defaultValue: '',
                relevantForValidation: 'true',
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.document,
                icon: inputElementSettings[inputElements.document].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [inputElements.staticText]: {
        icon: <TextOutlinedIcon />,
        getConfigurations: () => [configurations.label],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: inputElements.staticText,
                label: [],
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.staticText,
                icon: inputElementSettings[inputElements.staticText].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [inputElements.radio]: {
        icon: <RadioOutlinedIcon />,
        getConfigurations: () => [
            configurations.label,
            configurations.helperText,
            configurations.checked,
            configurations.selectTargetValue,
            configurations.relevantForValidation,
        ],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: inputElements.radio,
                checked: '',
                relevantForValidation: 'true',
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.radio,
                icon: inputElementSettings[inputElements.radio].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [inputElements.mediaOutput]: {
        icon: <PictureOutlinedIcon />,
        getConfigurations: () => [configurations.label, configurations.mediaUpload],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: inputElements.mediaOutput,
                value: '',
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.mediaOutput,
                icon: inputElementSettings[inputElements.mediaOutput].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [inputElements.dropdown]: {
        icon: <DropdownOutlinedInput />,
        getConfigurations: () => [
            configurations.label,
            configurations.selectValue,
            configurations.helperText,
            configurations.selectTargetValue,
            configurations.relevantForValidation,
            configurations.options,
        ],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: inputElements.dropdown,
                dropdown: {
                    defaultValue: [],
                    options: [
                        [
                            {
                                language: appLanguage.en,
                                value: 'Default',
                            },
                        ],
                    ],
                },
                relevantForValidation: 'true',
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.dropdown,
                icon: inputElementSettings[inputElements.dropdown].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [inputElements.sign]: {
        icon: <SignOutlinedIcon />,
        getConfigurations: () => [configurations.requestedSignatureRole, configurations.signatureRequired],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: inputElements.sign,
                signedBy: {
                    requestedSignatureRole: '',
                    signatureRequired: 'true',
                },
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.sign,
                icon: inputElementSettings[inputElements.sign].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [inputElements.date]: {
        icon: <DateOutlinedInput />,
        getConfigurations: () => [
            configurations.label,
            configurations.dateValue,
            configurations.helperText,
            configurations.relevantForValidation,
        ],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: inputElements.date,
                label: [],
                relevantForValidation: 'true',
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.date,
                icon: inputElementSettings[inputElements.date].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [inputElements.person]: {
        icon: <PersonOutlinedIcon />,
        getConfigurations: () => [
            configurations.label,
            configurations.personValue,
            configurations.helperText,
            configurations.placeholder,
            configurations.autofillPerson,
            configurations.relevantForValidation,
        ],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: inputElements.person,
                label: [],
                relevantForValidation: 'true',
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.person,
                icon: inputElementSettings[inputElements.person].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
    [inputElements.formattedTextInput]: {
        icon: <RichTextOutlinedIcon />,
        getConfigurations: () => [configurations.defaultValue, configurations.relevantForValidation],
        getInitialState: _id => {
            return {
                key: shortid.generate(),
                _id: _id,
                type: inputElements.formattedTextInput,
                defaultValue: '',
                formattedValue: '',
                relevantForValidation: 'true',
            };
        },
        getOption: option => {
            const { templateName, templateId, ...template } = option;
            return {
                _id: templateId,
                value: inputElements.formattedTextInput,
                icon: inputElementSettings[inputElements.formattedTextInput].icon,
                translation: truncateString(templateName, maxTemplateNameSize),
                helperText: templateName,
                template: template,
            };
        },
    },
};
