export const filterObject = (obj, key) => {
    if (!obj) {
        return obj;
    }

    for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] === 'object') {
            filterObject(obj[i], key);
        } else if (i === key) {
            delete obj[key];
        }
    }
    return obj;
};

export const recursiveObjectSearch = (obj, searchKey, results = []) => {
    const r = results;

    if (!obj) {
        return r;
    }

    Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (key === searchKey && typeof value !== 'object') {
            r.push(value);
        } else if (typeof value === 'object') {
            recursiveObjectSearch(value, searchKey, r);
        }
    });

    return r;
};

export const isObject = obj => {
    return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
};
