import moment from 'moment';
import { weekdays } from '../../../../../constants/Weekday';

import { editSchedulerTaskActions } from './editScheduler.actions';

const initialState = {
    machineDataSection: {
        costCenter: null,
        parentAssetId: null,
        childAssetId: null,
        asset: null,
    },
    isValid: true,
    taskModel: null,
};

export const editSchedulerReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case editSchedulerTaskActions.SET_TASK_MODEL: {
            const {
                data: { maintenanceTicket },
            } = payload;

            return {
                ...state,
                taskModel: {
                    ...payload,
                    data: {
                        ...payload.data,
                        dueDateOptions: {
                            ...payload.data?.dueDateOptions,
                            weekday: payload.data?.dueDateOptions?.weekdays[0],
                        },
                        maintenanceTicket: {
                            ...payload.data.maintenanceTicket,
                            jobCategory:
                                payload?.data?.maintenanceTicket?.jobCategory?.length &&
                                payload?.data?.maintenanceTicket?.jobCategory[0]?.name,
                        },
                    },
                },
                machineDataSection: {
                    costCenter: maintenanceTicket.costCenter,
                    parentAssetId: maintenanceTicket.resolvedAsset?.parentAsset?._id || maintenanceTicket.resolvedAsset?._id,
                    childAssetId: maintenanceTicket.resolvedAsset?.parentAsset?._id ? maintenanceTicket.resolvedAsset?._id : null,
                    asset: maintenanceTicket.resolvedAsset,
                },
            };
        }
        case editSchedulerTaskActions.SET_COST_CENTER: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            costCenter: payload,
                            assetId: null,
                        },
                    },
                },
                isValid: false,
                machineDataSection: {
                    ...state.machineDataSection,
                    costCenter: payload,
                    parentAssetId: null,
                    childAssetId: null,
                    asset: null,
                },
            };
        }
        case editSchedulerTaskActions.SET_PARENT_ASSET: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            assetId: payload?._id,
                        },
                    },
                },
                isValid: !!payload?._id,
                machineDataSection: {
                    ...state.machineDataSection,
                    parentAssetId: payload?._id,
                    childAssetId: null,
                    asset: payload,
                },
            };
        }
        case editSchedulerTaskActions.SET_CHILD_ASSET: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            assetId: payload?._id,
                        },
                    },
                },
                machineDataSection: {
                    ...state.machineDataSection,
                    childAssetId: payload?._id,
                    asset: payload,
                },
            };
        }
        case editSchedulerTaskActions.SET_FREQUENCY: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    frequency: payload,
                    data: {
                        ...state.taskModel.data,
                        dueDateOptions: {
                            weekday: weekdays.monday,
                            month: 0,
                            day: 0,
                            hour: 0,
                            minute: 0,
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.SET_TASK_DESCRIPTION: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            description: payload,
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.SET_JOB_CATEGORY: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            jobCategory: payload,
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.SET_RESPOSIBLE: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            resolvedResponsible: { _id: payload },
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.SET_SCHEDULED_START_DATE: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    schedulerStartDate: payload,
                },
            };
        }
        case editSchedulerTaskActions.SET_SCHEDULED_END_DATE: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    schedulerEndDate: payload,
                },
            };
        }
        case editSchedulerTaskActions.SET_IN_ADVANCE_DAYS: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    inAdvanceOptions: { day: payload },
                },
            };
        }
        case editSchedulerTaskActions.SET_DUE_DAY_OPTIONS_WEEKS: {
            const startDate = state.taskModel.schedulerStartDate;

            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        dueDateOptions: {
                            weekday: payload,
                            month: 0,
                            day: 0,
                            hour: startDate ? moment(startDate)?.hour() : 0,
                            minute: startDate ? moment(startDate)?.minutes() : 0,
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.SET_DUE_DAY_OPTIONS_DAYS: {
            const startDate = state.taskModel.schedulerStartDate;

            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        dueDateOptions: {
                            weekday: weekdays.monday,
                            month: 0,
                            day: payload,
                            hour: startDate ? moment(startDate)?.hour() : 0,
                            minute: startDate ? moment(startDate)?.minutes() : 0,
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.UPDATE_CHECK_LIST_ENTRY: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            checklists: state.taskModel.data.maintenanceTicket.checklists.map((checklist, checklistIndex) => {
                                if (checklistIndex !== payload.checklistIndex) {
                                    return checklist;
                                }

                                return {
                                    ...checklist,
                                    entries: checklist.entries.map((entry, entryIndex) => {
                                        if (entryIndex !== payload.entryIndex) {
                                            return entry;
                                        }

                                        return {
                                            ...entry,
                                            [payload.path]: payload.value,
                                        };
                                    }),
                                };
                            }),
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.UPDATE_CHECK_LIST: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            checklists: state.taskModel.data.maintenanceTicket.checklists.map((checklist, checklistIndex) => {
                                if (checklistIndex !== payload.checklistIndex) {
                                    return checklist;
                                }

                                return {
                                    ...checklist,
                                    [payload.path]: payload.value,
                                };
                            }),
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.ADD_CHECK_LIST_ENTRY: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            checklists: state.taskModel.data.maintenanceTicket.checklists.map((checklist, checklistIndex) => {
                                if (checklistIndex !== payload.checklistIndex) {
                                    return checklist;
                                }

                                return {
                                    ...checklist,
                                    entries: [...checklist.entries, payload.entryModel],
                                };
                            }),
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.REMOVE_CHECK_LIST_ENTRY: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            checklists: state.taskModel.data.maintenanceTicket.checklists.map((checklist, checklistIndex) => {
                                if (checklistIndex !== payload.checklistIndex) {
                                    return checklist;
                                }

                                return {
                                    ...checklist,
                                    entries: checklist.entries.filter((_, index) => index !== payload.entryIndex),
                                };
                            }),
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.REMOVE_CHECK_LIST: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            checklists: state.taskModel.data.maintenanceTicket.checklists.filter(
                                (_, checklistIndex) => checklistIndex !== payload.checklistIndex
                            ),
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.BULK_ADD_CHECK_LIST: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            checklists: [...(state.taskModel.data.maintenanceTicket.checklists || []), ...payload],
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.ADD_CHECK_LIST: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            checklists: [...(state.taskModel.data.maintenanceTicket.checklists || []), payload],
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.REMOVE_ATTACHMENT: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            attachments: state.taskModel.data.maintenanceTicket.attachments.filter(file => file !== payload),
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.ADD_ATTACHMENT: {
            return {
                ...state,
                taskModel: {
                    ...state.taskModel,
                    data: {
                        ...state.taskModel.data,
                        maintenanceTicket: {
                            ...state.taskModel.data.maintenanceTicket,
                            attachments: [...state.taskModel.data.maintenanceTicket.attachments, payload],
                        },
                    },
                },
            };
        }
        case editSchedulerTaskActions.SET_IS_VALID: {
            return {
                ...state,
                isValid: payload,
            };
        }

        default:
            return state;
    }
};
