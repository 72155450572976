import moment from 'moment';
import { convertedMonthFormat, fullYearFormat, shortMonthFormat } from '../constants';
import { maintenanceReportsActions } from './maintenanceReports.actions';

const currentDate = moment();
const currentMonth = currentDate.format(shortMonthFormat);
const currentYear = currentDate.format(fullYearFormat);
const currentConvertedMonth = currentDate.format(convertedMonthFormat);

const maintenanceReportsInitialState = {
    reportsData: [],
    filters: {
        timestamp: null,
        timestampFrom: null,
        timestampTo: null,
    },
    filterDataByMonth: {
        month: currentMonth,
        year: currentYear,
        convertedMonth: currentConvertedMonth,
        firstDay: new Date(currentYear, currentConvertedMonth - 1, 1),
        lastDay: new Date(currentYear, currentConvertedMonth, 1),
    },
};

export const maintenanceReportsReducer = (state = maintenanceReportsInitialState, { type, payload }) => {
    switch (type) {
        case maintenanceReportsActions.SET_REPORTS_DATA: {
            return {
                ...state,
                reportsData: payload,
            };
        }
        case maintenanceReportsActions.SET_REPORTS_FILTERS: {
            return {
                ...state,
                filters: payload,
            };
        }
        case maintenanceReportsActions.SET_FILTER_DATA_BY_MONTH: {
            return {
                ...state,
                filterDataByMonth: {
                    ...payload,
                    firstDay: new Date(payload.year, payload.convertedMonth - 1, 1),
                    lastDay: new Date(payload.year, payload.convertedMonth, 1),
                },
            };
        }

        default:
            return state;
    }
};
