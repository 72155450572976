import { materialsActions } from './material.actions';

import { defaultItemsPerPage, defaultCurrentPage } from '../constants';

const initialState = {
    searchTerm: '',
    materials: [],
    bulkDelete: {
        checkedItemsId: [],
        isActive: false,
    },
    itemsPerPage: defaultItemsPerPage,
    currentPage: defaultCurrentPage,
};

export const materialReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case materialsActions.SET_SEARCH_TERM: {
            return {
                ...state,
                searchTerm: payload,
                currentPage: defaultCurrentPage,
            };
        }
        case materialsActions.RESET_TO_INITIAL_STATE: {
            return {
                ...initialState,
            };
        }
        case materialsActions.TOOGLE_BULK_DELETE_MODE_ACTIVE: {
            return {
                ...state,
                bulkDelete: {
                    ...state.bulkDelete,
                    checkedItemsId: [],
                    isActive: !state.bulkDelete.isActive,
                },
            };
        }
        case materialsActions.SET_MATERIALS: {
            return {
                ...state,
                materials: payload,
            };
        }
        case materialsActions.REMOVE_ITEM_FROM_LIST: {
            return {
                ...state,
                materials: state.materials.filter(item => item._id !== payload),
            };
        }
        case materialsActions.CHECK_ITEM: {
            const isChecked = state.bulkDelete.checkedItemsId.some(materialId => materialId === payload);

            return {
                ...state,
                bulkDelete: {
                    ...state.bulkDelete,
                    checkedItemsId: isChecked
                        ? state.checkedItemsId.filter(materialId => materialId !== payload)
                        : [...state.bulkDelete.checkedItemsId, payload],
                },
            };
        }
        case materialsActions.DELETE_CHECKED_ITEMS: {
            const checkedItems = state.bulkDelete.checkedItemsId;

            return {
                ...state,
                checkedItemsId: [],
                materials: state.materials.filter(item => !checkedItems.some(materialId => materialId === item._id)),
            };
        }
        case materialsActions.UPDATE_MATERIAL: {
            return {
                ...state,
                materials: state.materials.map(material => (material._id !== payload?._id ? material : { ...material, ...payload })),
            };
        }
        case materialsActions.SET_CURRENT_PAGE: {
            return {
                ...state,
                currentPage: payload,
            };
        }
        case materialsActions.SET_ITEMS_PER_PAGE: {
            return {
                ...state,
                itemsPerPage: payload,
            };
        }

        default:
            return state;
    }
};
