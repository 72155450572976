import React from "react";

import SignIn from "../components/common/authentication/SignIn";
import WorkbookSignIn from "../components/workbook/SignIn";

import { APP_NAME } from "../utils/env";
import { reactAppName } from "../constants/Config";

const SignInProvider = props => {
    const application = APP_NAME;

    if (application === reactAppName.workbook) {
        return <WorkbookSignIn {...props} />
    }

    return (
        <SignIn {...props} />
    );
};

export default SignInProvider;
