
import { APP_NAME } from "../utils/env";

import { reactAppName } from "../constants/Config";
import { languageOptions as defaultLanguageOptions, workbookLanguageOptions, processLanguageOptions } from "../constants/Language";

export const getLanguageOptions = props => {
    const application = APP_NAME;

    if (application === reactAppName.workbook) {
        return workbookLanguageOptions;
    }

    if (application === reactAppName.process) {
        return processLanguageOptions;
    }

    return defaultLanguageOptions;
};