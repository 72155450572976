export const workshopsActions = {
    SET_STATUS_FILTER: 'WORKSHOPS/SET_STATUS_FILTER',
    SET_PROCESS_ORDERS_FILTER: 'WORKSHOPS/SET_PROCESS_ORDERS_FILTER',
    SET_PERIOD_FILTER: 'WORKSHOPS/SET_PERIOD_FILTER',
    SET_CRITICAL_TASK_FILTER: 'WORKSHOPS/SET_CRITICAL_TASK_FILTER',

    SET_WORKSHOPS_CHIPS_FILTER: 'WORKSHOPS/SET_WORKSHOPS_CHIPS_FILTER',
    SET_IS_ACTIVE_WORKSHOPS_CHIPS_FILTER: 'WORKSHOPS/SET_IS_ACTIVE_WORKSHOPS_CHIPS_FILTER',
    SET_WORKSHOPS_CHIPS: 'WORKSHOPS/SET_WORKSHOPS_CHIPS',

    RESET_WORKSHOPS_FILTERS: 'WORKSHOPS/RESET_WORKSHOPS_FILTERS',

    TOOGLE_TICKET_MODAL: 'WORKSHOPS/TOOGLE_TICKET_MODAL',
};

export const setStatusFilter = payload => {
    return {
        type: workshopsActions.SET_STATUS_FILTER,
        payload,
    };
};

export const setProcessOrdersFilter = payload => {
    return {
        type: workshopsActions.SET_PROCESS_ORDERS_FILTER,
        payload,
    };
};

export const setPeriodFilter = payload => {
    return {
        type: workshopsActions.SET_PERIOD_FILTER,
        payload,
    };
};

export const setCriticalTaskFilter = payload => {
    return {
        type: workshopsActions.SET_CRITICAL_TASK_FILTER,
        payload,
    };
};

export const setWorkshopsChipsFilter = payload => {
    return {
        type: workshopsActions.SET_WORKSHOPS_CHIPS_FILTER,
        payload,
    };
};

export const setIsActiveWorkshopsChipsFilter = payload => {
    return {
        type: workshopsActions.SET_IS_ACTIVE_WORKSHOPS_CHIPS_FILTER,
        payload,
    };
};

export const setWorkshopsChips = payload => {
    return {
        type: workshopsActions.SET_WORKSHOPS_CHIPS,
        payload,
    };
};

export const resetWorkshopsFilters = () => {
    return {
        type: workshopsActions.RESET_WORKSHOPS_FILTERS,
    };
};

export const toogleTicketModalIndex = payload => {
    return {
        type: workshopsActions.TOOGLE_TICKET_MODAL,
        payload,
    };
};
