import React from "react";
import i18next from "i18next";

import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BuildIcon from '@material-ui/icons/Build';

export const ticketStatus = {
  open: "open",
  inWork: "inWork",
  done: "done"
};

export const ticketStatusTranslations = {
  [ticketStatus.open]: 'common.ticket-status.open',
  [ticketStatus.inWork]: 'common.ticket-status.inWork',
  [ticketStatus.done]: 'common.ticket-status.done',
}

export const ticketStatusOptions = () => [
  {
    label: i18next.t(ticketStatusTranslations[ticketStatus.open]),
    value: ticketStatus.open
  },
  {
    label: i18next.t(ticketStatusTranslations[ticketStatus.inWork]),
    value: ticketStatus.inWork
  },
  {
    label: i18next.t(ticketStatusTranslations[ticketStatus.done]),
    value: ticketStatus.done
  }
];

export const ticketStatusIcons = {
  [ticketStatus.open]: <RadioButtonUncheckedIcon />,
  [ticketStatus.inWork]: <BuildIcon />,
  [ticketStatus.done]: <CheckCircleIcon />
};
