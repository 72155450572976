import { stepModalActions } from './stepModal.actions';

import update from '../../../../../../redux/update';

import { instanceStepModalTabsIndex } from '../instanceStepModalContants';

const stepModalReducerInitialState = {
    model: null,
    projectId: null,
    routingId: null,
    activeTab: instanceStepModalTabsIndex.ORDER_INFORMATION_TAB,
    type: null,
    showGoToWorkshopButton: false,
    productionOrderSchedulingData: null,
    processRoutingSchedulingData: null,
    processRoutingProgress: null,
};

export const stepModalReducer = (state = stepModalReducerInitialState, { type, payload }) => {
    switch (type) {
        case stepModalActions.SET_MODEL: {
            const { model, projectId, routingId, type, showGoToWorkshopButton } = payload;

            return {
                ...state,
                model,
                projectId,
                routingId,
                type,
                showGoToWorkshopButton,
            };
        }

        case stepModalActions.SET_ACTIVE_TAB: {
            return {
                ...state,
                activeTab: payload,
            };
        }

        case stepModalActions.HANDLE_UPDATE: {
            const newModel = update.set(state.model, payload.path, payload.value);

            return {
                ...state,
                model: newModel,
            };
        }

        case stepModalActions.DELETE_MEDIA_INPUT: {
            return {
                ...state,
                model: {
                    ...state.model,
                    mediaInputs: state.model?.mediaInputs?.filter(media => media._id !== payload.mediaId),
                },
            };
        }

        case stepModalActions.SET_MEDIA_INPUTS: {
            return {
                ...state,
                model: {
                    ...state.model,
                    mediaInputs: payload,
                },
            };
        }

        case stepModalActions.CHANGE_PROCESS_STEP_DURATION: {
            const routing = payload.productionOrder?.processRoutings?.find(routing => routing._id === state?.routingId);
            const processStep = routing?.processSteps?.find(step => step._id === state?.model?._id);

            return {
                ...state,
                model: {
                    ...state.model,
                    progress: processStep?.progress,
                    plannedDurations: {
                        processTime: processStep?.plannedDurations?.processTime,
                    },
                    schedulingData: processStep?.schedulingData,
                },
                productionOrderSchedulingData: payload.productionOrder.schedulingData,
                processRoutingSchedulingData: routing?.calculatedSchedulingData,
                processRoutingProgress: routing?.progress,
            };
        }

        case stepModalActions.CHANGE_PROCESS_STEP_RESPONSIBLE: {
            const routing = payload.productionOrder?.processRoutings?.find(routing => routing._id === state?.routingId);
            const processStep = routing?.processSteps?.find(step => step._id === state?.model?._id);

            return {
                ...state,
                model: {
                    ...state.model,
                    responsible: processStep?.responsible,
                    resolvedResponsible: processStep?.resolvedResponsible,
                },
            };
        }

        case stepModalActions.UPDATE_MATERIAL: {
            const { materialId, path, value } = payload;

            return {
                ...state,
                model: {
                    ...state.model,
                    procurementData: {
                        ...state.model?.procurementData,
                        materials: state?.model?.procurementData?.materials?.map(material => {
                            if (materialId !== material?._id) {
                                return material;
                            }

                            return {
                                ...material,
                                [path]: value,
                            };
                        }),
                    },
                },
            };
        }

        case stepModalActions.DELETE_MATERIAL: {
            return {
                ...state,
                model: {
                    ...state.model,
                    procurementData: {
                        ...state.model?.procurementData,
                        materials: state?.model?.procurementData?.materials?.filter(material => material?._id !== payload),
                    },
                },
            };
        }

        case stepModalActions.ADD_MATERIAL: {
            return {
                ...state,
                model: {
                    ...state.model,
                    procurementData: {
                        ...(state?.model?.procurementData || {}),
                        materials: [...(state?.model?.procurementData?.materials || []), payload],
                    },
                },
            };
        }

        case stepModalActions.SET_MODAL_TO_INITIAL_STATE: {
            return stepModalReducerInitialState;
        }

        default:
            return state;
    }
};
