//Function to get the boolean value that an object is an array and is not empty
export const isArrayWithItems = arr => {
    if (!arr) {
        return false;
    }

    return Array.isArray(arr) && arr.length ? true : false;
};

export const concatArrays = (arr1, arr2) => {
    return Array.isArray(arr1) && arr1.concat(arr2);
};

export const findLastIndex = (array, callback) => {
    const index = array.slice().reverse().findIndex(callback);
    const count = array.length - 1;
    const finalIndex = index >= 0 ? count - index : index;
    return finalIndex;
};

export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};

export const urlBase64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

export const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const flatten = arr => {
    return arr?.reduce(function (flat, toFlatten) {
        return flat?.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
};

export const getArrayLastItem = arr => {
    if (!isArrayWithItems(arr)) {
        return null;
    }

    return arr[arr.length - 1];
};
