import i18next from 'i18next';
import ApprovedIcon from '../components/icons/ApprovedIcon';
import PendingIcon from '../components/icons/PendingIcon';
import RejectedIcon from '../components/icons/RejectedIcon';

export const defaultItemsPerPage = 10;
export const defaultPage = 1;

export const defaultSortKey = 'headerData.releaseData.releasedBy.releasedAt';
export const orderNoColumnKey = 'headerData.orderNo';
export const materialNameColumnKey = 'headerData.resolvedMaterial.materialName';
export const plannedStartDateColumnKey = 'schedulingData.scheduledReleaseDate';
export const orderReleaseStatusColumnKey = 'headerData.releaseData.releaseStatus';

export const ProductionOrderReleaseStatus = {
    DRAFT: 'draft',
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    ARCHIVED: 'archived',
};

export const ProductionOrderReleaseStatusLabel = {
    [ProductionOrderReleaseStatus.ARCHIVED]: i18next.t('releases.table.releaseStatus.archived'),
    [ProductionOrderReleaseStatus.DRAFT]: i18next.t('releases.table.releaseStatus.draft'),
    [ProductionOrderReleaseStatus.PENDING]: i18next.t('releases.table.releaseStatus.pending'),
    [ProductionOrderReleaseStatus.APPROVED]: i18next.t('releases.table.releaseStatus.approved'),
    [ProductionOrderReleaseStatus.REJECTED]: i18next.t('releases.table.releaseStatus.rejected'),
};

export const ProductionOrderReleaseStatusOptions = [
    {
        title: ProductionOrderReleaseStatusLabel[ProductionOrderReleaseStatus.DRAFT],
        value: ProductionOrderReleaseStatus.DRAFT,
    },
    {
        title: ProductionOrderReleaseStatusLabel[ProductionOrderReleaseStatus.APPROVED],
        value: ProductionOrderReleaseStatus.APPROVED,
    },
    {
        title: ProductionOrderReleaseStatusLabel[ProductionOrderReleaseStatus.PENDING],
        value: ProductionOrderReleaseStatus.PENDING,
    },
    {
        title: ProductionOrderReleaseStatusLabel[ProductionOrderReleaseStatus.REJECTED],
        value: ProductionOrderReleaseStatus.REJECTED,
    },
];

export const ProductionOrderReleaseStatusBackgroundColor = {
    [ProductionOrderReleaseStatus.ARCHIVED]: '#8D90AF',
    [ProductionOrderReleaseStatus.DRAFT]: '#8D90AF',
    [ProductionOrderReleaseStatus.PENDING]: '#EF7A12',
    [ProductionOrderReleaseStatus.APPROVED]: '#0EBF5A',
    [ProductionOrderReleaseStatus.REJECTED]: '#F5325C',
};

export const ProductionOrderReleaseStatusIcons = {
    [ProductionOrderReleaseStatus.APPROVED]: () => <ApprovedIcon />,
    [ProductionOrderReleaseStatus.PENDING]: () => <PendingIcon />,
    [ProductionOrderReleaseStatus.REJECTED]: () => <RejectedIcon />,
};

export const orderManagementArchivedFilterOptions = [
    {
        title: i18next.t('releases.filters.archivedOptions.all'),
        value: undefined,
    },
    {
        title: i18next.t('releases.filters.archivedOptions.active'),
        value: false,
    },
    {
        title: i18next.t('releases.filters.archivedOptions.archived'),
        value: true,
    },
];
