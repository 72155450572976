export const projectsActions = {
    SET_PROGRESS_USERS: 'PROJECTS_OVERVIEW/SET_PROGRESS_USERS',
    SET_PROJECTS: 'PROJECTS_OVERVIEW/SET_PROJECTS',
    RESET_PROJECTS: 'PROJECTS_OVERVIEW/RESET_PROJECTS',
    SET_ROUTING_RESPONSIBLE: 'PROJECTS_OVERVIEW/SET_ROUTING_RESPONSIBLE',
    SET_ROUTING_WITH_STEPS: 'PROJECTS_OVERVIEW/SET_ROUTING_WITH_STEPS',
    SET_CURRENT_PROJECT: 'PROJECTS_OVERVIEW/SET_CURRENT_PROJECT',
    SET_DURATION: 'PROJECTS_OVERVIEW/SET_DURATION',
};

export const setProgressUsers = payload => {
    return {
        type: projectsActions.SET_PROGRESS_USERS,
        payload,
    };
};

export const setProjectsData = payload => {
    return {
        type: projectsActions.SET_PROJECTS,
        payload,
    };
};

export const resetProjectsData = () => {
    return {
        type: projectsActions.RESET_PROJECTS,
    };
};

export const setRoutingResponsible = payload => {
    return {
        type: projectsActions.SET_ROUTING_RESPONSIBLE,
        payload,
    };
};

export const setRouting = payload => {
    return {
        type: projectsActions.SET_ROUTING_WITH_STEPS,
        payload,
    };
};

export const setCurrentProject = payload => {
    return {
        type: projectsActions.SET_CURRENT_PROJECT,
        payload,
    };
};

export const setDuration = payload => {
    return {
        type: projectsActions.SET_DURATION,
        payload,
    };
};

export const setNewProcessStepLongText = payload => {
    return {
        type: projectsActions.SET_NEW_PROCESS_STEP_LONG_TEXT,
        payload,
    };
};
