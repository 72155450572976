import { combineReducers } from 'redux';

import { machineMaintenanceTicketsReducer } from './tickets/reducer';
import { machineMaintenanceReportsReducer } from './reports/store/machine-maintenance-reports.reducer';
import { machineMaintenanceAssetsReducer } from './assets/Assets.reducers';
import { machineMaintenanceSchedulerReducer } from './scheduler/ShedulerReducer';

export const machineMaintenanceReducer = combineReducers({
  tickets: machineMaintenanceTicketsReducer,
  reports: machineMaintenanceReportsReducer,
  assets: machineMaintenanceAssetsReducer,
  scheduler: machineMaintenanceSchedulerReducer
});