import { routingsOverviewActions } from './routingOverview.actions';

const routingsOverview = {
    searchTerm: '',
    selectedTypes: [],
};

export const routingsOverviewReducer = (state = routingsOverview, { type, payload }) => {
    switch (type) {
        case routingsOverviewActions.CHANGE_SELECTED_TYPES: {
            return {
                ...state,
                selectedTypes: payload,
            };
        }
        case routingsOverviewActions.CHANGE_SEARCH_TERM: {
            return {
                ...state,
                searchTerm: payload,
            };
        }
        case routingsOverviewActions.RESET_TO_INITIAL_STATE: {
            return {
                ...routingsOverview,
            };
        }

        default:
            return state;
    }
};
