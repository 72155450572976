import i18next from 'i18next';

export const instanceStepModalTabsIndex = {
    ORDER_INFORMATION_TAB: 0,
    BULK_ORDER_TAB: 1,
    ATTACHMENTS_TAB: 2,
};

export const currencyOptions = [
    {
        title: '$ US dollar',
        value: 'USD',
    },
    {
        title: '€ EUR',
        value: 'EUR',
    },
];

export const orderUnitOptions = [
    { value: 'kilograms', title: i18next.t('materials.storageSection.units.kilograms') },
    { value: 'grams', title: i18next.t('materials.storageSection.units.grams') },
    { value: 'liters', title: i18next.t('materials.storageSection.units.liters') },
    { value: 'pieces', title: i18next.t('materials.storageSection.units.pieces') },
    { value: 'meters', title: i18next.t('materials.storageSection.units.meters') },
];
