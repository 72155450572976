export const routingsOverviewActions = {
    CHANGE_SEARCH_TERM: 'ROUTINGS_OVERVIEW/CHANGE_SEARCH_TERM',
    CHANGE_SELECTED_TYPES: 'ROUTINGS_OVERVIEW/SET_SELECTED_TYPES',
    RESET_TO_INITIAL_STATE: 'ROUTINGS_OVERVIEW/RESET_TO_INITIAL_STATE',
};

export const changeSearchTerm = payload => ({
    type: routingsOverviewActions.CHANGE_SEARCH_TERM,
    payload,
});

export const changeSelectedTypes = payload => ({
    type: routingsOverviewActions.CHANGE_SELECTED_TYPES,
    payload,
});

export const resetRoutingOverviewReducerToInitialState = () => ({
    type: routingsOverviewActions.RESET_TO_INITIAL_STATE,
});
