import React from 'react';

// NOTE: Material-UI is using ForwardRef (Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?)
const FruitPreparationMenuIcon = React.forwardRef(() => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.13 15.13L17.82 4.15C17.92 3.55 17.45 3 16.83 3H14C14 2.45 13.55 2 13 2H11C10.45 2 10 2.45 10 3H5C3.9 3 3 3.9 3 5V9C3 10.1 3.9 11 5 11H7.23L7.87 15.13C6.74 16.05 6 17.43 6 19V20C6 21.1 6.9 22 8 22H16C17.1 22 18 21.1 18 20V19C18 17.43 17.26 16.05 16.13 15.13ZM5 9V5H6.31L6.93 9H5ZM12 19C11.45 19 11 18.55 11 18C11 17.45 11.45 17 12 17C12.55 17 13 17.45 13 18C13 18.55 12.55 19 12 19ZM14.29 14H9.72L8.33 5H15.67L14.29 14Z"
                fill="#B5BECC"
            />
        </svg>
    );
});

export default FruitPreparationMenuIcon;
