import { appLanguage } from "../../../../constants/AppLanguage";

export const headerGridTranslations = {
    drawingNo: {
        [appLanguage.en]: "Drawing No.:",
        [appLanguage.de]: "Zeichnungs-Nr.:"
    },
    drawingIndex: {
        [appLanguage.en]: "Drawing-Index:",
        [appLanguage.de]: "Zeichnungs-Index:"
    },
    supplier: {
        [appLanguage.en]: "Supplier:",
        [appLanguage.de]: "Name des Lieferanten:"
    },
    purchaseOrderNo: {
        [appLanguage.en]: "Purchase Order No.:",
        [appLanguage.de]: "Siemens Bestell-Nr.:"
    },
    purchasePositionNo: {
        [appLanguage.en]: "Purchase Position No.:",
        [appLanguage.de]: "Siemens Bestellpos-Nr.:"
    },
    projectNoSupplier: {
        [appLanguage.en]: "Project No. Supplier:",
        [appLanguage.de]: "Auftrags-Nr. Lieferant"
    },
    itemIdentification: {
        [appLanguage.en]: "Item Identification:",
        [appLanguage.de]: "Bauteilkenn-Nr.:"
    },
    serviceNotificationNumber: {
        [appLanguage.en]: "Service Notification No.:",
        [appLanguage.de]: "Servicemeldungs-Nr.:"
    },
    originalOrder: {
        [appLanguage.en]: "Original Order- / Project No.:",
        [appLanguage.de]: "Ursprungswerk- / Auftrags-Nr.:"
    }
};