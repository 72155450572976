export const userSettingsActions = {
    SET_CURRENT_USER_DATA: 'CURRENT_USER_DATA/SET_CURRENT_USER_DATA',
    SET_CURRENT_USER_SETTINGS_FIELD: 'CURRENT_USER_DATA/SET_CURRENT_USER_SETTINGS_FIELD',
    SET_CURRENT_USER_FIELD: 'CURRENT_USER_DATA/SET_CURRENT_USER_FIELD',
};

export const setCurrentUserData = (payload) => {
    return {
        type: userSettingsActions.SET_CURRENT_USER_DATA,
        payload
    }
};

export const setCurrentUserSettingsField = (payload) => {
    return {
        type: userSettingsActions.SET_CURRENT_USER_SETTINGS_FIELD,
        payload
    }
};

export const setCurrentUserField = (payload) => {
    return {
        type: userSettingsActions.SET_CURRENT_USER_FIELD,
        payload
    }
};
