import React from "react";

const LinkParagraph = ({ textBefore, textLink, textAfter, link }) => {
    return (
        <span>
            {textBefore && <span>{textBefore}</span>}
            <a href={link} target="_blank" rel="noreferrer">{textLink}</a>
            {textAfter && <span>{textAfter}</span>}
        </span>
    );
};

export default LinkParagraph;
