import { machineMaintenanceAssetsFiltersActions } from './machineMaintenanceAssets.actions';

const machineMaintenanceAssetsFiltersInitialState = {
    currentCostCenter: null,
    currentSearch: null,
    isLoadingData: false,
    paginatorPage: 1,
    isSearchByChildAsset: false,
};

export const assetsFiltersReducer = (state = machineMaintenanceAssetsFiltersInitialState, { type, payload }) => {
    switch (type) {
        case machineMaintenanceAssetsFiltersActions.SET_CURRENT_COST_CENTER: {
            return {
                ...state,
                currentCostCenter: payload,
            };
        }
        case machineMaintenanceAssetsFiltersActions.SET_IS_SEARCH_BY_CHILD_ASSET: {
            return {
                ...state,
                isSearchByChildAsset: payload,
            };
        }
        case machineMaintenanceAssetsFiltersActions.SET_SEARCH_VALUE: {
            return {
                ...state,
                currentSearch: payload,
            };
        }
        case machineMaintenanceAssetsFiltersActions.SET_IS_LOADING_DATA: {
            return {
                ...state,
                isLoadingData: payload,
            };
        }
        case machineMaintenanceAssetsFiltersActions.SET_PAGINATOR_PAGE: {
            return {
                ...state,
                paginatorPage: payload,
            };
        }
        case machineMaintenanceAssetsFiltersActions.RESET_ASSETS_FILTERS: {
            return {
                ...state,
                ...machineMaintenanceAssetsFiltersInitialState,
            };
        }

        default:
            return state;
    }
};
