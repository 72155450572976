import React from 'react';

import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

import { Auth } from 'aws-amplify';
import i18next from 'i18next';

import { ReactComponent as MicrosoftLogo } from './Icons/ms-symbollockup_mssymbol_19.svg';

import { AZUREAD_IDENTITY_PROVIDER_NAME } from '../../../../utils/env';

import './AzureADSignIn.scss';

const AdamosSignIn = () => {
    const handleFeredatedSignIn = async provider => {
        try {
            // returns user credentials as an object
            await Auth.federatedSignIn({ provider });
        } catch (err) {
            console.error('Something went wrong while logging in: ', err);
        }
    };

    return (
        <div className="AzureADSignIn-root">
            <Button className="azureADSignIn" onClick={async () => await handleFeredatedSignIn(AZUREAD_IDENTITY_PROVIDER_NAME)}>
                <MicrosoftLogo className="azureADLogo" />
                <Typography className="labelText">{i18next.t(`authentication.signIn.azureADSignIn`)}</Typography>
                <div className="loginIcon" />
            </Button>
        </div>
    );
};

export default AdamosSignIn;
