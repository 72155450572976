import React from 'react';

import i18next from 'i18next';

import { ReactComponent as EditIcon } from '../../../resources/icons/editIcon.svg';
import { ReactComponent as TranslationIcon } from '../../../resources/icons/translationIcon.svg';
import { ReactComponent as FileIcon } from '../../../resources/icons/fileIcon.svg';
import { ReactComponent as ExportIcon } from '../../../resources/icons/exportIcon.svg';

import { filterComparerType, filterTypes, tableColumnTypes } from '../../common/templates/constants';
import { APP_CUSTOMER } from '../../../utils/env';
import { reactAppCustomer } from '../../../constants/Config';

export const inspectionPlanOperations = {
    build: 'build',
    edit: 'edit',
    import: 'import',
};

export const inspectionPlanHandlerOperations = {
    add: 'add',
    update: 'update',
    remove: 'remove',
};

export const inspectionPlanHandlerPaths = {
    assemblyGroup: 'assemblyGroup.$[assemblyGroupId]',
    assemblyGroupArray: 'assemblyGroup',
    manufacturingStep: 'assemblyGroup.$[assemblyGroupId].manufacturingStep.$[manufacturingStepId]',
    manufacturingStepArray: 'assemblyGroup.$[assemblyGroupId].manufacturingStep',
    inspectionOrder: 'assemblyGroup.$[assemblyGroupId].manufacturingStep.$[manufacturingStepId].inspectionOrders.$[inspectionOrderId]',
    inspectionOrderArray: 'assemblyGroup.$[assemblyGroupId].manufacturingStep.$[manufacturingStepId].inspectionOrders',
};

export const customerInspectionPossibleOptions = [
    {
        key: 'yes',
        value: 'true',
        translation:
            'protocol-iut-plan-builder.right-panel.assembly-group.manufacturing-step.inspection-orders.customerInspectionPossible.options.yes',
    },
    {
        key: 'no',
        value: 'false',
        translation:
            'protocol-iut-plan-builder.right-panel.assembly-group.manufacturing-step.inspection-orders.customerInspectionPossible.options.no',
    },
];

export const tabs = {
    edit: 'edit',
    translation: 'translation',
    pdf: 'pdf',
    export: 'export',
};

export const tabOptions = [
    {
        value: tabs.edit,
        icon: <EditIcon />,
        translation: 'protocol-iut-plan-builder.top-panel.tabs.edit',
    },
    {
        value: tabs.translation,
        icon: <TranslationIcon />,
        translation: 'protocol-iut-plan-builder.top-panel.tabs.translation',
    },
    {
        value: tabs.pdf,
        icon: <FileIcon />,
        translation: 'protocol-iut-plan-builder.top-panel.tabs.pdf',
    },
    {
        value: tabs.export,
        icon: <ExportIcon />,
        translation: 'protocol-iut-plan-builder.top-panel.tabs.export',
    },
];

export const inspectionOrderInstructionOptions = {
    createProtocol: {
        label: 'protocol-iut-plan-builder.right-panel.assembly-group.manufacturing-step.inspection-orders.protocol-template.create-button',
    },
};

export const protocolTemplateSelectColumns = [
    {
        key: 'selectCheckbox',
        padding: 'checkbox',
        columnType: tableColumnTypes.action,
        minWidth: 50,
    },
    {
        key: 'name',
        title: `protocol-iut-plan-builder.right-panel.assembly-group.manufacturing-step.inspection-orders.protocol-templates-modal.table.columns.name`,
        columnType: tableColumnTypes.string,
        orderBy: 'name',
    },
    {
        key: 'title',
        title: `protocol-iut-plan-builder.right-panel.assembly-group.manufacturing-step.inspection-orders.protocol-templates-modal.table.columns.title`,
        columnType: tableColumnTypes.string,
        orderBy: 'title',
    },
    {
        key: 'documentStatus',
        title: `protocol-iut-plan-builder.right-panel.assembly-group.manufacturing-step.inspection-orders.protocol-templates-modal.table.columns.documentStatus`,
        columnType: tableColumnTypes.string,
        orderBy: 'titldocumentStatuse',
    },
    {
        key: 'createdAt',
        title: `protocol-iut-plan-builder.right-panel.assembly-group.manufacturing-step.inspection-orders.protocol-templates-modal.table.columns.createdAt`,
        columnType: tableColumnTypes.date,
        orderBy: 'protocolTemplateCreatedBy.createdAt',
    },
    {
        key: 'modifiedAt',
        title: `protocol-iut-plan-builder.right-panel.assembly-group.manufacturing-step.inspection-orders.protocol-templates-modal.table.columns.modifiedAt`,
        columnType: tableColumnTypes.date,
        orderBy: '_protocolTemplateModifiedBy.modifiedAt',
    },
];

export const protocolTemplateSelectFilters = [
    {
        key: 'name',
        type: filterTypes.string,
        filterKey: 'value',
        filterComparerType: filterComparerType.single,
        getValue: x => {
            return x.name;
        },
    },
    {
        key: 'title',
        type: filterTypes.string,
        filterKey: 'value',
        filterComparerType: filterComparerType.single,
        getValue: x => {
            return x.title;
        },
    },
    {
        key: 'documentStatus',
        type: filterTypes.objectArray,
        filterKey: 'value',
        filterComparerType: filterComparerType.single,
        getValue: x => {
            return x.documentStatus;
        },
    },
    {
        key: 'createdAt',
        type: filterTypes.dateArray,
        filterKey: 'value',
        filterComparerType: filterComparerType.between,
        getValue: x => {
            return x.protocolTemplateCreatedBy?.createdAt;
        },
    },
];

export const protocolTemplateSelectCriteries = [
    {
        key: 'name',
        getValue: x => {
            return x.name || '';
        },
    },
    {
        key: 'title',
        getValue: x => {
            return x.title || '';
        },
    },
];

export const metadataTabs = {
    main: 'main',
    materials: 'materials',
    dwmaterials: 'dwmaterials',
    inspectors: 'inspectors',
    documentData: 'documentData',
    comments: 'comments',
};

export const getMetadataTabOptions = () => {
    let tabs = [
        {
            key: metadataTabs.main,
            label: i18next.t('protocol-iut-plan-builder.metadata.tabs.main'),
        },
    ];

    if (APP_CUSTOMER === reactAppCustomer.gtcb) {
        tabs.push({
            key: metadataTabs.materials,
            label: i18next.t('protocol-iut-plan-builder.metadata.tabs.materials'),
        });
    }

    if (APP_CUSTOMER === reactAppCustomer.dw) {
        tabs.push({
            key: metadataTabs.dwmaterials,
            label: i18next.t('protocol-iut-plan-builder.metadata.tabs.materials'),
        });
    }

    if (APP_CUSTOMER !== reactAppCustomer.gtcb) {
        tabs.push({
            key: metadataTabs.inspectors,
            label: i18next.t('protocol-iut-plan-builder.metadata.tabs.inspectors'),
        });
    }

    tabs.push({
        key: metadataTabs.documentData,
        label: i18next.t('protocol-iut-plan-builder.metadata.tabs.documentData'),
    });

    tabs.push({
        key: metadataTabs.comments,
        label: i18next.t('protocol-iut-plan-builder.metadata.tabs.comments'),
    });

    return tabs;
};

export const inspectionLocationDefaultValue = 'ZL';
export const customerDocLanguageDefaultValue = 'DE-EN';
