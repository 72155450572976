import { createSelector } from 'reselect';
import update from '../../../redux/update';

const actions = {
    changeValue: 'SERVICE_WORKER_CHANGE_VALUE',
};

const initialState = {
    isOnline: true,
    currentAppVersion: '',
};

// The main purpose is to store form data.
export const serviceWorkerReducer = (state = initialState, action) => {
    let newState = state;

    switch (action.type) {
        case actions.changeValue:
            newState = update.set(state, action.payload.path, action.payload.value);
            break;

        default:
            break;
    }

    return newState;
};

export const changeValueAction = (path, value) => {
    return {
        type: actions.changeValue,
        payload: {
            path,
            value,
        },
    };
};

export const selectCurrentAppVersion = state => state.serviceWorker?.currentAppVersion;
