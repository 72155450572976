import { tagActions } from './tag.actions';
const tagInitialState = {
    tags: [],
    filters: {
        searchTerm: '',
    },
};

export const tagReducer = (state = tagInitialState, { type, payload }) => {
    switch (type) {
        case tagActions.SET_TAGS:
            return {
                ...state,
                tags: payload,
            };
        case tagActions.ADD_TAG:
            return {
                ...state,
                tags: [...state.tags, payload],
            };
        case tagActions.REMOVE_TAG:
            return {
                ...state,
                tags: state.tags.filter(tag => tag._id !== payload),
            };
        case tagActions.UPDATE_TAG:
            return {
                ...state,
                tags: state.tags.map(tag => {
                    if (tag._id !== payload._id) {
                        return tag;
                    }

                    return { ...tag, ...payload };
                }),
            };
        case tagActions.SET_SEARCH_TERM: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    searchTerm: payload,
                },
            };
        }
        default:
            return state;
    }
};
