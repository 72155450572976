import { projectsActions } from './projects.actions';

const projectsInitialState = {
    projectsData: [],
    users: [],
    currentProject: [],
};

export const projectsReducer = (state = projectsInitialState, { type, payload }) => {
    switch (type) {
        case projectsActions.SET_PROJECTS: {
            return {
                ...state,
                projectsData: state?.projectsData?.length ? state?.projectsData?.concat(payload) : payload,
            };
        }

        case projectsActions.RESET_PROJECTS: {
            return {
                ...state,
                projectsData: projectsInitialState.projectsData,
            };
        }

        case projectsActions.SET_PROGRESS_USERS: {
            return {
                ...state,
                users: payload.map(user => {
                    return {
                        ...user,
                        title: `${user.firstName} ${user.name}`,
                        value: user._id,
                    };
                }),
            };
        }

        case projectsActions.SET_ROUTING_WITH_STEPS: {
            return {
                ...state,
                projectsData: state.projectsData.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        schedulingData: {
                            ...project.schedulingData,
                            scheduledReleaseDate: payload.productionOrder.schedulingData.scheduledReleaseDate,
                            scheduledEndDate: payload.productionOrder.schedulingData.scheduledEndDate,
                        },
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.routingId) {
                                return routing;
                            }

                            const newRouting = payload.productionOrder.processRoutings.find(x => x._id === payload.routingId);

                            if (!newRouting) {
                                return routing;
                            }

                            return {
                                ...routing,
                                calculatedSchedulingData: {
                                    ...routing.calculatedSchedulingData,
                                    scheduledReleaseDate: newRouting.calculatedSchedulingData.scheduledReleaseDate,
                                    scheduledEndDate: newRouting.calculatedSchedulingData.scheduledEndDate,
                                },
                                processSteps: routing.processSteps.map(step => {
                                    const newStep = newRouting.processSteps.find(x => x._id === step._id);

                                    if (!newStep) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        schedulingData: {
                                            ...step.schedulingData,
                                            scheduledReleaseDate: newStep.schedulingData?.scheduledReleaseDate,
                                            scheduledEndDate: newStep.schedulingData?.scheduledEndDate,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case projectsActions.SET_DURATION: {
            return {
                ...state,
                projectsData: state.projectsData.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        schedulingData: {
                            ...project.schedulingData,
                            scheduledReleaseDate: payload.productionOrder.schedulingData.scheduledReleaseDate,
                            scheduledEndDate: payload.productionOrder.schedulingData.scheduledEndDate,
                        },
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.routingId) {
                                return routing;
                            }

                            const newRouting = payload.productionOrder.processRoutings.find(x => x._id === payload.routingId);

                            if (!newRouting) {
                                return routing;
                            }

                            return {
                                ...routing,
                                calculatedSchedulingData: {
                                    ...routing.calculatedSchedulingData,
                                    scheduledReleaseDate: newRouting.calculatedSchedulingData.scheduledReleaseDate,
                                    scheduledEndDate: newRouting.calculatedSchedulingData.scheduledEndDate,
                                },
                                progress: {
                                    ...routing.progress,
                                    durationCompletedInSeconds: newRouting.progress.durationCompletedInSeconds,
                                    durationTotalInSeconds: newRouting.progress.durationTotalInSeconds,
                                },
                                processSteps: routing.processSteps.map(step => {
                                    if (step._id !== payload.stepId) {
                                        return step;
                                    }

                                    const newStep = newRouting.processSteps.find(x => x._id === step._id);

                                    if (!newStep) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        schedulingData: {
                                            ...step.schedulingData,
                                            scheduledReleaseDate: newStep.schedulingData.scheduledReleaseDate,
                                            scheduledEndDate: newStep.schedulingData.scheduledEndDate,
                                        },
                                        progress: {
                                            ...step.progress,
                                            durationCompletedInSeconds: newStep.progress.durationCompletedInSeconds,
                                            durationTotalInSeconds: newStep.progress.durationTotalInSeconds,
                                        },
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        }

        case projectsActions.SET_CURRENT_PROJECT_DURATION: {
            return {
                ...state,
                currentProject: {
                    ...state.currentProject,
                    schedulingData: {
                        ...state.currentProject.schedulingData,
                        scheduledReleaseDate: payload.productionOrder.schedulingData.scheduledReleaseDate,
                        scheduledEndDate: payload.productionOrder.schedulingData.scheduledEndDate,
                    },
                    processRoutings: state.currentProject.processRoutings.map(routing => {
                        if (routing._id !== payload.routingId) {
                            return routing;
                        }

                        const newRouting = payload.productionOrder.processRoutings.find(x => x._id === payload.routingId);

                        if (!newRouting) {
                            return routing;
                        }

                        return {
                            ...routing,
                            calculatedSchedulingData: {
                                ...routing.calculatedSchedulingData,
                                scheduledReleaseDate: newRouting.calculatedSchedulingData.scheduledReleaseDate,
                                scheduledEndDate: newRouting.calculatedSchedulingData.scheduledEndDate,
                            },
                            progress: {
                                ...routing.progress,
                                durationCompletedInSeconds: newRouting.progress.durationCompletedInSeconds,
                                durationTotalInSeconds: newRouting.progress.durationTotalInSeconds,
                            },
                            processSteps: routing.processSteps.map(step => {
                                if (step._id !== payload.stepId) {
                                    return step;
                                }

                                const newStep = newRouting.processSteps.find(x => x._id === step._id);

                                if (!newStep) {
                                    return step;
                                }

                                return {
                                    ...step,
                                    schedulingData: {
                                        ...step.schedulingData,
                                        scheduledReleaseDate: newStep.schedulingData.scheduledReleaseDate,
                                        scheduledEndDate: newStep.schedulingData.scheduledEndDate,
                                    },
                                    progress: {
                                        ...step.progress,
                                        durationCompletedInSeconds: newStep.progress.durationCompletedInSeconds,
                                        durationTotalInSeconds: newStep.progress.durationTotalInSeconds,
                                    },
                                };
                            }),
                        };
                    }),
                },
            };
        }

        case projectsActions.SET_ROUTING_RESPONSIBLE: {
            return {
                ...state,
                projectsData: state.projectsData.map(project => {
                    if (project._id !== payload.projectId) {
                        return project;
                    }

                    return {
                        ...project,
                        processRoutings: project.processRoutings.map(routing => {
                            if (routing._id !== payload.routingId) {
                                return routing;
                            }

                            const newRouting = payload.newRoutings.find(x => x._id === payload.routingId);

                            if (!newRouting) {
                                return routing;
                            }

                            return {
                                ...routing,
                                resolvedResponsible: [...newRouting.resolvedResponsible],
                            };
                        }),
                    };
                }),
            };
        }

        case projectsActions.SET_CURRENT_PROJECT: {
            return {
                ...state,
                currentProject: payload,
            };
        }

        default:
            return state;
    }
};
