import { getWorkplaceName } from '../../../utils/ordersCommon.utils';
import { materialAllocationActions } from './materialAllocation.actions';
import { workplaceType } from '../../fruitPreparation/constants/processOrderConstants';

const materialAllocationInitialState = {
    tableData: [],
    isModalOpened: false,
    isDeleteModalOpened: false,
    materialId: null,
    isMaterialWithoutAllocationModalOpened: false,
    materialWithoutAllocation: null,
};

export const materialAllocationReducer = (state = materialAllocationInitialState, { type, payload }) => {
    switch (type) {
        case materialAllocationActions.SET_MATERIAL_ALLOCATION_DATA: {
            const { processRouting, language } = payload;

            const data = getMaterialViewModel(processRouting, language).filter(material => material.workplaceType === workplaceType.AREA && material?.materialStatus !== 'blocked');
            return {
                ...state,
                tableData: data,
            };
        }
        case materialAllocationActions.SET_MATERIAL_ALLOCATION_MODAL_STATE: {
            const { isModalOpened, materialId } = payload;

            return {
                ...state,
                isModalOpened,
                materialId,
            };
        }
        case materialAllocationActions.SET_MATERIAL_ALLOCATION_WITHOUT_ALLOCATION_MODAL_STATE: {
            const { isMaterialWithoutAllocationModalOpened, materialWithoutAllocation } = payload;

            return {
                ...state,
                isMaterialWithoutAllocationModalOpened,
                materialWithoutAllocation,
            };
        }
        case materialAllocationActions.SET_MATERIAL_STATUS_MODAL_STATE: {
            const { isMaterialStatusModalOpened, materialWithStatus } = payload;

            if (state?.isMaterialStatusModalOpened == isMaterialStatusModalOpened) {
                return state;
            }

            return {
                ...state,
                isMaterialStatusModalOpened,
                materialWithStatus,
            };
        }
        case materialAllocationActions.RESET_MATERIAL_ALLOCATION_STATE: {
            return {
                ...state,
                ...materialAllocationInitialState,
            };
        }
        case materialAllocationActions.SET_MATERIAL_ALLOCATION_DELETE_MODAL_STATE: {
            const { isModalOpened, materialId } = payload;

            return {
                ...state,
                materialId: materialId,
                isDeleteModalOpened: isModalOpened,
            };
        }
        case materialAllocationActions.UPDATE_MATERIAL_ALLOCATION_TABLE_ITEM: {
            const { zone, kgH, newProcessStepReference, materialStatus } = payload;
            const materialId = state.materialId;

            return {
                ...state,
                tableData: state.tableData?.filter(el => el?.materialStatus !== 'blocked')?.map(material => {
                    if (material?._id !== materialId) {
                        return material;
                    }

                    return {
                        ...material,
                        kgH: Number(kgH),
                        zone: { key: zone },
                        materialStatus,
                        processStepReferences: [...material.processStepReferences, { foreignID: newProcessStepReference }],
                    };
                }),
            };
        }

        default:
            return state;
    }
};

export const getMaterialViewModel = (tableData, language) =>
    tableData.map(processRouting => {
        return {
            _id: processRouting?.resolvedMaterials[0]?._id,
            processRoutingId: processRouting._id,
            materialNumber: processRouting?.resolvedMaterials[0]?.materialNumber,
            materialName: processRouting?.resolvedMaterials[0]?.materialName,
            materialStatus: processRouting?.resolvedMaterials[0]?.materialStatus,
            kgH: 1 / processRouting?.resolvedProcessStep[0]?.plannedDurations?.processTime,
            zone: { key: getWorkplaceName(processRouting, language) },
            zoneId: processRouting?.resolvedProcessStep[0]?.resolvedWorkplaces[0]?._id,
            processStepId: processRouting?.resolvedProcessStep[0]?._id,
            processStepReferences: processRouting?.resolvedProcessStep[0]?.resolvedWorkplaces[0]?.processStepReferences || [],
            workplaceType: processRouting?.resolvedProcessStep[0]?.resolvedWorkplaces[0]?.workplaceType,
        };
    });
