import { leftBarActions } from './leftBar.actions';

import { findingOperations } from '../../constants';

const leftBarInitialState = {
    isCollapsed: false,
    inspectionPlan: null,
    activeGroup: null,
    activeStep: null,
    filters: { inspectionLocation: [] },
};

export const leftBarReducer = (state = leftBarInitialState, { type, payload }) => {
    switch (type) {
        case leftBarActions.SET_LEFT_BAR_COLLAPSED: {
            return {
                ...state,
                isCollapsed: payload,
            };
        }
        case leftBarActions.SET_INSPECTION_PLAN: {
            return {
                ...state,
                inspectionPlan: payload,
            };
        }
        case leftBarActions.SET_ACTIVE_STEP: {
            return {
                ...state,
                activeStep: payload,
            };
        }
        case leftBarActions.SET_ACTIVE_GROUP: {
            const activeGroup = payload === state.activeGroup ? null : payload;

            return {
                ...state,
                activeGroup,
            };
        }
        case leftBarActions.SET_INSPECTION_LOCATION_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    inspectionLocation: payload,
                },
            };
        }
        case leftBarActions.UPDATE_RESOLVED_TICKETS: {
            if (payload.operation === findingOperations.update) {
                return {
                    ...state,
                    inspectionPlan: {
                        ...state.inspectionPlan,
                        resolvedTickets:
                            payload.operation === findingOperations.add
                                ? [...state.inspectionPlan.resolvedTickets, payload.ticket]
                                : state.inspectionPlan.resolvedTickets.map(ticket =>
                                      ticket._id === payload.ticket._id ? payload.ticket : ticket
                                  ),
                    },
                };
            }

            return {
                ...state,
                inspectionPlan: {
                    ...state.inspectionPlan,
                    resolvedTickets:
                        payload.operation === findingOperations.add
                            ? [...state.inspectionPlan.resolvedTickets, payload.ticket]
                            : state.inspectionPlan.resolvedTickets.filter(ticket => ticket._id !== payload.id),
                },
            };
        }
        case leftBarActions.CHANGE_SIGN_INSPECTION_REPORT_LEFT_BAR: {
            return {
                ...state,
                inspectionPlan: {
                    ...state.inspectionPlan,
                    assemblyGroup: state.inspectionPlan.assemblyGroup.map(assemblyGroup => {
                        if (!assemblyGroup.manufacturingStep.some(step => step._id === state.activeGroup)) {
                            return assemblyGroup;
                        }

                        return {
                            ...assemblyGroup,
                            manufacturingStep: assemblyGroup.manufacturingStep.map(manufacturingStep => {
                                if (manufacturingStep._id !== state.activeGroup) {
                                    return manufacturingStep;
                                }

                                return {
                                    ...manufacturingStep,
                                    progress: {
                                        ...manufacturingStep.progress,
                                        completed:
                                            payload === 'sign'
                                                ? manufacturingStep.progress.completed + 1
                                                : manufacturingStep.progress.completed - 1,
                                    },
                                    inspectionOrders: manufacturingStep.inspectionOrders.map(order => {
                                        if (order.inspectionReportID !== state.activeStep) {
                                            return order;
                                        }

                                        return {
                                            ...order,
                                            reportProgress: {
                                                ...order.reportProgress,
                                                completed: payload === 'sign' ? order.reportProgress.total : 0,
                                            },
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        }
        case leftBarActions.SET_INITIAL_STATE_LEFT_BAR: {
            return leftBarInitialState;
        }

        default:
            return state;
    }
};
