export const stepModalActions = {
    SET_MODEL: 'STEP_MODAL/SET_MODEL',
    SET_ACTIVE_TAB: 'STEP_MODAL/SET_ACTIVE_TAB',
    HANDLE_UPDATE: 'STEP_MODAL/HANDLE_UPDATE',
    DELETE_MEDIA_INPUT: 'STEP_MODAL/DELETE_MEDIA_INPUT',
    SET_MEDIA_INPUTS: 'STEP_MODAL/ADD_MEDIA_INPUT',
    CHANGE_PROCESS_STEP_DURATION: 'STEP_MODAL/CHANGE_PROCESS_STEP_DURATION',
    CHANGE_PROCESS_STEP_RESPONSIBLE: 'STEP_MODAL/CHANGE_PROCESS_STEP_RESPONSIBLE',
    UPDATE_MATERIAL: 'STEP_MODAL/CHANGE_MATERIAL',
    DELETE_MATERIAL: 'STEP_MODAL/DELETE_MATERIAL',
    ADD_MATERIAL: 'STEP_MODAL/ADD_MATERIAL',
    SET_MODAL_TO_INITIAL_STATE: 'STEP_MODAL/SET_MODAL_TO_INITIAL_STATE',
};

export const stepModalSetModel = payload => ({
    type: stepModalActions.SET_MODEL,
    payload,
});

export const stepModalSetActiveTab = payload => ({
    type: stepModalActions.SET_ACTIVE_TAB,
    payload,
});

export const stepModalHandleUpdateStep = payload => ({
    type: stepModalActions.HANDLE_UPDATE,
    payload,
});

export const stepModalDeleteMediaInput = payload => ({
    type: stepModalActions.DELETE_MEDIA_INPUT,
    payload,
});

export const stepModalSetMediaInputs = payload => ({
    type: stepModalActions.SET_MEDIA_INPUTS,
    payload,
});

export const stepModalChangeProcessRoutingDuration = payload => ({
    type: stepModalActions.CHANGE_PROCESS_STEP_DURATION,
    payload,
});

export const stepModalChangeProcessStepResponsible = payload => ({
    type: stepModalActions.CHANGE_PROCESS_STEP_RESPONSIBLE,
    payload,
});

export const stepModalUpdateMaterial = payload => ({
    type: stepModalActions.UPDATE_MATERIAL,
    payload,
});

export const stepModalDeleteMaterial = payload => ({
    type: stepModalActions.DELETE_MATERIAL,
    payload,
});

export const stepModalAddMaterial = payload => ({
    type: stepModalActions.ADD_MATERIAL,
    payload,
});

export const setModalToInitialState = payload => ({
    type: stepModalActions.SET_MODAL_TO_INITIAL_STATE,
    payload,
});
