export const ticketsFiltersActions = {
    CLEAR_TICKETS_FILTERS: 'TICKETS_FILTERS/CLEAR_TICKETS_FILTERS',
    SET_IS_FILTER_BUTTON_ACTIVE: 'TICKETS_FILTERS/SET_IS_FILTER_BUTTON_ACTIVE',
    SET_IS_SHOW_MY_TICKETS: 'TICKETS_FILTERS/SET_IS_SHOW_MY_TICKETS',
    SET_IS_DATA_LOADING: 'TICKETS_FILTERS/SET_IS_DATA_LOADING',
    SET_SEARCH_TERM: 'TICKETS_FILTERS/SET_SEARCH_TERM',
    SET_ARCHIVE_STATUS: 'TICKETS_FILTERS/SET_ARCHIVE_STATUS',
    SET_TICKETS_STATUS: 'TICKETS_FILTERS/SET_TICKETS_STATUS',
    SET_INCIDENT_TYPE: 'TICKETS_FILTERS/SET_INCIDENT_TYPE',
    SET_TICKETS_PRIORITY: 'TICKETS_FILTERS/SET_TICKETS_PRIORITY',
    SET_TICKETS_CREATED_AT: 'TICKETS_FILTERS/SET_TICKETS_CREATED_AT',
    SET_TICKETS_RESPONSIBLE: 'TICKETS_FILTERS/SET_TICKETS_RESPONSIBLE',
    SET_TICKETS_RESOLVED_ASSETS: 'TICKETS_FILTERS/SET_TICKETS_RESOLVED_ASSETS',
    SET_TICKETS_FAILURE_CATEGORY: 'TICKETS_FILTERS/SET_TICKETS_FAILURE_CATEGORY',
    SET_TICKETS_FAILURE_MODE: 'TICKETS_FILTERS/SET_TICKETS_FAILURE_MODE',
};

export const clearTicketsFilters = () => {
    return {
        type: ticketsFiltersActions.CLEAR_TICKETS_FILTERS
    }
}

export const setTicketsIsFilterButtonActive = payload => {
    return {
        type: ticketsFiltersActions.SET_IS_FILTER_BUTTON_ACTIVE,
        payload,
    };
};

export const setIsDataLoading = payload => {
    return {
        type: ticketsFiltersActions.SET_IS_DATA_LOADING,
        payload,
    };
};

export const setSearchTerm = payload => {
    return {
        type: ticketsFiltersActions.SET_SEARCH_TERM,
        payload,
    };
};

export const setTicketsIsShowMyTickets = payload => {
    return {
        type: ticketsFiltersActions.SET_IS_SHOW_MY_TICKETS,
        payload,
    };
};

export const setArchiveStatus = payload => {
    return {
        type: ticketsFiltersActions.SET_ARCHIVE_STATUS,
        payload,
    };
};

export const setTicketsStatus = payload => {
    return {
        type: ticketsFiltersActions.SET_TICKETS_STATUS,
        payload,
    };
};

export const setIncidentType = payload => {
    return {
        type: ticketsFiltersActions.SET_INCIDENT_TYPE,
        payload,
    };
};

export const setTicketsPriority = payload => {
    return {
        type: ticketsFiltersActions.SET_TICKETS_PRIORITY,
        payload,
    };
};

export const setTicketsCreatedAt = payload => {
    return {
        type: ticketsFiltersActions.SET_TICKETS_CREATED_AT,
        payload,
    };
};

export const setTicketsResponsible = payload => {
    return {
        type: ticketsFiltersActions.SET_TICKETS_RESPONSIBLE,
        payload,
    };
};

export const setTicketsResolvedAsset = payload => {
    return {
        type: ticketsFiltersActions.SET_TICKETS_RESOLVED_ASSETS,
        payload,
    };
};

export const setTicketsFailureCategory = payload => {
    return {
        type: ticketsFiltersActions.SET_TICKETS_FAILURE_CATEGORY,
        payload,
    };
};

export const setTicketsFailureMode = payload => {
    return {
        type: ticketsFiltersActions.SET_TICKETS_FAILURE_MODE,
        payload,
    };
};