import { templatesOverviewActions } from './templatesOverview.actions';

const templatesOverview = {
    searchTerm: '',
    selectedTypes: [],
    templates: [],
    checkedItemsId: [],
    bulkDelete: {
        isActive: false,
    },
};

export const templatesOverviewReducer = (state = templatesOverview, { type, payload }) => {
    switch (type) {
        case templatesOverviewActions.SET_SELECTED_TYPES: {
            return {
                ...state,
                selectedTypes: payload,
            };
        }
        case templatesOverviewActions.SET_SEARCH_TERM: {
            return {
                ...state,
                searchTerm: payload,
            };
        }
        case templatesOverviewActions.RESET_TO_INITIAL_STATE: {
            return {
                ...templatesOverview,
            };
        }
        case templatesOverviewActions.TOOGLE_BULK_DELETE_MODE_ACTIVE: {
            return {
                ...state,
                checkedItemsId: [],
                bulkDelete: {
                    ...state.bulkDelete,
                    isActive: !state.bulkDelete.isActive,
                },
            };
        }
        case templatesOverviewActions.SET_TEMPLATES: {
            return {
                ...state,
                templates: payload,
            };
        }
        case templatesOverviewActions.REMOVE_ITEM_FROM_LIST: {
            return {
                ...state,
                templates: state.templates.filter(item => item._id !== payload),
            };
        }
        case templatesOverviewActions.CHECK_ITEM: {
            const isChecked = state.checkedItemsId.some(templateId => templateId === payload);

            return {
                ...state,
                checkedItemsId: isChecked
                    ? state.checkedItemsId.filter(templateId => templateId !== payload)
                    : [...state.checkedItemsId, payload],
            };
        }
        case templatesOverviewActions.DELETE_CHECKED_ITEMS: {
            const checkedItems = state.checkedItemsId;

            return {
                ...state,
                checkedItemsId: [],
                templates: state.templates.filter(item => !checkedItems.some(templateId => templateId === item._id)),
            };
        }
        case templatesOverviewActions.ADD_TEMPLATE_TO_THE_TOP: {
            return {
                ...state,
                templates: [payload, ...state.templates],
            };
        }
        case templatesOverviewActions.UPDATE_TEPLATE: {
            return {
                ...state,
                templates: state.templates.map(template => (template._id !== payload?._id ? template : { ...template, ...payload })),
            };
        }

        default:
            return state;
    }
};
