//https://www.npmjs.com/package/react-app-polyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

// import { Userpilot } from 'userpilot';

// NOTE: i18n must be on the top before App, it will make translations available for all the components via the context api
// https://react.i18next.com/guides/quick-start#configure-i18next
import './utils/i18n';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// import { USER_PILOT_TOKEN } from './utils/env';

import './index.css';

// if (USER_PILOT_TOKEN) {
//     Userpilot.initialize(USER_PILOT_TOKEN);
// }

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register();
