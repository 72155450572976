import React from 'react';

// LIBS
import clsx from 'clsx';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';

// MATERIAL UI - CORE
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

// COMPONENTS
import PrivateComponent from '../common/PrivateComponent';

// ICONS
import { ReactComponent as IuTPlanBuilderIcon } from '../../resources/icons/orderSettingsIcon.svg';
import { ReactComponent as IuTPlanTemplateBuilderIcon } from '../../resources/icons/orderListIcon.svg';
import { ReactComponent as ProtocolExecutionIcon } from '../../resources/icons/executionIcon.svg';
import { ReactComponent as ProtocolTemplateBuilderIcon } from '../../resources/icons/builderSettingsIcon.svg';

// CONSTANTS
import { userRole } from '../../constants/UserRole';

const LeftNavbar = ({ open, classes, isNavigationButtonActive, getListIconComponent }) => {
    const history = useHistory();

    return (
        <React.Fragment>
            <PrivateComponent
                allowedRoles={[userRole.protocolAdmin, userRole.protocolOperator, userRole.protocolSupervisor, userRole.protocolPlanner]}>
                <ListItem
                    className={clsx(classes.navigationButton, isNavigationButtonActive('/protocols') ? 'active' : '')}
                    onClick={() => history.push('/protocols')}>
                    <ListItemIcon>{getListIconComponent('menu.protocols', <ProtocolExecutionIcon id="protocols" />, open)}</ListItemIcon>
                    <ListItemText primary={i18next.t(`menu.protocols`)} />
                </ListItem>
            </PrivateComponent>

            <PrivateComponent allowedRoles={[userRole.protocolAdmin, userRole.protocolPlanner]}>
                <ListItem
                    className={clsx(classes.navigationButton, isNavigationButtonActive('/planTemplates') ? 'active' : '')}
                    onClick={() => history.push('/planTemplates')}>
                    <ListItemIcon>
                        {getListIconComponent('menu.planTemplates', <IuTPlanTemplateBuilderIcon id="planTemplates" />, open)}
                    </ListItemIcon>
                    <ListItemText primary={i18next.t(`menu.planTemplates`)} />
                </ListItem>
            </PrivateComponent>

            <PrivateComponent allowedRoles={[userRole.protocolAdmin, userRole.protocolPlanner]}>
                <ListItem
                    className={clsx(classes.navigationButton, isNavigationButtonActive('/plans') ? 'active' : '')}
                    onClick={() => history.push('/plans')}>
                    <ListItemIcon>{getListIconComponent('menu.plans', <IuTPlanBuilderIcon id="plans" />, open)}</ListItemIcon>
                    <ListItemText primary={i18next.t(`menu.plans`)} />
                </ListItem>
            </PrivateComponent>

            <PrivateComponent allowedRoles={[userRole.protocolAdmin, userRole.protocolPlanner]}>
                <ListItem
                    className={clsx(classes.navigationButton, isNavigationButtonActive('/templates') ? 'active' : '')}
                    onClick={() => history.push('/templates')}>
                    <ListItemIcon>
                        {getListIconComponent('menu.templates', <ProtocolTemplateBuilderIcon id="templates" />, open)}
                    </ListItemIcon>
                    <ListItemText primary={i18next.t(`menu.templates`)} />
                </ListItem>
            </PrivateComponent>
        </React.Fragment>
    );
};

export default LeftNavbar;
