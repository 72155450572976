import React from 'react';
import { useHistory } from 'react-router-dom';

//LIBS
import i18next from 'i18next';
import clsx from 'clsx';

// MATERIAL UI - CORE
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Snackbar, Typography, MenuItem, Grid, Avatar, useTheme } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

// COMPONENTS
import FileUploader from '../../common/fileUploader/FileUploader';
import TextField from '../../common/templates/components/TextField';
import Switch from '../../common/templates/components/Switch';
import userSettingsData from './UserSettingsData';
import userSettingsHandler from './UserSettingsHandler';

//UTILS
import { AWS_S3_BUCKET_EHS_USER_FOLDER, AWS_AMPLIFY_DEFAULT_LANGUAGE, APP_CUSTOMER } from '../../../utils/env';
import { showPushNotificationOption } from '../../../utils/pushNotificationHelper';
import { getLanguageOptions } from '../../../providers/LanguageOptionsProvider';

//CONSTANTS
import { getUserRoleBadges } from '../../../constants/UserRole';
import { themeType } from '../../../constants/ThemeType';
import { reactAppCustomer } from '../../../constants/Config';
import { dailyOverdueTicketsMailValue, deviceSettings, weeklyOverdueTicketsMailValue } from './constants';
import { daysCountDailyNotification, daysCountWeeklyNotification } from './constants';

//DESIGN
import { commonStyles } from '../../../design/commonStyles';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        background: theme.palette.background.paper,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        maxWidth: 569,
        margin: '0 auto',
        marginTop: 72,
        padding: 8,
        borderRadius: 9,
        zIndex: 1000,
        right: 12,
        '&.home': {
            backgroundColor: theme.palette.homeScreen.backgroundColor,
        },
        '& .MuiOutlinedInput-input': {
            fontSize: 16,
        },
    },
    innerRoot: {
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        padding: 24,
    },
    userDataContainer: {
        display: 'flex',
    },
    avatar: {
        width: 80,
        height: 80,
        cursor: 'pointer',
        borderRadius: 4,
    },
    userData: {
        marginLeft: 32,
    },
    userName: {
        fontSize: 32,
        lineHeight: '40px',
    },
    roleBadges: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 12,
    },
    roleBadge: {
        margin: 4,
        padding: 2,
        backgroundColor: theme.palette.badge.grey,

        '& .MuiTypography-root': {
            color: theme.palette.badge.color,
            fontSize: 12,
            letterSpacing: '0.48px',
            lineHeight: '18px',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
        },
    },
    userInfoContainer: {
        marginTop: 56,
        marginBottom: 36,
        [theme.breakpoints.down('xs')]: {
            marginTop: 12,
            marginBottom: 12,
            '&.MuiGrid-spacing-xs-4 > .MuiGrid-item': {
                padding: 8,
            },
        },
    },
    infoLabel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '8px 4px',
        '& .MuiTypography-root': {
            color: theme.palette.textColor.primary,
            fontSize: 16,
            lineHeight: '24px',
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
                lineHeight: '21px',
            },
        },
        '& .MuiTypography-colorSecondary': {
            color: theme.palette.textColor.secondary,
            fontSize: 14,
            lineHeight: '21px',
        },
    },
    iconLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        color: theme.palette.textColor.secondary,
        padding: '12px 0px !important',
        '& svg': {
            height: 22,
            marginBottom: 6,
            fill: theme.palette.textColor.secondary,
        },
        '&.active': {
            borderRadius: 4,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.textColor.primary,
            '&.negative': {
                backgroundColor: theme.palette.background.default,
            },
            '& svg': {
                fill: theme.palette.textColor.primary,
            },
        },
    },
    marginRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start',
        },
    },
}));

const UserSettingsModal = ({
    token,

    model,
    user,

    userImage,
    userRoles,

    userDevice,
    setUserDevice,

    imageUploadModalOpen,
    openImageUploadModal,
    changeUserAvatar,
    closeImageUploadModal,

    showDailyEmail,
    showWeeklyEmail,
    showDailyTicketReport,
    isPushNotificationChecked,

    handleInputChange,
    handleThemeChange,
    handleSwitchChange,
    handlePushNotificationChange,

    pushNotificationWarning,
    closePushNotificationWarning,
}) => {
    const classes = useStyles();
    const commonClasses = commonStyles();
    const history = useHistory();
    const theme = useTheme();

    const isHomePage = () => {
        const currentLocation = history?.location?.pathname;
        return currentLocation === '/';
    };

    const isSwitchNegative = () => {
        const currentTheme = theme.palette.type;

        if (currentTheme === themeType.dark) {
            return true;
        }

        if (currentTheme === themeType.light) {
            return !isHomePage();
        }

        return false;
    };

    const isHome = isHomePage();
    const isNegative = isSwitchNegative();

    return (
        <div className={clsx(classes.root, isHome ? 'home' : 'landing')}>
            <div className={clsx(classes.innerRoot, commonClasses.customScrollContainer)}>
                <Snackbar open={pushNotificationWarning} autoHideDuration={4000} onClose={closePushNotificationWarning}>
                    <MuiAlert elevation={6} onClose={closePushNotificationWarning} severity="warning">
                        {i18next.t('common.push-notification.permission-error')}
                    </MuiAlert>
                </Snackbar>

                <div className={classes.userDataContainer}>
                    <Avatar
                        id="file-upload-trigger"
                        className={classes.avatar}
                        alt={model.name || 'userAvatar'}
                        src={model.profilePic?.thumbnail ? userImage : null}
                        onClick={openImageUploadModal}
                    />
                    <div className={classes.userData}>
                        <div className={classes.userName}>{`${model.firstName} ${model.name}`}</div>
                        <div className={classes.roleBadges}>
                            {React.Children.toArray(
                                userRoles.map(x => {
                                    return (
                                        <div className={classes.roleBadge}>
                                            <Typography>{getUserRoleBadges()[x]?.label}</Typography>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </div>

                <Grid container className={classes.userInfoContainer} spacing={3}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                <Typography>{i18next.t('user-settings.user-info.theme.label')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} className={classes.marginRight}>
                                <Switch
                                    negative={isNegative}
                                    value={model.userSettings.theme !== themeType.light}
                                    onChange={handleThemeChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                <Typography>{i18next.t('user-settings.user-info.email-notifications.label')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} className={classes.marginRight}>
                                <Switch
                                    negative={isNegative}
                                    value={model.notifications?.email}
                                    onChange={handleSwitchChange.bind({}, 'notifications.email')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {showDailyEmail && (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                    <Typography>{i18next.t('user-settings.user-info.daily-mail.label')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} className={classes.marginRight}>
                                    <Switch
                                        negative={isNegative}
                                        value={
                                            model.notifications?.overdueTicketsMail.active &&
                                            model.notifications?.overdueTicketsMail.weekdays.length === daysCountDailyNotification
                                        }
                                        onChange={active =>
                                            handleSwitchChange('notifications.overdueTicketsMail', {
                                                active,
                                                weekdays: dailyOverdueTicketsMailValue,
                                            })
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {showWeeklyEmail && (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                    <Typography>{i18next.t('user-settings.user-info.weekly-mail.label')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} className={classes.marginRight}>
                                    <Switch
                                        negative={isNegative}
                                        value={
                                            model.notifications?.overdueTicketsMail.active &&
                                            model.notifications?.overdueTicketsMail.weekdays.length === daysCountWeeklyNotification
                                        }
                                        onChange={active =>
                                            handleSwitchChange('notifications.overdueTicketsMail', {
                                                active,
                                                weekdays: weeklyOverdueTicketsMailValue,
                                            })
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {showPushNotificationOption() && (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                    <Typography>{i18next.t('user-settings.user-info.web-push-notifications.label')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} className={classes.marginRight}>
                                    <Switch
                                        negative={isNegative}
                                        value={isPushNotificationChecked()}
                                        onChange={handlePushNotificationChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {showDailyTicketReport && (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                    <Typography>{i18next.t('user-settings.user-info.dailyTicketOverview.label')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} className={classes.marginRight}>
                                    <Switch
                                        negative={isNegative}
                                        value={model.notifications?.dailyTicketOverview}
                                        onChange={handleSwitchChange.bind({}, 'notifications.dailyTicketOverview')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {APP_CUSTOMER === reactAppCustomer.vwpwk && (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                    <Typography>{i18next.t('user-settings.user-info.device.label')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Grid container spacing={1}>
                                        {React.Children.toArray(
                                            deviceSettings.map(x => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        className={clsx(
                                                            classes.iconLabel,
                                                            userDevice === x.key ? 'active' : '',
                                                            isNegative ? 'negative' : ''
                                                        )}
                                                        onClick={() => {
                                                            localStorage.setItem('userDevice', x.key);
                                                            setUserDevice(x.key);
                                                        }}>
                                                        {x.icon}
                                                        {i18next.t(x.label)}
                                                    </Grid>
                                                );
                                            })
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                <Typography>{i18next.t('user-settings.user-info.firstName.label')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={model.firstName}
                                    type="text"
                                    disabled={true}
                                    placeholder={i18next.t('user-settings.user-info.firstName.placeholder')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                <Typography>{i18next.t('user-settings.user-info.lastName.label')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={model.name}
                                    type="text"
                                    disabled={true}
                                    placeholder={i18next.t('user-settings.user-info.lastName.placeholder')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                <Typography>{i18next.t('user-settings.user-info.email.label')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={model.email}
                                    type="text"
                                    disabled={true}
                                    placeholder={i18next.t('user-settings.user-info.email.placeholder')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                <Typography>{i18next.t('user-settings.user-info.mobile.label')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={model.mobileNumber}
                                    type="text"
                                    disabled={true}
                                    placeholder={i18next.t('user-settings.user-info.mobile.placeholder')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} className={classes.infoLabel}>
                                <Typography>{i18next.t('user-settings.user-info.language.label')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    select
                                    value={model.userSettings.language || AWS_AMPLIFY_DEFAULT_LANGUAGE}
                                    onChange={handleInputChange.bind({}, 'userSettings.language')}
                                    type="text">
                                    {React.Children.toArray(
                                        getLanguageOptions().map(option => {
                                            return <MenuItem value={option.value}>{option.label}</MenuItem>;
                                        })
                                    )}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {token && user._id && (
                    <FileUploader
                        allowUniqueName
                        open={imageUploadModalOpen}
                        closeModal={closeImageUploadModal}
                        targetFolder={`${AWS_S3_BUCKET_EHS_USER_FOLDER}/${user._id}`}
                        token={token}
                        addAttachments={changeUserAvatar}
                        maxNumberOfFiles={1}
                        facingMode="user"
                        allowCropping
                        compressBeforeUpload
                        specification="profile-picture"
                    />
                )}
            </div>
        </div>
    );
};

export default userSettingsData(userSettingsHandler(UserSettingsModal));
