import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { APP_INSIGHTS_KEY } from "./utils/env";
import { awsAuth } from "./utils/awsAuth";
import { AppInsights } from "applicationinsights-js";
import { makeStyles } from "@material-ui/core/styles";
import RouterProvider from "./providers/RouterProvider";

import withStoreProvider from "./components/hocs/StoreProvider";
import withApolloProvider from "./components/hocs/AppoloProvider";
import withServiceWorkerProvider from "./components/hocs/ServiceWorkerProvider";
import withAuthenticator from "./components/hocs/AuthenticatorProvider";
import withErrorBoundary from "./components/hocs/ErrorBoundary";
import withUserSettingsProvider from "./components/hocs/UserSettingsProvider";
import withAppVersion from "./components/hocs/AppVersionProvider";

if (APP_INSIGHTS_KEY) {
  AppInsights.downloadAndSetup({
    instrumentationKey: APP_INSIGHTS_KEY,
    enableCorsCorrelation: true
  });
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  }
}));

// TODO: refactor auth logic to fix useless subscriptions and App re-renders
awsAuth();

const App = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Router>
        <>
          <Suspense fallback={null}>
            <RouterProvider />
          </Suspense>
        </>
      </Router>
    </div>
  );
};

export default (
  withErrorBoundary(
    withAuthenticator(
      withApolloProvider(
        withStoreProvider(
          withServiceWorkerProvider(
            withAppVersion(
              withUserSettingsProvider(App)
            )
          )
        )
      )
    )
  )
);
