import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { get, merge } from 'lodash';

import commonTranslationsEn from '../resources/translations/common-en.json';
import commonTranslationsDe from '../resources/translations/common-de.json';
import commonTranslationsHu from '../resources/translations/common-hu.json';
import commonTranslationsPt from '../resources/translations/common-pt.json';
import commonTranslationsEnGTCB from '../resources/translations/common-en-gtcb.json';
import commonTranslationsDeGTCB from '../resources/translations/common-de-gtcb.json';

import safetyTranslationsEn from '../resources/translations/safety-en.json';
import safetyTranslationsDe from '../resources/translations/safety-de.json';
import safetyTranslationsHu from '../resources/translations/safety-hu.json';
import safetyTranslationsDeTODO from '../resources/translations/safety-de-todo.json';

import protocolTranslationsEn from '../resources/translations/protocol-en.json';
import protocolTranslationsDe from '../resources/translations/protocol-de.json';
import protocolTranslationsHu from '../resources/translations/protocol-hu.json';
import protocolTranslationsEnGTCB from '../resources/translations/protocol-en-gtcb.json';
import protocolTranslationsDeGTCB from '../resources/translations/protocol-de-gtcb.json';
import protocolTranslationsEnDW from '../resources/translations/protocol-en-dw.json';
import protocolTranslationsDeDW from '../resources/translations/protocol-de-dw.json';
import protocolTranslationsHuDW from '../resources/translations/protocol-hu-dw.json';

import progressTranslationsEn from '../resources/translations/progress-en.json';
import progressTranslationsDe from '../resources/translations/progress-de.json';
import progressTranslationsHu from '../resources/translations/progress-hu.json';

import processTranslationsEn from '../resources/translations/process-en.json';
import processTranslationsDe from '../resources/translations/process-de.json';
import processTranslationsPt from '../resources/translations/process-pt.json';

import maintenanceTranslationsEn from '../resources/translations/maintenance-en.json';
import maintenanceTranslationsDe from '../resources/translations/maintenance-de.json';
import maintenanceTranslationsHu from '../resources/translations/maintenance-hu.json';
import maintenanceTranslationsDeVW from '../resources/translations/maintenance-de-vw.json';

import machineMaintenanceTranslationsEn from '../resources/translations/machine-maintenance-en.json';
import machineMaintenanceTranslationsDe from '../resources/translations/machine-maintenance-de.json';
import machineMaintenanceTranslationsHu from '../resources/translations/machine-maintenance-hu.json';

import maintenanceStandardTranslationsEn from '../resources/translations/maintenance-standard-en.json';
import maintenanceStandardTranslationsDe from '../resources/translations/maintenance-standard-de.json';
import maintenanceStandardTranslationsHu from '../resources/translations/maintenance-standard-hu.json';

// import workbookTranslationsEn from "../resources/translations/workbook-en.json";
import workbookTranslationsDe from '../resources/translations/workbook-de.json';
// import workbookTranslationsHu from "../resources/translations/workbook-hu.json";

import { AWS_AMPLIFY_DEFAULT_LANGUAGE, APP_CUSTOMER, APP_NAME } from './env';
import { reactAppCustomer, reactAppName } from '../constants/Config';

const translations = {
    [reactAppName.audit]: {
        en: safetyTranslationsEn,
        de: safetyTranslationsDe,
        hu: safetyTranslationsHu,
    },
    [reactAppName.protocol]: {
        en: protocolTranslationsEn,
        de: protocolTranslationsDe,
        hu: protocolTranslationsHu,
    },
    [reactAppName.progress]: {
        en: progressTranslationsEn,
        de: progressTranslationsDe,
        hu: progressTranslationsHu,
    },
    [reactAppName.process]: {
        en: processTranslationsEn,
        de: processTranslationsDe,
        pt: processTranslationsPt,
    },
    [reactAppName.maintenance]: {
        en: maintenanceTranslationsEn,
        de: maintenanceTranslationsDe,
        hu: maintenanceTranslationsHu,
    },
    [reactAppName.machine_maintenance]: {
        en: machineMaintenanceTranslationsEn,
        de: machineMaintenanceTranslationsDe,
        hu: machineMaintenanceTranslationsHu,
    },
    [reactAppName.maintenance_standard]: {
        en: maintenanceStandardTranslationsEn,
        de: maintenanceStandardTranslationsDe,
        hu: maintenanceStandardTranslationsHu,
    },
    [reactAppName.workbook]: {
        // en: workbookTranslationsEn,
        de: workbookTranslationsDe,
        // hu: workbookTranslationsHu
    },
};

const customerTranslations = {
    [reactAppCustomer.vwpwk]: {
        [reactAppName.maintenance]: {
            de: maintenanceTranslationsDeVW,
        },
    },
    [reactAppCustomer.gtcb]: {
        [reactAppName.protocol]: {
            en: protocolTranslationsEnGTCB,
            de: protocolTranslationsDeGTCB,
        },
    },
    [reactAppCustomer.dw]: {
        [reactAppName.protocol]: {
            en: protocolTranslationsEnDW,
            de: protocolTranslationsDeDW,
            hu: protocolTranslationsHuDW,
        },
    },
    [reactAppCustomer.todo]: {
        [reactAppName.audit]: {
            de: safetyTranslationsDeTODO,
        },
    },
};

const commonCustomerTranslations = {
    [reactAppCustomer.gtcb]: {
        en: commonTranslationsEnGTCB,
        de: commonTranslationsDeGTCB,
    },
};

const translationEn = get(translations, `${APP_NAME}.en`) || {};
const translationDe = get(translations, `${APP_NAME}.de`) || {};
const translationHu = get(translations, `${APP_NAME}.hu`) || {};
const translationPt = get(translations, `${APP_NAME}.pt`) || {};

const customerTranslationEn = get(customerTranslations, `${APP_CUSTOMER}.${APP_NAME}.en`) || {};
const customerTranslationDe = get(customerTranslations, `${APP_CUSTOMER}.${APP_NAME}.de`) || {};
const customerTranslationHu = get(customerTranslations, `${APP_CUSTOMER}.${APP_NAME}.hu`) || {};

const commonCustomerTranslationEn = get(commonCustomerTranslations, `${APP_CUSTOMER}.en`) || {};
const commonCustomerTranslationDe = get(commonCustomerTranslations, `${APP_CUSTOMER}.de`) || {};
const commonCustomerTranslationHu = get(commonCustomerTranslations, `${APP_CUSTOMER}.hu`) || {};

const translationsEn = merge({}, commonTranslationsEn, commonCustomerTranslationEn, translationEn, customerTranslationEn);
const translationsDe = merge({}, commonTranslationsDe, commonCustomerTranslationDe, translationDe, customerTranslationDe);
const translationsHu = merge({}, commonTranslationsHu, commonCustomerTranslationHu, translationHu, customerTranslationHu);
const translationsPt = merge({}, commonTranslationsPt, translationPt);

// the translations
const resources = {
    ...translationsEn,
    ...translationsDe,
    ...translationsHu,
    ...translationsPt,
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: AWS_AMPLIFY_DEFAULT_LANGUAGE,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
