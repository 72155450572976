const WorkshopsMainMenuItem = ({ id, className = {} }) => {
    return (
        <svg id={id} className={className} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.40485 1.60403C8.59331 0.797261 7.54973 0.262742 6.4193 0.0748336C5.28887 -0.113075 4.12781 0.0549702 3.09764 0.555592L6.17514 3.62392C6.25375 3.70212 6.31611 3.79501 6.35866 3.89727C6.40121 3.99953 6.42312 4.10915 6.42312 4.21986C6.42312 4.33057 6.40121 4.4402 6.35866 4.54246C6.31611 4.64472 6.25375 4.73761 6.17514 4.8158L4.82693 6.15944C4.74954 6.23965 4.65672 6.30346 4.55401 6.34704C4.4513 6.39063 4.34083 6.41309 4.2292 6.41309C4.11758 6.41309 4.0071 6.39063 3.90439 6.34704C3.80169 6.30346 3.70886 6.23965 3.63148 6.15944L0.553972 3.09112C0.0719903 4.07969 -0.101184 5.18977 0.0568768 6.27759C0.214938 7.36541 0.696953 8.38086 1.44049 9.19242C2.18404 10.004 3.15485 10.5743 4.2272 10.8294C5.29955 11.0846 6.42404 11.0128 7.45503 10.6235L14.2033 17.3505C14.4098 17.5564 14.655 17.7198 14.9249 17.8312C15.1947 17.9426 15.4839 18 15.776 18C16.068 18 16.3572 17.9426 16.6271 17.8312C16.8969 17.7198 17.1421 17.5564 17.3486 17.3505C17.5551 17.1446 17.7189 16.9002 17.8307 16.6312C17.9425 16.3621 18 16.0738 18 15.7826C18 15.4914 17.9425 15.203 17.8307 14.934C17.7189 14.665 17.5551 14.4205 17.3486 14.2146L10.6342 7.51305C11.0292 6.51583 11.1232 5.42527 10.9048 4.37546C10.6864 3.32565 10.165 2.36236 9.40485 1.60403Z"
            />
        </svg>
    );
};

export default WorkshopsMainMenuItem;
