export const assignAsssetModalActions = {
    HANDLE_FILTERS_CHANGE: 'ASSIGN_ASSSET_MODAL/HANDLE_FILTERS_CHANGE',
    SET_CHILD_ASSET_MODAL_VISIBILITY: 'ASSIGN_ASSSET_MODAL/SET_CHILD_ASSET_MODAL_VISIBILITY',
    SET_PARENT_ASSET_MODAL_VISIBILITY: 'ASSIGN_ASSSET_MODAL/SET_PARENT_ASSET_MODAL_VISIBILITY',
    SET_ASSET_ID: 'ASSIGN_ASSSET_MODAL/SET_ASSET_ID',
    SET_ASSIGN_ASSET_MODAL_CHECKED_ITEM: 'ASSIGN_ASSSET_MODAL/SET_ASSIGN_ASSET_MODAL_CHECKED_ITEM_ID',
    SET_IS_EDIT_PAGE: 'ASSIGN_ASSSET_MODAL/SET_IS_EDIT_PAGE',
    RESET_ASSIGN_ASSET_MODAL_TO_INITIAL_STATE: 'ASSIGN_ASSSET_MODAL/RESET_ASSIGN_ASSET_MODAL_TO_INITIAL_STATE',
}

export const handleFiltersChange = (payload) => ({
    type: assignAsssetModalActions.HANDLE_FILTERS_CHANGE,
    payload
})

export const setParentAssetModalVisibility = (payload) => ({
    type: assignAsssetModalActions.SET_PARENT_ASSET_MODAL_VISIBILITY,
    payload
})

export const setChildAssetModalVisibility = (payload) => ({
    type: assignAsssetModalActions.SET_CHILD_ASSET_MODAL_VISIBILITY,
    payload
})

export const setAssignAssetModalAssetId = (payload) => ({
    type: assignAsssetModalActions.SET_ASSET_ID,
    payload
})

export const setAssignAssetCheckedItem = (payload) => ({
    type: assignAsssetModalActions.SET_ASSIGN_ASSET_MODAL_CHECKED_ITEM,
    payload
})

export const setIsEditPage = (payload) => ({
    type: assignAsssetModalActions.SET_IS_EDIT_PAGE,
    payload
})

export const resetAssignAssetModalSliceToInitialState = () => ({
    type: assignAsssetModalActions.RESET_ASSIGN_ASSET_MODAL_TO_INITIAL_STATE
})
