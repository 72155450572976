import { ticketsFiltersActions } from './ticketsFilters.actions';

const ticketsFiltersInitialState = {
    isFilterButtonActive: false,
    isShowMyTickets: false,
    searchTerm: '',
    isDataLoading: false,
    archiveStatus: null,
    ticketsStatus: '',
    incidentType: null,
    ticketsPriority: '',
    ticketsCreatedAt: [null, null],
    ticketsResponsible: '',
    ticketsResolvedAsset: '',
    ticketsFailureCategory: '',
    ticketsFailureMode: '',
};

export const ticketsFiltersReducer = (state = ticketsFiltersInitialState, { type, payload }) => {
    switch (type) {
        case ticketsFiltersActions.CLEAR_TICKETS_FILTERS: {
            return {
                ...state,
                ...ticketsFiltersInitialState,
            };
        }
        case ticketsFiltersActions.SET_IS_FILTER_BUTTON_ACTIVE: {
            return {
                ...state,
                isFilterButtonActive: payload,
            };
        }
        case ticketsFiltersActions.SET_IS_SHOW_MY_TICKETS: {
            return {
                ...state,
                isShowMyTickets: payload,
            };
        }
        case ticketsFiltersActions.SET_IS_DATA_LOADING: {
            return {
                ...state,
                isDataLoading: payload,
            };
        }
        case ticketsFiltersActions.SET_SEARCH_TERM: {
            return {
                ...state,
                searchTerm: payload,
            };
        }
        case ticketsFiltersActions.SET_ARCHIVE_STATUS: {
            return {
                ...state,
                archiveStatus: payload,
            };
        }
        case ticketsFiltersActions.SET_TICKETS_STATUS: {
            return {
                ...state,
                ticketsStatus: payload,
            };
        }
        case ticketsFiltersActions.SET_INCIDENT_TYPE: {
            return {
                ...state,
                incidentType: payload,
            };
        }
        case ticketsFiltersActions.SET_TICKETS_PRIORITY: {
            return {
                ...state,
                ticketsPriority: payload,
            };
        }
        case ticketsFiltersActions.SET_TICKETS_CREATED_AT: {
            return {
                ...state,
                ticketsCreatedAt: payload,
            };
        }
        case ticketsFiltersActions.SET_TICKETS_RESPONSIBLE: {
            return {
                ...state,
                ticketsResponsible: payload,
            };
        }
        case ticketsFiltersActions.SET_TICKETS_RESOLVED_ASSETS: {
            return {
                ...state,
                ticketsResolvedAsset: payload,
            };
        }
        case ticketsFiltersActions.SET_TICKETS_FAILURE_CATEGORY: {
            return {
                ...state,
                ticketsFailureCategory: payload,
            };
        }
        case ticketsFiltersActions.SET_TICKETS_FAILURE_MODE: {
            return {
                ...state,
                ticketsFailureMode: payload,
            };
        }

        default:
            return state;
    }
};