export const language = {
  english: "en",
  germany: "de",
  hungarian: "hu",
  portugal: "pt"
};

export const languageOptions = [
  {
    label: "English 🇬🇧",
    value: language.english
  },
  {
    label: "Magyar 🇭🇺",
    value: language.hungarian
  },
  {
    label: "Deutsch 🇩🇪",
    value: language.germany
  }
];

export const workbookLanguageOptions = [
  {
    label: "Deutsch 🇩🇪",
    value: language.germany
  }
];

export const processLanguageOptions = [
  {
    label: "English 🇬🇧",
    value: language.english
  },
  {
    label: "Deutsch 🇩🇪",
    value: language.germany
  },
  {
    label: "Portuguese 🇵🇹",
    value: language.portugal
  }
]