import React from "react";

import i18next from "i18next";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 196,
        maxHeight: 56,
        display: "flex",
        boxShadow: "none",
        borderRadius: 4,
        "&.negative": {
            backgroundColor: theme.palette.background.default
        }
    },
    switch: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 8,
        width: 86,
        height: 40,
        borderRadius: 2,
        fontSize: 18,
        lineHeight: "27px",
        cursor: "pointer",
        color: theme.palette.switch.textTertiary,
        [theme.breakpoints.down("xs")]: {
            fontSize: 16,
            lineHeight: "25px"
        }
    },
    off: {
        color: theme.palette.switch.textSecondary,
        backgroundColor: theme.palette.switch.secondary,
        "&.negative": {
            backgroundColor: theme.palette.background.paper
        }
    },
    on: {
        color: theme.palette.switch.textPrimary,
        backgroundColor: theme.palette.switch.primary
    }
}));

const Switch = props => {
    const classes = useStyles();

    const handleOnClick = () => {
        props.onChange(true);
    };

    const handleOffClick = () => {
        props.onChange(false);
    };

    return (
        <Paper className={clsx(classes.root, props.negative ? 'negative' : '')}>
            <div className={clsx(classes.switch, !props.value ? classes.off : "", props.negative ? 'negative' : '')} onClick={handleOffClick}>
                {i18next.t("common.templates.switch.off")}
            </div>
            <div className={clsx(classes.switch, props.value ? classes.on : "", props.negative ? 'negative' : '')} onClick={handleOnClick}>
                {i18next.t("common.templates.switch.on")}
            </div>
        </Paper>
    );
};

export default Switch;
