import { GET_EHS_TICKETS_STACK_RANK, UPDATE_MANY_EHS_TICKETS_STACK_RANK } from '../gqlQueries/auditQueries';
import { GET_MAINTENANCE_TICKETS_STACK_RANK, UPDATE_MANY_MAINTENANCE_TICKETS_STACK_RANK } from '../gqlQueries/maintenanceQueries';

export const ticketType = {
    ehs: 'ehs',
    maintenance: 'maintenance',
    machineMaintenance: 'machineMaintenance',
    maintenanceStandard: 'maintenanceStandard',
};

export const ticketTypeInstance = {
    [ticketType.ehs]: 'ehsTicket',
    [ticketType.maintenance]: 'maintenanceTicket',
    [ticketType.machineMaintenance]: 'machineMaintenanceTicket',
    [ticketType.maintenanceStandard]: 'maintenanceStandardTicket',
};

export const ticketTypeConfigurations = {
    [ticketType.ehs]: {
        getTicketsStackRankQuery: GET_EHS_TICKETS_STACK_RANK,
        getTicketsPath: 'getEhsTickets',

        updateManyTicketsStackRankQuery: UPDATE_MANY_EHS_TICKETS_STACK_RANK,
        updateManyTicketsPath: 'ehsTickets',
        updateManyTicketsQueryPath: 'updateManyEhsTickets',
        updateManyCallbackPath: 'updateManyEhsTickets.ehsTickets',
    },
    [ticketType.maintenance]: {
        getTicketsStackRankQuery: GET_MAINTENANCE_TICKETS_STACK_RANK,
        getTicketsPath: 'getMaintenanceTickets',

        updateManyTicketsStackRankQuery: UPDATE_MANY_MAINTENANCE_TICKETS_STACK_RANK,
        updateManyTicketsPath: 'maintenanceTickets',
        updateManyTicketsQueryPath: 'updateManyMaintenanceTickets',
        updateManyCallbackPath: 'updateManyMaintenanceTickets.data',
    },
    [ticketType.machineMaintenance]: {
        getTicketsStackRankQuery: GET_MAINTENANCE_TICKETS_STACK_RANK,
        getTicketsPath: 'getMaintenanceTickets',

        updateManyTicketsStackRankQuery: UPDATE_MANY_MAINTENANCE_TICKETS_STACK_RANK,
        updateManyTicketsPath: 'maintenanceTickets',
        updateManyTicketsQueryPath: 'updateManyMaintenanceTickets',
        updateManyCallbackPath: 'updateManyMaintenanceTickets.data',
    },
    [ticketType.maintenanceStandard]: {
        getTicketsStackRankQuery: GET_MAINTENANCE_TICKETS_STACK_RANK,
        getTicketsPath: 'getMaintenanceTickets',

        updateManyTicketsStackRankQuery: UPDATE_MANY_MAINTENANCE_TICKETS_STACK_RANK,
        updateManyTicketsPath: 'maintenanceTickets',
        updateManyTicketsQueryPath: 'updateManyMaintenanceTickets',
        updateManyCallbackPath: 'updateManyMaintenanceTickets.data',
    },
};
