export const productionStandSharedActions = {
    SET_USERS_DATA: 'PRODUCTION_STAND_SHARED/SET_USERS_DATA',
    SET_MEDIA_INPUTS: 'PRODUCTION_STAND_SHARED/SET_MEDIA_INPUTS',
    SET_MEDIA_OUTPUTS: 'PRODUCTION_STAND_SHARED/SET_MEDIA_OUTPUTS',
    SET_MEDIA_DOCUMENTS: 'PRODUCTION_STAND_SHARED/SET_MEDIA_DOCUMENTS',
    SET_ACTIVE_TAB: 'PRODUCTION_STAND_SHARED/SET_ACTIVE_TAB',
    SET_REPORT_TAB_MEDIA: 'PRODUCTION_STAND_SHARED/SET_REPORT_TAB_MEDIA',
    ADD_MEDIA_OUTPUT: 'PRODUCTION_STAND_SHARED/ADD_MEDIA_OUTPUT',
    ADD_MEDIA_INPUTS: 'PRODUCTION_STAND_SHARED/ADD_MEDIA_INPUTS',
    SET_INITIAL_STATE_PRODUCTION_STAND_SHARED: 'PRODUCTION_STAND_SHARED/SET_INITIAL_STATE_PRODUCTION_STAND_SHARED',
};

export const setUsersData = payload => ({
    type: productionStandSharedActions.SET_USERS_DATA,
    payload,
});

export const setMediaInput = payload => ({
    type: productionStandSharedActions.SET_MEDIA_INPUTS,
    payload,
});

export const setMediaOutputs = payload => ({
    type: productionStandSharedActions.SET_MEDIA_OUTPUTS,
    payload,
});

export const setMediaDocuments = payload => ({
    type: productionStandSharedActions.SET_MEDIA_DOCUMENTS,
    payload,
});

export const setActiveTab = payload => ({
    type: productionStandSharedActions.SET_ACTIVE_TAB,
    payload,
});

export const setMediaForReportTab = payload => ({
    type: productionStandSharedActions.SET_REPORT_TAB_MEDIA,
    payload,
});

export const addMediaOutputs = payload => ({
    type: productionStandSharedActions.ADD_MEDIA_OUTPUT,
    payload,
});

export const setInitialStateProductionStandShared = () => ({
    type: productionStandSharedActions.SET_INITIAL_STATE_PRODUCTION_STAND_SHARED,
});

export const addMediaInputs = payload => ({
    type: productionStandSharedActions.ADD_MEDIA_INPUTS,
    payload,
});
