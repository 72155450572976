import i18next from "i18next";
import { protocolRole } from "./UserRole";

export const certificationTypes = {
    _2_1: "2.1",
    _2_2: "2.2",
    _3_1: "3.1",
    _3_2: "3.2",
    IPZ: "IPZ",
    IPB: "IPB"
};

export const certificationTypesTranslations = {
    [certificationTypes._2_1]: "common.certificationType._2_1.label",
    [certificationTypes._2_2]: "common.certificationType._2_2.label",
    [certificationTypes._3_1]: "common.certificationType._3_1.label",
    [certificationTypes._3_2]: "common.certificationType._3_2.label",
    [certificationTypes.IPZ]: "common.certificationType.IPZ.label",
    [certificationTypes.IPB]: "common.certificationType.IPB.label"
};

export const certificationDocumentTypesTranslations = {
    [certificationTypes._2_1]: "common.certificationType._2_1.documentType",
    [certificationTypes._2_2]: "common.certificationType._2_2.documentType",
    [certificationTypes._3_1]: "common.certificationType._3_1.documentType",
    [certificationTypes._3_2]: "common.certificationType._3_2.documentType",
    [certificationTypes.IPZ]: "common.certificationType.IPZ.documentType",
    [certificationTypes.IPB]: "common.certificationType.IPB.documentType"
};

export const getCertificationTypesOptions = () => [
    {
        title: i18next.t(certificationTypesTranslations[certificationTypes._2_1]),
        value: certificationTypes._2_1
    },
    {
        title: i18next.t(certificationTypesTranslations[certificationTypes._2_2]),
        value: certificationTypes._2_2
    },
    {
        title: i18next.t(certificationTypesTranslations[certificationTypes._3_1]),
        value: certificationTypes._3_1
    },
    {
        title: i18next.t(certificationTypesTranslations[certificationTypes._3_2]),
        value: certificationTypes._3_2
    },
    {
        title: i18next.t(certificationTypesTranslations[certificationTypes.IPZ]),
        value: certificationTypes.IPZ
    },
    {
        title: i18next.t(certificationTypesTranslations[certificationTypes.IPB]),
        value: certificationTypes.IPB
    }
];

export const certificationTypeSignatures = {
    [certificationTypes._2_1]: [protocolRole.manufacturer],
    [certificationTypes._2_2]: [protocolRole.manufacturer],
    [certificationTypes._3_1]: [protocolRole.manufacturer, protocolRole.quality],
    [certificationTypes._3_2]: [protocolRole.manufacturer, protocolRole.quality, protocolRole.customer],
    [certificationTypes.IPZ]: [protocolRole.manufacturer],
    [certificationTypes.IPB]: [protocolRole.manufacturer]
};