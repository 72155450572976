// eslint-disable-next-line no-extend-native
Number.prototype.pad = function (n) {
    return new Array(n).join('0').slice((n || 2) * -1) + this;
}

export const parseDecimal = str => {
    return str ? parseFloat(str.replace(',', '.')) : '';
};

export const removeZeros = str => {
    if (str.includes(',') || str.includes('.')) {
        while (true) {
            const char = str[str.length - 1];

            if (char === '0') {
                str = str.slice(0, -1);
                continue;
            }

            if (char === ',' || char === '.') {
                str = str.slice(0, -1);
                break;
            }

            break;
        }
    }

    return str;
};