const ProjectsMainMenuIcon = ({ id, className = {} }) => {
    return (
        <svg id={id} className={className} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2998_17256)">
                <path
                    d="M56.4403 6.53924H42.7721C42.1025 4.62809 40.8555 2.97226 39.2037 1.80076C37.5518 0.629266 35.5768 0 33.5517 0C31.5266 0 29.5516 0.629266 27.8998 1.80076C26.248 2.97226 25.001 4.62809 24.3313 6.53924H10.6652C8.93248 6.54413 7.27212 7.23448 6.04671 8.4595C4.82131 9.68451 4.13044 11.3447 4.125 13.0774V58.8525C4.1299 60.5856 4.82052 62.2462 6.04599 63.4717C7.27145 64.6972 8.93213 65.3878 10.6652 65.3927H56.4403C58.1734 65.3878 59.8341 64.6972 61.0595 63.4717C62.285 62.2462 62.9756 60.5856 62.9805 58.8525V13.0774C62.9751 11.3447 62.2842 9.68451 61.0588 8.4595C59.8334 7.23448 58.173 6.54413 56.4403 6.53924V6.53924ZM33.5466 6.53924C34.1931 6.53924 34.8252 6.73097 35.3628 7.09018C35.9004 7.44938 36.3194 7.95994 36.5668 8.55729C36.8142 9.15463 36.8789 9.81193 36.7528 10.4461C36.6267 11.0802 36.3153 11.6627 35.8581 12.1199C35.401 12.5771 34.8185 12.8884 34.1843 13.0146C33.5502 13.1407 32.8929 13.0759 32.2956 12.8285C31.6982 12.5811 31.1876 12.1621 30.8284 11.6245C30.4692 11.0869 30.2775 10.4549 30.2775 9.8083C30.2802 8.94106 30.6264 8.11021 31.2402 7.49755C31.854 6.8849 32.6855 6.54032 33.5528 6.53924H33.5466ZM24.6778 49.992L16.2236 41.5234C15.8979 41.2262 15.6359 40.8662 15.4533 40.4649C15.2707 40.0636 15.1714 39.6295 15.1613 39.1888C15.1513 38.748 15.2307 38.3098 15.3948 37.9006C15.5589 37.4915 15.8043 37.1198 16.1161 36.8081C16.4279 36.4965 16.7996 36.2513 17.2089 36.0874C17.6181 35.9234 18.0564 35.8442 18.4971 35.8545C18.9379 35.8647 19.3719 35.9642 19.7731 36.147C20.1743 36.3298 20.5343 36.592 20.8313 36.9178L27.0188 43.0641L46.2433 23.8395C46.8602 23.2561 47.6803 22.9364 48.5293 22.9482C49.3783 22.9601 50.1892 23.3026 50.7895 23.903C51.3899 24.5033 51.7324 25.3142 51.7443 26.1632C51.7561 27.0122 51.4364 27.8323 50.853 28.4492L29.3019 49.992C28.9995 50.295 28.6402 50.5354 28.2447 50.6994C27.8492 50.8635 27.4253 50.9479 26.9971 50.9479C26.5689 50.9479 26.145 50.8635 25.7495 50.6994C25.354 50.5354 24.9947 50.295 24.6923 49.992H24.6778Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_2998_17256">
                    <rect width="66" height="66" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ProjectsMainMenuIcon;
