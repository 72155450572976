export const ticketsActions = {
    RESET_TICKETS: 'TICKETS/RESET_TICKETS',
    SET_TICKETS_VIEW: 'TICKETS/SET_TICKETS_VIEW',
};

export const resetTickets = () => {
    return {
        type: ticketsActions.RESET_TICKETS
    }
}

export const setTicketsView = payload => {
    return {
        type: ticketsActions.SET_TICKETS_VIEW,
        payload,
    };
};