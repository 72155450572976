const CreateProjectMenuIcon = ({ id, className = {} }) => {
    return (
        <svg id={id} className={className} viewBox="0 0 56 44" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M50 5.5H28L24.1225 1.6225C23.0775 0.5775 21.675 0 20.2175 0H6C2.9475 0 0.5275 2.4475 0.5275 5.5L0.5 38.5C0.5 41.5525 2.9475 44 6 44H50C53.025 44 55.5 41.525 55.5 38.5V11C55.5 7.975 53.025 5.5 50 5.5ZM44.5 27.5H39V33C39 34.5125 37.7625 35.75 36.25 35.75C34.7375 35.75 33.5 34.5125 33.5 33V27.5H28C26.4875 27.5 25.25 26.2625 25.25 24.75C25.25 23.2375 26.4875 22 28 22H33.5V16.5C33.5 14.9875 34.7375 13.75 36.25 13.75C37.7625 13.75 39 14.9875 39 16.5V22H44.5C46.0125 22 47.25 23.2375 47.25 24.75C47.25 26.2625 46.0125 27.5 44.5 27.5Z"
            />
        </svg>
    );
};

export default CreateProjectMenuIcon;
