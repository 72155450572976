import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { get } from 'lodash';
import i18next from "i18next";

import { clearResultMessageAction } from "./reducers/RequestReducer";

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: 99999
    }
}));

const ResultMessageContainer = ({ ...props }) => {
    const classes = useStyles();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        props.clearResultMessage();
    };

    const isSuccess = (props.code && props.code === "200") || (props.data && props.data.every(res => res.code === "200"));
    const isPartialSuccess = (props.data && props.data.some(res => res.code === "200") && props.data.some(res => res.code !== "200"));
    const isError = (props.code && props.code !== "200") || (props.data && props.data.every(res => res.code !== "200"));

    return (
        <React.Fragment>
            {
                isSuccess &&
                <Snackbar className={classes.root} open={props.isOpen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                    <MuiAlert elevation={2} variant="filled" onClose={handleClose} severity="success">
                        {props.content || i18next.t(`common.result-message.success-message`)}
                    </MuiAlert>
                </Snackbar>
            }
            {
                isPartialSuccess &&
                <Snackbar className={classes.root} open={props.isOpen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                    <MuiAlert elevation={2} variant="filled" onClose={handleClose} severity="warning">
                        {props.content || i18next.t(`common.result-message.partial-success-message`)}
                    </MuiAlert>
                </Snackbar>
            }
            {
                isError &&
                <Snackbar className={classes.root} open={props.isOpen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                    <MuiAlert elevation={2} variant="filled" onClose={handleClose} severity="error">
                        {props.content || i18next.t(`common.result-message.error-message`)}
                    </MuiAlert>
                </Snackbar>
            }
        </React.Fragment>
    );
}

const ResultMessage = connect(
    store => {
        return {
            isOpen: get(store.request, 'showMessage'),
            code: get(store.request, 'code'),
            data: get(store.request, 'data'),
            content: get(store.request, 'content')
        };
    },
    dispatch => {
        return {
            clearResultMessage: () => {
                dispatch(clearResultMessageAction());
            }
        };
    })
    (ResultMessageContainer);

export default ResultMessage;