import React, { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

import { Auth } from 'aws-amplify';
import i18next from 'i18next';

import { ReactComponent as AdamosLogo } from './Icons/AdamosLogo.svg';

import { ADAMOS_IDENTITY_PROVIDER_NAME } from '../../../../utils/env';

import './AdamosSignIn.scss';

const AdamosSignIn = () => {

    useEffect(() => {
        const adamosKnowIssuerDomainsRegex = /https:\/\/id\.adamos\.(?:dev|com)/;
        const queryParams = new URLSearchParams(window.location.search);
        const term = queryParams.get("iss");

        if (adamosKnowIssuerDomainsRegex.test(term)) {
            handleFeredatedSignIn(ADAMOS_IDENTITY_PROVIDER_NAME);
        }
    }, []);

    const handleFeredatedSignIn = async provider => {
        try {
            // returns user credentials as an object
            await Auth.federatedSignIn({ provider });
        } catch (err) {
            console.error('Something went wrong while logging in: ', err);
        }
    };

    return (
        <div className="AdamosSignIn-root">
            <Button className="adamosSignIn" onClick={async () => await handleFeredatedSignIn(ADAMOS_IDENTITY_PROVIDER_NAME)}>
                <AdamosLogo className="adamosLogo" />
                <Typography className="labelText">{i18next.t(`authentication.signIn.adamosSignIn`)}</Typography>
                <div className="loginIcon" />
            </Button>
        </div>
    );
};

export default AdamosSignIn;
