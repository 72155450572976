import React from 'react';

import { DeviceType } from '../../../constants/DeviceEnums';
import { themeType } from '../../../constants/ThemeType';
import { darkTheme } from '../../../design/darkTheme';
import { lightTheme } from '../../../design/lightTheme';

import { ReactComponent as DesktopViewIcon } from '../../../resources/icons/desktopViewIcon.svg';
import { ReactComponent as MobileViewIcon } from '../../../resources/icons/mobileViewIcon.svg';
import { ReactComponent as TabletViewIcon } from '../../../resources/icons/tabletViewIcon.svg';

export const deviceSettings = [
    {
        key: DeviceType.mobile,
        icon: <MobileViewIcon />,
        label: 'user-settings.user-info.device.mobile-label',
    },
    {
        key: DeviceType.tablet,
        icon: <TabletViewIcon />,
        label: 'user-settings.user-info.device.tablet-label',
    },
    {
        key: DeviceType.desktop,
        icon: <DesktopViewIcon />,
        label: 'user-settings.user-info.device.desktop-label',
    },
];

export const userThemePallete = {
    [themeType.light]: lightTheme,
    [themeType.dark]: darkTheme,
};

export const daysCountWeeklyNotification = 1;
export const daysCountDailyNotification = 5;
export const dailyOverdueTicketsMailValue = [1, 2, 3, 4, 5];
export const weeklyOverdueTicketsMailValue = [1];
