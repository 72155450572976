// UTILS
import update from "../../../redux/update";

// CONSTANTS
import { globalActionTypes } from "../../../redux/actions";

const actions = {
    clear: "MACHINE_MAINTENANCE/TICKETS/CLEAR",
    changeValue: "MACHINE_MAINTENANCE/TICKETS/CHANGE_VALUE"
};

const initialState = {
    assetSelection: {
        assetData: {
            costCenter: "",
            parentAssetId: ""
        },
        costCenterOptions: [],
        assetParentOptions: [],
        assetChildrenOptions: []
    }
};

export const machineMaintenanceTicketsReducer = (state = initialState, action) => {
    let newState = state;

    switch (action.type) {
        case globalActionTypes.generalClear:
        case actions.clear: {
            newState = initialState;
            break;
        }

        case actions.changeValue: {
            const { path, value } = action.payload;
            newState = update.set(state, path, value);
            break;
        }

        default: break;
    }

    return newState;
};

export const changeValueAction = (path, value) => {
    return {
        type: actions.changeValue,
        payload: {
            path,
            value
        }
    };
};

export const stateClearAction = () => {
    return {
        type: actions.clear
    };
};