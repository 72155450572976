export const siemensPDFLogo = {
    svg: `<svg width="100" height="50" viewBox="0 0 1000 159.10601">
            <g transform="translate(0,-893.25616)">
                <g
                    id="g5329"
                    transform="matrix(1.4419456,0,0,1.4419456,0,-465.08683)">
                    <path
                        class="fil0"
                        d="m 685.39819,945.8929 0,20.65359 c -10.70008,-4.03937 -20.22661,-6.08173 -28.56437,-6.08173 -4.93866,0 -8.87138,0.91452 -11.82827,2.68263 -2.95724,1.78335 -4.46633,3.97843 -4.46633,6.56965 0,3.4448 3.33814,6.50835 10.06015,9.25228 l 19.4191,9.45036 c 15.70004,7.48417 23.48893,17.42212 23.48893,29.90582 0,10.3802 -4.13078,18.657 -12.46854,24.7844 -8.27681,6.1888 -19.41909,9.2523 -33.28972,9.2523 -6.40205,0 -12.14823,-0.2743 -17.25449,-0.8536 -5.10626,-0.5485 -10.97469,-1.6767 -17.48339,-3.2924 l 0,-21.6138 c 11.93492,4.0393 22.80296,6.0817 32.58851,6.0817 11.6759,0 17.48338,-3.3839 17.48338,-10.2125 0,-3.3991 -2.37791,-6.1427 -7.20956,-8.2921 l -21.56847,-9.1913 c -7.9565,-3.5972 -13.87063,-7.95651 -17.7424,-13.12372 -3.81047,-5.21291 -5.74654,-11.18799 -5.74654,-18.01665 0,-9.55737 4.02414,-17.25485 11.98063,-23.10804 8.0178,-5.80748 18.65729,-8.71866 31.88764,-8.71866 4.29839,0 9.25228,0.38091 14.7398,1.08213 5.53288,0.74693 10.85245,1.66146 15.97394,2.78964 z"
                        id="path4166"
                        inkscape:connector-curvature="0"
                        style="clip-rule:evenodd;fill:#009999;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision" />
                    <path
                        class="fil0"
                        d="m 74.53629,945.8929 0,20.65359 c -10.70043,-4.03937 -20.21173,-6.08173 -28.54948,-6.08173 -4.9539,0 -8.87103,0.91452 -11.82827,2.68263 -2.95689,1.78335 -4.46599,3.97843 -4.46599,6.56965 0,3.4448 3.38386,6.50835 10.10587,9.25228 l 19.4191,9.45036 c 15.63874,7.48417 23.44322,17.42212 23.44322,29.90582 0,10.3802 -4.14602,18.657 -12.42283,24.7844 -8.33776,6.1888 -19.4191,9.2523 -33.33543,9.2523 -6.40205,0 -12.16382,-0.2743 -17.27008,-0.8536 -5.10626,-0.5485 -10.91374,-1.6767 -17.4678,-3.2924 l 0,-21.6138 c 11.98063,4.0393 22.84866,6.0817 32.57327,6.0817 11.67591,0 17.48339,-3.3839 17.48339,-10.2125 0,-3.3991 -2.36268,-6.1427 -7.14898,-8.2921 l -21.56811,-9.1913 C 15.48637,1001.391 9.57224,997.03169 5.76177,991.86448 1.89,986.65157 0,980.67649 0,973.84783 c 0,-9.55737 3.99366,-17.25485 11.99586,-23.10804 7.95685,-5.80748 18.61122,-8.71866 31.84193,-8.71866 4.3441,0 9.23705,0.38091 14.78516,1.08213 5.48752,0.74693 10.80709,1.66146 15.91334,2.78964 z"
                        id="path4168"
                        inkscape:connector-curvature="0"
                        style="clip-rule:evenodd;fill:#009999;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision" />
                    <polygon
                        class="fil0"
                        points="42.9291,9.8002 42.9291,39.8483 34.0028,39.8483 34.0028,9.8002 "
                        id="polygon4170"
                        style="clip-rule:evenodd;fill:#009999;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision"
                        transform="matrix(3.5433071,0,0,3.5433072,-23.69197,909.23172)" />
                    <polygon
                        class="fil0"
                        points="71.9576,39.8483 49.8335,39.8483 49.8335,9.8002 71.6866,9.8002 71.6866,15.3539 58.4241,15.3539 58.4241,22.0173 69.9272,22.0173 69.9272,27.0246 58.4241,27.0246 58.4241,34.0194 71.9576,34.0194 "
                        id="polygon4172"
                        style="clip-rule:evenodd;fill:#009999;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision"
                        transform="matrix(3.5433071,0,0,3.5433072,-23.69197,909.23172)" />
                    <polygon
                        class="fil0"
                        points="76.8918,39.8483 76.8918,9.8002 87.7882,9.8002 95.226,28.1947 103.008,9.8002 113.358,9.8002 113.358,39.8483 105.025,39.8483 105.025,20.0299 96.3789,40.1236 91.234,40.1236 82.9186,20.0299 82.9186,39.8483 "
                        id="polygon4174"
                        style="clip-rule:evenodd;fill:#009999;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision"
                        transform="matrix(3.5433071,0,0,3.5433072,-23.69197,909.23172)" />
                    <polygon
                        class="fil0"
                        points="142.374,39.8483 120.25,39.8483 120.25,9.8002 142.103,9.8002 142.103,15.3539 128.913,15.3539 128.913,22.0173 140.416,22.0173 140.416,27.0246 128.913,27.0246 128.913,34.0194 142.374,34.0194 "
                        id="polygon4176"
                        style="clip-rule:evenodd;fill:#009999;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision"
                        transform="matrix(3.5433071,0,0,3.5433072,-23.69197,909.23172)" />
                    <polygon
                        class="fil0"
                        points="173.424,39.8483 163.956,39.8483 153.331,20.5762 153.331,39.8483 147.308,39.8483 147.308,9.8002 157.052,9.8002 167.402,28.7411 167.402,9.8002 173.424,9.8002 "
                        id="polygon4178"
                        style="clip-rule:evenodd;fill:#009999;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision"
                        transform="matrix(3.5433071,0,0,3.5433072,-23.69197,909.23172)" />
                </g>
            </g>
        </svg>
    `,
    margin: [4, 0, 0, 0],
    width: 100,
    height: 50,
    colSpan: 3,
    rowSpan: 2
};