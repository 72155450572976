import React from "react";

// LIBS
import clsx from "clsx";
import i18next from "i18next";
import { useHistory } from "react-router-dom";

// MATERIAL UI - CORE
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

// ICONS
import { ReactComponent as MaintenanceIcon } from "../../resources/icons/maintenanceIcon.svg";
import { ReactComponent as AssetsIcon } from "../../resources/icons/assetsIcon.svg";
import { ReactComponent as ReportsIcon } from "../../resources/icons/reportsIcon.svg";
import { ReactComponent as ShiftbookIcon } from "../../resources/icons/shiftbookIcon.svg";

// CONSTANTS
import { APP_CUSTOMER } from "../../utils/env";
import { reactAppCustomer } from "../../constants/Config";

const LeftNavbar = ({
    open,
    classes,
    isNavigationButtonActive,
    getListIconComponent
}) => {
    const history = useHistory();

    const isTicketsActive = isNavigationButtonActive('/tickets');
    const isAssetsActive = isNavigationButtonActive('/assets');
    const isShiftbookActive = isNavigationButtonActive('/shiftbook');
    const isReportsActive = isNavigationButtonActive('/reports');

    return (
        <React.Fragment>
            <ListItem className={clsx(classes.navigationButton, isTicketsActive ? "active" : "")} onClick={() => history.push("/tickets")}>
                <ListItemIcon>
                    {getListIconComponent('menu.maintenance-tickets', <MaintenanceIcon id='maintenanceTickets' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.maintenance-tickets`)} />
            </ListItem>

            <ListItem className={clsx(classes.navigationButton, isAssetsActive ? "active" : "")} onClick={() => history.push("/assets")}>
                <ListItemIcon>
                    {getListIconComponent('menu.assets', <AssetsIcon id='maintenanceAssets' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.assets`)} />
            </ListItem>

            {
                APP_CUSTOMER === reactAppCustomer.vwpwk &&
                <ListItem className={clsx(classes.navigationButton, isShiftbookActive ? "active" : "")} onClick={() => history.push("/shiftbook")}>
                    <ListItemIcon>
                        {getListIconComponent('menu.shiftbook', <ShiftbookIcon id='maintenanceShiftbook' />, open)}
                    </ListItemIcon>
                    <ListItemText primary={i18next.t(`menu.shiftbook`)} />
                </ListItem>
            }

            <ListItem className={clsx(classes.navigationButton, isReportsActive ? "active" : "")} onClick={() => history.push("/reports")}>
                <ListItemIcon>
                    {getListIconComponent('menu.reports', <ReportsIcon id='maintenanceReports' />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.reports`)} />
            </ListItem>
        </React.Fragment>
    );
};

export default LeftNavbar;