import React from 'react';

// LIBS
import i18next from 'i18next';

// MASKS
import BubbleMask1 from "../../resources/images/BubbleMask1.png";
import BubbleMask2 from "../../resources/images/BubbleMask2.png";
import BubbleMask3 from "../../resources/images/BubbleMask3.png";

// OTHER
import "./Auth.css";

const Auth = props => {
    return (
        <div className="auth-layout">
            <div className="bubble-1">
                <img src={BubbleMask1} alt="bubbleMask1" />
            </div>
            <div className="bubble-2">
                <img src={BubbleMask2} alt="bubbleMask2" />
            </div>
            <div className="bubble-3">
                <img src={BubbleMask3} alt="bubbleMask3" />
            </div>
            <div className="auth-container">
                <div className="app-name-container">
                    {i18next.t(`menu.appName`)}
                </div>
                {props.children}
            </div>
        </div>
    );
};

export default Auth;
