import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import i18next from "i18next";

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: 99999,
        "& .MuiAlert-action": {
            marginRight: 0
        }
    },
    actionButton: {
        color: "#007AFF",
        textDecoration: "underline",
        textTransform: "uppercase"
    }
}));

const NewVersionSnackBar = props => {
    const classes = useStyles();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    };

    return (
        <Snackbar
            className={classes.root}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={props.open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <MuiAlert elevation={6} onClose={handleClose} severity="info" action={
                <React.Fragment>
                    <Button color="secondary" size="small" onClick={props.handleActionClick}>
                        <span className={classes.actionButton}>
                            {i18next.t("common.new-version-available.button")}
                        </span>
                    </Button>
                </React.Fragment>
            }>
                {i18next.t("common.new-version-available.content")}
            </MuiAlert>
        </Snackbar>
    );
};

export default NewVersionSnackBar;
