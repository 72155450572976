import createMongoObjectId from '../../../../../../utils/createMongoObjID';
import { templateCreationActions } from './templateCreation.actions';

const templateCreationIntialState = {
    disableInputs: false,
    model: {
        _id: null,
        templateName: '',
        templateDescription: '',
        processRoutings: null,
        templateImage: {},
        templateImageTileData: [],
        selectedRoutings: [],
        materialID: null,
        mediaOutputs: [],
    },
    filters: {
        searchTerm: '',
        selectedTypes: [],
        showFilter: false,
    },
    attachmentTypes: [],
    attachmentsActiveTab: 'all',
};

export const templateCreationReducer = (state = templateCreationIntialState, { type, payload }) => {
    switch (type) {
        case templateCreationActions.CHANGE_DESCRIPTION_INPUT: {
            return {
                ...state,
                model: {
                    ...state.model,
                    [payload.path]: payload.value,
                },
            };
        }
        case templateCreationActions.SET_MODEL: {
            return {
                ...state,
                model: {
                    ...state.model,
                    ...payload,
                },
            };
        }
        case templateCreationActions.CHANGE_SEARCH_TERM: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    searchTerm: payload,
                },
            };
        }
        case templateCreationActions.CHANGE_SELECTED_TYPES: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedTypes: payload,
                },
            };
        }
        case templateCreationActions.SET_SELECTED_ROUTINGS: {
            return {
                ...state,
                model: {
                    ...state.model,
                    selectedRoutings: payload,
                },
            };
        }
        case templateCreationActions.SET_TEMPLATE_IMAGE_TILE_DATA: {
            return {
                ...state,
                model: {
                    ...state.model,
                    templateImageTileData: payload,
                },
            };
        }

        case templateCreationActions.SET_TEMPLATE_IMAGE_FILE_NAME: {
            return {
                ...state,
                model: {
                    ...state.model,
                    templateImage: {
                        mediaName: payload,
                    },
                },
            };
        }
        case templateCreationActions.SET_SHOW_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    showFilter: !state.filters.showFilter,
                },
            };
        }
        case templateCreationActions.RESET_TO_INITIAL_STATE: {
            return templateCreationIntialState;
        }

        case templateCreationActions.TOOGLE_DISABLE_OF_INPUTS: {
            return {
                ...state,
                disableInputs: payload,
            };
        }

        case templateCreationActions.SET_MATERIAL_ID: {
            return {
                ...state,
                model: { ...state.model, materialID: payload },
            };
        }

        case templateCreationActions.SET_PROJECT_ATTACHMENTS_ACTIVE_TAB: {
            return {
                ...state,
                attachmentsActiveTab: payload,
            };
        }

        case templateCreationActions.SET_ATTACHMENT_TYPES: {
            return {
                ...state,
                attachmentTypes: payload,
            };
        }

        case templateCreationActions.ADD_MEDIA: {
            return {
                ...state,
                model: { ...state.model, mediaOutputs: [...state.model.mediaOutputs, ...payload] },
            };
        }

        case templateCreationActions.CHANGE_MEDIA_ATTACHMENT_TYPE: {
            return {
                ...state,
                model: {
                    ...state.model,
                    mediaOutputs: state.model.mediaOutputs.map(media => {
                        if (media._id !== payload.mediaId) {
                            return media;
                        }

                        return {
                            ...media,
                            resolvedAttachmentType: {
                                _id: payload.attachmentType,
                            },
                        };
                    }),
                },
            };
        }

        case templateCreationActions.DELETE_MEDIA: {
            return {
                ...state,
                model: {
                    ...state.model,
                    mediaOutputs: state.model.mediaOutputs.filter(media => media._id !== payload.mediaId),
                },
            };
        }

        default:
            return state;
    }
};
