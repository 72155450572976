import i18next from "i18next";
import { get } from "lodash";

import { DDCExportOptions } from "../../../../constants/DDCExport";

import { parseDecimal } from "../../../../utils/numberHelper";
import { findProtocolValue } from "../../../../utils/protocolHelper";

import { alignmentOptions } from "./alignment";

export const configurations = {
    label: "label",
    stringLabel: "stringLabel",
    measurand: "measurand",
    unit: "unit",
    targetValue: "targetValue",
    selectTargetValue: "selectTargetValue",
    lowerTolerance: "lowerTolerance",
    upperTolerance: "upperTolerance",
    defaultValue: "defaultValue",
    helperText: "helperText",
    placeholder: "placeholder",
    relevantForValidation: "relevantForValidation",
    startAdornment: "startAdornment",
    endAdornment: "endAdornment",
    checked: "checked",
    value: "value",
    highlighted: "highlighted",
    autoFillValue: "autoFillValue",
    width: "width",
    mediaUpload: "mediaUpload",
    selectValue: "selectValue",
    options: "options",
    alignment: "alignment",
    characteristicRequestedSignatureRole: "characteristicRequestedSignatureRole",
    characteristicSignatureRequired: "characteristicSignatureRequired",
    requestedSignatureRole: "requestedSignatureRole",
    signatureRequired: "signatureRequired",
    personValue: "personValue",
    autofillPerson: "autofillPerson",
    dateValue: "dateValue",
    ddcExportFile: "ddcExportFile",
    ddcExportWorksheet: "ddcExportWorksheet",
    ddcExportColumn: "ddcExportColumn"
};

export const configurationInputType = {
    input: "input",
    select: "select",
    mediaUpload: "mediaUpload",
    options: "options",
    userAutocomplete: "userAutocomplete",
    datepicker: "datepicker"
};

export const configurationSelectDefaultOptions = [
    {
        key: "yes",
        value: "true",
        translation: "protocol-template-builder.configurations.default-select-options.yes"
    },
    {
        key: "no",
        value: "false",
        translation: "protocol-template-builder.configurations.default-select-options.no"
    }
];

export const configurationSelectBooleanDefaultOptions = [
    {
        key: "true",
        value: "true",
        translation: "protocol-template-builder.configurations.default-boolean-select-options.true"
    },
    {
        key: "false",
        value: "false",
        translation: "protocol-template-builder.configurations.default-boolean-select-options.false"
    }
];

export const configurationOptions = {
    [configurations.label]: {
        path: "label",
        translation: "protocol-template-builder.configurations.options.label",
        inputType: configurationInputType.input,
        allowLanguages: true
    },
    [configurations.stringLabel]: {
        path: "label",
        translation: "protocol-template-builder.configurations.options.label",
        inputType: configurationInputType.input,
        allowLanguages: false
    },
    [configurations.measurand]: {
        path: "measurand",
        translation: "protocol-template-builder.configurations.options.measurand",
        inputType: configurationInputType.input
    },
    [configurations.unit]: {
        path: "unit",
        translation: "protocol-template-builder.configurations.options.unit",
        inputType: configurationInputType.input
    },
    [configurations.targetValue]: {
        path: "targetValue",
        translation: "protocol-template-builder.configurations.options.targetValue",
        inputType: configurationInputType.input
    },
    [configurations.selectTargetValue]: {
        path: "targetValue",
        translation: "protocol-template-builder.configurations.options.targetValue",
        inputType: configurationInputType.select,
        options: configurationSelectBooleanDefaultOptions
    },
    [configurations.lowerTolerance]: {
        path: "lowerToleranceAbsolut",
        translation: "protocol-template-builder.configurations.options.lowerTolerance.label",
        inputType: configurationInputType.input,
        isValid: model => {
            const targetValue = model.targetValue;
            const parsedTargetValue = parseDecimal(targetValue);

            if (!parsedTargetValue) {
                return true;
            }

            const lowerTolerance = model.lowerToleranceAbsolut || '';
            const parsedLowerTolerance = parseDecimal(lowerTolerance);

            if (!parsedLowerTolerance) {
                return true;
            }

            return parsedLowerTolerance <= parsedTargetValue;
        },
        getValidationMessage: model => {
            return i18next.t("protocol-template-builder.configurations.options.lowerTolerance.validationMessage");
        }
    },
    [configurations.upperTolerance]: {
        path: "upperToleranceAbsolut",
        translation: "protocol-template-builder.configurations.options.upperTolerance.label",
        inputType: configurationInputType.input,
        isValid: model => {
            const targetValue = model.targetValue;
            const parsedTargetValue = parseDecimal(targetValue);

            if (!parsedTargetValue) {
                return true;
            }

            const upperTolerance = model.upperToleranceAbsolut || '';
            const parsedUpperTolerance = parseDecimal(upperTolerance);

            if (!parsedUpperTolerance) {
                return true;
            }

            return parsedUpperTolerance >= parsedTargetValue;
        },
        getValidationMessage: model => {
            return i18next.t("protocol-template-builder.configurations.options.upperTolerance.validationMessage");
        }
    },
    [configurations.defaultValue]: {
        path: "defaultValue",
        translation: "protocol-template-builder.configurations.options.defaultValue",
        inputType: configurationInputType.input
    },
    [configurations.helperText]: {
        path: "helperText",
        translation: "protocol-template-builder.configurations.options.helperText",
        inputType: configurationInputType.input,
        allowLanguages: true
    },
    [configurations.placeholder]: {
        path: "placeholder",
        translation: "protocol-template-builder.configurations.options.placeholder",
        inputType: configurationInputType.input,
        allowLanguages: true
    },
    [configurations.relevantForValidation]: {
        path: "relevantForValidation",
        translation: "protocol-template-builder.configurations.options.relevantForValidation",
        inputType: configurationInputType.select,
        options: configurationSelectDefaultOptions
    },
    [configurations.startAdornment]: {
        path: "startAdornment",
        translation: "protocol-template-builder.configurations.options.startAdornment",
        inputType: configurationInputType.input
    },
    [configurations.endAdornment]: {
        path: "endAdornment",
        translation: "protocol-template-builder.configurations.options.endAdornment",
        inputType: configurationInputType.input
    },
    [configurations.checked]: {
        path: "checked",
        translation: "protocol-template-builder.configurations.options.checked",
        inputType: configurationInputType.select,
        options: configurationSelectDefaultOptions
    },
    [configurations.value]: {
        path: "value",
        translation: "protocol-template-builder.configurations.options.value",
        inputType: configurationInputType.input,
        allowLanguages: true
    },
    [configurations.highlighted]: {
        path: "highlighted",
        translation: "protocol-template-builder.configurations.options.highlighted",
        inputType: configurationInputType.select,
        options: configurationSelectDefaultOptions,
        allowGroupChange: true
    },
    [configurations.autoFillValue]: {
        path: "autoFillValue",
        translation: "protocol-template-builder.configurations.options.autoFillValue",
        inputType: configurationInputType.select,
        options: configurationSelectDefaultOptions,
        allowGroupChange: true
    },
    [configurations.width]: {
        path: "width",
        translation: "protocol-template-builder.configurations.options.width",
        inputType: configurationInputType.input,
        allowGroupChange: true,
        endAdornment: 'px'
    },
    [configurations.mediaUpload]: {
        path: "defaultValue",
        labelTranslation: "protocol-template-builder.configurations.options.mediaUpload.label",
        buttonTranslation: "protocol-template-builder.configurations.options.mediaUpload.button",
        inputType: configurationInputType.mediaUpload
    },
    [configurations.selectValue]: {
        path: "dropdown.defaultValue",
        optionsPath: "dropdown.options",
        translation: "protocol-template-builder.configurations.options.defaultValue",
        inputType: configurationInputType.select,
        allowLanguages: true,
        getOptions: (options, language) => {
            if (options) {
                return options.map(opt => {
                    const val = findProtocolValue(opt, language);

                    return val ? {
                        value: val
                    } : null;
                });
            }
            return [];
        }
    },
    [configurations.options]: {
        path: "dropdown.options",
        labelTranslation: "protocol-template-builder.configurations.options.options.label",
        inputType: configurationInputType.options,
        allowLanguages: true
    },
    [configurations.alignment]: {
        path: "alignment",
        translation: "protocol-template-builder.configurations.options.alignment",
        inputType: configurationInputType.select,
        options: alignmentOptions
    },
    [configurations.characteristicRequestedSignatureRole]: {
        path: "requestedSignatureRole",
        translation: "protocol-template-builder.configurations.options.requestedSignatureRole",
        inputType: configurationInputType.input
    },
    [configurations.characteristicSignatureRequired]: {
        path: "signatureRequired",
        translation: "protocol-template-builder.configurations.options.signatureRequired",
        inputType: configurationInputType.select,
        options: configurationSelectDefaultOptions,
    },
    [configurations.requestedSignatureRole]: {
        path: "signedBy.requestedSignatureRole",
        translation: "protocol-template-builder.configurations.options.requestedSignatureRole",
        inputType: configurationInputType.input
    },
    [configurations.signatureRequired]: {
        path: "signedBy.signatureRequired",
        translation: "protocol-template-builder.configurations.options.signatureRequired",
        inputType: configurationInputType.select,
        options: configurationSelectDefaultOptions,
    },
    [configurations.personValue]: {
        path: "defaultValue",
        translation: "protocol-template-builder.configurations.options.defaultValue",
        inputType: configurationInputType.userAutocomplete,
        getOptions: users => {
            return users ? users
                .filter(user => !user.disabled)
                .map(person => {
                    const name = `${person.name}, ${person.firstName}`;
                    return {
                        title: name,
                        value: name
                    };
                }) : [];
        }
    },
    [configurations.autofillPerson]: {
        path: "autofillPerson",
        translation: "protocol-template-builder.configurations.options.autofillPerson",
        inputType: configurationInputType.select,
        options: configurationSelectDefaultOptions,
    },
    [configurations.dateValue]: {
        path: "defaultValue",
        translation: "protocol-template-builder.configurations.options.defaultValue",
        inputType: configurationInputType.datepicker
    },
    [configurations.ddcExportFile]: {
        path: "gtXlsExport.templateFile",
        translation: "protocol-template-builder.configurations.options.ddcExportFile",
        inputType: configurationInputType.select,
        allowGroupChange: true,
        dependencies: ["gtXlsExport.worksheet", "gtXlsExport.columnName"],
        getOptions: value => {
            return DDCExportOptions;
        }
    },
    [configurations.ddcExportWorksheet]: {
        path: "gtXlsExport.worksheet",
        translation: "protocol-template-builder.configurations.options.ddcExportWorksheet",
        inputType: configurationInputType.select,
        allowGroupChange: true,
        dependencies: ["gtXlsExport.columnName"],
        getOptions: (value, language, model) => {
            const templateFile = get(model, 'gtXlsExport.templateFile');

            return DDCExportOptions.find(item => item.value === templateFile)?.worksheets || [];
        }
    },
    [configurations.ddcExportColumn]: {
        path: "gtXlsExport.columnName",
        translation: "protocol-template-builder.configurations.options.ddcExportColumn",
        inputType: configurationInputType.select,
        allowGroupChange: true,
        getOptions: (value, language, model) => {
            const templateFile = get(model, 'gtXlsExport.templateFile');
            const worksheet = get(model, 'gtXlsExport.worksheet');

            const worksheets = DDCExportOptions.find(file => file.value === templateFile)?.worksheets || [];

            return worksheets.find(item => item.value === worksheet)?.columns || [];
        }
    },
};