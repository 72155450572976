export const orderActions = {
    CHANGE_WORKPLACE_TAB: 'ORDER_OVERVIEW/CHANGE_WORKPLACE_TAB',
    SET_WORKPLACE_TABS: 'ORDER_OVERVIEW/SET_WORKPLACE_TABS',
    SET_ORDERS: 'ORDER_OVERVIEW/SET_ORDERS',
};

export const changeWorkplaceTabAction = (payload) => {
    return {
        type: orderActions.CHANGE_WORKPLACE_TAB,
        payload
    }
}

export const setWorkplaceTabs = (payload) => {
    return {
        type: orderActions.SET_WORKPLACE_TABS,
        payload
    }
};

export const setOrders = (payload) => {
    return {
        type: orderActions.SET_ORDERS,
        payload
    }
};
