export const topBarActions = {
    HANDLE_TOP_BAR_VISIBILITY_CHANGE: 'TOP_BAR/HANDLE_TOP_BAR_VISIBILITY_CHANGE',
    HANDLE_LANGUAGE_CHANGE: 'TOP_BAR/HANDLE_LANGUAGE_CHANGE',
    SET_INITIAL_STATE_TOP_BAR: 'TOP_BAR/SET_INITIAL_STATE_TOP_BAR',
};

export const handleTopBarVisibilityChange = () => ({
    type: topBarActions.HANDLE_TOP_BAR_VISIBILITY_CHANGE,
});

export const handleLanguageChange = payload => ({
    type: topBarActions.HANDLE_LANGUAGE_CHANGE,
    payload,
});

export const setInitialStateTopBar = () => ({
    type: topBarActions.SET_INITIAL_STATE_TOP_BAR,
});
