export const createProductionOrderActions = {
    SET_IS_OVERVIEW_CONTENT: 'CREATE_PRODUCTION_ORDER/SET_IS_OVERVIEW_CONTENT',
    SET_IS_ATTACHMENTS_CONTENT: 'CREATE_PRODUCTION_ORDER/SET_IS_ATTACHMENTS_CONTENT',
    SET_TEMPLATE_PRODUCTION_ORDER: 'CREATE_PRODUCTION_ORDER/SET_TEMPLATE_PRODUCTION_ORDER',
    SET_IS_PRODUCTION_ORDER_CREATED: 'CREATE_PRODUCTION_ORDER/SET_IS_PRODUCTION_ORDER_CREATED',
    UPDATE_TEMPLATE_PRODUCTION_ORDER_PROPERTY: 'CREATE_PRODUCTION_ORDER/UPDATE_TEMPLATE_PRODUCTION_ORDER_PROPERTY',
    SET_DISABLE_INPUTS: 'CREATE_PRODUCTION_ORDER/SET_DISABLE_INPUTS',

    RESET_CREATE_PRODUCTION_ORDER: 'CREATE_PRODUCTION_ORDER/RESET_CREATE_PRODUCTION_ORDER',
};

export const setIsOverviewContent = payload => {
    return {
        type: createProductionOrderActions.SET_IS_OVERVIEW_CONTENT,
        payload,
    };
};

export const setIsAttachmentsContent = payload => {
    return {
        type: createProductionOrderActions.SET_IS_ATTACHMENTS_CONTENT,
        payload,
    };
};

export const setTemplateProductionOrder = payload => {
    return {
        type: createProductionOrderActions.SET_TEMPLATE_PRODUCTION_ORDER,
        payload,
    };
};

export const updateTemplateProductionOrderProperty = payload => {
    return {
        type: createProductionOrderActions.UPDATE_TEMPLATE_PRODUCTION_ORDER_PROPERTY,
        payload,
    };
};

export const setIsProductionOrderCreated = payload => {
    return {
        type: createProductionOrderActions.SET_IS_PRODUCTION_ORDER_CREATED,
        payload,
    };
};

export const setDisableInputs = payload => {
    return {
        type: createProductionOrderActions.SET_DISABLE_INPUTS,
        payload,
    };
};

export const resetCreateProductionOrder = () => {
    return {
        type: createProductionOrderActions.RESET_CREATE_PRODUCTION_ORDER,
    };
};
